import { Colors, Sizes } from '@dermloop/ui/util';
import { ReactNode } from 'react';
import styled from 'styled-components/native';
import Icon from '../../icon/icon';
import Typography from '../../typography/typography';
import { webSpecificValue } from '../../utils';
import { BaseInputProps } from '../base-input-props';

/* eslint-disable-next-line */
export interface InputContainerProps extends BaseInputProps {
  hasValue?: boolean;
  hasFocus?: boolean;
  children: ReactNode;
}

export function InputContainer(props: InputContainerProps) {
  return (
    <StyledInputContainer>
      {(props.hasValue || props.hasFocus) && props.label ? (
        <StyledInputLabel>
          <Typography
            color={
              props.validationState === 'error'
                ? 'danger'
                : props.hasFocus
                ? 'info'
                : 'medium'
            }
            size="small"
          >
            {props.label}
          </Typography>
        </StyledInputLabel>
      ) : null}
      {props.children}
      {props.validationMessage ? (
        <StyledInputErrorContainer>
          <StyledInputErrorContainerIcon>
            <Icon icon="Error" size="parent" />
          </StyledInputErrorContainerIcon>

          <Typography color={'danger'} size={'small'}>
            {props.validationMessage}
          </Typography>
        </StyledInputErrorContainer>
      ) : null}
    </StyledInputContainer>
  );
}

export default InputContainer;

const StyledInputLabel = styled.View`
  background-color: ${Colors.BRAND_WHITE};
  z-index: 10;
  padding: 0px ${Sizes.GENERAL_SPACING / 2}px;
  position: absolute;
  height: ${Sizes.GENERAL_SPACING}px;
  left: ${Sizes.GENERAL_SPACING}px;
  top: -${Sizes.GENERAL_SPACING / 2}px;
`;
const StyledInputContainer = styled.View`
  width: 100%;
  ${webSpecificValue('z-index: unset')}
  background: white;
  margin-top: ${Sizes.GENERAL_SPACING / 2}px;
`;

const StyledInputErrorContainer = styled.View`
  display: flex;
  flex: 0;
  margin: ${Sizes.GENERAL_SPACING / 2}px 0;
  min-height: ${Sizes.GENERAL_SPACING}px;
  align-items: center;
  flex-direction: row;
`;

const StyledInputErrorContainerIcon = styled.View`
  margin-right: ${Sizes.GENERAL_SPACING / 2}px;
  width: ${Sizes.GENERAL_SPACING}px;
`;
