import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsNumber,
  IsOptional,
  ValidateNested,
} from 'class-validator';

/**
 * DTO for requesting difficulty statistics.
 */
export class DifficultyStatisticsDto {
  @Type(() => DifficultyIntervalDto)
  @ValidateNested({ each: true })
  intervals: DifficultyIntervalDto[];

  @IsOptional()
  @IsBoolean()
  countNoDifficulty?: boolean;
}

/**
 * Difficulty interval when requesting difficulty statistics.
 */
export class DifficultyIntervalDto {
  // Minimum difficulty, inclusive. E.g. 0.5 would mean 0.5 and above
  @IsNumber()
  minDifficulty: number;

  // Max difficulty, exclusive. E.g. 0.5 would mean everything below 0.5 is included
  @IsNumber()
  maxDifficulty: number;
}

/**
 * Result for requesting difficulty statistics.
 */
export interface DifficultyStatisticsResDto {
  statistics: DifficultyIntervalStatisticsDto[];
}

/**
 * Statistics for a difficulty interval.
 */
export interface DifficultyIntervalStatisticsDto {
  minDifficulty: number;
  maxDifficulty: number;
  count: number;
}
