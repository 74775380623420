import MultiOptionPicker, {
  Option,
} from '../multi-option-picker/multi-option-picker';

/* eslint-disable-next-line */
export interface OptionPickerProps<T> {
  name?: string;
  description?: string;
  readonly?: boolean;
  selectedValue?: T;
  disabledChoices?: T[];
  datatipOnDisable?: string;
  onOptionSelected: (option?: Option<T>) => void;
  options: Option<T>[];
}

export function OptionPicker<T>(props: OptionPickerProps<T>) {
  return (
    <MultiOptionPicker<T>
      options={props.options}
      selectedValues={[props.selectedValue]}
      name={props.name}
      readonly={props.readonly}
      disabledChoices={props.disabledChoices}
      datatipOnDisable={props.datatipOnDisable}
      onOptionsSelected={(options) => {
        const selection = options.find((o) => o.value !== props.selectedValue);
        props.onOptionSelected(selection);
      }}
    ></MultiOptionPicker>
  );
}

export default OptionPicker;
