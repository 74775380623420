import { Exclude } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { BaseCreateDto, BaseUpdateDto } from '../base.dto';
import { SkinTypeConditionDto } from '../skin-type-condition.dtos';
import { NamedEntityDto } from './named.dto';

export interface SymptomDto extends NamedEntityDto {
  requiresUserInput?: boolean;
  skinTypeConditions?: SkinTypeConditionDto[];
}

export class CreateSymptomDto
  extends BaseCreateDto
  implements Partial<SymptomDto>
{
  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  name_da?: string;

  @IsBoolean()
  requiresUserInput: boolean;

  @IsUUID('all', { each: true })
  @ArrayNotEmpty()
  skinTypeConditionIds: string[];

  @Exclude()
  skinTypeConditions?: never;
}

export class UpdateSymptomDto
  extends BaseUpdateDto
  implements Partial<SymptomDto>
{
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  name_da?: string;

  @IsOptional()
  @IsBoolean()
  requiresUserInput?: boolean;

  @IsOptional()
  @IsUUID('all', { each: true })
  @ArrayNotEmpty()
  skinTypeConditionIds?: string[];

  @Exclude()
  skinTypeConditions?: never;
}
