import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Matches,
  ValidateNested,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { SkinTypeDto } from '../clinical-details';
import { GenderDto } from '../clinical-details/gender.dto';
import { LatestLesionInfoDto, LesionDto } from '../lesion-info/lesion.dtos';
import { UserDto } from '../user.dto';
import { PatientDetailsDto } from './patient-details.dtos';

export class UpdateHealthRecordConsentDto {
  @IsOptional()
  @IsBoolean()
  studyParticipationConsent?: boolean;

  @IsOptional()
  @IsBoolean()
  applicationImprovementsParticipationConsent?: boolean;

  @IsOptional()
  @IsBoolean()
  anonymisationForEducationalPurposesConsent?: boolean;

  @IsOptional()
  @IsBoolean()
  furtherQuestionsConsent?: boolean;
}

class HealthRecordConsentDto extends UpdateHealthRecordConsentDto {
  @IsOptional()
  @IsString()
  consentsUpdatedDate?: string | Date;
}

export class CreateHealthRecordDto extends HealthRecordConsentDto {
  @IsNotEmpty()
  @IsString()
  @Matches(/^\d{10}$/, { message: 'Not a valid CPR number' })
  ssn: string;

  @IsNotEmpty()
  @IsString()
  genderId: string;
}

export class UpdateHealthRecordDto {}

export class SearchHealthRecordRequestDto {
  @IsString()
  @IsOptional()
  @Matches(/^\d{10}$/, { message: 'Not a valid CPR number' })
  ssn?: string;

  @IsNumber()
  @IsOptional()
  take?: number;

  @IsBoolean()
  @IsOptional()
  ownOnly?: boolean;

  @IsBoolean()
  @IsOptional()
  complete?: boolean;

  // @IsString()
  // @IsNotEmpty()
  // medicalUnitId: string;
}

export class HealthRecordDto extends HealthRecordConsentDto implements BaseDto {
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;
  created?: string | Date;
  updated?: string | Date;
  deleted?: string | Date;

  @IsString()
  @IsNotEmpty()
  @Matches(/^\d{10}$/, { message: 'SSN does not match!' })
  ssn: string;

  @IsString()
  @IsNotEmpty()
  jurisdictionalGroupId: string;

  @IsBoolean()
  @IsOptional()
  complete?: boolean;

  @IsOptional()
  @IsNotEmpty()
  @IsArray()
  lesions?: LesionDto[];

  @IsNotEmpty()
  @IsString()
  gender: GenderDto;

  @IsOptional()
  @ValidateNested()
  latestPatientDetails?: PatientDetailsDto;
}

export class MinimalHealthRecordDto
  extends HealthRecordConsentDto
  implements BaseDto
{
  id: string;

  @IsString()
  @IsNotEmpty()
  @Matches(/^\d{10}$/, { message: 'SSN does not match!' })
  ssn: string;

  @IsOptional()
  @IsNotEmpty()
  @IsArray()
  lesions?: LatestLesionInfoDto[];

  @IsNotEmpty()
  @IsString()
  gender: GenderDto;

  @IsOptional()
  latestSkinType?: SkinTypeDto;

  // latest PatientDetails in DB
  patientDetails?: PatientDetailsDto;
}
