import { Typography } from '@dermloop/ui/components';
import { Colors } from '@dermloop/ui/util';
import { MouseEventHandler, useRef } from 'react';
import styled from 'styled-components';

const KNOB_SIZE = 50;

export interface KnobProperties {
  /**
   * Rotation must be a number between 0-360
   */
  rotation: number;
  onRotationChange: (rotation: number) => void;
}

export default function Knob(props: KnobProperties) {
  const knobRef = useRef<HTMLDivElement>();
  const startDrag: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (e.target instanceof HTMLDivElement) {
      e.target.style.cursor = 'grabbing';
    }
    const moveHandler = (e: MouseEvent) => {
      const degrees = getDegreesBetweenPointAndDiv(
        e.clientX,
        e.clientY,
        knobRef.current
      );
      props.onRotationChange(degrees);
    };
    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', (e) => {
      if (e.target instanceof HTMLDivElement) {
        e.target.style.cursor = 'grab';
      }
      document.removeEventListener('mousemove', moveHandler);
    });
  };

  return (
    <StyledKnobContainer>
      <StyledKnobLabel>
        <Typography size="small">{`${Math.round(
          props.rotation
        ).toString()}°`}</Typography>
      </StyledKnobLabel>
      <StyledKnob ref={knobRef} rotation={props.rotation}>
        <StyledKnobHandle onMouseDown={startDrag} />
      </StyledKnob>
    </StyledKnobContainer>
  );
}

const getDegreesBetweenPointAndDiv = (
  pX: number,
  pY: number,
  relativeToDiv: HTMLDivElement
) => {
  const {
    width: knobWidth,
    height: knobHeight,
    x: knobX,
    y: knobY,
  } = relativeToDiv.getBoundingClientRect();
  const knobCenterX = knobX + knobWidth / 2;
  const knobCenterY = knobY + knobHeight / 2;

  let degrees =
    (Math.atan2(knobCenterY - pY, knobCenterX - pX) * 180) / Math.PI - 90;
  if (degrees < 0) {
    degrees = 360 + degrees;
  }
  return Math.min(Math.max(0, degrees), 360);
};

const StyledKnob = styled.div<{ rotation: number }>`
  transform: rotate(${(props) => props.rotation}deg);
  background: white;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;
const StyledKnobLabel = styled.div`
  z-index: 10;
  user-select: none;
`;

const StyledKnobContainer = styled.div`
  position: relative;
  display: flex;
  width: ${KNOB_SIZE}px;
  height: ${KNOB_SIZE}px;
  justify-content: center;
  align-items: center;
`;

const StyledKnobHandle = styled.div`
  top: 0px;
  left: ${KNOB_SIZE / 2}px;
  transform: translate(-50%, -50%);
  width: ${KNOB_SIZE / 3}px;
  height: ${KNOB_SIZE / 3}px;
  background: ${Colors.BRAND_SUCCESS};
  cursor: grab;
  position: absolute;
  border-radius: ${KNOB_SIZE / 3}px;
`;
