import { HotspotSize } from '@dermloop/ui/components';
import { Html } from '@react-three/drei';
import Hotspot from '../hotspot/hotspot';

export function PositionedHotspot(props: {
  size?: HotspotSize;
  point: { x: number; y: number; z: number };
  onClick: (identifier: string) => void;
  onHover: (identifier?: string) => void;
  visible: boolean;
  active: boolean;
  removed: boolean;
  identifier: string;
}) {
  return (
    <Html
      name={props.identifier}
      position={[props.point.x, props.point.y, props.point.z]}
      center
    >
      <div>
        <Hotspot
          size={props.size}
          onClick={() => props.onClick(props.identifier)}
          onHover={() => props.onHover(props.identifier)}
          onHoverEnd={() => {
            props.onHover(null);
          }}
          removed={props.removed}
          active={props.active}
          occluded={!props.visible}
        />
      </div>
    </Html>
  );
}
