import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { UserDto } from '../user.dto';
import { PathologicalAssessmentDto } from './pathological-assessment.dtos';

export class CreateBiopsyDto {
  @IsOptional()
  @IsNotEmpty()
  @IsNumber()
  widthMm?: number;

  @IsOptional()
  @IsNotEmpty()
  @IsNumber()
  heightMm?: number;

  @IsNotEmpty()
  @IsString()
  biopsyTypeId: string;

  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;
}

export class UpdateBiopsyDto {
  @IsOptional()
  @IsNumber()
  @IsNotEmpty()
  widthMm: number;

  @IsOptional()
  @IsNumber()
  @IsNotEmpty()
  heightMm: number;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  biopsyTypeId: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  reference: string;

  @IsString()
  biopsyStyle: 'full' | 'partial';

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;
}

export class BiopsyDto implements BaseDto {
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;
  created?: string;
  updated?: string;
  deleted?: string;

  @IsNotEmpty()
  @IsNumber()
  widthMm: number;

  @IsNotEmpty()
  @IsNumber()
  heightMm: number;

  @IsNotEmpty()
  @IsString()
  biopsyTypeId: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  reference: string;

  @IsOptional()
  @IsNotEmpty()
  @ValidateNested()
  latestPathologicalAssessment?: PathologicalAssessmentDto;

  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;

  @IsString()
  biopsyStyle: 'full' | 'partial';
}
