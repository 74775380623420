import { ThumbnailProcessingState } from '@dermloop/static-values';
import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { ModelViewerLocation } from '../../model-viewer-location.dto';
import { SearchIndexMetadataDto } from './index-metadata.dto';

/**
 * DTO for indexing lesions.
 */
export class LesionIndexDto {
  @Type(() => LesionIndexDataDto)
  @ValidateNested({ each: true })
  lesions: LesionIndexDataDto[];

  @Type(() => SearchIndexMetadataDto)
  @IsOptional()
  @ValidateNested()
  metadata?: SearchIndexMetadataDto;
}

/**
 * DTO for deleting an indexed lesions.
 */
export class LesionIndexDeleteDto {
  @IsUUID()
  lesionId: string;

  // If no lesion triage id provided, all triages for lesion will be deleted
  @IsOptional()
  @IsUUID()
  lesionTriageId?: string;
}

export enum LesionIndexAccurateDiagnosisType {
  Clinical = 'clinical',
  Tele = 'tele',
  Pathology = 'pathology',
}

export enum LesionIndexActionType {
  ReferralPhysical = 'referralPhysical',
  ReferralTele = 'referralTele',
  ReferralTeleUrgent = 'referralTeleUrgent',
  FollowUp = 'followUp',
  Dismissal = 'dismissal',
  Biopsy = 'biopsy',
  PlannedBiopsy = 'plannedBiopsy',
  NewImageRegistration = 'newImageRegistration',
  NoAction = 'noAction',
}

/**
 * DTO representing a lesion for indexing.
 */
export class LesionIndexDataDto {
  /**
   * General lesion information, always being the latest information available.
   */
  @IsUUID()
  lesionId: string;

  @IsOptional()
  @IsUUID()
  skinTypeId?: string;

  @IsNotEmpty()
  @IsString()
  ssn: string;

  @ValidateIf((o) => o.accurateDiagnosisId)
  @IsEnum(LesionIndexAccurateDiagnosisType)
  accurateDiagnosisType?: LesionIndexAccurateDiagnosisType;

  @Type(() => Date)
  @ValidateIf((o) => o.accurateDiagnosisId)
  @IsDate()
  accurateDiagnosisDate?: Date;

  @ValidateIf((o) => o.accurateSubDiagnosisId)
  @IsUUID()
  accurateDiagnosisId?: string;

  @ValidateIf((o) => o.accurateSubSubDiagnosisId)
  @IsUUID()
  accurateSubDiagnosisId?: string;

  @ValidateIf((o) => o.accurateSubSubSubDiagnosisId)
  @IsUUID()
  accurateSubSubDiagnosisId?: string;

  @IsOptional()
  @IsUUID()
  accurateSubSubSubDiagnosisId?: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  accurateDiagnosisSeverity?: number;

  // Oldest clinical image id for triage
  @IsOptional()
  @IsUUID()
  clinicalImageId?: string;

  @IsOptional()
  @IsEnum(ThumbnailProcessingState)
  clinicalImageThumbnailProcessingState?: ThumbnailProcessingState;

  // Focal point x of latest clinical image
  @IsOptional()
  @IsNumber()
  focalPointX?: number;

  // Focal point y of latest clinical image
  @IsOptional()
  @IsNumber()
  focalPointY?: number;

  // Oldest clinical image id for triage
  @IsOptional()
  @IsUUID()
  dermoscopicImageId?: string;

  @Type(() => Date)
  @IsDate()
  updatedDate: Date;

  @Type(() => Date)
  @IsDate()
  createdDate: Date;

  @IsUUID()
  jurisdictionalGroupId: string;

  @IsUUID()
  healthRecordId: string;

  @IsNumber()
  @Min(0)
  lesionOrdering: number;

  @Type(() => ModelViewerLocation)
  @IsOptional()
  @ValidateNested()
  preciseLocation?: ModelViewerLocation;

  @IsOptional()
  @IsUUID()
  genderId?: string;

  @IsOptional()
  @IsNumber()
  patientAge?: number;

  // @IsOptional()
  // @IsBoolean()
  // patientContacted?: boolean;

  // Must be set, as this is the elasticsearch document id
  @IsUUID()
  lesionTriageId: string;

  /**
   * User / medical unit specific information
   */
  @IsUUID()
  userId: string;

  @IsUUID()
  userMedicalUnitId: string;

  @IsOptional()
  @IsUUID()
  userOccupationId?: string;

  @IsOptional()
  @IsUUID()
  userSpecialityId?: string;

  @ValidateIf((o) => !!o.latestActionId)
  @IsEnum(LesionIndexActionType)
  userActionType?: LesionIndexActionType;

  @IsOptional()
  @IsUUID()
  userActionBiopsyTypeId?: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  userActionFollowUpMonths?: number;

  @IsOptional()
  @IsUUID()
  userActionId?: string;

  @IsOptional()
  @IsBoolean()
  userActionResolved?: boolean;

  @Type(() => Date)
  @IsDate()
  userActionDate: Date;

  @ValidateIf((o) => o.userSubDiagnosisId)
  @IsUUID()
  userDiagnosisId?: string;

  @ValidateIf((o) => o.userSubSubDiagnosisId)
  @IsUUID()
  userSubDiagnosisId?: string;

  @ValidateIf((o) => o.userSubSubSubDiagnosisId)
  @IsUUID()
  userSubSubDiagnosisId?: string;

  @IsOptional()
  @IsUUID()
  userSubSubSubDiagnosisId?: string;

  @IsOptional()
  @IsBoolean()
  userDiagnosisCorrect?: boolean;

  @IsOptional()
  @IsUUID()
  userDiagnosisDifficultyId?: string;

  // Latest action performed in a medical unit, for filtering
  @IsBoolean()
  isLatestMedicalUnitAction: boolean;

  // Latest action performed by the user, for filtering
  @IsBoolean()
  isLatestUserAction: boolean;

  // @IsOptional()
  // @IsUUID()
  // userSupervisesTriageId?: string;

  // @IsOptional()
  // @IsBoolean()
  // userNeedsSupervision?: boolean;

  // @IsOptional()
  // @IsBoolean()
  // userSupervisionFeedbackReviewed?: boolean;
}

/**
 * Given a lesion index data dto, returns the most specific user diagnosis id.
 * @param lesion
 * @returns
 */
export const getMostSpecificUserDiagnosisId = (
  lesion: LesionIndexDataDto
): string => {
  return (
    lesion.userSubSubSubDiagnosisId ||
    lesion.userSubSubDiagnosisId ||
    lesion.userSubDiagnosisId ||
    lesion.userDiagnosisId
  );
};

/**
 * Given a lesion index data dto, returns the most specific accurate diagnosis id.
 * @param lesion
 * @returns
 */
export const getMostSpecificAccurateDiagnosisId = (
  lesion: LesionIndexDataDto
): string => {
  return (
    lesion.accurateSubSubSubDiagnosisId ||
    lesion.accurateSubSubDiagnosisId ||
    lesion.accurateSubDiagnosisId ||
    lesion.accurateDiagnosisId
  );
};
