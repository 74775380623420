import { Colors, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import Typography from '../typography/typography';

export interface BadgeProps {
  /**
   * Badge text content
   */
  children: string;
  /**
   * Background of badge
   * defaults to success
   */
  color?: 'success' | 'danger' | 'warning';
}

const StyledBadge = styled.View<{ background: string }>`
  position: absolute;
  z-index: 9999;
  top: ${Sizes.GENERAL_SPACING / 2}px;
  left: 0px;
  border-top-right-radius: ${Sizes.BORDER_RADIUS}px;
  border-bottom-right-radius: ${Sizes.BORDER_RADIUS}px;
  padding: 1px 5px 1px 5px;
  background: ${(props) => props.background};
`;

/**
 * Badge assumes that it is placed inside a positioned element. Otherwise its position will have undefined behaviour.
 */
export function Badge(props: BadgeProps) {
  return (
    <StyledBadge
      background={
        props.color === 'danger'
          ? Colors.BRAND_WARNING
          : props.color === 'warning'
          ? Colors.BRAND_MANDATORY
          : Colors.BRAND_SUCCESS
      }
    >
      <Typography color="light" size="small">
        {props.children}
      </Typography>
    </StyledBadge>
  );
}

export default Badge;
