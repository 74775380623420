import { DAHTLesionDto } from '@dermloop/api-dtos';
import {
  ClickableInfoDisplay,
  FormControlGroup,
  InfoList,
} from '@dermloop/ui/components';
import { formatDuration } from 'date-fns';
import { useState } from 'react';

export interface LesionDetailProps {
  lesion: DAHTLesionDto;
}

/**
 *
 * @param name
 * @param value
 */
function createInfoList(name: string, value: string): InfoList {
  if (!value) return null;
  return {
    title: {
      text: name,
    },
    subtitle: {
      text: (name ? '⦁ ' : '') + value,
      color: 'success',
    },
    separator: { text: '' },
  };
}

/**
 *
 * @param props
 */
export function LesionDetails(props: LesionDetailProps) {
  const [open, setOpen] = useState(false);

  const fieldValues: [string, string][] = [
    [
      'Lesion age',
      props.lesion.lesionAgeLessThanMonths
        ? props.lesion.lesionAgeLessThanMonths >= 121
          ? 'More than ten years old'
          : `Less than ${formatDuration(
              {
                years: Math.floor(props.lesion.lesionAgeLessThanMonths / 12),
                months: props.lesion.lesionAgeLessThanMonths % 12,
              },
              { format: ['years', 'months'] }
            )} old`
        : null,
    ],
    [
      'Lesion size',
      props.lesion.sizeX && props.lesion.sizeY
        ? `${props.lesion.sizeX}mm x ${props.lesion.sizeY}mm`
        : null,
    ],
    ['Recurrence', props.lesion.recurrence ? 'Yes' : 'No'],
    [
      'Family history of melanoma',
      props.lesion.familyHistoryOfMelanoma ? 'Yes' : 'No',
    ],
    [
      'Sunburn within the last six months',
      props.lesion.sunExposure ? 'Yes' : 'No',
    ],
    // ['Ulceration', props.lesion.ulceration ? 'Yes' : 'No'],
    [
      'Pregnant within the last six months',
      props.lesion.recentPregnancy ? 'Yes' : 'No',
    ],
    [
      'Changes',
      props.lesion.appearanceChanges?.map((c) => c.name)?.join(', ') ||
        'No changes',
    ],
    [
      'Symptoms',
      props.lesion.symptoms?.map((c) => c.name)?.join(', ') || 'No symptoms',
    ],
    [
      'Treatments',
      props.lesion.treatments?.map((c) => c.name)?.join(', ') ||
        'No former treatments',
    ],
    ['Former melanoma', props.lesion.formerMelanoma ? 'Yes' : 'No'],
    [
      '',
      props.lesion.formerMelanoma ? props.lesion.formerMelanomaDetails : null,
    ],
  ];

  return (
    <FormControlGroup
      shadow
      showCollapseButton
      onCollapse={() => setOpen(false)}
      open={open}
      onPress={() => setOpen(true)}
      title={`Lesion ${props.lesion.lesionIdentifier} info`}
      previewValue={'Expand this section for additional information'}
    >
      <ClickableInfoDisplay
        multilineDescription
        infoList={fieldValues
          .map(([name, value]) => createInfoList(name, value))
          .filter((il) => il)}
      />
    </FormControlGroup>
  );
}
