import React from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import Checkbox from '../checkbox/checkbox';
import { Colors, GENERAL_SPACING } from '@dermloop/ui/util';
import Button from '../button/button';

/**
 * Creates a vertical list of elements that are selectable.
 * Selected elements are represented with a left border.
 * Elements can either be single-select or multi-select.
 */

/* eslint-disable-next-line */
export interface MultiSelectListProps<T> {
  enableReset?: boolean;
  // List of entities that are selectable
  selectStyle?: 'checkbox' | 'indicator';
  options: SelectListOption<T>[];
  selectedValues: T[];

  // selectedIds is a list of the ids currently selected on click
  // Function to be called when option is selected
  // If multiSelect is false, is given a list containing one element
  onSelect: (selectedOptions: SelectListOption<T>[]) => void;

  // Optional margin to give to select list
  margin?: string;
}
export type SelectListOption<T> = {
  value: T;
  name: string;
};

const SELECTED_LEFT_BORDER_WITH = 5;
const ELEMENT_LEFT_PADDING = 15;

const SelectListStyle = styled.View<{ margin?: string; padded?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.BRAND_WHITE};
  border: 1px solid ${Colors.BRAND_TERTIARY};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')};
  ${(props) => (props.padded ? `padding: ${GENERAL_SPACING}px;` : '')};
`;

const SelectElementStyle = styled.View<{
  selected: boolean;
  displayTopBorder: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  padding-left: ${(props) =>
    props.selected
      ? ELEMENT_LEFT_PADDING - SELECTED_LEFT_BORDER_WITH
      : ELEMENT_LEFT_PADDING}px;
  background-color: ${Colors.BRAND_WHITE};
  p {
    color: ${Colors.BRAND_PRIMARY};
  }

  /* Selected item border */
  ${(props) =>
    props.selected
      ? `
  border-left-color: ${Colors.BRAND_SUCCESS};
  border-left-width: ${SELECTED_LEFT_BORDER_WITH}px;
  border-left-style: solid;
  `
      : ''}

  /* Top border */
  ${(props) =>
    props.displayTopBorder
      ? `
  border-top-color: ${Colors.BRAND_TERTIARY};
  border-top-width: 1px;
  border-top-style: solid;`
      : ''}
`;

const StyledCheckBoxStyle = styled.View`
  padding: ${0.5 * GENERAL_SPACING}px ${0}px;
`;

const StyledLine = styled.View`
  width: 100%;
  height: 1px;
  background: ${Colors.BRAND_GRAY_MEDIUM};
`;

const StyledFooter = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${GENERAL_SPACING}px;
  padding: 0;
`;

const SelectElementTextStyle = styled.Text`
  color: ${Colors.BRAND_SUCCESS};
  font-size: 12px;
  font-weight: 400;
`;

export function MultiSelectList<T>(props: MultiSelectListProps<T>) {
  // If no pre-selected ids and there is a default id, set selection to this
  // if (!props.selectedIds?.length && props.defaultIdIfNoSelections)
  //   selectedIds = [props.defaultIdIfNoSelections];

  const onPress = (option: SelectListOption<T>) => {
    const selectedValues = props.options?.filter(
      (o) =>
        (option.value === o.value ||
          (props.selectedValues || []).includes(o.value)) &&
        !(
          option.value === o.value &&
          (props.selectedValues || []).includes(o.value)
        )
    );

    // Call provided select function
    props.onSelect(selectedValues);
  };

  return (
    <SelectListStyle
      margin={props.margin}
      padded={props.selectStyle === 'checkbox'}
    >
      {props.options?.map((option, index) =>
        props.selectStyle === 'checkbox' ? (
          <StyledCheckBoxStyle>
            <Checkbox
              id={option.value?.toString()}
              text={option.name}
              selected={props.selectedValues?.includes(option.value)}
              onPress={() => onPress(option)}
            />
          </StyledCheckBoxStyle>
        ) : (
          <Pressable
            key={option.value?.toString()}
            onPress={() => onPress(option)}
            pointerEvents={'auto'}
          >
            <SelectElementStyle
              selected={props.selectedValues?.includes(option?.value)}
              displayTopBorder={index !== 0}
            >
              <SelectElementTextStyle>{option.name}</SelectElementTextStyle>
            </SelectElementStyle>
          </Pressable>
        )
      )}

      {props.enableReset ? (
        <StyledFooter>
          <StyledLine />
          <Button
            color="tertiary"
            type="clear"
            onPress={() => {
              props.onSelect([]);
            }}
          >
            Reset
          </Button>
        </StyledFooter>
      ) : null}
    </SelectListStyle>
  );
}

export default MultiSelectList;
