import {
  AnnotationListDto,
  AnnotationListResultDto,
  CreateAnnotationDto,
  DermoscopicCriteriaDto,
  Page,
  TrainingLesionAnnotationOverviewDto,
} from '@dermloop/api-dtos';
import {
  AnnotationFilterEasy,
  AnnotationFilterStatus,
} from '@dermloop/static-values';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AnnotationSlice = {
  // Data for annotation overview
  annotationListPage: Page<AnnotationListResultDto>;
  // Boolean stating if the current training lesions are being fetched in the annotation overview
  fetchingListPage?: boolean;
  // The current filtering values used to select training lesions
  filterValues: AnnotationListDto;
  // The current index in the filtered array of training lesions that an annotation should be created for
  currentAnnotationIndex?: number;
  // List of training lesion ids that are currently being annotated
  currentAnnotationTrainingLesionIds?: string[];
  fetchingTrainingLesionIds?: boolean;
  // Training lesion annotation dto
  trainingLesionAnnotations?: TrainingLesionAnnotationOverviewDto;
  fetchingTrainingLesionAnnotations?: boolean;
  // All dermoscopic criteria
  dermoscopicCriteria?: DermoscopicCriteriaDto[];
  fetchingDermoscopicCriteria?: boolean;
  // Boolean specifying if an annotation is currently being saved
  savingAnnotation?: boolean;
  doingApproval?: boolean;
  doingUnapproval?: boolean;
};

export const annotationDefaultPageSize = 20;
const initialState: AnnotationSlice = {
  annotationListPage: null,
  filterValues: {
    skip: 0,
    // selectedEasy: 'all',
    selectedStatus: 'all',
    take: annotationDefaultPageSize,
    selectedDifficulty: {
      min: 0,
      max: 1,
    },
    selectedRaters: {
      min: 0,
      max: 1000,
    },
  },
};

const slice = createSlice({
  name: 'annotation',
  initialState,
  reducers: {
    // Fetch training lesion overview list for annotations
    fetchAnnotationList: (
      state,
      _: PayloadAction<{ dto: AnnotationListDto }>
    ) => ({
      ...state,
      fetchingListPage: true,
    }),
    // Fetch all ids of training lesions for current filtering
    fetchTrainingLesionIds: (
      state,
      _: PayloadAction<{ dto: AnnotationListDto }>
    ) => ({
      ...state,
      fetchingTrainingLesionIds: true,
    }),
    // Fetch detailed annotation information for a training lesion id
    fetchTrainingLesionAnnotations: (
      state,
      action: PayloadAction<{ trainingLesionId: string }>
    ) => ({
      ...state,
      fetchingTrainingLesionAnnotations: true,
    }),
    fetchDermoscopicCriteria: (state, action: PayloadAction) => ({
      ...state,
      fetchingDermoscopicCriteria: true,
    }),
    saveAnnotation: (
      state,
      _: PayloadAction<{ dto: CreateAnnotationDto }>
    ) => ({
      ...state,
      savingAnnotation: true,
    }),
    approveAnnotation: (state, _: PayloadAction<{ annotationId: string }>) => ({
      ...state,
      doingApproval: true,
    }),
    unapproveAnnotation: (
      state,
      _: PayloadAction<{ annotationId: string }>
    ) => ({
      ...state,
      doingUnapproval: true,
    }),
    update: (
      state,
      action: PayloadAction<{ update: Partial<AnnotationSlice> }>
    ) => {
      return { ...state, ...action.payload?.update };
    },
  },
});

export const annotationActions = slice.actions;
export const annotationReducer = slice.reducer;

export interface AnnotationFilterValues {
  selectedFilterStatus?: AnnotationFilterStatus;
  selectedDiagnosisIds?: string[];
  selectedLocationIds?: string[];
  selectedRaters?: RangeFilterValue;
  selectedDifficulty?: RangeFilterValue;
  selectedFilterEasy?: AnnotationFilterEasy;
}

export type AnnotationState = {
  annotation: {
    filterValues: AnnotationFilterValues;
    currentAnnotationIndex?: number;
    currentAnnotationTrainingLesionIds?: string[];
  };
};

interface RangeFilterValue {
  min: number;
  max: number;
}
