import styled from 'styled-components';

/* eslint-disable-next-line */
export interface SpacerProps {}

export const Spacer = styled.div<{ height?: 20 | 10 }>`
  width: 1rem;
  height: ${(props) => (props.height ? props.height + 'px' : 1 + 'rem')};
`;

export default Spacer;
