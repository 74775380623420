import { Colors, Sizes } from '@dermloop/ui/util';
import { ReactNode } from 'react';
import styled from 'styled-components';

/**
 * A section for the side bar, with optional header and content.
 */

const DEFAULT_HEADER_HEIGHT = 60;

export interface SidebarSectionProps {
  title?: string;
  children: ReactNode;
}

export function SidebarSection(props: SidebarSectionProps) {
  return (
    <StyledContainer>
      {props.title ? <StyledHeader>{props.title}</StyledHeader> : null}
      <StyledContentContainer>{props.children}</StyledContentContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 100%;
  background-color: ${Colors.BRAND_WHITE};
  box-shadow: 0px 2px 4px ${Colors.MEDIUM_SHADOW_BACKGROUND};
`;

const StyledHeader = styled.div`
  padding: ${Sizes.GENERAL_SPACING}px;
  min-height: ${DEFAULT_HEADER_HEIGHT - Sizes.DOUBLE_GENERAL_SPACING}px;
  border-bottom: 1px solid ${Colors.BRAND_TERTIARY};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${Sizes.SUB_HEADING_FONT_SIZE}px;
  font-weight: ${Sizes.MEDIUM_WEIGHT};
`;

const StyledContentContainer = styled.div`
  padding: ${Sizes.GENERAL_SPACING}px;
`;
