import { Platform } from 'react-native';

/**
 * Internal helper to return value based on current platform
 */
function platformSpecificValueFunc<T>(props: {
  webValue?: T;
  nativeValue?: T;
}) {
  return Platform.OS === 'web' ? props.webValue : props.nativeValue;
}

/**
 * Returns only value if current platform is web
 */
export const webSpecificValue = <T>(webValue: T) =>
  platformSpecificValueFunc({ webValue });

/**
 * Returns only value if current platform is native
 */
export const nativeSpecificValue = <T>(nativeValue: T) =>
  platformSpecificValueFunc({ nativeValue });

/**
 * Platform Specific Value
 * Gets a value based on which platform it is invoked from. Can be used for various purposes including styled components
 * @param webValue value to be used if current platform is web
 * @param nativeValue value to be used if current platform is native
 * @returns the correct value for the platform
 */
export const platformSpecificValue = <T>(webValue: T, nativeValue: T) =>
  platformSpecificValueFunc({ nativeValue, webValue });
