import { Button, useAlert } from '@dermloop/ui/components';
import {
  Colors,
  DESKTOP_HEADER_HEIGHT,
  GENERAL_SPACING,
} from '@dermloop/ui/util';
import {
  ErrorPage,
  Header,
  LoadingSpinner,
  ModelViewer,
  OpenSeaDragonViewer,
} from '@dermloop/ui/web/components';
import { minutesToMilliseconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import SessionSidebar, {
  Visual,
} from '../../components/session-sidebar/session-sidebar';
import { dermloopApi } from '../../store/api';

/* eslint-disable-next-line */
export interface SessionPageProps {}

const StyledSessionPage = styled.div`
  display: flex;
  flex: 1;
  padding-top: ${DESKTOP_HEADER_HEIGHT}px;
`;

const StyledSessionPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const StyledContent = styled.div`
  position: fixed;
  right: 400px;
  top: ${DESKTOP_HEADER_HEIGHT}px;
  left: 0;
  bottom: 0;
  display: flex;
`;

const StyledSlideToggler = styled.div`
  position: absolute;
  bottom: ${GENERAL_SPACING}px;
  right: ${GENERAL_SPACING}px;
`;

const StyledVisualContainer = styled.div`
  position: absolute;
  background: ${Colors.BRAND_GRAY_MEDIUM};
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
`;

/**
 *
 * @param props
 */
export function SessionPage(props: SessionPageProps) {
  const { lesionIdentifier } = useParams<{ lesionIdentifier?: string }>();
  const [activeVisual, setActiveVisual] = useState<Visual>('clinical');
  const [alternativeStain, setAlternativeStain] = useState<boolean>(false);
  const [slideRotation, setSlideRotation] = useState(0);

  const history = useHistory();
  const {
    data: session,
    isLoading: sessionLoading,
    error,
  } = dermloopApi.useGetDAHTSessionQuery(undefined, {
    // Require session to update after some time to avoid cached assessments have expired urls
    pollingInterval: minutesToMilliseconds(15),
  });
  const alert = useAlert();

  const currentAssessment = lesionIdentifier
    ? session?.assessments.find(
        (a) => a.lesion.lesionIdentifier === lesionIdentifier
      )
    : session?.assessments.find((a) => !a.assessedDate);
  useEffect(() => {
    setActiveVisual('clinical');
    setAlternativeStain(false);
    setSlideRotation(0);
  }, [currentAssessment?.id]);

  useEffect(() => {
    //If everything is assessed and a lesion is not specified
    if (
      session?.assessments?.every((a) => a.assessedDate) &&
      !lesionIdentifier
    ) {
      history.push('/overview');
    }
  }, [history, session]);

  if (!currentAssessment || !session) return <LoadingSpinner />;
  if (!currentAssessment.lesion)
    return <ErrorPage message="No lesion on assesment" />;

  const slideUrl = `https://slides.dermloop.io/he/he${currentAssessment.lesion.lesionIdentifier}.dzi`;
  const alternativeSlideUrl = `https://slides.dermloop.io/ih/ih${currentAssessment.lesion.lesionIdentifier}.dzi`;

  return (
    <StyledSessionPage>
      <Header
        title="DAHT"
        content={
          <Button
            onPress={() => {
              history.goBack();
            }}
            type="outline"
            color="secondary"
            display="inline-narrow"
          >
            Back
          </Button>
        }
      ></Header>
      <StyledSessionPageContainer>
        <StyledContent>
          <StyledVisualContainer>
            {activeVisual === 'clinical' ? (
              <OpenSeaDragonViewer
                imageId={'clinical'}
                imageUrl={currentAssessment.lesion.clinicalImageUrl}
                focalPointX={currentAssessment.lesion.clinicalImageFocalPointX}
                focalPointY={currentAssessment.lesion.clinicalImageFocalPointY}
                hasOutline={true}
                showOutline={true}
                fitPositionedContainer
              />
            ) : null}
            {activeVisual === 'dermoscopy' ? (
              <OpenSeaDragonViewer
                imageId={'dermoscopy'}
                imageUrl={currentAssessment.lesion.dermoscopicImageUrl}
                focalPointX={undefined}
                focalPointY={undefined}
                hasOutline={false}
                showOutline={false}
                fitPositionedContainer
              />
            ) : null}

            {activeVisual === 'slide' ? (
              <OpenSeaDragonViewer
                imageId={'alt-slide'}
                imageUrl={alternativeStain ? alternativeSlideUrl : slideUrl}
                focalPointX={undefined}
                focalPointY={undefined}
                enableRotation
                rotation={slideRotation}
                onRotationChange={setSlideRotation}
                gammaCorrectionValue={0.5}
                hasOutline={false}
                showOutline={false}
                fitPositionedContainer
              />
            ) : null}

            {activeVisual === 'location' ? (
              <ModelViewer
                gender={
                  currentAssessment.lesion?.gender.name.toLowerCase() ===
                  'female'
                    ? 'female'
                    : 'male'
                }
                center={currentAssessment.lesion.preciseLocation}
                disableNavigationByPress
                hotspots={[
                  {
                    point: currentAssessment.lesion.preciseLocation,
                    removed: false,
                    identifier: 'location',
                    active: true,
                  },
                ]}
                onHotspotClick={() => {}}
                onHotspotCreated={() => {}}
                onHotspotHover={() => {}}
              />
            ) : null}
            {activeVisual === 'slide' ? (
              <StyledSlideToggler>
                <Button
                  display="block"
                  type="solid"
                  color="secondary"
                  onPress={() => {
                    if (currentAssessment.lesion.hasIhSlide) {
                      setAlternativeStain((s) => !s);
                    } else {
                      alert.display({
                        title: 'Sorry',
                        content:
                          'No alternative stain is available for this lesion.',
                      });
                    }
                  }}
                >
                  Show alternative stain
                </Button>
              </StyledSlideToggler>
            ) : null}
          </StyledVisualContainer>
        </StyledContent>
        <SessionSidebar
          disableNextNavigation={!!lesionIdentifier}
          activeVisual={activeVisual}
          onAssessmentSaved={() => {
            if (lesionIdentifier) {
              toast(`Succesfully saved lesion #${lesionIdentifier}`, {
                hideProgressBar: true,
                type: 'info',
                position: 'bottom-right',
              });
              history.push('/overview');
            }
          }}
          onChangeActiveVisual={setActiveVisual}
          session={session}
          currentAssessment={currentAssessment}
        />
      </StyledSessionPageContainer>
    </StyledSessionPage>
  );
}
export default SessionPage;
