import { IsUUID, ValidateIf } from 'class-validator';

/**
 * DTO for determining if a session has access.
 */
export class SessionAccessDto {
  // Is the user of the session in the jurisdictional group
  @ValidateIf((o) => !o.medicalUnitId && !o.userId)
  @IsUUID()
  jurisdictionalGroupId?: string;

  // Is the user of the session in the medical unit id
  @ValidateIf((o) => !o.jurisdictionalGroupId && !o.userId)
  @IsUUID()
  medicalUnitId?: string;

  // Does the session belong to this user
  @ValidateIf((o) => !o.jurisdictionalGroupId && !o.medicalUnitId)
  @IsUUID()
  userId?: string;
}

export interface SessionAccessResponseDto {
  hasAccess: boolean;
}
