import {
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { TeleDifferentialDiagnosisMutationDto } from '../diagnoses';
import { UserDto } from '../user.dto';
import { ClinicalManagementActionDto } from './clinical-management-action.dto';
import { ImageQualityRatingDto } from './image-quality-rating.dto';

export class CreateTeledermatologicalAssessmentDto {
  @IsOptional()
  @IsUUID('all')
  id?: string;

  created?: string;

  @IsNotEmpty()
  @IsString()
  referralId: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  diagnosisId?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  difficultyId?: string;

  @IsOptional()
  @IsNotEmpty()
  differentialDiagnoses?: TeleDifferentialDiagnosisMutationDto[];

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  clinicalImageQualityRatingId?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString({ each: true })
  clinicalImageQualityRatingReasonsIds?: string[];

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  clinicalImageQualityRatingReasonsDescription?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  dermoscopicImageQualityRatingId?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString({ each: true })
  dermoscopicImageQualityRatingReasonsIds?: string[];

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  dermoscopicImageQualityRatingReasonsDescription?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  proposedClinicalManagementActionId?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  proposedClinicalManagementActionDescription?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  note?: string;
}

export class UpdateTeledermatologicalAssessmentDto {
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  referralId: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  diagnosisId: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  secondaryDiagnosisId: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  medicalUnitId: string;
}

export class TeledermatologicalAssessmentDto implements BaseDto {
  @IsString()
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;
  created?: string;
  updated?: string;
  deleted?: string;

  @IsNotEmpty()
  @IsString()
  referralId: string;

  @IsNotEmpty()
  @IsString()
  diagnosisId: string;

  @IsNotEmpty()
  @IsString()
  difficultyId: string;

  @IsOptional()
  @IsNotEmpty()
  differentialDiagnoses?: TeleDifferentialDiagnosisMutationDto[];

  @IsOptional()
  @IsNotEmpty()
  clinicalImageQualityRating: ImageQualityRatingDto;

  @IsOptional()
  @IsNotEmpty()
  clinicalImageQualityRatingReasonsIds: string[];

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  clinicalImageQualityRatingReasonsDescription?: string;

  @IsOptional()
  @IsNotEmpty()
  dermoscopicImageQualityRating: ImageQualityRatingDto;

  @IsOptional()
  @IsNotEmpty()
  dermoscopicImageQualityRatingReasonsIds: string[];

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  dermoscopicImageQualityRatingReasonsDescription?: string;

  @IsOptional()
  @IsNotEmpty()
  proposedClinicalManagementAction: ClinicalManagementActionDto;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  proposedClinicalManagementActionDescription?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  note: string;

  @IsNotEmpty()
  @IsString()
  createdByUserName: string;

  @IsNotEmpty()
  @IsString()
  medicalUnitId: string;

  @IsOptional()
  @IsBoolean()
  canEdit?: boolean;
}
