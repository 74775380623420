import { DiagnosisStatusEnum } from '@dermloop/static-values';
import {
  isArray,
  IsBoolean,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
} from 'class-validator';

export class BiopsySearchRequestDto {
  @IsOptional()
  @IsString()
  query?: string;

  @IsOptional()
  @IsEnum(DiagnosisStatusEnum)
  status?: DiagnosisStatusEnum;

  @IsOptional()
  @IsUUID('all', { each: true })
  biopsyTypes?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  skinTypes?: string[];

  @IsOptional()
  @IsNumber()
  @Max(30)
  take?: number;

  @IsOptional()
  @IsNumber()
  skip?: number;
}

export class PatientSummaryDto {
  healthRecordId: string;
  skinTypeId?: string;
  ssn?: string;
}

export class BiopsySearchResultItemDto {
  id?: string;
  patient: PatientSummaryDto;
  diagnosed: boolean;
  lesionId: string;
  clinicalDiagnosisName?: string;
  histopathologicalDiagnosisName?: string;
  biopsyTypeName?: string;
  createdDate: Date;
  reference: string;
}
