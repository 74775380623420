import {
  BaseInputProps,
  Icon,
  InputContainer,
  Typography,
  ValidationState,
} from '@dermloop/ui/components';
import {
  BORDER_RADIUS,
  Colors,
  GENERAL_SPACING,
  INPUT_HEIGHT,
  useOnClickOutside,
} from '@dermloop/ui/util';

import { format } from 'date-fns';
import { isFunction } from 'lodash';
import { useRef, useState } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface DateInputProps extends BaseInputProps {
  value: Date;
  onValueChange?: (value: Date) => void;
}

const StyledDateInput = styled.div<{
  focused: boolean;
  open?: boolean;
  validationState: ValidationState;
}>`
  border: 1px solid
    ${(props) =>
      props.validationState === 'error'
        ? Colors.BRAND_WARNING
        : props.focused
        ? Colors.BRAND_INFO
        : Colors.BRAND_SECONDARY};
  height: ${INPUT_HEIGHT}px;
  box-sizing: border-box;
  padding: 0px ${GENERAL_SPACING}px;
  border-top-left-radius: ${BORDER_RADIUS}px;
  border-top-right-radius: ${BORDER_RADIUS}px;
  border-bottom-right-radius: ${(props) => (props.open ? 0 : BORDER_RADIUS)}px;
  border-bottom-left-radius: ${(props) => (props.open ? 0 : BORDER_RADIUS)}px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`;

const StyledCalendar = styled.div<{ validationState: ValidationState }>`
  .react-calendar {
    width: 100%;
    position: absolute;
    z-index: 5150;
    border: 1px solid
      ${(props) =>
        props.validationState === 'error'
          ? Colors.BRAND_WARNING
          : Colors.BRAND_INFO};
    border-top: none;
    border-bottom-left-radius: ${BORDER_RADIUS}px;
    border-bottom-right-radius: ${BORDER_RADIUS}px;
  }
`;

const StyledIcon = styled.div`
  height: 20px;
  user-select: none;
`;
export function DateInput(props: DateInputProps) {
  // Ref to calendar div element
  const calendarRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);

  // Close calendar if user clicks outside of calendar
  useOnClickOutside(calendarRef, () => {
    setOpen(false);
  });

  return (
    <div ref={calendarRef}>
      <InputContainer
        label={props.label}
        hasFocus={open}
        hasValue={true}
        validationState={props.validationState}
        validationMessage={props.validationMessage}
      >
        <StyledDateInput
          open={open}
          onClick={() => setOpen((o) => !o)}
          focused={open}
          validationState={props.validationState}
        >
          <Typography size="medium-small">
            {props.value
              ? format(props.value, 'dd-MM-yyyy')
              : 'No date selected'}
          </Typography>
          <StyledIcon>
            <Icon icon="Calendar" />
          </StyledIcon>
        </StyledDateInput>
        {open ? (
          <StyledCalendar validationState={props.validationState}>
            <Calendar
              value={props.value}
              onChange={(newValue: Date) => {
                setOpen(false);
                if (isFunction(props.onValueChange))
                  props.onValueChange(newValue);
              }}
            />
          </StyledCalendar>
        ) : null}
      </InputContainer>
    </div>
  );
}

export default DateInput;
