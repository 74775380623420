import { isArray, isBoolean } from 'lodash';

export function cleanObject<T>(object: T): T {
  if (object == null) return null;
  return Object.keys(object)
    .filter((key) => object[key] !== undefined)
    .reduce((obj, key) => {
      obj[key] = isEmpty(object[key]) ? null : object[key];
      return obj;
    }, {} as T);
}

/**
 * Check if value is empty
 * @param value - value to check
 * @returns true if value is null, empty string or empty array
 */
export function isEmpty(value: unknown): boolean {
  if (value == null || value === '') return true;
  if (isBoolean(value)) return false;
  if (isArray(value)) return value.length === 0;
  return false;
}
