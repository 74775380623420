import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { DiagnosisGroupDto } from '../diagnoses';

export interface TrainingConfigurationDto extends BaseDto {
  name: string;
  description?: string;
  sessionLength?: number;
  enableTiming?: boolean;
  minimumDifficulty?: number;
  maximumDifficulty?: number;
  answerOptions?: DiagnosisGroupDto;
}

/**
 * DTO for editing or creating a training configuration.
 */
export class EditTrainingConfigurationDto {
  @IsOptional()
  @IsNotEmpty()
  @IsUUID()
  id?: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsNumber()
  @Min(1)
  sessionLength: number;

  @IsBoolean()
  enableTiming: boolean;

  // Diagnosis group id
  @IsOptional()
  @IsNotEmpty()
  @IsUUID()
  answerOptionsId?: string;

  @IsNumber()
  @Min(0)
  @Max(1)
  minimumDifficulty: number;

  @IsNumber()
  @Min(0)
  @Max(1)
  maximumDifficulty: number;
}
