import { Colors } from '@dermloop/ui/util';
import styled from 'styled-components/native';

const size = 20;

/* eslint-disable-next-line */
export interface FocalPointProps {
  x: number;
  y: number;
}

const StyledFocalPoint = styled.View<{ x: number; y: number }>`
  position: absolute;
  padding: 0px;
  border: 1px solid ${Colors.BRAND_WHITE};
  border-radius: 999px;
  height: ${size}px;
  width: ${size}px;
  left: ${(props) => props.x}%;
  top: ${(props) => props.y}%;
`;

export function FocalPoint(props: FocalPointProps) {
  return (
    <StyledFocalPoint
      x={props.x * 100}
      y={props.y * 100}
      style={{
        transform: [
          {
            translateX: -(size / 2),
          },
          {
            translateY: -(size / 2),
          },
        ],
      }}
    />
  );
}

export default FocalPoint;
