import { Colors } from '@dermloop/ui/util';
import styled, { keyframes } from 'styled-components';

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;
const DotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div<{ delay: string }>`
  background-color: ${Colors.BRAND_GRAY_MEDIUM};
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 0 3px;
  /* Animation */
  animation: ${BounceAnimation} 0.7s linear infinite;
  animation-delay: ${(props) => props.delay};
`;

/* eslint-disable-next-line */
export interface LoadingIndicatorProps {}
export function LoadingIndicator(props: LoadingIndicatorProps) {
  return (
    <DotWrapper data-cy="loading-indicator">
      <Dot delay="0s" />
      <Dot delay=".1s" />
      <Dot delay=".2s" />
    </DotWrapper>
  );
}

export default LoadingIndicator;
