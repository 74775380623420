import { HealthRecordClient, PatientDetailClient } from '@dermloop/api-client';
import { Logger } from '@dermloop/shared';
import { Action } from '@reduxjs/toolkit';
import axios from 'axios';
import { Epic } from 'redux-observable';
import { combineLatest, of, throwError, timer } from 'rxjs';
import {
  delayWhen,
  filter,
  map,
  mergeMap,
  retry,
  switchMap,
  tap,
} from 'rxjs/operators';
import { authenticationActions } from '../authentication/authentication.slice';
import { MinimalPatientState, MinimalState } from '../state';
import { healthRecordActions } from './health-record.slice';

const getHealthRecord: Epic<Action, Action, MinimalState> = (action$, state) =>
  action$.pipe(
    filter(healthRecordActions.getHealthRecord.match),
    tap((action) => {
      console.log('Trigger epic', action);
    }),
    delayWhen(() =>
      state.value.authentication?.authenticated ? timer(0) : timer(200)
    ),
    mergeMap((action) => {
      if (state.value.authentication?.authenticated) {
        return of(action);
      }
      return throwError(() => new Error('Not authenticated'));
    }),
    retry(10),
    switchMap((action) => {
      return HealthRecordClient.getHealthRecord(action.payload.healthRecordId);
    }),
    map((response) => {
      if (axios.isAxiosError(response)) {
        return healthRecordActions.error({
          message: response?.response?.statusText,
          error: response,
          stateUpdate: { fetchingCurrentHealthRecord: false },
        });
      } else {
        return healthRecordActions.update({
          currentHealthRecord: response.data,
          fetchingCurrentHealthRecord: false,
          patientDetails: null,
        });
      }
    })
  );

const reloadHealthRecord: Epic<Action, Action, MinimalPatientState> = (
  action$,
  state
) =>
  action$.pipe(
    filter(healthRecordActions.reloadHealthRecord.match),
    tap((action) => {
      console.log('Trigger epic', action);
    }),
    delayWhen(() =>
      state.value.authentication?.authenticated ? timer(0) : timer(200)
    ),
    mergeMap((action) => {
      if (state.value.authentication?.authenticated) {
        return of(action);
      }
      return throwError('Not authenticated');
    }),
    retry(10),
    switchMap((action) => {
      return HealthRecordClient.getHealthRecord(
        state.value.healthRecord?.currentHealthRecord?.id
      );
    }),
    map((response) => {
      if (axios.isAxiosError(response)) {
        return healthRecordActions.error({
          message: response?.response?.statusText,
          error: response,
          stateUpdate: { fetchingCurrentHealthRecord: false },
        });
      } else {
        return healthRecordActions.update({
          currentHealthRecord: response.data,
          fetchingCurrentHealthRecord: false,
          patientDetails: null,
        });
      }
    })
  );

const fetchLatestHealthRecordsOnAuthenticate: Epic<
  Action,
  Action,
  MinimalState
> = (action$, state) =>
  action$.pipe(
    filter(authenticationActions.authenticated.match),
    map((a) => {
      return healthRecordActions.fetchLatestHealthRecords({
        searchHealthRecords: { take: 20 },
      });
    })
  );

const fetchLatestHealthRecords: Epic<Action, Action> = (action$, state) =>
  action$.pipe(
    filter(healthRecordActions.fetchLatestHealthRecords.match),
    switchMap((action) => {
      return HealthRecordClient.searchHealthRecord({
        ...action.payload.searchHealthRecords,
        ownOnly: true,
      });
    }),
    map((recentResponse) => {
      if (axios.isAxiosError(recentResponse)) {
        Logger.error({
          message: 'Error occured while fetching recent health records',
          error: recentResponse,
        });

        return healthRecordActions.error({
          message: recentResponse?.response?.statusText,
          error: recentResponse,
        });
      } else {
        return healthRecordActions.update({
          recentHealthRecords: recentResponse.data,
          fetchingHealthRecords: false,
        });
      }
    })
  );

const dispatchGetHealthRecord: Epic<Action, Action> = (action$, state) =>
  action$.pipe(
    filter(healthRecordActions.persistedPatientDetails.match),
    tap((action) => {
      console.log('Trigger epic', action);
    }),
    map((response) => {
      return healthRecordActions.getHealthRecord({
        healthRecordId: response.payload.healthRecordId,
      });
    })
  );

const persistPatientDetails: Epic<Action, Action> = (action$, state) =>
  action$.pipe(
    filter(healthRecordActions.persistPatientDetails.match),
    tap((action) => {
      console.log('Trigger epic', action);
    }),
    switchMap((action) => {
      return combineLatest([
        of(action.payload.healthRecordId),
        PatientDetailClient.createPatientDetails({
          healthRecordId: action.payload.healthRecordId,
          ...action.payload.details,
        }),
      ]);
    }),
    map(([healthRecordId, response]) => {
      if (axios.isAxiosError(response)) {
        return healthRecordActions.error({
          message: 'Error occured while persisting patient',
          error: response,
          failedTask: {
            healthRecordId: healthRecordId,
            type: 'update-patient-details',
          },
        });
      }
      return healthRecordActions.persistedPatientDetails({
        healthRecordId: healthRecordId,
      });
    })
  );

const searchHealthRecord: Epic<Action, Action> = (action$, state) =>
  action$.pipe(
    filter(healthRecordActions.searchHealthRecord.match),
    switchMap((action) => {
      return combineLatest([
        HealthRecordClient.searchHealthRecord(
          action.payload.searchHealthRecordDto
        ),
        of(action),
      ]);
    }),
    map(([response, action]) => {
      if (!axios.isAxiosError(response)) {
        if (response.data.length) {
          if (action.payload.onSuccess) {
            action.payload.onSuccess(response.data[0]);
          }
          return healthRecordActions.update({
            searching: false,
            searchResult: response.data[0],
          });
        } else {
          return healthRecordActions.error({
            message: 'CPR did not match any health records',
          });
        }
      } else {
        return healthRecordActions.error({
          message: response?.response?.statusText,
          error: response,
        });
      }
    })
  );

export const desktopHealthRecordEpics = [
  getHealthRecord,
  searchHealthRecord,
  fetchLatestHealthRecords,
  persistPatientDetails,
  dispatchGetHealthRecord,
  reloadHealthRecord,
];

export const captureHealthRecordEpics = [
  ...desktopHealthRecordEpics,
  fetchLatestHealthRecordsOnAuthenticate,
];
