import { Button, ButtonIcon } from '@dermloop/ui/components';
import { Colors, Icons } from '@dermloop/ui/util';
import styled from 'styled-components';

export interface ArrowsSliderProps {
  count?: number;
  currentImageNo?: number;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  hasOutline?: boolean;
  showOutline?: boolean;
  onClickOutline?: () => void;
  hasAlternativeImage?: boolean;
  showAlternativeImage?: boolean;
  onClickAlternativeImage?: () => void;
  hasImageCompare?: boolean;
  onClickCompare?: () => void;
}

const StyledArrows = styled.div`
  margin: 0px auto;
  border: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  border-radius: 2rem;
  display: flex;
  max-width: 13rem;
  flex: 1;
  background-color: ${Colors.BRAND_WHITE};
  padding: 0.5rem 1rem;
`;

const Arrow = styled.div`
  width: fit-content;
`;

const ImageCount = styled.div`
  color: ${Colors.TEXT_ALTERNATIVE};
`;

const StyledArrowSilder = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0.5rem auto;
`;

const StyledOutline = styled.div`
  padding-left: 0.5rem;
  min-width: 2.3rem;
  flex-shrink: 0;
`;

const StyledFlash = styled.div`
  padding-right: 0.5rem;
  min-width: 2.3rem;
  flex-shrink: 0;
`;

const StyledCompareButton = styled.div<{ isActive: boolean }>`
  margin: 0 0.5rem;
  cursor: ${(props) => (props.isActive ? 'pointer' : null)};
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

export function ArrowsSlider(props: ArrowsSliderProps) {
  return (
    <StyledArrowSilder>
      {props.hasAlternativeImage ? (
        <StyledFlash>
          <ButtonIcon
            children={
              props.showAlternativeImage
                ? Icons.FlashRounded
                : Icons.NoFlashRounded
            }
            onPress={props.onClickAlternativeImage}
            type={'solid'}
            color={'secondary'}
            outline={true}
            noPadding={true}
            size={'large'}
          />
        </StyledFlash>
      ) : null}
      <StyledArrows>
        {props.currentImageNo <= 1 || props.count === 1 ? (
          <Arrow
            style={{
              content: `url(${Icons.LeftArrowLight})`,
              marginRight: 'auto',
              marginLeft: '1rem',
            }}
          ></Arrow>
        ) : (
          <Arrow
            style={{
              content: `url(${Icons.LeftArrow})`,
              cursor: 'pointer',
              marginRight: 'auto',
              marginLeft: '1rem',
            }}
            onClick={props.onClickLeft}
          ></Arrow>
        )}

        <ImageCount>
          {props.currentImageNo}/{props.count} Images
        </ImageCount>

        {props.currentImageNo === props.count || props.count === 1 ? (
          <Arrow
            style={{
              content: `url(${Icons.RightArrowLight})`,
              marginRight: '1rem',
              marginLeft: 'auto',
            }}
          ></Arrow>
        ) : (
          <Arrow
            style={{
              content: `url(${Icons.RightArrow})`,
              cursor: 'pointer',
              marginRight: '1rem',
              marginLeft: 'auto',
            }}
            onClick={props.onClickRight}
          ></Arrow>
        )}
      </StyledArrows>
      <RightSideContainer>
        {props.hasImageCompare ? (
          <StyledCompareButton isActive={props.count > 1}>
            <Button
              children="Compare"
              onPress={props.count > 1 ? props.onClickCompare : null}
              type={'solid'}
              color={'secondary'}
            ></Button>
          </StyledCompareButton>
        ) : null}
        {props.hasOutline ? (
          <StyledOutline>
            <ButtonIcon
              children={props.showOutline ? Icons.NoOutline : Icons.ShowOutline}
              onPress={props.onClickOutline}
              type={'solid'}
              color={'secondary'}
              outline={true}
              noPadding={true}
              size={'large'}
            />
          </StyledOutline>
        ) : null}
      </RightSideContainer>
    </StyledArrowSilder>
  );
}
