import { Colors, Sizes } from '@dermloop/ui/util';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import Icon from '../icon/icon';

type CheckboxPosition = 'left' | 'right';

export interface CheckboxProps {
  id: string;
  text: string;
  selected: boolean;
  onPress?: () => void;

  // Default left
  checkboxPosition?: CheckboxPosition;

  // If disabled, the checkbox will be greyed out and not clickable
  disabled?: boolean;
}

export function Checkbox(props: CheckboxProps) {
  // Default left position
  const checkboxPosition = props.checkboxPosition || 'left';

  return (
    <TouchableOpacity
      onPress={() => (props?.disabled ? {} : props.onPress?.())}
      disabled={props.disabled}
    >
      <CheckboxWrapperStyle checkboxPosition={checkboxPosition}>
        <CheckboxSpanStyle
          selected={props.selected}
          checkboxPosition={checkboxPosition}
          disabled={props.disabled}
        >
          {props.selected && <Icon icon={'CheckMark'} />}
        </CheckboxSpanStyle>
        <StyledTextView>
          <StyledText disabled={props.disabled}>{props.text}</StyledText>
        </StyledTextView>
      </CheckboxWrapperStyle>
    </TouchableOpacity>
  );
}

export default Checkbox;

const CheckboxWrapperStyle = styled.View<{
  checkboxPosition: CheckboxPosition;
}>`
  display: flex;
  ${(props) =>
    `flex-direction: ${
      props.checkboxPosition === 'left' ? 'row' : 'row-reverse'
    };`}
  align-items: center;
  ${(props) =>
    props.checkboxPosition === 'left' ? '' : 'justify-content: space-between;'}
  font-size: ${Sizes.FONT_SIZE}px;
  font-weight: ${Sizes.REGULAR_WEIGHT};
  cursor: pointer;
`;

const CheckboxSpanStyle = styled.View<{
  selected: boolean;
  checkboxPosition: CheckboxPosition;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  ${(props) =>
    props.disabled
      ? `
        background: ${
          props.selected ? Colors.BRAND_TERTIARY : Colors.BRAND_WHITE
        };
        border: ${Sizes.BORDER_WIDTH}px ${Colors.BRAND_TERTIARY} solid;
        ${
          props.checkboxPosition === 'left'
            ? `margin-right: ${Sizes.GENERAL_SPACING / 2}px;`
            : `margin-left: ${Sizes.GENERAL_SPACING / 2}px;`
        }
      `
      : `
        background: ${
          props.selected ? Colors.BRAND_SUCCESS : Colors.BRAND_WHITE
        };
        border: ${Sizes.BORDER_WIDTH}px ${
          props.selected ? Colors.BRAND_SUCCESS : Colors.BRAND_SECONDARY
        } solid;
        ${
          props.checkboxPosition === 'left'
            ? `margin-right: ${Sizes.GENERAL_SPACING / 2}px;`
            : `margin-left: ${Sizes.GENERAL_SPACING / 2}px;`
        }
  `}
`;

const StyledTextView = styled.View`
  flex-grow: 1;
`;

const StyledText = styled.Text<{ disabled?: boolean }>`
  color: ${(props) =>
    props.disabled ? Colors.BRAND_TERTIARY : Colors.BRAND_PRIMARY};
`;
