import { Colors, GENERAL_SPACING } from '@dermloop/ui/util';
import { ReactNode } from 'react';
import styled from 'styled-components/native';
import Typography from '../typography/typography';

export type ColumnConfig = {
  weight: string;
  align?: 'left' | 'center' | 'right';
};

export type Row =
  | {
      onPress?: () => void;
      cells: ReactNode[];
    }
  | ReactNode[];

export interface SimpleTableProps {
  columnConfiguration: ColumnConfig[];
  titles: string[];
  rows: Row[];
}

const StyledSimpleTable = styled.View``;

const StyledColumn = styled.View<ColumnConfig>`
  flex-basis: ${(props) => props.weight};
  align-items: ${(props) =>
    props.align === 'center'
      ? 'center'
      : props.align === 'right'
      ? 'flex-end'
      : 'flex-start'}
  justify-content: center;
`;

const StyledRow = styled.Pressable`
  flex-direction: row;
  height: 65px;
  background: ${Colors.BRAND_WHITE};
  justify-content: space-between;
  padding: ${GENERAL_SPACING}px;
  border-bottom-color: ${Colors.BRAND_TERTIARY};
  border-bottom-width: 1px;
`;

const StyledSimpleTableHeader = styled.View`
  display: flex;
  flex-direction: row;
  background: ${Colors.BRAND_WHITE};
  padding: ${GENERAL_SPACING / 2}px ${GENERAL_SPACING}px;
  justify-content: flex-start;
  border-bottom-color: ${Colors.BRAND_TERTIARY};
  border-bottom-width: 1px;
`;

export function SimpleTable(props: SimpleTableProps) {
  return (
    <StyledSimpleTable>
      <StyledSimpleTableHeader>
        {props.titles.map((t, i) => (
          <StyledColumn key={t} {...props.columnConfiguration[i]}>
            <Typography singleLine color="medium" size="small">
              {t}
            </Typography>
          </StyledColumn>
        ))}
      </StyledSimpleTableHeader>
      {props?.rows?.map((r, i) => {
        const onPress = 'onPress' in r ? r.onPress : null;
        const cells = 'cells' in r ? r.cells : r;
        return (
          <StyledRow key={i} onPress={onPress}>
            {cells.map((c, i) => (
              <StyledColumn key={i} {...props.columnConfiguration[i]}>
                {c}
              </StyledColumn>
            ))}
          </StyledRow>
        );
      })}
    </StyledSimpleTable>
  );
}

export default SimpleTable;
