import { MacroscopicInspectionTypeDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const macroscopicInspectionTypeEndpoints = (
  builder: EndpointBuilderType
) => ({
  getMacroscopicInspectionTypes: builder.query<
    MacroscopicInspectionTypeDto[],
    void
  >({
    query: () => 'macroscopic-inspection-types',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [
      { type: 'MACROSCOPIC_INSPECTION_TYPES', id: 'LIST' },
    ],
  }),
});
