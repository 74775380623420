import { TrainingLesionStatus } from '@dermloop/static-values';
import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateNested,
} from 'class-validator';
import { DiagnosisDto } from '../diagnoses/diagnosis.dto';

/**
 * DTO used for requesting list data.
 */
export class ManageTrainingLesionListDto {
  selectedStatus?: TrainingLesionStatus | 'all';
  @Min(0)
  skip: number;
  @Max(20)
  @Min(1)
  take: number;
}

/**
 * Returned data after requesting list.
 */
export interface ManageTrainingLesionListResultDto {
  id: string;
  status: string;
  diagnosisName: string;
  locationName: string;
}
/**
 * DTO representing training lesion for management.
 */
export interface ManageTrainingLesionDto {
  id: string;
  status: string;
  locationName: string;
  diagnosisIsHistopathological: boolean;
  primaryDiagnosis: DiagnosisDto;
  secondaryDiagnosis?: DiagnosisDto;
  clinicalImage: ManageTrainingLesionImageDto;
  baselineImage: ManageTrainingLesionImageDto;
  followUpImage: ManageTrainingLesionImageDto;
  additionalFollowUpImages?: ManageTrainingLesionImageDto[];
}

export interface ManageTrainingLesionImageDto {
  imageId: string;
  croppedIdentifier: string;
  imageUrl: string;
  createdDate: Date;
  isReferenceOnly?: boolean;
  isDiscarded?: boolean;
  focalPointXPercentage?: number;
  focalPointYPercentage?: number;
}

export class ManageTrainingLesionApproveDto {
  @IsString()
  @IsNotEmpty()
  @IsUUID()
  trainingLesionId: string;

  @IsOptional()
  @Type(() => ManageTrainingLesionUpdatedFocalPoint)
  @ValidateNested({ each: true })
  updateFocalPoints?: ManageTrainingLesionUpdatedFocalPoint[];
}

export class ManageTrainingLesionUpdatedFocalPoint {
  @IsString()
  @IsNotEmpty()
  @IsUUID()
  imageId: string;

  @IsNumber()
  newFocalPointX: number;

  @IsNumber()
  newFocalPointY: number;
}
