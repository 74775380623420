/* eslint-disable */
import {
  ReactThreeFiber,
  extend,
  useFrame,
  useThree,
} from '@react-three/fiber';
import CameraControlsDefault from 'camera-controls';
import {
  ForwardedRef,
  MutableRefObject,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import * as THREE from 'three';

// Partially stolen from camera-controls github example
declare global {
  namespace JSX {
    interface IntrinsicElements {
      cameraControlsDefault: ReactThreeFiber.Node<
        CameraControlsDefault,
        typeof CameraControlsDefault
      >;
    }
  }
}

CameraControlsDefault.install({ THREE: THREE });
extend({ CameraControlsDefault });

export const CameraControls = forwardRef<
  CameraControlsDefault,
  {
    /**Triggered when user moves camera */
    onControl?: (controls: CameraControlsDefault) => void;
    /**Triggered when user stops moving camera */
    onControlStop?: (controls: CameraControlsDefault) => void;
    /**Triggered when camera movement is behold rest threshold */
    onRest?: (controls: CameraControlsDefault) => void;
    /**Triggered when camera starts movement*/
    onWake?: (controls: CameraControlsDefault) => void;
    /**Triggered when user starts moving camera*/
    onControlStart?: (controls: CameraControlsDefault) => void;
    /**Triggered when camera position is updated*/
    onUpdate?: (controls: CameraControlsDefault) => void;
  }
>((props, ref) => {
  const cameraControls = useRef<CameraControlsDefault | null>(null);
  const camera = useThree((state) => state.camera);
  const renderer = useThree((state) => state.gl);
  useFrame((_, delta) => cameraControls.current?.update(delta));
  useEffect(() => () => cameraControls.current?.dispose(), []);
  useEffect(() => {
    const listener = (evt) => {
      props.onControl && props.onControl(cameraControls.current);
    };

    cameraControls.current.addEventListener('control', listener);
    return () => {
      cameraControls.current?.removeEventListener('control', listener);
    };
  }, [cameraControls, props.onControl]);
  useEffect(() => {
    const listener = (evt) => {
      props.onControlStop && props.onControlStop(cameraControls.current);
    };
    cameraControls.current.addEventListener('controlend', listener);
    return () => {
      cameraControls.current?.removeEventListener('controlend', listener);
    };
  }, [cameraControls, props.onControlStop]);
  useEffect(() => {
    const listener = (evt) => {
      props.onControlStart && props.onControlStart(cameraControls.current);
    };
    cameraControls.current.addEventListener('controlstart', listener);
    return () => {
      cameraControls.current?.removeEventListener('controlstart', listener);
    };
  }, [cameraControls, props.onControlStart]);
  useEffect(() => {
    const listener = (evt) => {
      props.onUpdate && props.onUpdate(cameraControls.current);
    };
    cameraControls.current.addEventListener('update', listener);
    return () => {
      cameraControls.current?.removeEventListener('update', listener);
    };
  }, [cameraControls, props.onUpdate]);

  useEffect(() => {
    const listener = (evt) => {
      props.onRest && props.onRest(cameraControls.current);
    };
    cameraControls.current.addEventListener('rest', listener);
    return () => {
      cameraControls.current?.removeEventListener('rest', listener);
    };
  }, [cameraControls, props.onRest]);
  useEffect(() => {
    const listener = (evt) => {
      props.onWake && props.onWake(cameraControls.current);
    };
    cameraControls.current.addEventListener('wake', listener);
    return () => {
      cameraControls.current?.removeEventListener('wake', listener);
    };
  }, [cameraControls, props.onWake]);
  return (
    <cameraControlsDefault
      ref={mergeRefs<CameraControlsDefault>(cameraControls, ref)}
      args={[camera, renderer.domElement]}
    />
  );
});

export type CameraControls = CameraControlsDefault;

function mergeRefs<T>(...refs: (MutableRefObject<T> | ForwardedRef<T>)[]) {
  return (instance: T): void => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref) {
        ref.current = instance;
      }
    }
  };
}
