import React from 'react';
import styled from 'styled-components/native';
import Typography, { TypographyProps } from '../typography/typography';

export type CompositeTitle = {
  /** Primary text */
  title: string;
  titleColor?: 'medium' | 'black' | 'success' | 'danger' | 'warning';

  /** Option seperator added between primary and secondary text */
  seperator?: string;
  trimSpace?: boolean;
  /** Secondary text */
  subtitle: string;
  subtitleColor?: 'medium' | 'black' | 'success' | 'danger' | 'warning';
};
function isCompositeHeading(
  title: string | CompositeTitle
): title is CompositeTitle {
  return (
    !(typeof title === 'string' || title instanceof String) &&
    typeof title !== 'function'
  );
}

type TypographyStyles = Omit<TypographyProps, 'children'>;
/**
 * A helper component for render inline typograhy with limited styling.
 * It helps by enabling a title to be structured of either a string or a more complex two part typography
 * Primary use case is ListItem headings and texts.
 */
export function CompositeTypography(props: {
  /** Typography to present */
  typography: string | CompositeTitle;

  /** Styles that applied to primary text. Only configurable from inside ListItem.  */
  titleStyles: TypographyStyles;
  /** Styles that applied to secondary text. Only configurable from inside ListItem.  */
  subtitlesStyles: TypographyStyles;

  /** Styles that applied to simple string typography. Only configurable from inside ListItem.  */
  baseStyles: TypographyStyles;
}) {
  return props.typography && isCompositeHeading(props.typography) ? (
    <StyledInlineTypography>
      <Typography
        singleLine
        preventShrink
        {...props.titleStyles}
        color={props.typography.titleColor}
      >
        {props.typography.title}
      </Typography>
      {props.typography.subtitle ? (
        <Typography
          singleLine
          {...props.subtitlesStyles}
          color={props.typography.subtitleColor}
        >
          {`${props.typography.trimSpace ? '' : ' '}${
            props.typography.seperator ? props.typography.seperator + ' ' : ''
          }${props.typography.subtitle}`}
        </Typography>
      ) : null}
    </StyledInlineTypography>
  ) : props.typography ? (
    <Typography singleLine {...props.baseStyles}>
      {props.typography.toString()}
    </Typography>
  ) : null;
}

const StyledInlineTypography = styled.View`
  display: flex;
  flex-direction: row;
`;
