import { Logger } from '@dermloop/shared';
import { Colors, NATIVE_BORDER_WIDTH } from '@dermloop/ui/util';
import React, { useEffect } from 'react';
import { Animated, Dimensions } from 'react-native';
import styled from 'styled-components/native';

/* eslint-disable-next-line */
export interface Tab {
  name?: string;
  icon?: JSX.Element;
  disabledIcon?: JSX.Element;
  notificationCount?: number;
  notificationColor?: string;
}

export interface SegmentedControlProps {
  tabs: Tab[];
  onChange: (index: number, tab?: Tab) => void;
  currentIndex: number;
  color?: 'light' | 'dark';
  display: 'inline' | 'block';
  padded?: boolean;
}

// So that it stretches in landscape mode.
const width = Dimensions.get('screen').width - 32;

export const SegmentedControl = (props: SegmentedControlProps) => {
  const { tabs, onChange, currentIndex, color, display, padded } = props;

  let translateValue = (width - 4) / props?.tabs?.length;
  if (display === 'inline') {
    translateValue = (63 - 8) / props?.tabs?.length;
  }
  const [tabTranslate, setTabTranslate] = React.useState(new Animated.Value(0));

  // useCallBack with an empty array as input, which will call inner lambda only once and memoize the reference for future calls
  const memoizedTabPressCallback = React.useCallback(
    (index, tab) => {
      onChange(index, tab);
    },
    [onChange]
  );

  useEffect(() => {
    if (tabTranslate) {
      // Animating the active index based current index
      Animated.spring(tabTranslate, {
        toValue: currentIndex * translateValue,
        stiffness: 180,
        damping: 24,
        mass: 0.5,
        useNativeDriver: true,
      }).start();
    } else {
      Logger.error({
        message: 'Animation not found',
        context: SegmentedControl.name,
      });
    }
  }, [currentIndex, tabTranslate, translateValue]);

  return (
    <SegmentedControlWrapper
      color={color}
      displayWidth={display}
      padded={padded}
    >
      <ActiveTab
        tabs={tabs}
        color={color}
        displayWidth={display}
        style={{ transform: [{ translateX: tabTranslate }] }}
      />
      {props?.tabs.map((tab, index) => {
        const isCurrentIndex = props?.currentIndex === index;
        return (
          <TextWrapper
            key={index}
            onPress={() => memoizedTabPressCallback(index, tab)}
            hitSlop={5}
          >
            {tab.name ? (
              <TabText numberOfLines={1} isCurrentIndex={isCurrentIndex}>
                {tab.name}
              </TabText>
            ) : isCurrentIndex ? (
              tab.icon
            ) : (
              tab.disabledIcon
            )}

            {tab.notificationColor && (
              <NotificationBubble notificationColor={tab.notificationColor}>
                {tab.notificationCount && (
                  <NotificationText>
                    {tab.notificationCount > 9
                      ? '9+'
                      : tab.notificationCount.toString()}
                  </NotificationText>
                )}
              </NotificationBubble>
            )}
          </TextWrapper>
        );
      })}
    </SegmentedControlWrapper>
  );
};

const SegmentedControlWrapper = styled(Animated.View)<{
  color?: 'light' | 'dark';
  displayWidth: 'inline' | 'block';
  padded?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  border-width: ${(props) => (props.color ? NATIVE_BORDER_WIDTH : 0)}px;
  border-color: ${(props) =>
    props.color === 'light' ? Colors.BRAND_TERTIARY : Colors.BRAND_PRIMARY};
  background-color: ${(props) =>
    props.color === 'light'
      ? Colors.WHITE_TRANSPARENT_BACKGROUND
      : Colors.BRAND_WHITE};
  width: ${(props) => (props.displayWidth === 'block' ? width : 63)}px;
  margin-top: ${(props) => (props.padded ? 15 : 0)}px;
  padding-vertical: ${(props) => (props.padded ? 10 : 6.5)}px;
`;

const ActiveTab = styled(Animated.View)<{
  tabs: Tab[];
  color?: 'light' | 'dark';
  displayWidth: 'inline' | 'block';
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: ${(props) =>
    props.displayWidth === 'block'
      ? (width - 4) / props.tabs.length
      : (63 - 4) / props.tabs.length}px;
  margin-vertical: 2px;
  margin-horizontal: 2px;
  background-color: ${(props) =>
    props.color === 'light' ? Colors.BRAND_WHITE : Colors.BRAND_BROWN_DARK};
  border-radius: 30px;
  border-width: ${(props) => (props.color ? NATIVE_BORDER_WIDTH : 0)}px;
  border-color: ${(props) =>
    props.color === 'light' ? Colors.BRAND_TERTIARY : Colors.BRAND_PRIMARY};
`;

const TextWrapper = styled.Pressable`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  elevation: 9;
  padding-horizontal: 5px;
`;

const TabText = styled.Text<{ isCurrentIndex: boolean }>`
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  font-weight: 500;
  color: ${(props) =>
    props.isCurrentIndex ? Colors.BRAND_WHITE : Colors.BRAND_BROWN_DARK};
`;

const NotificationBubble = styled.View<{ notificationColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.notificationColor};
  border-radius: 20px;
  width: 5px;
  height: 5px;
`;

const NotificationText = styled.Text`
  color: ${Colors.BRAND_WHITE};
  font-size: 8px;
  font-weight: 600;
  line-height: 10px;
`;

export default SegmentedControl;
