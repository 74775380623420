import { DermloopReferralTypeEnum } from '@dermloop/static-values';
import {
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { UserDto } from '../user.dto';
import { TeledermatologicalAssessmentDto } from './teledermatological-assessment.dtos';

export class CreateReferralDto {
  @IsOptional()
  @IsEnum(DermloopReferralTypeEnum)
  referralType?: DermloopReferralTypeEnum;

  @IsNotEmpty()
  @IsString()
  medicalUnitId: string;

  @IsOptional()
  @IsBoolean()
  urgent?: boolean;
}

export class UpdateReferralDto {
  @IsOptional()
  @IsEnum(DermloopReferralTypeEnum)
  referralType?: DermloopReferralTypeEnum;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  medicalUnitId: string;

  @IsOptional()
  @IsBoolean()
  urgent?: boolean;
}

export class ReferralDto implements BaseDto {
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;
  created?: string;
  updated?: string;
  deleted?: string;

  @IsOptional()
  @IsEnum(DermloopReferralTypeEnum)
  referralType?: DermloopReferralTypeEnum;

  @IsOptional()
  @IsNotEmpty()
  latestTeledermatologicalAssessment?: TeledermatologicalAssessmentDto;

  @IsNotEmpty()
  @IsString()
  medicalUnitId: string;

  @IsOptional()
  @IsBoolean()
  urgent?: boolean;
}
