import {
  BUTTON_BORDER_RADIUS,
  Colors,
  GENERAL_SPACING,
} from '@dermloop/ui/util';
import { useState } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { Icon } from '../icon/icon';
import { webSpecificValue } from '../utils';

/**
 * Search bar that searches locally in an array of input.
 */

/* eslint-disable-next-line */
export interface SearchBarProps {
  onSearch?: (query: string) => void;
  onTypeSearch?: (query: string) => void;
  placeholder?: string;
  value?: string;
  onSearchClear?: () => void;
}

const Container = styled.View`
  ${webSpecificValue('display: inline-block;')}
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  position: relative;
  border: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  width: 100%;
  background-color: ${Colors.BRAND_WHITE};
`;

const SearchIconContainer = styled.View`
  position: absolute;
  display: flex;
  width: 35px;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
`;

const SearchIcon = styled.View`
  height: 20px;
  width: 20px;
`;

const RemoveIconContainer = styled.View`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${2 * GENERAL_SPACING}px;
`;

const RemoveIcon = styled.View`
  display: flex;
  height: 12px;
  width: 12px;
`;

const StyledSearchBar = styled.TextInput.attrs({
  placeholderTextColor: Colors.BRAND_TERTIARY,
})`
  border-radius: ${BUTTON_BORDER_RADIUS}px;
  padding-left: 35px;
  padding-right: 35px;
  border: none;
  flex-grow: 1;
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  background-color: ${Colors.BRAND_WHITE};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: red;
  }
  :-ms-input-placeholder {
    color: red;
  }
  color: ${Colors.BRAND_SECONDARY};
`;

const Positioned = styled.View`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

export function SearchBar(props: SearchBarProps) {
  const [searchQuery, setSearchQuery] = useState(props.value || '');

  const clearOnPress = () => {
    // Reset search query
    setSearchQuery('');

    // If onSearchClear provided, call function
    if (props.onSearchClear) props.onSearchClear();
  };

  return (
    <Container>
      <Positioned>
        <SearchIconContainer>
          <SearchIcon>
            <Icon size="parent" icon="SearchDark"></Icon>
          </SearchIcon>
        </SearchIconContainer>
        <StyledSearchBar
          onKeyPress={(event) => {
            if (event.nativeEvent.key === 'Enter' && props.onSearch) {
              props.onSearch(searchQuery);
            }
          }}
          placeholder={props.placeholder}
          value={searchQuery}
          onChange={(e) => {
            const query = e.nativeEvent.text;
            if (props.onTypeSearch) {
              props.onTypeSearch(query);
            }
            setSearchQuery(query);
          }}
        />
        <RemoveIconContainer>
          {searchQuery?.length > 0 ? (
            <RemoveIcon>
              <Pressable onPress={clearOnPress} pointerEvents={'auto'}>
                <Icon size="parent" icon={'CloseButtonNoOutline'}></Icon>
              </Pressable>
            </RemoveIcon>
          ) : null}
        </RemoveIconContainer>
      </Positioned>
    </Container>
  );
}

export default SearchBar;
