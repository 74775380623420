import { Exclude } from 'class-transformer';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { BaseCreateDto, BaseDto, BaseUpdateDto } from '../base.dto';
import { TrainingLesionDifficultyLevelDto } from './training-lesion-difficulty-level.dtos';

export interface TrainingLesionDifficultyLevelGroupingDto extends BaseDto {
  name: string;
  defaultDifficultyLevelGroup?: boolean;
  trainingLesionDifficultyLevels?: TrainingLesionDifficultyLevelDto[];
  notes?: string;
}

export class CreateTrainingLesionDifficultyLevelGroupingDto
  extends BaseCreateDto
  implements Partial<TrainingLesionDifficultyLevelGroupingDto>
{
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsBoolean()
  defaultDifficultyLevelGroup: boolean;

  @Exclude()
  trainingLesionDifficultyLevels?: never;

  @IsOptional()
  @IsString()
  notes?: string;
}

export class UpdateTrainingLesionDifficultyLevelGroupingDto
  extends BaseUpdateDto
  implements Partial<TrainingLesionDifficultyLevelGroupingDto>
{
  @IsOptional()
  @IsString()
  @IsNotEmpty()
  name?: string;

  @IsOptional()
  @IsBoolean()
  defaultDifficultyLevelGroup?: boolean;

  @Exclude()
  trainingLesionDifficultyLevels?: never;

  @IsOptional()
  @IsString()
  notes?: string;
}
