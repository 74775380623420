import { Colors } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import { webSpecificValue } from '../utils';
import Option from './option';

export interface Option<T> {
  value: T;
  name: string;
}
/* eslint-disable-next-line */
export interface MultiOptionPickerProps<T> {
  name?: string;
  description?: string;
  readonly?: boolean;
  selectedValues?: T[];
  disabledChoices?: T[];
  datatipOnDisable?: string;
  onOptionsSelected: (option?: Option<T>[]) => void;
  options: Option<T>[];
}

const StyledOptionPicker = styled.View`
  color: pink;

  // Deactive text selection
  ${webSpecificValue(`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */`)}
`;

const Options = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.BRAND_GRAY_MEDIUM};
`;

const OptionText = styled.Text<{ selected: boolean; disabled: boolean }>`
  text-align: center;
  color: ${(props) =>
    props.selected
      ? Colors.TEXT_NEGATIVE
      : props.disabled
      ? Colors.BRAND_TERTIARY
      : Colors.TEXT};
`;

export function MultiOptionPicker<T>(props: MultiOptionPickerProps<T>) {
  return (
    <StyledOptionPicker>
      <Options>
        {props.options?.map((o) => {
          const isCurrentlySelected = props.selectedValues?.includes(o.value);
          const isDisabled = props.disabledChoices?.includes(o.value);
          return (
            <Option
              datatipOnDisable={props.datatipOnDisable}
              key={o.name}
              isSelected={isCurrentlySelected}
              isDisabled={isDisabled}
              onPress={() => {
                if (props.readonly) return;
                if (isDisabled) return;
                const selectedValues = props.selectedValues
                  ? [...props.selectedValues]
                  : [];

                const isSelected = !isCurrentlySelected;

                if (!selectedValues.includes(o.value) && isSelected) {
                  selectedValues.push(o.value);
                }

                const newSelections = selectedValues?.filter(
                  (so) => so !== o.value || (isSelected && so === o.value)
                );
                const selectedOptions = props.options.filter(
                  (o) => newSelections && newSelections?.includes(o.value)
                );
                props.onOptionsSelected(selectedOptions);
              }}
            >
              <OptionText
                lineBreakMode="head"
                selected={isCurrentlySelected}
                disabled={isDisabled}
              >
                {o.name}
              </OptionText>
            </Option>
          );
        })}
      </Options>
    </StyledOptionPicker>
  );
}

export default MultiOptionPicker;
