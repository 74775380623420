import { Colors, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import FormControlHeader from '../form-control-header/form-control-header';

/* eslint-disable-next-line */
export interface FormControlProps {
  title?: string;
  mandatory?: boolean;
  subtitle?: string;
  children: JSX.Element;
}

const StyledFormControl = styled.View`
  background: ${Colors.BRAND_WHITE};
  display: flex;
`;

const StyledFormControlBody = styled.View`
  display: flex;
  flex-direction: column;
`;

const StyledFormControlPreview = styled.Text`
  padding: 0 ${Sizes.GENERAL_SPACING}px ${Sizes.GENERAL_SPACING}px
    ${Sizes.GENERAL_SPACING}px;
  color: ${Colors.BRAND_SUCCESS};
`;

export function FormControl(props: FormControlProps) {
  return (
    <StyledFormControl>
      {props.title ? (
        <FormControlHeader
          title={props.title}
          mandatory={props.mandatory}
          subtitle={props.subtitle}
        />
      ) : null}
      <StyledFormControlBody>{props.children}</StyledFormControlBody>
    </StyledFormControl>
  );
}

export default FormControl;
