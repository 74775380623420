import {
  CreatePatientDetailsDto,
  PatientDetailsDto,
  UpdatePatientDetailsDto,
} from '@dermloop/api-dtos';
import { EndpointBuilderType } from '../constants';

export const patientDetailsEndpoints = (builder: EndpointBuilderType) => ({
  createPatientDetails: builder.mutation<
    PatientDetailsDto,
    { data: CreatePatientDetailsDto }
  >({
    query: ({ data }) => ({
      url: 'patient-details',
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (res) => {
      if (res?.id) {
        return [
          { type: 'HEALTH_RECORD', id: 'LIST' },
          { type: 'PATIENT_DETAILS', id: res.id },
          { type: 'PATIENT_DETAILS', id: 'LIST' },
        ];
      }
      return [{ type: 'PATIENT_DETAILS', id: 'LIST' }];
    },
  }),
  updatePatientDetails: builder.mutation<
    PatientDetailsDto,
    { id: string; data: UpdatePatientDetailsDto }
  >({
    query: ({ id, data }) => ({
      url: `patient-details/${id}`,
      method: 'PUT',
      body: data,
    }),
    invalidatesTags: (res) => {
      if (res?.id) {
        return [
          { type: 'HEALTH_RECORD', id: 'LIST' },
          { type: 'PATIENT_DETAILS', id: res.id },
          { type: 'PATIENT_DETAILS', id: 'LIST' },
        ];
      }
      return [{ type: 'PATIENT_DETAILS', id: 'LIST' }];
    },
  }),
});
