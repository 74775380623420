import {
  AnnotationPointDto,
  CriteriaAnnotationArrowDto,
} from '@dermloop/api-dtos';
import { Colors } from '@dermloop/ui/util';
import Draggable, { DraggableData } from 'react-draggable';
import { Circle, G, Path, Rect } from 'react-native-svg';
import styled from 'styled-components/native';
import { webSpecificValue } from '../../utils';

// Init coordinates of the first arrow ArrowPoint in path. Extracted from Figma SVG
export const arrowInitX = 0.939381;
export const arrowInitY = 10.9393;

// The radius of the circle on click
export const circleRadius = 12.5;

// The distance that arrows should have to the circle border
export const arrowDistCircle = 8;

// The arrow drag rectangle dist from circle center
const arrowRotateDragRectDistCenter = 55;
const arrowRotateDragRectWidth = 6;
const arrowRotateDragRectClickableWidth = 20;

/* eslint-disable-next-line */
export interface AnnotationPointProps {
  size?: 'normal' | 'large';
  arrowId?: string;
  active?: boolean;
  onPress: () => void;
  onDrag?: (e, ui: DraggableData) => void;
  onDragStop?: () => void;
  arrow: CriteriaAnnotationArrowDto;
  hideCircle?: boolean;
  updateArrowCache?: (arrow: CriteriaAnnotationArrowDto) => void;
  updateArrowState?: (arrow: CriteriaAnnotationArrowDto) => void;
  editable?: boolean;
}

export function AnnotationPoint(props: AnnotationPointProps) {
  const upscaling = props.size === 'large' ? 2 : 1;

  const arrow = props.arrow;
  const calculateAngle = (
    focalPoint: AnnotationPointDto,
    rotatePoint: AnnotationPointDto
  ) =>
    (Math.atan2(
      focalPoint?.y - rotatePoint?.y,
      focalPoint?.x - rotatePoint?.x
    ) *
      180) /
      Math.PI -
    180;

  const angleDeg = calculateAngle(arrow.focalPoint, arrow.rotatePoint);

  // First move coordinates of path from figma. Extracted to variables to include in other transformations
  const distAdd = circleRadius + arrowDistCircle;

  const translateX = arrow.focalPoint.x - arrowInitX + distAdd;
  const translateY = arrow.focalPoint.y - arrowInitY - 1.5;

  // Offset for drag rectangle. It should be positioned based on its own center
  const dragRectOffset = -(arrowRotateDragRectWidth / 2);

  return (
    <G>
      {props.editable ? (
        <Draggable
          disabled={!props.editable}
          key={props.arrowId}
          onDrag={props.onDrag}
          onStop={props.onDragStop}
          position={{ x: arrow.focalPoint?.x, y: arrow.focalPoint?.y }}
        >
          <G>
            <FocalPointCircle
              editable={props.editable}
              isActive={props.active}
              cx={0}
              cy={0}
              onPress={props.onPress}
            />
          </G>
        </Draggable>
      ) : !props.hideCircle ? (
        <FocalPointCircle
          cx={props.arrow.focalPoint?.x}
          cy={props.arrow.focalPoint?.y}
          editable={false}
          isActive={props.active}
          onPress={props.onPress}
        />
      ) : null}
      <>
        <G
          transform={`translate(${translateX},${translateY}) rotate(${angleDeg},-${distAdd},${arrowInitY})`}
        >
          <Path
            transform={
              props.size
                ? ` translate(${arrowInitX}, ${arrowInitY}) scale(${upscaling}) translate(-${arrowInitX}, -${arrowInitY})`
                : null
            }
            d={`M${arrowInitX} ${arrowInitY}C0.353594 11.5251 0.353594 12.4749 0.939381 13.0607L10.4853 22.6066C11.0711 23.1924 12.0209 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12136 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0209 0.807613 11.0711 0.807613 10.4853 1.3934L0.939381 10.9393ZM31.7322 10.5L2.00004 10.5L2.00004 13.5L31.7322 13.5L31.7322 10.5Z`}
            fill={Colors.BRAND_WHITE}
          />
        </G>
        <G
          transform={`rotate(${angleDeg}, ${arrow.rotatePoint?.x},${arrow.rotatePoint?.y})`}
        >
          {props.editable ? (
            <Draggable
              position={{
                x: arrow.rotatePoint?.x,
                y: arrow.rotatePoint?.y,
              }}
              positionOffset={{
                x: dragRectOffset,
                y: dragRectOffset,
              }}
              onDrag={(e, ui: DraggableData) => {
                // When dragging, directly update the position, allowing for easy dragging of the box
                const newX = arrow.rotatePoint?.x + ui.deltaX;
                const newY = arrow.rotatePoint?.y + ui.deltaY;
                if (props.updateArrowCache)
                  props.updateArrowCache({
                    ...arrow,
                    rotatePoint: {
                      x: newX,
                      y: newY,
                    },
                  });
              }}
              onStop={(e, ui) => {
                // When release, update the position to be on the edge of the arrow
                const newAngleRad =
                  (calculateAngle(arrow.focalPoint, {
                    x: ui.x,
                    y: ui.y,
                  }) *
                    Math.PI) /
                  180;
                if (props.updateArrowState)
                  props.updateArrowState({
                    ...arrow,
                    rotatePoint: {
                      x:
                        arrow.focalPoint.x +
                        Math.cos(newAngleRad) * arrowRotateDragRectDistCenter,
                      y:
                        arrow.focalPoint.y +
                        Math.sin(newAngleRad) * arrowRotateDragRectDistCenter,
                    },
                  });
              }}
            >
              <G>
                <StyledArrowDragRect />
                <G
                  transform={`translate(-${
                    arrowRotateDragRectClickableWidth / 2 -
                    arrowRotateDragRectWidth / 2
                  }, -${
                    arrowRotateDragRectClickableWidth / 2 -
                    arrowRotateDragRectWidth / 2
                  })`}
                >
                  <StyledArrowDragClickableRect />
                </G>
              </G>
            </Draggable>
          ) : null}
        </G>
      </>
    </G>
  );
}

const FocalPointCircle = (props: {
  isActive: boolean;
  editable: boolean;
  cx: number;
  cy: number;
  onPress: () => void;
}) => {
  return (
    <StyledCircle
      cx={props.cx}
      cy={props.cy}
      onClick={props.onPress}
      arrowsEditable={props.editable}
      fill="none"
      r={circleRadius}
      strokeWidth={2}
      stroke={props.isActive ? Colors.BRAND_SUCCESS : Colors.BRAND_WHITE}
    />
  );
};

const StyledCircle = styled(Circle)<{
  arrowsEditable: boolean;
  onClick: () => void;
}>`
  cursor: ${(props) => (props.arrowsEditable ? 'grab' : 'auto')};
  ${webSpecificValue('pointer-events: all;')}
`;

const StyledArrowDragRect = styled(Rect)`
  width: ${arrowRotateDragRectWidth}px;
  height: ${arrowRotateDragRectWidth}px;
  stroke: ${Colors.BRAND_WHITE};
  fill: ${Colors.BRAND_SUCCESS};
  ${webSpecificValue('pointer-events: none;')}
`;
const StyledArrowDragClickableRect = styled(Rect)`
  width: ${arrowRotateDragRectClickableWidth}px;
  height: ${arrowRotateDragRectClickableWidth}px;
  stroke: none;
  fill: none;
  cursor: grab;
  ${webSpecificValue('pointer-events: all;')}
`;

export default AnnotationPoint;
