// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { productionEnvironment } from './environment.prod';
import { testEnvironment } from './environment.test';

const NODE_ENV = process.env.NODE_ENV;
export const defaultEnvironment = {
  production: false,
  ENV: 'development',
  API_URL: 'http://localhost:3333',
  AUTH0_DOMAIN: 'dermloop-dev.eu.auth0.com',
  AUTH0_CLIENT_ID: 'boztdnISxJNojILfcIX5JeWSqXDLtnuM',
  AUTH0_AUDIENCE: 'localhost:3333',
};

export const environment =
  NODE_ENV === 'prod'
    ? productionEnvironment
    : NODE_ENV === 'test'
    ? testEnvironment
    : defaultEnvironment;
