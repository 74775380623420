import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import {
  EditTrainingConfigurationDto,
  TrainingConfigurationDto,
} from '../training';

export interface LessonDto {
  id: string;
  levelId: string;
  latestUserLessonId?: string;
  title: string;
  description: string;
  duration: string;
  published: boolean;
  isOptional: boolean;
  articleId?: string;
  quizMinimumAccuracy?: number;
  trainingConfiguration?: TrainingConfigurationDto;
  trainingConfigurationId?: string;
  trainingSessionId?: string;
  lessonNumber: number;
  started?: boolean;
  completed?: boolean;
}

export interface UserLessonDto {
  id: string;
  lesson: LessonDto;
}

export class UpdateLessonDto {
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  title?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  description?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  duration?: string;

  @IsOptional()
  @IsBoolean()
  published?: boolean;

  // False or null are regarded as the same
  @IsOptional()
  @IsBoolean()
  isOptional?: boolean;

  @ValidateIf((o) => o.trainingConfigurationId === null)
  @IsNotEmpty()
  @IsUUID()
  articleId?: string;

  @ValidateIf((o) => o.articleId == null)
  @IsNumber()
  @Min(0)
  @Max(1)
  quizMinimumAccuracy?: number;

  @ValidateIf((o) => o.articleId == null)
  @ValidateNested()
  trainingConfiguration?: EditTrainingConfigurationDto;
}

export class CreateLessonDto {
  @IsNotEmpty()
  @IsUUID()
  levelId: string;

  @IsNotEmpty()
  @IsString()
  title: string;

  @IsNotEmpty()
  @IsString()
  description: string;

  @IsNotEmpty()
  @IsString()
  duration: string;

  @IsOptional()
  @IsBoolean()
  published?: boolean;

  @IsOptional()
  @IsBoolean()
  isOptional?: boolean;

  @ValidateIf((o) => o.trainingConfigurationId === null)
  @IsNotEmpty()
  @IsUUID()
  articleId?: string;

  @ValidateIf((o) => o.articleId == null)
  @IsNumber()
  @Min(0)
  @Max(1)
  quizMinimumAccuracy?: number;

  @ValidateIf((o) => o.articleId == null)
  @ValidateNested()
  trainingConfiguration?: EditTrainingConfigurationDto;
}

export class StartLessonDto {
  @IsNotEmpty()
  @IsUUID()
  lessonId: string;

  @IsNotEmpty()
  @IsUUID()
  userLevelId: string;
}

/**
 * DTO for changing order of courses.
 */
export class ChangeLessonOrderDto {
  @Type(() => LessonOrderDto)
  @ValidateNested({ each: true })
  lessons: LessonOrderDto[];
}

/**
 * DTO for changing the order of a single course.
 */
export class LessonOrderDto {
  @IsNotEmpty()
  @IsUUID()
  lessonId: string;

  @IsNotEmpty()
  @IsNumber()
  @Min(1)
  lessonNumber: number;
}

/**
 * DTO for changing level publish state.
 */
export class LessonPublishDto {
  @IsNotEmpty()
  @IsUUID()
  lessonId: string;

  @IsBoolean()
  published: boolean;
}
