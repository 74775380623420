import { Colors, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components';
import ImageMedia from '../image-media/image-media';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import Spacer from '../spacer/spacer';

/* eslint-disable-next-line */
export interface ListItemProps {
  images: {
    // Src of image to display, e.g. urls, icons etc.
    src: string;
    // Loading state of image src. E.g. if fetching URL from API
    loadingSrc?: boolean;
  }[];
  title?: string;
  action?: string;
  actionDetailAndStatus?: string;
  date?: string;
  diagnosis?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  selected?: boolean;
  hovered?: boolean;
}

const StyledListItem = styled.div<{ selected?: boolean; hovered?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  position: relative;
  border-bottom: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  border-right: ${(props) =>
    !props.selected
      ? '8px solid ' + Colors.TRANSPARENT
      : '8px solid ' + Colors.BRAND_SUCCESS};
  background-color: ${(props) =>
    props.hovered
      ? Colors.BACKGROUND_WHITE
      : Colors.BRAND_SECONDARY_TRANSPARENT_BACKGROUND};
  cursor: ${(props) => (props.hovered ? 'pointer' : null)};
`;

const Images = styled.div<{ wide?: boolean }>`
  justify-content: center;
`;

const Image = styled.div`
  display: flex;
  flex: 1;
  width: 100px;
  position: relative;
`;

const Details = styled.div`
  line-height: 1.375rem;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DetailDate = styled.div``;

const DetailAction = styled.div<{ hasDetails: boolean }>`
  font-weight: ${Sizes.MEDIUM_WEIGHT};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow: ${({ hasDetails }) => (hasDetails ? 'visible' : 'hidden')};
`;

const DetailDiagnosis = styled.span`
  padding-left: 0.35rem;
  color: ${Colors.BRAND_SUCCESS};
  font-weight: ${Sizes.REGULAR_WEIGHT};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DetailActionStatus = styled.div<{ pending: boolean; disabled: boolean }>`
  padding-left: 0.35rem;
  color: ${({ pending, disabled }) =>
    pending
      ? Colors.BRAND_MANDATORY
      : disabled
      ? Colors.BRAND_TERTIARY
      : Colors.TEXT};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledTitle = styled.span<{ ssnStyle: boolean }>`
  color: ${(props) => (props.ssnStyle ? Colors.BRAND_SUCCESS : 'none')};
  font-weight: ${(props) => (props.ssnStyle ? '600' : '')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: ${({ ssnStyle }) => (ssnStyle ? 'hidden' : 'visible')};
`;

const DetailIncomplete = styled.div`
  padding-left: 0.35rem;
  color: ${Colors.BRAND_WARNING};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

/**
 *
 * @param props
 */
export function ListItem(props: ListItemProps) {
  return (
    <StyledListItem
      onClick={props.onClick}
      selected={props.selected}
      hovered={props.hovered}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
    >
      <Images wide={props.images?.length > 1}>
        {props.images.map((i, idx) => (
          <Image key={idx}>
            {i.loadingSrc ? <LoadingSpinner /> : <ImageMedia src={i.src} />}
            <Spacer />
          </Image>
        ))}
      </Images>
      <Details>
        <DetailContainer>
          <StyledTitle ssnStyle={props.diagnosis === undefined ? true : false}>
            {props.title}
          </StyledTitle>
          {props.diagnosis ? (
            <DetailDiagnosis>• {props.diagnosis}</DetailDiagnosis>
          ) : null}
        </DetailContainer>
        <DetailContainer key={'lesion-action-' + props.title}>
          <DetailAction hasDetails={props.actionDetailAndStatus != null}>
            {props.action}
          </DetailAction>
          {props.actionDetailAndStatus ? (
            <DetailActionStatus
              pending={props.actionDetailAndStatus === 'Pending diagnostic'}
              disabled={props.actionDetailAndStatus === 'Diagnosed'}
            >
              {' '}
              • {props.actionDetailAndStatus}
            </DetailActionStatus>
          ) : null}
        </DetailContainer>
        <DetailContainer key={'lesion-date-' + props.title}>
          <DetailDate>{props.date}</DetailDate>
        </DetailContainer>
      </Details>
    </StyledListItem>
  );
}

export default ListItem;
