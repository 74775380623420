import {
  IsArray,
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import {
  DermloopSearchIndexName,
  DermloopSearchIndexNameDtoMapping,
} from '../search.constants';
import { SearchIndexMetadataDto } from './index-metadata.dto';

/**
 * DTO used for sending indexing results from search microservice to API.
 */
export class SearchIndexResultDto<T extends DermloopSearchIndexName> {
  @IsEnum(DermloopSearchIndexName)
  indexName: T;

  @IsArray()
  // The entities that were indexed and boolean specifying if index was successful or failed
  indexedEntities: DermloopSearchIndexNameDtoMapping[T][];

  @IsBoolean()
  success: boolean;

  @IsOptional()
  @ValidateNested()
  metadata?: SearchIndexMetadataDto;
}

export class SearchDeleteResultDto {
  @IsEnum(DermloopSearchIndexName)
  indexName: DermloopSearchIndexName;

  @IsBoolean()
  success: boolean;

  // DTO that was sent to search microservice for deletion
  @IsNotEmpty()
  sentDeleteDto: any;
}

export interface DeleteIndicesDto {
  indices: DermloopSearchIndexName[];
  // Metadata that can be set, which will be returned in the DeleteIndicesResultDto
  doReindex: boolean;
}

export interface DeleteIndicesResultDto {
  successIndexNames: DermloopSearchIndexName[];
  failedIndexNames: DermloopSearchIndexName[];
  doReindex: boolean;
}
