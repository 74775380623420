import { HotspotSize } from '@dermloop/ui/components';
import { Colors } from '@dermloop/ui/util';
import styled from 'styled-components';

export interface HotspotProps {
  size?: HotspotSize;
  onClick?: () => void;
  onHover?: () => void;
  onHoverEnd?: () => void;
  occluded?: boolean;
  active?: boolean;
  removed?: boolean;
}

const StyledHotspotCenter = styled.div<{
  transparent?: boolean;
  active?: boolean;
}>`
  border-radius: 100%;
  background: ${(props) =>
    props.active ? Colors.BRAND_SUCCESS : 'transparent'};
  flex-grow: 1;
  transition: background 0.2s;

  border: 2px solid ${Colors.BRAND_SUCCESS};
`;

const StyledCross = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    left: 9px;
    height: 20px;
    width: 2px;
    background-color: ${Colors.DANGER};
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const StyledHotspot = styled.div<{
  active?: boolean;
  transparent?: boolean;
  size?: HotspotSize;
}>`
  border-radius: 100%;
  width: ${(props) => (props.size === 'small' ? 7 : 14)}px;
  display: flex;
  padding: 3px;
  height: ${(props) => (props.size === 'small' ? 7 : 14)}px;
  cursor: pointer;
  ${(props) => props.transparent && 'opacity: 0.5'};
  border: ${(props) => (props.size === 'small' ? 1 : 2)}px solid
    ${(props) => (props.active ? Colors.BRAND_SUCCESS : 'transparent')};
  transition: border 0.2s;

  &:hover {
    border: 2px solid ${Colors.BRAND_SUCCESS};
  }

  &:hover ${StyledHotspotCenter} {
    background: ${Colors.BRAND_SUCCESS};
  }
`;

export function Hotspot(props: HotspotProps) {
  return (
    <StyledHotspot
      size={props.size}
      onClick={props.onClick}
      onMouseEnter={props.onHover}
      onMouseLeave={props.onHoverEnd}
      active={props.active}
      transparent={props.occluded}
    >
      <StyledHotspotCenter active={props.active} />
      {props.removed && <StyledCross />}
    </StyledHotspot>
  );
}

export default Hotspot;
