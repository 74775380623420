import { Colors, Icons } from '@dermloop/ui/util';
import styled from 'styled-components';

export interface EmptyStateProps {
  message: string;
}

const StyledEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Icon = styled.img``;
const Message = styled.p`
  color: ${Colors.BRAND_BROWN_DARK};
  padding: 1rem;
  text-align: center;
`;

export function EmptyState(props: EmptyStateProps) {
  return (
    <StyledEmptyState>
      <Icon src={Icons.InvisibleDark}></Icon>
      <Message>{props.message}</Message>
    </StyledEmptyState>
  );
}

export default EmptyState;
