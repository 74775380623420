import React from 'react';
import { Colors, Icons, IconType } from '@dermloop/ui/util';

export type IconSizeType = 'natural' | 'parent';

export interface IconProps {
  icon: IconType;

  /** Currently only supported in native due to how svgs are handled */
  fill?: 'dark' | 'light' | 'none';
  /** Overrides fill if present.
   * Currently only supported in native due to how svgs are handled */
  customFill?: string;

  // Default 'natural'
  size?: IconSizeType;
}

export function Icon(props: IconProps) {
  if (!props.icon) return null;
  const icon = Icons[props.icon];
  if (!icon) return null;
  if (typeof document != 'undefined') {
    return (
      <img
        src={icon.toString()}
        alt={props?.icon}
        style={props.size === 'parent' ? { width: '100%', height: '100%' } : {}}
      />
    );
  } else {
    return React.createElement(icon, {
      ...(props.size === 'parent' ? { width: '100%', height: '100%' } : {}),
      fill: props.customFill
        ? props.customFill
        : props.fill === 'light'
        ? Colors.BRAND_WHITE
        : props.fill === 'none'
        ? null
        : Colors.BRAND_PRIMARY,
    });
  }
}

export default Icon;
