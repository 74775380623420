export enum DAHTSecondOpinion {
  No = 'No', // Users with access to learn
  YesPolicyRequirement = 'YesPolicyRequirement', // Intended for executive users and all developers
  Yes = 'Yes', // Executive users of a medical unit, sort of like a medical unit admin
}

export type DAHTRelevance =
  | 'very-relevant'
  | 'relevant'
  | 'neither-relevant-nor-irrelevant'
  | 'irrelevant'
  | 'very-irrelevant';

export const DAHTDermoscopyRelevance: { value: DAHTRelevance; name: string }[] =
  [
    { name: 'Very irrelevant', value: 'very-irrelevant' },
    { name: 'Irrelevant', value: 'irrelevant' },
    {
      name: 'Neither relevant nor irrelevant',
      value: 'neither-relevant-nor-irrelevant',
    },
    { name: 'Relevant', value: 'relevant' },
    { name: 'Very relevant', value: 'very-relevant' },
  ];

//This allows to create a type and use it with class validator.
export const DAHTNumericalValues = ['1', '2', '3', '4', '5', '6'] as const;
export type DAHTNumericalValue = typeof DAHTNumericalValues[number];
