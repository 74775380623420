import {
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { LesionImageDto } from './lesion-image.dtos';
import { LesionTriageDto } from './lesion-triage.dtos';
import { DermloopAssessmentTypeEnum } from '@dermloop/static-values';
import { PathologicalAssessmentDto } from '../clinical-actions/pathological-assessment.dtos';
import { TeledermatologicalAssessmentDto } from '../clinical-actions/teledermatological-assessment.dtos';

export class LesionHistoryDto implements BaseDto {
  @IsString()
  id: string;

  @IsString()
  date: string;

  /**
   * @deprecated legacy field for a single image
   */
  clinicalImage?: LesionImageDto;
  /**
   * @deprecated legacy field for a single image
   */
  dermoscopicImage?: LesionImageDto;

  clinicalImages?: LesionImageDto[];
  dermoscopicImages?: LesionImageDto[];

  @IsOptional()
  @IsNotEmpty()
  triage?: LesionTriageDto;

  @IsOptional()
  @ValidateNested()
  pathologicalAssessment?: PathologicalAssessmentDto;

  @IsOptional()
  @IsNotEmpty()
  teledermatologicalAssessment?: TeledermatologicalAssessmentDto;

  @IsEnum(DermloopAssessmentTypeEnum)
  @IsOptional()
  assessmentType?: DermloopAssessmentTypeEnum;
}
