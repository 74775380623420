import {
  AnnotationFilterStatus,
  AnnotationTool,
} from '@dermloop/static-values';
import { Max, Min } from 'class-validator';
import { DermoscopicCriteriaDto } from '../diagnoses/dermoscopic-criteria.dtos';
import { UserDto } from '../user.dto';

/**
 * Dto for requesting annotation list dtos.
 */
export class AnnotationListDto {
  selectedDiagnosisIds?: string[];
  selectedDifficulty?: { min: number; max?: number };
  // selectedEasy?: AnnotationFilterEasy;
  selectedLocationIds?: string[];
  selectedRaters?: { min: number; max?: number };
  selectedStatus?: AnnotationFilterStatus;
  @Min(0)
  skip?: number;
  @Max(200)
  @Min(1)
  take? = 10;
}

/**
 * Annotation containing data for annotation overview row.
 */
export interface AnnotationListResultDto {
  // Training lesion id
  id: string;
  // True = approved. false = annotated but not approved. Null = not annotated
  approved?: boolean;
  diagnosisName: string;
  locationName: string;
  raters?: number;
  difficulty?: number;
  annotationRequestsCount: number;
}

/**
 * Overall details for the annotation page. Does not include the actual annotations,
 * but all supporting data.
 */
export interface TrainingLesionAnnotationOverviewDto {
  trainingLesionId: string;
  imageId: string;
  imageUrl: string;
  difficulty?: number;
  diagnosisId: string;
  diagnosisName: string;
  formerRatersTotalCount?: number;
  formerRaters?: {
    diagnosisGroupName: string;
    answerCount: number;
  }[];
  classicCriteria?: {
    id: string;
    name: string;
    diagnosisGroupName?: string;
  }[];
  annotations: AnnotationDto[];
}

/**
 * DTO used for creating an annotation for a training lesion.
 */
export interface CreateAnnotationDto {
  trainingLesionId: string;
  // The id of the image that was annotated. Stored in case the image is changed for a training lesion
  trainingLesionImageId: string;
  // Optional, as there might not be any criteria, but user has added a comment
  annotatedCriteria?: CriteriaAnnotationDto[];
  comment?: string;
  imageWidth: number;
  imageHeight: number;
}

export interface AnnotationDto {
  id: string;
  createdByUser: UserDto;
  created: Date;
  approved?: boolean;
  approvedBy?: UserDto;
  criteriaAnnotations?: CriteriaAnnotationDto[];
  comment?: string;
  imageWidth: number;
  imageHeight: number;
  imageId: string;
}

export interface CriteriaAnnotationDto {
  // Optional id, used for state in frontend
  id?: string;
  annotate: boolean;
  tool?: AnnotationTool;
  // Normalized data when coming from API. Absolute points when working with data in frontend
  data?: CriteriaAnnotationArrowDto[] | CriteriaAnnotationAreaDto[];
  dermoscopicCriteria: DermoscopicCriteriaDto;
}

/**
 * Interface representing a collection of arrows. Ids are solely used for display purposes.
 */
export interface CriteriaAnnotationArrowCollectionDto {
  [arrowId: string]: CriteriaAnnotationArrowDto;
}

/**
 * Interface representing a collection of areas½. Ids are solely used for display purposes.
 */
export interface CriteriaAnnotationAreaCollectionDto {
  [areaId: string]: CriteriaAnnotationAreaDto;
}

export interface CriteriaAnnotationArrowDto {
  focalPoint: AnnotationPointDto;
  rotatePoint: AnnotationPointDto;
}

export interface CriteriaAnnotationAreaDto {
  points: AnnotationPointDto[];
}

export interface AnnotationPointDto {
  x: number;
  y: number;
}
