import {
  ManageTrainingLesionDto,
  ManageTrainingLesionListDto,
  ManageTrainingLesionListResultDto,
  Page,
} from '@dermloop/api-dtos';
import { TrainingLesionStatus } from '@dermloop/static-values';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ManageTrainingLesionSlice = {
  fetchingTrainingLesionList?: boolean;
  trainingLesionListPage?: Page<ManageTrainingLesionListResultDto>;
  fetchingTrainingLesionIds?: boolean;
  trainingLesionIds?: string[];
  fetchingTrainingLesion?: boolean;
  trainingLesion?: ManageTrainingLesionDto;
  currentLesionIndex?: number;
  filterValues: ManageTrainingLesoinFilterValues;
};

const initialState: ManageTrainingLesionSlice = {
  filterValues: {},
};

const slice = createSlice({
  name: 'manageTrainingLesion',
  initialState,
  reducers: {
    fetchTrainingLesionList: (
      state,
      _: PayloadAction<{ dto: ManageTrainingLesionListDto }>
    ) => ({
      ...state,
      fetchingTrainingLesionList: true,
    }),
    // Set the current lesion index
    setLesionIndex: (state, action: PayloadAction<{ newIndex: number }>) => ({
      ...state,
      currentLesionIndex: action.payload?.newIndex,
    }),
    // Sets the current training lesion ids. Can be used to explicitly fetch select training lesion ids
    setTrainingLesionIds: (
      state,
      action: PayloadAction<{ ids: string[] }>
    ) => ({
      ...state,
      trainingLesionIds: action.payload.ids,
    }),
    fetchTrainingLesionIds: (
      state,
      _: PayloadAction<{ dto: ManageTrainingLesionListDto }>
    ) => ({
      ...state,
      fetchingTrainingLesionIds: true,
    }),
    fetchTrainingLesion: (
      state,
      action: PayloadAction<{ trainingLesionId: string }>
    ) => ({
      ...state,
      fetchingTrainingLesion: true,
    }),
    update: (
      state,
      action: PayloadAction<{ update: Partial<ManageTrainingLesionSlice> }>
    ) => {
      return { ...state, ...action.payload?.update };
    },
  },
});

export interface ManageTrainingLesoinFilterValues {
  selectedFilterStatus?: TrainingLesionStatus;
}

export const trainingLesionActions = slice.actions;
export const trainingLesionReducer = slice.reducer;

export type ManageTrainingLesionState = {
  manageTrainingLesion: {
    filterValues: ManageTrainingLesoinFilterValues;
    currentLesionIndex?: number;
    trainingLesionIds?: string[];
  };
};
