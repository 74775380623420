/**
 * Given 3 rgb numbers and an alpha values, returns an rgba string.
 * @param rgb
 * @param alpha
 * @returns
 */
export const rgba = (rgb: number[], alpha: number) =>
  `rgba(${rgb.join(', ')}, ${alpha})`;

/**
 * Given 3 rgb numbers, returns an rgba string with alpha 1.
 * @param rgb
 * @returns
 */
export const rgb = (rgb: number[]) => `rgba(${rgb.join(', ')}, 1)`;

/**
 * RGB values
 */
export const RGB_BLACK = [0, 0, 0];
export const RGB_WHITE = [255, 255, 255];
export const RGB_PRIMARY = [40, 38, 37];
export const RGB_SECONDARY = [128, 128, 128];
export const RGB_TERTIARY = [191, 191, 191];
export const RGB_SUCCESS = [24, 99, 70];
export const RGB_WARNING = [247, 116, 116];
export const RGB_MANDATORY = [255, 194, 85];
export const RGB_PRIMARY_BACKGROUND = [233, 237, 233];
export const RGB_SECONDARY_BACKGROUND = [233, 233, 233];
export const RGB_INFO = [0, 123, 173];

export const BRAND_PRIMARY = rgb(RGB_PRIMARY);
export const BRAND_SECONDARY = rgb(RGB_SECONDARY);
export const BRAND_TERTIARY = rgb(RGB_TERTIARY);
export const BRAND_INFO = rgb(RGB_INFO);

export const BRAND_SUCCESS = rgb(RGB_SUCCESS);
export const BRAND_WARNING = rgb(RGB_WARNING);
export const BRAND_MANDATORY = rgb(RGB_MANDATORY);

export const BRAND_BLACK = rgb(RGB_BLACK);
export const BRAND_WHITE = rgb(RGB_WHITE);

export const BRAND_PRIMARY_BACKGROUND = rgb(RGB_PRIMARY_BACKGROUND);
export const BRAND_SECONDARY_BACKGROUND = rgba(RGB_SECONDARY_BACKGROUND, 0.5);
export const BRAND_SECONDARY_TRANSPARENT_BACKGROUND =
  BRAND_SECONDARY_BACKGROUND;

export const BRAND_BROWN_DARK = BRAND_PRIMARY;
export const BRAND_GRAY_MEDIUM = '#D1D3D6';
export const BACKGROUND = '#F7F8F8';
export const BACKGROUND_WHITE = BRAND_WHITE;
export const BACKGROUND_DARK = BRAND_PRIMARY;
export const TEXT = BRAND_PRIMARY;
export const TEXT_NEGATIVE = BRAND_WHITE;
export const TEXT_ALTERNATIVE = BRAND_SUCCESS;

export const BRAND_TERTIARY_BACKGROUND = rgba(RGB_TERTIARY, 0.5);

export const TRANSPARENT = rgba(RGB_BLACK, 0);
export const LIGHT_SHADOW_BACKGROUND = rgba(RGB_BLACK, 0.1);
export const MEDIUM_SHADOW_BACKGROUND = rgba(RGB_BLACK, 0.2);
export const DARK_TRANSPARENT_BACKGROUND = rgba(RGB_BLACK, 0.5);
export const DARK_SEMI_TRANSPARENT_BACKGROUND = rgba(RGB_BLACK, 0.65);
export const LIGHT_TRANSPARENT_BACKGROUND = rgba(RGB_BLACK, 0.3);
export const WHITE_TRANSPARENT_BACKGROUND = rgba(RGB_WHITE, 0.8);

export const OVERLAY_TRANSPARENT_BACKGROUND = rgba(RGB_PRIMARY, 0.98);
export const BRAND_SUCCESS_BACKGROUND = rgba(RGB_SUCCESS, 0.1);
export const LIGHT_BRAND_SUCCESS = rgba(RGB_SUCCESS, 0.3);

export const SUCCESS = BRAND_SUCCESS;
export const WARNING = BRAND_MANDATORY;
export const DANGER = BRAND_WARNING;

export const HOVER_SOLID_PRIMARY_BUTTON = '#246B4F';
export const PRESS_SOLID_PRIMARY_BUTTON = '#3B7A62';

export const HOVER_SOLID_SECONDARY_BUTTON = '#DCE8E3';
export const PRESS_SOLID_SECONDARY_BUTTON = '#BAD0C8';

export const HOVER_SOLID_DANGER_BUTTON = '#F77B7B';
export const PRESS_SOLID_DANGER_BUTTON = '#F88282';

export const HOVER_SUCCESS_BUTTON = rgba(RGB_SUCCESS, 0.05);
export const PRESS_SUCCESS_BUTTON = rgba(RGB_SUCCESS, 0.1);
export const HOVER_PRIMARY_BUTTON = rgba(RGB_WHITE, 0.2);
export const PRESS_PRIMARY_BUTTON = rgba(RGB_WHITE, 0.3);
export const HOVER_EXTRA_BUTTON = 'rgb(220, 232, 227)';
export const PRESS_EXTRA_BUTTON = 'rgb(186, 208, 199)';

export const TAG_YELLOW_BACKGROUND = rgba(RGB_MANDATORY, 0.3);
export const TAG_RED_BACKGROUND = rgba(RGB_WARNING, 0.3);

export const HEADER_NAVIGATION_HOVER = rgba(RGB_SECONDARY, 0.25);
export const HEADER_NAVIGATION_ACTIVE = rgba(RGB_SECONDARY, 0.5);

/**
 * Colors for medical unit role tags
 */
export const RGB_MEDICAL_UNIT_MANAGER = [141, 43, 0];
export const RGB_MEDICAL_UNIT_PARTICIPANT = [181, 81, 25];
export const RGB_MEDICAL_UNIT_TELEDERMATOLOGIST = [92, 44, 12];
export const RGB_MEDICAL_UNIT_TRAINEE = [60, 58, 30];
export const RGB_MEDICAL_UNIT_SUPERVISOR = [19, 72, 66];
export const RGB_MEDICAL_UNIT_PATHOLOGIST = [19, 72, 66];

export const MEDICAL_UNIT_MANAGER_TAG_BACKGROUND = rgba(
  RGB_MEDICAL_UNIT_MANAGER,
  0.3
);
export const MEDICAL_UNIT_MANAGER_TAG_COLOR = rgb(RGB_MEDICAL_UNIT_MANAGER);
export const MEDICAL_UNIT_PARTICIPANT_TAG_BACKGROUND = rgba(
  RGB_MEDICAL_UNIT_PARTICIPANT,
  0.3
);
export const MEDICAL_UNIT_PARTICIPANT_TAG_COLOR = rgb(
  RGB_MEDICAL_UNIT_PARTICIPANT
);
export const MEDICAL_UNIT_TELEDERMATOLOGIST_TAG_BACKGROUND = rgba(
  RGB_MEDICAL_UNIT_TELEDERMATOLOGIST,
  0.3
);
export const MEDICAL_UNIT_TELEDERMATOLOGIST_TAG_COLOR = rgb(
  RGB_MEDICAL_UNIT_TELEDERMATOLOGIST
);
export const MEDICAL_UNIT_TRAINEE_TAG_BACKGROUND = rgba(
  RGB_MEDICAL_UNIT_TRAINEE,
  0.3
);
export const MEDICAL_UNIT_TRAINEE_TAG_COLOR = rgb(RGB_MEDICAL_UNIT_TRAINEE);
export const MEDICAL_UNIT_SUPERVISOR_TAG_BACKGROUND = rgba(
  RGB_MEDICAL_UNIT_SUPERVISOR,
  0.3
);
export const MEDICAL_UNIT_SUPERVISOR_TAG_COLOR = rgb(
  RGB_MEDICAL_UNIT_SUPERVISOR
);
export const MEDICAL_UNIT_PATHOLOGIST_TAG_BACKGROUND = rgba(
  RGB_MEDICAL_UNIT_PATHOLOGIST,
  0.3
);
export const MEDICAL_UNIT_PATHOLOGIST_TAG_COLOR = rgb(
  RGB_MEDICAL_UNIT_PATHOLOGIST
);
