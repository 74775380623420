import { configureApi, UserClient } from '@dermloop/api-client';
import { Action } from '@reduxjs/toolkit';
import axios from 'axios';
import { Epic } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { MinimalState } from '../state';
import { authenticationActions } from './authentication.slice';

const checkAuthenticatedEpic: Epic<Action, Action, MinimalState> = (
  action$,
  state
) =>
  action$.pipe(
    filter(authenticationActions.authenticate.match),
    map((action) => {
      configureApi({ token: action.payload.token });
      return authenticationActions.fetchCurrentUser();
    })
  );

const onFetchCurrentUser: Epic<Action, Action, MinimalState> = (
  action$,
  state
) =>
  action$.pipe(
    filter(authenticationActions.fetchCurrentUser.match),
    switchMap((action) => {
      return UserClient.getCurrentUser();
    }),
    map((response) => {
      console.log('got user', response);
      return !axios.isAxiosError(response)
        ? authenticationActions.fetchedCurrentUser(response.data)
        : authenticationActions.error({
            message: response.message,
            error: response,
          });
    })
  );

const updateUserEpic: Epic<Action, Action, MinimalState> = (action$, state) =>
  action$.pipe(
    filter(authenticationActions.updateUser.match),
    switchMap((action) => {
      return UserClient.updateUser(action.payload.update);
    }),
    map((response) => {
      return !axios.isAxiosError(response)
        ? authenticationActions.update({
            currentUser: response.data,
            updating: false,
          })
        : authenticationActions.error({
            message:
              response?.response?.data?.message?.toString() || response.message,
            error: response,
          });
    })
  );

const applicationReadyEpic: Epic<Action, Action, MinimalState> = (
  action$,
  state
) =>
  action$.pipe(
    filter(
      (a) =>
        authenticationActions.fetchedCurrentUser.match(a) ||
        authenticationActions.update.match(a)
    ),
    switchMap((a) => {
      if (state.value.authentication?.medicalUnit?.id) {
        configureApi({
          medicalUnitId: state.value.authentication.medicalUnit.id,
        });
      }

      if (
        state.value.authentication?.medicalUnit?.id &&
        state.value.authentication.currentUser &&
        a
      ) {
        console.log('Application is ready!');
        return of(authenticationActions.authenticated({}));
      }
      return EMPTY;
    })
  );

const logoutEpic: Epic<Action, Action, MinimalState> = (action$, state) =>
  action$.pipe(
    filter(authenticationActions.logout.match),
    switchMap((a) => {
      configureApi({
        medicalUnitId: null,
        token: null,
      });
      return EMPTY;
    })
  );

export const authenticationEpics = [
  checkAuthenticatedEpic,
  applicationReadyEpic,
  onFetchCurrentUser,
  updateUserEpic,
  logoutEpic,
];
