import { Colors, Sizes } from '@dermloop/ui/util';
import { ReactNode, useEffect, useState } from 'react';
import { BackHandler, Pressable } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { EdgeInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import {
  Button,
  ButtonPropColor,
  ButtonPropDisplay,
  ButtonPropType,
} from '../button/button';
import Icon from '../icon/icon';
import { Typography } from '../typography/typography';
import { platformSpecificValue, webSpecificValue } from '../utils';
import { useKeyboardHeight } from '@dermloop/ui/util';

const DEFAULT_MAX_WIDTH = 400;

/* eslint-disable-next-line */
export interface AlertProps {
  title: string;
  content: ReactNode;
  edgeInsets?: EdgeInsets;
  disableCloseOnBackdropPress?: boolean;
  // Default normal
  alertStyle?: 'bottom' | 'normal';
  noContentPadding?: boolean;

  // Buttons to display at bottom of alert.
  // If empty array provided, no buttons are shown.
  // If undefined provided, a centered close-button will be shown.
  actions?: {
    onPress: (
      close: () => void,
      setLoading: (loading: boolean) => void
    ) => void;
    title: string;
    type?: ButtonPropType;
    color?: ButtonPropColor;
    disabled?: boolean;
    // Defaults to inline if only 1 action, else defaults to block
    display?: ButtonPropDisplay;
    loading?: boolean;
  }[];

  // Optional specify how the actions should be positioned with flex. Defaults to 'center'
  actionJustifyContent?: 'flex-start' | 'center' | 'flex-end';

  // Optional onClose function. If none provided, user cannot close alert by clicking on background
  onClose?: () => void;
  showCloseIcon?: boolean;

  // Optional max width of alert. Default to DEFAULT_MAX_WIDTH
  // Note: only works in web
  maxWidth?: number;
}

const StyledAlertContainer = styled.Pressable`
  position: ${platformSpecificValue('fixed', 'absolute')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  background: ${Colors.DARK_TRANSPARENT_BACKGROUND};
  justify-content: center;

  align-items: center;
`;

const StyledAlert = styled.View<AlertProps>`
  ${(props) => (props.alertStyle === 'bottom' ? 'position: absolute;' : '')}
  ${(props) => (props.alertStyle === 'bottom' ? 'bottom: 0;' : '')}
  ${(props) =>
    props.alertStyle === 'bottom'
      ? ''
      : `margin: 0px ${2 * Sizes.GENERAL_SPACING}px`}
  background: ${Colors.BRAND_WHITE};
  ${(props) => (props.alertStyle === 'bottom' ? '' : `max-height: 80%;`)}

  ${(props) =>
    webSpecificValue(`max-width: ${props.maxWidth ?? DEFAULT_MAX_WIDTH}px;`)}
  padding-top: ${Sizes.GENERAL_SPACING}px;
  ${(props) =>
    props.alertStyle === 'bottom'
      ? `border-radius: ${Sizes.BORDER_RADIUS}px;`
      : ''}
  padding-bottom: ${(props) =>
    props.alertStyle === 'bottom'
      ? Math.max(props.edgeInsets?.bottom || 0, Sizes.GENERAL_SPACING)
      : 0.5 * Sizes.GENERAL_SPACING}px;
  ${(props) => (props.alertStyle === 'bottom' ? `width: 100%;` : 'width: 80%;')}
`;

const StyledAlertActions = styled.View<AlertProps>`
  margin-top: 0px;
  justify-content: ${(props) => props.actionJustifyContent || 'center'};
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: ${1.5 * Sizes.GENERAL_SPACING}px;
  padding-left: ${0.5 * Sizes.GENERAL_SPACING}px;
  padding-bottom: ${0.5 * Sizes.GENERAL_SPACING}px;
`;

//Pressable to prevent touch events to bubble up to root pressable
const StyledAlertContent = styled.Pressable<{
  padded?: boolean;
  alertStyle: 'bottom' | 'normal';
}>`
  flex-grow: 1;
  ${(props) =>
    props.padded
      ? `padding: ${
          (props.alertStyle === 'bottom' ? 0 : 1.5) * Sizes.GENERAL_SPACING
        }px ${Sizes.GENERAL_SPACING}px ${Sizes.GENERAL_SPACING}px
    ${Sizes.GENERAL_SPACING}px;`
      : ''}
`;

const StyledAlertHeading = styled.View<AlertProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.BRAND_WHITE};
  margin-top: ${0.5 * Sizes.GENERAL_SPACING}px;
  padding: 0 ${Sizes.GENERAL_SPACING}px ${1.5 * Sizes.GENERAL_SPACING}px
    ${Sizes.GENERAL_SPACING}px;
`;

const StyledLine = styled.View`
  height: 2px;
  width: 100%;
  background: ${Colors.BRAND_GRAY_MEDIUM};
`;

export function Alert(props: AlertProps) {
  const [buttonLoading, setButtonLoading] = useState<boolean[]>([]);
  const keyboardHeight = useKeyboardHeight();

  useEffect(() => {
    const handler = BackHandler.addEventListener('hardwareBackPress', () => {
      props.onClose?.();

      /**
       * From documentation: https://reactnative.dev/docs/0.66/backhandler
       * Returning false will let the event to bubble up & let other event listeners
       * or the system's default back action to be executed.
       */
      return false;
    });

    return () => handler.remove();
  }, [props]);

  return (
    <StyledAlertContainer
      onPress={() => {
        if (!props.disableCloseOnBackdropPress) props.onClose?.();
      }}
    >
      <StyledAlert {...props}>
        {props.title || props.showCloseIcon ? (
          <StyledAlertHeading {...props}>
            {props.title ? (
              <Typography size="normal" weight="medium">
                {props.title}
              </Typography>
            ) : null}

            {props.showCloseIcon ? (
              <Pressable onPress={() => props.onClose?.()}>
                <Icon icon="DarkGreyClose" />
              </Pressable>
            ) : null}
          </StyledAlertHeading>
        ) : null}

        {props.alertStyle !== 'bottom' ? <StyledLine /> : null}

        {/* Enable scrolling when keyboard is opened */}
        <KeyboardAwareScrollView
          {...props}
          extraScrollHeight={150}
          keyboardOpeningTime={750}
          contentInset={{
            bottom: keyboardHeight
              ? keyboardHeight
              : props.alertStyle === 'bottom'
              ? undefined
              : 0.5 * Sizes.GENERAL_SPACING,
          }}
        >
          <StyledAlertContent
            alertStyle={props.alertStyle}
            padded={!props.noContentPadding}
          >
            {typeof props.content === 'string' ? (
              <Typography size="normal">{props.content}</Typography>
            ) : (
              props.content
            )}
          </StyledAlertContent>

          {props.actions?.length || !props.actions ? (
            <StyledAlertActions {...props}>
              {props.actions ? (
                props.actions.map((action, i) => {
                  return (
                    <Button
                      key={i}
                      margin="horizontal"
                      loading={buttonLoading[i]}
                      type={action.type}
                      display={
                        action.display
                          ? action.display
                          : props.actions.length > 1
                          ? 'block'
                          : 'inline'
                      }
                      color={action.color}
                      onPress={() => {
                        action.onPress(
                          () => props.onClose?.(),
                          (loadingState) => {
                            setButtonLoading((loading) => {
                              loading[i] = loadingState;
                              return [...loading];
                            });
                          }
                        );
                      }}
                      disabled={action.disabled}
                    >
                      {action.title}
                    </Button>
                  );
                })
              ) : (
                <Button onPress={() => props.onClose?.()}>Close</Button>
              )}
            </StyledAlertActions>
          ) : null}
        </KeyboardAwareScrollView>
      </StyledAlert>
    </StyledAlertContainer>
  );
}

export default Alert;
