import ArrowLeftLight from './arrow-left-light.svg';
import ClinicalImagePlaceholder from './clinical-image-placeholder.svg';
import DermoscopicImagePlaceholder from './dermoscopic-image-placeholder.svg';
import AddButton from './icon-add.svg';
import AnnotationArrow from './icon-annotation-arrow.svg';
import AnnotationContext from './icon-annotation-context.svg';
import IconAnnotationsOutline from './icon-annotations-outline.svg';
import ArrowBack from './icon-arrow-back.svg';
import ArrowDown from './icon-arrow-down.svg';
import ArrowRight from './icon-arrow-right.svg';
import ArrowUp from './icon-arrow-up.svg';
import ArrowsCollapse from './icon-arrows-collapse.svg';
import ArrowsExpand from './icon-arrows-expand.svg';
import AvatarMissing from './icon-avatar-missing.svg';
import Back from './icon-back.svg';
import BrainOutline from './icon-brain-outline.svg';
import Brain from './icon-brain.svg';
import Calendar from './icon-calendar.svg';
import CheckMark from './icon-checkmark.svg';
import CloseButtonNoOutline from './icon-close-button-no-outline.svg';
import CloseButtonWhiteNoOutline from './icon-close-button-white-no-outline.svg';
import IconCloseOutline from './icon-close-outline.svg';
import CloseRed from './icon-close-red.svg';
import Close from './icon-close.svg';
import ConversationOutline from './icon-conversation-outline.svg';
import Conversation from './icon-conversation.svg';
import DermatoscopeWithBackground from './icon-dermatoscope-background.svg';
import DermoscopicCriteriaPencilBlack from './icon-dermoscopic-criteria-pencil-black.svg';
import DermoscopicCriteriaPencilGreen from './icon-dermoscopic-criteria-pencil-green.svg';
import DownArrow from './icon-down-arrow.svg';
import Draggable from './icon-draggable.svg';
import FolderOutline from './icon-folder-outline.svg';
import Folder from './icon-folder.svg';
import Forward from './icon-forward.svg';
import HomeOutline from './icon-home-outline.svg';
import Home from './icon-home.svg';
import InvisibleDark from './icon-invisible-dark.svg';
import LeftArrowLight from './icon-left-arrow-light.svg';
import LeftArrow from './icon-left-arrow.svg';
import LesionApprovalBlack from './icon-lesion-approval-black.svg';
import LesionApprovalGreen from './icon-lesion-approval-green.svg';
import LesionOldFront from './icon-lesion-old-front.svg';
import LesionOldHover from './icon-lesion-old-hover.svg';
import LesionOldSelected from './icon-lesion-old-selected.svg';
import LesionPendingFront from './icon-lesion-pending-front.svg';
import LesionPendingHover from './icon-lesion-pending-hover.svg';
import LesionPendingSelected from './icon-lesion-pending-selected.svg';
import ListSolid from './icon-list-solid.svg';
import MinusButton from './icon-minus.svg';
import NoOutline from './icon-no-outline.svg';
import NoResults from './icon-no-results.svg';
import Plus from './icon-plus.svg';
import Profile from './icon-profile.svg';
import RemoveButton from './icon-remove.svg';
import IconReportOutline from './icon-report-outline.svg';
import RightArrowLight from './icon-right-arrow-light.svg';
import RightArrow from './icon-right-arrow.svg';
import SearchDark from './icon-search-dark.svg';
import SegueDown from './icon-segue-down.svg';
import SegueUp from './icon-segue-up.svg';
import SettingsWheel from './icon-settings-wheel.svg';
import ShowOutline from './icon-show-outline.svg';
import SignOut from './icon-sign-out.svg';
import ThreeGreen from './icon-three-green.svg';
import TrashBinGrey from './icon-trash-bin-grey.svg';
import TrashBinWhite from './icon-trash-bin-white.svg';
import TrashBin from './icon-trash-bin.svg';
import TwoGreen from './icon-two-green.svg';
import UnitUsers from './icon-unit-users.svg';
import UpArrow from './icon-up-arrow.svg';
import Warning from './icon-warning.svg';
import WiggleLine from './icon-wiggle-line.svg';
import LogoClinic from './logo-clinic.svg';
import LogoDesktop from './logo-desktop.svg';
import LogoMyCases from './logo-my-cases.svg';
import LogoPathologyLight from './logo-pathology-light.svg';
import LogoPathology from './logo-pathology.svg';
import LogoTeleLight from './logo-tele-light.svg';
import LogoTele from './logo-tele.svg';

import ModelControlsDisabled from './icon-model-controls-disabled.svg';
import ModelControlsEnabled from './icon-model-controls-enabled.svg';

import Error from './icon-error.svg';
import FlashDisabled from './icon-flash-disabled.svg';
import FlashEnabled from './icon-flash-enabled.svg';
import FlashRounded from './icon-flash-rounded.svg';
import NoFlashRounded from './icon-no-flash-rounded.svg';

import TakeImageButton from './button-take-image.svg';

import DarkGreyClose from './dark-grey-close.svg';
import { Icons as DifficultyLevelIcons } from './difficulty-levels';
import ConsentIcon from './icon-consent.svg';
import OfflineIcon from './icon-offline.svg';

import BlackPlayArrow from './black-play-arrow.svg';
import GreenCheckMark from './green-checkmark-icon.svg';
import GreenPlayArrow from './green-play-arrow.svg';
import GreyClockIcon from './grey-clock-icon.svg';
import Refresh from './icon-refresh.svg';
import LockIcon from './lock-icon.svg';
import WhiteCheckMark from './white-checkmark-icon.svg';
import WhiteClockIcon from './white-clock-icon.svg';
import WhitePlayArrow from './white-play-arrow.svg';

import HandIcon from './hand-icon.svg';

import FlashGreen from './flash-green-icon.svg';
import FlashGrey from './flash-grey-icon.svg';

import ClinicalTipBad from './clinical-image-bad.svg';
import ClinicalTipGood from './clinical-image-good.svg';

import HandyscopeTip from './handyscope-tip.svg';
import Handyscope from './handyscope.svg';

import EditPencilBlack from './icon-edit-pencil-black.svg';

import TriangleWarning from './icon-triangle-warning.svg';
import Download from './icon-download.svg';
import DownloadWhite from './icon-download.white.svg';

export const Icons = {
  Refresh,
  ModelControlsEnabled,
  ModelControlsDisabled,
  ArrowDown,
  ArrowUp,
  Back,
  ListSolid,
  Forward,
  AnnotationContext,
  Draggable,
  AnnotationArrow,
  ArrowLeftLight,
  ArrowBack,
  EditPencilBlack,
  ClinicalImagePlaceholder,
  DermoscopicImagePlaceholder,
  Plus,
  AddButton,
  ArrowRight,
  RemoveButton,
  ArrowsCollapse,
  ArrowsExpand,
  TrashBin,
  TrashBinWhite,
  TrashBinGrey,
  CheckMark,
  CloseButtonWhiteNoOutline,
  CloseButtonNoOutline,
  Close,
  CloseRed,
  DarkGreyClose,
  DermatoscopeWithBackground,
  IconAnnotationsOutline,
  InvisibleDark,
  LeftArrowLight,
  DownArrow,
  Calendar,
  UpArrow,
  LeftArrow,
  LesionOldFront,
  LesionOldHover,
  Warning,
  LesionOldSelected,
  LesionPendingFront,
  LogoMyCases,
  LesionPendingHover,
  LesionPendingSelected,
  NoOutline,
  NoResults,
  RightArrowLight,
  RightArrow,
  SearchDark,
  ShowOutline,
  ThreeGreen,
  SegueDown,
  SettingsWheel,
  SegueUp,
  TwoGreen,
  LogoDesktop,
  LogoClinic,
  LogoPathology,
  LogoPathologyLight,
  LogoTele,
  IconReportOutline,
  LogoTeleLight,
  AvatarMissing,
  DermoscopicCriteriaPencilBlack,
  DermoscopicCriteriaPencilGreen,
  LesionApprovalBlack,
  LesionApprovalGreen,
  WiggleLine,
  HomeOutline,
  Home,
  FolderOutline,
  Folder,
  BrainOutline,
  Brain,
  ConversationOutline,
  Conversation,
  IconCloseOutline,
  MinusButton,
  FlashEnabled,
  FlashDisabled,
  TakeImageButton,
  OfflineIcon,
  WhitePlayArrow,
  GreenPlayArrow,
  BlackPlayArrow,
  LockIcon,
  WhiteClockIcon,
  GreyClockIcon,
  GreenCheckMark,
  WhiteCheckMark,
  HandIcon,
  FlashGreen,
  FlashGrey,
  ClinicalTipGood,
  ClinicalTipBad,
  Handyscope,
  HandyscopeTip,
  NoFlashRounded,
  FlashRounded,
  SignOut,
  UnitUsers,
  Error,
  Profile,
  TriangleWarning,
  ConsentIcon,
  Download,
  DownloadWhite,
  ...DifficultyLevelIcons,
};
export type IconType = keyof typeof Icons;
