import { BiopsyTypeDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const biopsyTypeEndpoints = (builder: EndpointBuilderType) => ({
  getBiopsyTypes: builder.query<BiopsyTypeDto[], void>({
    query: () => 'biopsy-types',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'BIOPSY_TYPES', id: 'LIST' }],
  }),
});
