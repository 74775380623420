import { Transform } from 'class-transformer';
import {
  IsBoolean,
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { FieldConfigurationDto } from './field-configuration/field-configuration.dto';
import type { JurisdictionalGroupDto } from './jurisdictional-group.dtos';
import type { MedicalUnitGroupDto } from './medical-unit-group.dtos';

/**
 * DTO representing a medical unit.
 */
export class MedicalUnitDto {
  @IsString()
  id: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsEmail()
  sharedEmail?: string;

  @IsOptional()
  @IsString()
  jurisdictionalGroupId?: string;

  @IsOptional()
  jurisdictionalGroup?: JurisdictionalGroupDto;

  @IsString()
  fieldConfigurationId: string;

  @IsOptional()
  fieldConfiguration?: FieldConfigurationDto;

  @IsOptional()
  medicalUnitGroups?: MedicalUnitGroupDto[];

  @IsOptional()
  @IsBoolean()
  useImageBlurrinessDetection?: boolean;
}

export class CreateMedicalUnitDto {
  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsEmail()
  sharedEmail?: string;

  @IsUUID()
  jurisdictionalGroupId: string;

  @IsUUID()
  fieldConfigurationId: string;

  // Note that we do not use uuid validation. This caused issues when in an array
  @IsOptional()
  @IsString({ each: true })
  medicalUnitGroupIds?: string[];

  @IsBoolean()
  useImageBlurrinessDetection: boolean;
}

export class UpdateMedicalUnitDto {
  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsEmail()
  // Empty string is transformed to null
  @Transform(({ value }) => (value?.length > 0 ? value : null))
  sharedEmail?: string;

  @IsOptional()
  @IsUUID()
  jurisdictionalGroupId?: string;

  @IsOptional()
  @IsUUID()
  fieldConfigurationId?: string;

  // Note that we do not use uuid validation. This caused issues when in an array
  @IsOptional()
  @IsString({ each: true })
  medicalUnitGroupIds?: string[];

  @IsOptional()
  @IsBoolean()
  useImageBlurrinessDetection?: boolean;
}

/**
 * DTO for querying medical units.
 */
export interface MedicalUnitSearchQueryDto {
  skip?: number;
  take?: number;
  searchText?: string;
  jurisdictionalGroupId?: string;
}
