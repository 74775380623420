import { Colors, HEADING_FONT_SIZE, REGULAR_WEIGHT } from '@dermloop/ui/util';
import styled from 'styled-components/native';

export interface NoDataProps {
  children: string;
}

export function NoData(props: NoDataProps) {
  return <StyledNoData>{props.children}</StyledNoData>;
}

const StyledNoData = styled.Text`
  background-color: ${Colors.BRAND_SUCCESS_BACKGROUND};
  width: 100%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: ${HEADING_FONT_SIZE}px;
  font-weight: ${REGULAR_WEIGHT};
  color: ${Colors.BRAND_SUCCESS};
`;
