export function distinct<T>(items: Array<T>, distinctBy?: (item: T) => string) {
  if (!items) return items;
  const results: T[] = [];
  const map = new Map();
  for (const item of items) {
    const key = distinctBy ? distinctBy(item) : item;
    if (!map.has(key)) {
      map.set(key, true);
      results.push(item);
    }
  }

  return results;
}
