import {
  Bubble,
  Button,
  ButtonDropdown,
  Icon,
  PaginationSelector,
  SearchBar,
  SearchBarProps,
  Typography,
} from '@dermloop/ui/components';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Colors, GENERAL_SPACING, MEDIUM_WEIGHT } from '@dermloop/ui/util';
import styled from 'styled-components';
import LoadingSpinner from '../loading-spinner/loading-spinner';

export interface DataTableFilter {
  name: string;
  selectedCount?: number;
  component: () => JSX.Element;
}

export interface DataTableColumn {
  name: string;
  align?: 'left' | 'center' | 'right';
}

export interface DataTableRow {
  /**
   * Unique identifier for the row.
   */
  id?: string;

  /**
   * Optional href for row. Entire row will act as link
   */
  href?: string;

  /**
   * Fields to display in the row. Either text or a component.
   */
  fields: ReactNode[];
}
export interface DataTableProps {
  /**
   * List of filters available to the user.
   */
  filters: DataTableFilter[];
  searchbar?: SearchBarProps;
  /**
   * List of columns to display.
   */
  columns: DataTableColumn[];
  /**
   * List of rows to display.
   */
  rows: DataTableRow[];
  totalCount?: number;
  currentPage?: number;
  resetAction?: () => void;
  resetActionTitle?: string;
  noResultsTitle?: string;
  noResultsDescription?: string;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  // Maximum number of showed pages. E.g. if there are results for 1000 pages, but maxPageCount is 10, only 10 pages will be shown and accessible
  maxPageCount?: number;
  fetchingData?: boolean;
  title?: string;
  // Optional title font size in pixels
  titleFontSize?: number;

  // Optional header action reactnode. It will be rendered in the same row as the title, but on the right side
  headerAction?: ReactNode;

  // If true, hides the pagination selector at the bottom of the table
  hidePagination?: boolean;
}

const StyledDataTable = styled.div``;

const StyledTable = styled.div`
  width: 100%;
  margin-top: 23px;
  table {
    width: 100%;
    border: 1px solid ${Colors.BRAND_TERTIARY};
    border-collapse: collapse;
  }

  box-shadow: 0px 4px 20px ${Colors.LIGHT_SHADOW_BACKGROUND};

  /* Styling for first table row */
  tr:first-child {
    td {
      padding: 10px 25px 10px 25px;
    }
  }

  /* Styling of all rows that are not the first row */
  tr:not(:first-child) {
    td {
      padding: ${GENERAL_SPACING}px 0px ${GENERAL_SPACING}px
        ${GENERAL_SPACING}px;
      min-width: 10px;
    }
    td:last-child {
      padding-right: 25px;
    }
    border-top: 1px solid ${Colors.BRAND_TERTIARY};
  }
`;

const StyledCell = styled.td<{ align?: 'left' | 'center' | 'right' }>`
  text-align: ${(props) => (props.align ? props.align : 'left')};
`;

const StyledCellContent = styled.div`
  display: inline-block;
`;

const StyledTableHeaderText = styled.div`
  font-size: 14px;
  font-weight: ${MEDIUM_WEIGHT};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledPagination = styled.div`
  margin: 15px 0px 15px 0px;
  width: 100%;
`;
const StyledNoDataRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const StyledNoDataRowHeader = styled.p`
  font-size: 18px;
  font-weight: 500;
`;
const StyledNoDataRowText = styled.p`
  font-size: 14px;
  font-weight: 400;
`;

const StyledNoDataRowAction = styled.div`
  margin-top: ${GENERAL_SPACING * 3}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
`;

const StyledMainContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
  }
`;
const StyledMainContentHeaderText = styled.div<{ fontSize?: number }>`
  flex: 1;
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}px;` : '')}
`;
const StyledMainContentHeaderTextCount = styled.span`
  color: ${Colors.BRAND_SECONDARY};
`;

const calculatePaginationPageCount = (
  totalCount: number,
  pageSize: number
): number =>
  (totalCount =
    !null && pageSize != null ? Math.ceil(totalCount / pageSize) : 1);

const StyledFiltersSearchbarContainer = styled.div`
  width: 21%;
`;

export function DataTable(props: DataTableProps) {
  return (
    <StyledDataTable>
      {props.title || props.headerAction ? (
        <StyledMainContentHeader>
          {props.title ? (
            <StyledMainContentHeaderText fontSize={props.titleFontSize}>
              {props.title}
              {' • '}
              <StyledMainContentHeaderTextCount>
                {props.totalCount} results
              </StyledMainContentHeaderTextCount>
            </StyledMainContentHeaderText>
          ) : null}
          {props.headerAction ? props.headerAction : null}
        </StyledMainContentHeader>
      ) : null}
      {/* HEADER */}

      {/* HEADER END */}
      <StyledTable>
        <table>
          <tbody>
            {/* FILTERS */}
            <tr>
              <td colSpan={props.columns?.length}>
                <StyledFiltersContainer>
                  <StyledFilters>
                    {props.filters.map((f, index) => (
                      <ButtonDropdown
                        key={index}
                        color={'primary'}
                        type={'outline'}
                        shape={'round'}
                        noShadow={true}
                        display={'block'}
                        outlineColor={Colors.BRAND_SECONDARY}
                        dropdownElement={f.component()}
                      >
                        <>
                          {f.name}{' '}
                          {f.selectedCount ? (
                            <Bubble horizontalMargin="none" color="success">
                              {f.selectedCount?.toString()}
                            </Bubble>
                          ) : null}
                        </>
                      </ButtonDropdown>
                    ))}
                  </StyledFilters>
                  {/* {TableFilterButtons(
                filterValues,
                (filterValues: AnnotationFilterValues) => {
                  dispatch(<
                    annotationActions.update({ update: { filterValues } })
                  );
                }
              )} */}
                  {props.searchbar ? (
                    <StyledFiltersSearchbarContainer>
                      <SearchBar {...props.searchbar}></SearchBar>
                    </StyledFiltersSearchbarContainer>
                  ) : null}
                </StyledFiltersContainer>
              </td>
            </tr>
            {/* FILTERS END */}
            {/* TABLE HEADERS */}
            <tr>
              {props.columns?.map((c, index) => (
                <td key={index}>
                  <StyledTableHeaderText>{c?.name}</StyledTableHeaderText>
                </td>
              ))}
            </tr>

            {props.fetchingData ? (
              <tr>
                <td colSpan={props.columns?.length}>
                  <StyledNoDataRow>
                    <LoadingSpinner />
                  </StyledNoDataRow>
                </td>
              </tr>
            ) : props.rows?.length ? (
              props.rows.map((r, index) => (
                <tr key={index} style={{ position: 'relative' }}>
                  {r.fields.map((f, i) => (
                    <StyledCell key={i} align={props.columns[i]?.align}>
                      <StyledCellContent>
                        {typeof f === 'string' ? (
                          <Typography size="small">{f}</Typography>
                        ) : (
                          f
                        )}
                      </StyledCellContent>
                    </StyledCell>
                  ))}
                  {r.href ? (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <Link
                      to={r.href}
                      style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        right: 0,
                        top: 0,
                        background: 'none',
                      }}
                    ></Link>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={props.columns?.length}>
                  <StyledNoDataRow>
                    <Icon icon="NoResults" />
                    <StyledNoDataRowHeader>
                      {props.noResultsTitle || 'No results found'}
                    </StyledNoDataRowHeader>
                    <StyledNoDataRowText>
                      {props.noResultsDescription ||
                        "Try adjusting your search or filter to find what you're looking for"}
                    </StyledNoDataRowText>
                    {props.resetAction ? (
                      <StyledNoDataRowAction>
                        <Button type="outline" onPress={props.resetAction}>
                          {props.resetActionTitle || 'Reset filters'}
                        </Button>
                      </StyledNoDataRowAction>
                    ) : null}
                  </StyledNoDataRow>
                </td>
              </tr>
            )}
            {/* DATA END */}
          </tbody>
        </table>
      </StyledTable>
      {/* PAGINATION */}
      {props.totalCount > 0 && !props.hidePagination ? (
        <StyledPagination>
          <PaginationSelector
            currentPage={props.currentPage}
            totalPages={calculatePaginationPageCount(
              props.totalCount,
              props.pageSize
            )}
            selectPage={props.onPageChange}
            maxPageCount={props.maxPageCount}
          ></PaginationSelector>
        </StyledPagination>
      ) : null}
      {/* PAGINATION END */}
    </StyledDataTable>
  );
}

export default DataTable;
