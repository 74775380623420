import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  MaxLength,
  Min,
  ValidateNested,
} from 'class-validator';
import {
  LesionIndexAccurateDiagnosisType,
  LesionIndexActionType,
} from '../index-dtos/lesion.index-dto';

/**
 * Range query for dates used for searching lesions.
 */
export class SearchIndexedLesionsUserDateRangeDto {
  @IsOptional()
  @IsDate()
  from?: Date;

  @IsOptional()
  @IsDate()
  to?: Date;
}

export class SearchIndexedLesionsDto {
  /**
   * Pagination
   */
  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1000)
  skip?: number;

  @IsNumber()
  @Min(1)
  @Max(30)
  take: number;

  /**
   * Wildcard search (case sensitive)
   */
  @IsOptional()
  @IsString()
  @IsNotEmpty()
  @MaxLength(11)
  ssn?: string;

  /**
   * Filters
   */
  @IsOptional()
  @IsUUID('all', { each: true })
  userIds?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  userMedicalUnitIds?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  jurisdictionalGroupIds?: string[];

  @IsOptional()
  @IsBoolean()
  userActionResolved?: boolean;

  // If true, filters for lesions that have an accurate diagnosis id null
  // NB! Should not be set if accurateDiagnosisIds is set
  @IsOptional()
  @IsBoolean()
  accurateDiagnosisIdNull?: boolean;

  // NB! Should not be set if accurateDiagnosisIdNull is set
  @IsOptional()
  @IsUUID('all', { each: true })
  accurateDiagnosisIds?: string[];

  // Filter to lesions that have user diagnosis === accurate diagnosis
  @IsOptional()
  @IsBoolean()
  correct?: boolean;

  // Filter actions
  @IsOptional()
  @IsEnum(LesionIndexActionType, { each: true })
  userActionTypes?: LesionIndexActionType[];

  // The below is kept as example code for ranges

  /**
   * Ranges (inclusive)
   */
  // @Type(() => SearchIndexedLesionsUserDateRangeDto)
  // @IsOptional()
  // @ValidateNested()
  // userActionDateRange?: SearchIndexedLesionsUserDateRangeDto;
}
