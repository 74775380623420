import { Colors, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components/native';

export interface FormControlStepHeaderProps {
  title: string;
  index: number;
}

export function FormControlStepHeader(props: FormControlStepHeaderProps) {
  return (
    <StyledFormControlStepHeader>
      <StepText>Step 0{props.index}: </StepText>
      <StepTitle>{props.title}</StepTitle>
    </StyledFormControlStepHeader>
  );
}

const StyledFormControlStepHeader = styled.View`
  display: flex;
  flex-direction: row;
  border-bottom-width: ${Sizes.NATIVE_BORDER_WIDTH}px 
  // border-bottom-color: ${Colors.BRAND_GRAY_MEDIUM};
  padding: ${Sizes.GENERAL_SPACING}px;
  background-color: ${Colors.BRAND_WHITE};
  `;

const StepText = styled.Text`
  color: ${Colors.BRAND_SECONDARY};
  font-weight: ${Sizes.MEDIUM_WEIGHT};
  font-size: ${Sizes.HEADING_FONT_SIZE}px;
`;

const StepTitle = styled.Text`
  color: ${Colors.BRAND_PRIMARY};
  font-weight: ${Sizes.MEDIUM_WEIGHT};
  font-size: ${Sizes.HEADING_FONT_SIZE}px;
`;

export default FormControlStepHeader;
