import { Exclude } from 'class-transformer';
import {
  ArrayMinSize,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
} from 'class-validator';
import { BaseCreateDto, BaseDto, BaseUpdateDto } from '../base.dto';
import { TrainingAssessmentDto } from '../training/training-assessment.dto';
import { ReportTypeDto } from './report-type.dtos';

export interface ReportDto extends BaseDto {
  userDescription?: string;
  adminNote?: string;
  trainingAssessment?: TrainingAssessmentDto;
  trainingAssessmentId?: string;
  discarded?: boolean;
  reportTypes: ReportTypeDto[];
}

export class CreateReportDto
  extends BaseCreateDto
  implements Partial<ReportDto>
{
  @IsOptional()
  @IsString()
  userDescription?: string;

  @Exclude()
  adminNote?: never;

  @Exclude()
  trainingAssessment?: never;

  @IsOptional()
  @IsUUID()
  trainingAssessmentId?: string;

  @Exclude()
  reportTypes?: never[];

  @IsOptional()
  @ArrayMinSize(1)
  @IsUUID('all', { each: true })
  reportTypeIds?: string[];
}

export class UpdateReportDto
  extends BaseUpdateDto
  implements Partial<ReportDto>
{
  @Exclude()
  userDescription?: never;

  @IsOptional()
  @IsString()
  adminNote?: string;

  @IsOptional()
  discarded?: boolean;

  @Exclude()
  trainingAssessment?: never;

  @Exclude()
  trainingAssessmentId?: never;

  @Exclude()
  reportTypes?: never[];

  @IsOptional()
  @ArrayMinSize(1)
  @IsUUID('all', { each: true })
  reportTypeIds?: string;
}

export class ReportSearchQueryDto {
  @IsNumber()
  @Min(0)
  skip: number;

  @IsNumber()
  @Min(1)
  take: number;

  // If null, shows discarded and not discarded
  // @IsOptional()
  // @IsBoolean()
  // @Transform(({ value }) => {
  //   console.log('Transforming value', value);
  //   return value === 'true';
  // })
  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  showDiscarded?: number;

  // Filter to specific report type id
  @IsOptional()
  @IsString()
  @IsUUID()
  reportTypeId?: string;
}
