import { ExceptionCodeEnum } from './exception.constants';

export abstract class Exception extends Error {
  public metadata;
  public exceptionCode: ExceptionCodeEnum;

  constructor(
    message: string,
    name: string,
    exceptionCode?: ExceptionCodeEnum,
    metadata?: any
  ) {
    super(message);
    super.name = name;
    this.metadata = metadata;
    this.exceptionCode = exceptionCode;
  }
}
