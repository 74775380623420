import { DiagnosisStatusEnum } from '@dermloop/static-values';
import {
  IsBoolean,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
} from 'class-validator';
import { PatientSummaryDto } from './pathological-assessment-request.dtos';

export class TeledermatologicalReferralSearchDto {
  @IsOptional()
  @IsString()
  query?: string;

  @IsOptional()
  @IsEnum(DiagnosisStatusEnum)
  status?: DiagnosisStatusEnum;

  @IsOptional()
  @IsBoolean()
  urgentReferrals?: boolean;

  @IsOptional()
  @IsUUID('all', { each: true })
  diagnosisIds?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  skinTypes?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  medicalUnitIds?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  assessedByIds?: string[];

  @IsOptional()
  @IsNumber()
  @Max(30)
  take?: number;

  @IsOptional()
  @IsNumber()
  skip?: number;
}

export class TeledermatologicalReferralSearchResultItemDto {
  referralId: string;
  referralMedicalUnitName: string;
  teledermatologicalAssessmentMedicalUnitName?: string;
  patient: PatientSummaryDto;
  undiagnosable: boolean;
  lesionId: string;
  clinicalDiagnosisName?: string;
  teledermatologicalDiagnosisName?: string;
  createdDate: Date;
  referrerFirstName?: string;
  referrerLastName?: string;
  teledermatologicalAssessmentUserFirstName?: string;
  teledermatologicalAssessmentUserLastName?: string;
}
