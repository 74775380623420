import { MinimalHealthRecordDto } from '@dermloop/api-dtos';
import {
  getAgeFromSSN,
  getBirthDateFromSSN,
  getStyledDate,
} from '@dermloop/shared';
import { Colors, GENERAL_SPACING } from '@dermloop/ui/util';
import { View } from 'react-native';
import styled from 'styled-components/native';
import PatientInformationDisplay from './patient-information-display/patient-information-display';

/* eslint-disable-next-line */
export interface PatientInformationProps {
  healthRecord: MinimalHealthRecordDto;
  shadow?: boolean;
}

export function PatientInformation(props: PatientInformationProps) {
  if (!props.healthRecord) return null;
  const dateAgePreview = `${getStyledDate(
    getBirthDateFromSSN(props?.healthRecord?.ssn).toDateString()
  )} (${getAgeFromSSN(props?.healthRecord?.ssn)} years)`;
  return (
    <StyledPatientInformation shadow={props.shadow}>
      <InlineInput>
        <PatientInformationDisplay
          value={props.healthRecord?.gender?.name}
          placeholder="Gender"
          color="success"
        />
        <View style={{ width: GENERAL_SPACING }} />
        <PatientInformationDisplay
          value={dateAgePreview}
          placeholder="Date of birth"
          color="success"
        />
      </InlineInput>
    </StyledPatientInformation>
  );
}

export default PatientInformation;

const StyledPatientInformation = styled.View<{ shadow: boolean }>`
  padding: ${GENERAL_SPACING}px;
  background-color: ${Colors.BRAND_WHITE};
  shadow-color: ${Colors.BRAND_BLACK};
  shadow-offset: 0 2px;
  shadow-radius: 5px;
  shadow-opacity: ${(props) => (props.shadow ? 0.25 : 0)};
  margin-bottom: ${(props) => (props.shadow ? 10 : 0)}px;
`;

const InlineInput = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
