import { Colors, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface ChipProps {
  title: string;
}

const StyledChip = styled.div`
  font-size: 0.875rem;
  background-color: ${Colors.WHITE_TRANSPARENT_BACKGROUND};
  border-radius: ${Sizes.BUTTON_BORDER_RADIUS}px;
  padding: 0.25rem 0.5rem;
`;

export function Chip(props: ChipProps) {
  return <StyledChip>{props.title}</StyledChip>;
}

export default Chip;
