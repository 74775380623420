import { Colors, Sizes } from '@dermloop/ui/util';
import { KeyboardEventHandler } from 'react';
import styled from 'styled-components';
import Seperator from '../seperator/seperator';

/* eslint-disable-next-line */
export interface CardProps {
  title?: string;
  icon?: string;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  children: JSX.Element | JSX.Element[];
  rightHeaderContent?: JSX.Element | JSX.Element[];
  style?;
  // Optional data-cy value, replacing default value
  'data-cy'?: string;
}

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: ${Colors.BACKGROUND_WHITE};
  border-radius: ${Sizes.BORDER_RADIUS}px;
`;

const CardHeader = styled.div`
  display: flex;
  padding: 0 1rem;
  height: ${Sizes.DESKTOP_HEADER_HEIGHT}px;
  align-items: center;
  flex-direction: row;
`;

const CardTitle = styled.div<{ size?: string }>`
  font-weight: 400;
  font-size: 18px;
`;

const CardContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: 1rem;
`;

const Icon = styled.img`
  border-radius: ${Sizes.BORDER_RADIUS}px;
  margin: 0 1rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
`;

const CardHeaderRightContent = styled.div`
  margin-left: auto;
`;

export function Card(props: CardProps) {
  return (
    <StyledCard
      tabIndex={0}
      onKeyDown={props.onKeyDown ? props.onKeyDown : null}
      style={props.style}
      data-cy={props['data-cy'] || 'card'}
    >
      {props.title || props.icon ? (
        <>
          <CardHeader>
            {props.icon ? <Icon src={props.icon} /> : null}
            {props.title ? <CardTitle>{props.title}</CardTitle> : null}
            <CardHeaderRightContent>
              {props.rightHeaderContent ? props.rightHeaderContent : null}
            </CardHeaderRightContent>
          </CardHeader>
          <Seperator />
        </>
      ) : null}
      <CardContent>{props.children}</CardContent>
    </StyledCard>
  );
}

export default Card;
