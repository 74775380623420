/**
 * Deletes select properties from an object. Recursively looks through all
 * child objects for these properties and deletes them.
 * @param obj               Object to delete properties from.
 * @param deleteProperties  Set of property names to delete.
 * @returns                 Object with deleted properties.
 */
export const deleteNestedProperty = <T extends object>(
  obj: T,
  deleteProperties: Set<string>,
  prevSeenObjects?: WeakSet<any>
): T => {
  if (!obj) return null;

  try {
    const seenObjects = prevSeenObjects ?? new WeakSet();

    const keys = Object.keys(obj);
    keys.forEach((key) => {
      if (deleteProperties.has(key)) {
        delete obj[key];
      } else if (obj[key] != null && typeof obj[key] === 'object') {
        // Ensure that the object has not been seen before. This fixes cyclic object references
        if (!seenObjects.has(obj[key])) {
          seenObjects.add(obj[key]);
          obj[key] = deleteNestedProperty(
            obj[key],
            deleteProperties,
            seenObjects
          );
        }
      }
    });
  } catch (error) {
    return {
      ...obj,
      toString: obj.toString,
      message: `${
        'message' in obj ? `${(obj as any).message} ` : ''
      } ERROR ${error.toString()}`,
    };
  }

  // If array, return array
  if (Array.isArray(obj)) {
    return obj;
  } else {
    // If object, ensure the toString method is not '[object Object]'
    return {
      ...obj,
      toString: () => {
        if (Object.prototype.toString === obj.toString) {
          return JSON.stringify(obj ?? {});
        }
        return obj?.toString();
      },
    };
  }
};
