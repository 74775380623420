import { Colors, IconType, Icons, Sizes } from '@dermloop/ui/util';
import { ActivityIndicator, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { PressableState } from '../button/button';
import { Icon } from '../icon/icon';
import { webSpecificValue } from '../utils';

/* eslint-disable-next-line */
export interface ButtonIconProps {
  type?: 'solid' | 'clear';
  loading?: boolean;
  disabled?: boolean;
  onPress: () => void;
  color?: 'primary' | 'secondary' | 'danger';
  // CHILD MUST BE AN SVG STRING
  children: string | IconType;
  shape?: 'round' | 'square';
  noShadow?: boolean;
  outline?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
  size?: 'large' | 'medium' | 'small' | 'natural';
}

const StyledButton = styled.View<{
  background: string;
  outline: boolean;
  backgroundOpacity?: number;
  shadow?: string;
  roundedBorders: boolean;
  disabled?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
}>`
  display: flex;
  border-width: ${Sizes.NATIVE_BORDER_WIDTH}px;
  border-radius: ${(props) =>
    props.roundedBorders ? Sizes.BUTTON_BORDER_RADIUS : ''}px;
  border-color: ${(props) =>
    props.outline ? Colors.BRAND_TERTIARY : Colors.TRANSPARENT};
  background-color: ${(props) => props.background};
  ${(props) => (props.shadow ? props.shadow : null)};
  ${(props) =>
    props.backgroundOpacity
      ? 'filter: opacity(' + props.backgroundOpacity + ')'
      : null}
  ${webSpecificValue('user-select: none;')};
  margin: 0 ${({ noMargin }) => (!noMargin ? Sizes.GENERAL_SPACING / 4 : 0)}px;
  padding: ${(props) =>
    !props.noPadding ? Sizes.GENERAL_SPACING / 2 + 'px' : '1px'};
`;

const StyledIcon = styled.View<{
  source?: string;
  size: string;
  disableStyle?: string;
}>`
  ${(props) => webSpecificValue(`background-image: url(${props.source});`)}
  ${webSpecificValue(`background-position: center;`)}  
  ${webSpecificValue(`background-repeat: no-repeat;`)}  
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  ${(props) => (props.disableStyle ? props.disableStyle : null)}
`;

const getBackground = (
  props: ButtonIconProps,
  pressed: boolean,
  hovered: boolean
) => {
  let background = Colors.TRANSPARENT;

  if (props.type === 'solid') {
    if (props.color === 'danger') background = Colors.DANGER;
    else if (props.color === 'secondary') {
      if (pressed) background = Colors.PRESS_EXTRA_BUTTON;
      else if (hovered) background = Colors.HOVER_EXTRA_BUTTON;
      else background = Colors.BRAND_WHITE;
    } else background = Colors.BRAND_SUCCESS;
  } else if (props.type === 'clear' && props.color === 'secondary') {
    if (pressed) background = Colors.PRESS_SUCCESS_BUTTON;
    else if (hovered) background = Colors.HOVER_SUCCESS_BUTTON;
  }

  return { background };
};

const getBackgroundOpacity = (
  props: ButtonIconProps,
  pressed: boolean,
  hovered: boolean
) => {
  if (props.type === 'solid' && props.color !== 'secondary') {
    if (pressed) return 0.85;
    if (hovered) return 0.95;
  }

  return null;
};

const getShadow = (
  props: ButtonIconProps,
  pressed: boolean,
  hovered: boolean
) => {
  if (props.noShadow || props.disabled) return null;
  else if (props.type === 'solid' && props.color === 'secondary') {
    if (pressed) return 'box-shadow: 0 0 7px ' + Colors.PRESS_PRIMARY_BUTTON;
    else if (hovered)
      return 'box-shadow: 0 0 7px ' + Colors.HOVER_PRIMARY_BUTTON;
  } else if (props.type === 'clear' && props.color === 'primary') {
    return '';
  }
  return 'box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25)';
};

const getDisabledStyle = (props: ButtonIconProps) => {
  if (props.disabled) {
    if (props.type === 'clear' && props.color === 'secondary')
      return 'filter: brightness(0) invert(1);';
    else if (props.type === 'clear' && props.color === 'primary')
      return 'filter: grayscale(1) opacity(0.4)';
    else return 'filter: grayscale(1)';
  }
  return null;
};

const getSize = (props: ButtonIconProps) => {
  if (props.size === 'large') return '30px';
  if (props.size === 'small') return '7px';
  if (props.size === 'natural') return '100%';

  return '16px';
};

export function ButtonIcon(props: ButtonIconProps) {
  return (
    <Pressable
      onPress={() =>
        !props.disabled && !props.loading && props.onPress && props.onPress()
      }
      pointerEvents={props.disabled ? 'none' : 'auto'}
    >
      {({ pressed, hovered, focused }: PressableState) => {
        const { background } = getBackground(props, pressed, hovered);

        return (
          <StyledButton
            background={background}
            outline={props.outline}
            backgroundOpacity={getBackgroundOpacity(props, pressed, hovered)}
            shadow={getShadow(props, pressed, hovered)}
            roundedBorders={props.shape !== 'square' ? true : false}
            noPadding={props.noPadding}
            noMargin={props.noMargin}
          >
            {props.loading ? (
              <ActivityIndicator
                style={{ position: 'absolute' }}
                size="small"
              />
            ) : null}
            {Icons[props.children] ? (
              <StyledIcon size={getSize(props)}>
                <Icon
                  icon={props.children as IconType}
                  fill="none"
                  size={props.size !== 'natural' ? 'parent' : 'natural'}
                />
              </StyledIcon>
            ) : (
              <StyledIcon
                source={props.children}
                size={getSize(props)}
                disableStyle={getDisabledStyle(props)}
              ></StyledIcon>
            )}
          </StyledButton>
        );
      }}
    </Pressable>
  );
}

export default ButtonIcon;
