import { Colors, GENERAL_SPACING } from '@dermloop/ui/util';
import React from 'react';
import styled from 'styled-components/native';
import Typography from '../typography/typography';

/* eslint-disable-next-line */
export interface OverlayWithTextProps {
  text: string | React.ReactNode;
}

const StyledOverlayWithText = styled.View`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.DARK_TRANSPARENT_BACKGROUND};
  padding: ${GENERAL_SPACING}px;
`;

export function OverlayWithText(props: OverlayWithTextProps) {
  return (
    <StyledOverlayWithText>
      {typeof props.text === 'string' ? (
        <Typography color="light">{props.text}</Typography>
      ) : (
        props.text
      )}
    </StyledOverlayWithText>
  );
}

export default OverlayWithText;
