import { LesionImageDto } from '@dermloop/api-dtos';
import { getStyledDate } from '@dermloop/shared';
import { ButtonIcon, Typography } from '@dermloop/ui/components';
import {
  Colors,
  FONT_SIZE,
  Icons,
  MEDIUM_WEIGHT,
  REGULAR_WEIGHT,
} from '@dermloop/ui/util';
import { uniqBy } from 'lodash';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { ImageCarousel } from '../image-carousel/image-carousel';
import NoScrollComponent from '../no-scroll-component/no-scroll-component';

export interface ImageOverlayProps {
  images?: LesionImageDto[];
  selectedImageId?: string;
  onClose?: () => void;
  onChange?: (id: string) => void;
  hasOutline?: boolean;
  titles?: Record<string, string>;
  onClickCompare?: () => void;
}

const StyledImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${Colors.OVERLAY_TRANSPARENT_BACKGROUND};
  height: 100%;
  margin: 0;
  display: flex;
  z-index: 999999;
`;

const ImageContainer = styled.div`
  width: 42%;
  margin: auto;
  padding-top: 2rem;
`;

const ImageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  color: ${Colors.BRAND_WHITE};
  font-weight: ${MEDIUM_WEIGHT};
  font-size: 20px;
  padding: 0.5rem 0 0rem 0.2rem;
`;

const ImageDate = styled.div`
  color: ${Colors.BRAND_WHITE};
  align-self: flex-start;
  padding: 0.2rem;
  font-size: ${FONT_SIZE}px;
  font-weight: ${REGULAR_WEIGHT};
`;

const ImageBaseLineHeaderText = styled.div`
  flex-direction: column;
  flex-basis: 20%;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  justify-content: center;
  align-items: flex-end;
`;

const DownloadText = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

export function ImageOverlay(props: ImageOverlayProps) {
  const currentImage = props.images.find((i) => i.id === props.selectedImageId);
  const [showAlternativeImage, setShowAlternativeImage] = useState(false);
  if (!currentImage) return null;
  return (
    <NoScrollComponent>
      {(ref) => (
        <StyledImageOverlay
          ref={ref}
          onMouseDown={(event) => {
            //Ensure that only click events on the actual background is closing the overlay
            if (event.target instanceof Node && ref.current === event.target) {
              props.onClose();
            }
          }}
        >
          <ImageContainer onClick={(e) => e.stopPropagation()}>
            <ImageHeader>
              <ImageBaseLineHeaderText>
                <Title>{props.titles[currentImage.lesionTriageId]}</Title>
                <ImageDate>
                  {getStyledDate(
                    props.images.find((img) => img.id === props.selectedImageId)
                      .created
                  )}
                </ImageDate>
              </ImageBaseLineHeaderText>
              <DownloadText>
                <img
                  style={{ marginRight: '5px' }}
                  src={Icons.DownloadWhite}
                  alt="download"
                />
                <a
                  href={
                    showAlternativeImage
                      ? currentImage.alternativeImageUrl
                      : currentImage.url
                  }
                  style={{
                    textDecoration: 'none',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                  }}
                >
                  <Typography
                    size={'large'}
                    color="light"
                    decoration="underline"
                  >
                    Download image
                  </Typography>
                </a>
              </DownloadText>
              <CloseButtonContainer>
                <ButtonIcon
                  children={Icons.Close}
                  onPress={() => props.onClose()}
                  type={'solid'}
                  color={'secondary'}
                  outline={false}
                />
              </CloseButtonContainer>
            </ImageHeader>
            <div style={{ display: 'flex' }}></div>
            <ImageCarousel
              images={props.images}
              titles={props.titles}
              selectedImageId={props.selectedImageId}
              onChange={props.onChange}
              hasOutline={props.hasOutline}
              usesOpenSeaDragon={true}
              isOverlay={true}
              hasImageCompare={
                uniqBy(props.images, (i) => i.lesionTriageId).filter(
                  (img) => img.url
                ).length > 1
              }
              onClickCompare={props.onClickCompare}
              setOverlayAlternativeImage={(s) => setShowAlternativeImage(s)}
            ></ImageCarousel>
          </ImageContainer>
        </StyledImageOverlay>
      )}
    </NoScrollComponent>
  );
}
