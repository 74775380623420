import {
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { BiopsyDto } from '../clinical-actions/biopsy.dtos';
import { DismissalDto } from '../clinical-actions/dimissal.dtos';
import type { FollowUpRequestDto } from '../clinical-actions/follow-up-request.dtos';
import { ReferralDto } from '../clinical-actions/referral.dtos';
import { FieldConfigurationDto } from '../field-configuration/field-configuration.dto';
import type { UserDto } from '../user.dto';
import { LesionDetailsDto } from './lesion-details.dtos';
import { LesionImageDto } from './lesion-image.dtos';

export class CreateLesionTriageDto {
  @IsOptional()
  @IsBoolean()
  draft?: boolean;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  note?: string;

  @IsUUID()
  lesionId: string;

  @IsOptional()
  @IsNotEmpty()
  lesionDetails?: LesionDetailsDto;

  @IsOptional()
  clinicalImage?: LesionImageDto;

  @IsOptional()
  dermoscopicImage?: LesionImageDto;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  patientDetailsId?: string;

  @IsOptional()
  @IsNotEmpty()
  referralAction?: ReferralDto;

  @IsOptional()
  @IsNotEmpty()
  requestedFollowUp?: FollowUpRequestDto;

  @IsOptional()
  @IsNotEmpty()
  dismissalAction?: DismissalDto;

  @IsOptional()
  @IsNotEmpty()
  biopsyAction?: BiopsyDto;

  @IsOptional()
  @IsBoolean()
  plannedBiopsyAction?: boolean;

  @IsOptional()
  @IsNotEmpty()
  @IsBoolean()
  newImageRegistration?: boolean;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  diagnosisId?: string;

  @IsOptional()
  diagnosisGroupIds?: string[];

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  differentialDiagnosisId?: string;

  @IsOptional()
  differentialDiagnosisGroupIds?: string[];

  @IsOptional()
  @IsString()
  difficultyId?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  dismissalActionId?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  medicalUnitId?: string;
}

export class UpdateLesionTriageDto {
  @IsOptional()
  @IsString()
  id?: string;

  @IsOptional()
  @IsBoolean()
  draft?: boolean;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  note?: string;

  @IsOptional()
  @IsUUID()
  lesionId?: string;

  @IsOptional()
  @IsNotEmpty()
  lesionDetails?: LesionDetailsDto;

  @IsOptional()
  clinicalImage?: LesionImageDto;

  @IsOptional()
  dermoscopicImage?: LesionImageDto;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  patientDetailsId?: string;

  @IsOptional()
  @IsNotEmpty()
  referralAction?: ReferralDto;

  @IsOptional()
  @IsNotEmpty()
  requestedFollowUp?: FollowUpRequestDto;

  @IsOptional()
  @IsNotEmpty()
  dismissalAction?: DismissalDto;

  @IsOptional()
  @IsNotEmpty()
  biopsyAction?: BiopsyDto;

  @IsOptional()
  @IsBoolean()
  plannedBiopsyAction?: boolean;

  @IsOptional()
  @IsNotEmpty()
  @IsBoolean()
  newImageRegistration?: boolean;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  diagnosisId?: string;

  @IsOptional()
  diagnosisGroupIds?: string[];

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  differentialDiagnosisId?: string;

  @IsOptional()
  differentialDiagnosisGroupIds?: string[];

  @IsOptional()
  @IsString()
  difficultyId?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  dismissalActionId?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  medicalUnitId?: string;
}

export class LesionTriageDto implements BaseDto {
  @IsString()
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;
  created?: string;
  updated?: string;
  deleted?: string;

  @IsBoolean()
  draft: boolean;

  @IsBoolean()
  complete: boolean;

  @IsBoolean()
  canEdit: boolean;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  note?: string;

  @IsString()
  @IsNotEmpty()
  lesionId: string;

  clinicalImage: LesionImageDto;
  dermoscopicImage: LesionImageDto;

  clinicalImages?: LesionImageDto[];
  dermoscopicImages?: LesionImageDto[];

  @IsOptional()
  @IsNotEmpty()
  lesionDetails?: LesionDetailsDto;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  patientDetailsId?: string;

  @IsOptional()
  @IsNotEmpty()
  referralAction?: ReferralDto;

  @IsOptional()
  @IsNotEmpty()
  requestedFollowUp?: FollowUpRequestDto;

  @IsOptional()
  @IsNotEmpty()
  dismissalAction?: DismissalDto;

  @IsOptional()
  @IsNotEmpty()
  biopsyAction?: BiopsyDto;

  @IsOptional()
  @IsBoolean()
  plannedBiopsyAction?: boolean;

  @IsOptional()
  @IsNotEmpty()
  @IsBoolean()
  newImageRegistration?: boolean;

  @IsNotEmpty()
  fieldConfiguration: FieldConfigurationDto;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  diagnosisId?: string;

  @IsOptional()
  @IsNotEmpty()
  diagnosisGroupIds?: string[];

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  differentialDiagnosisId?: string;

  @IsOptional()
  differentialDiagnosisGroupIds?: string[];

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  difficultyId?: string;

  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;

  @IsString()
  @IsNotEmpty()
  medicalUnitName: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  healthRecordId: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  ssn: string;

  @IsOptional()
  @IsBoolean()
  @IsNotEmpty()
  isNewRegistration?: boolean;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  registrationDate?: string;

  @IsOptional()
  validation: string[];
}
