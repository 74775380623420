import {
  Colors,
  DataSet,
  IconType,
  Sizes,
  getDatasetProp,
} from '@dermloop/ui/util';
import { ReactElement } from 'react';
import { ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';
import Icon from '../icon/icon';
import { webSpecificValue } from '../utils';
type ButtonMarginType = 'vertical' | 'horizontal';
type ButtonSizeType = 'small' | 'normal' | 'big';

/* eslint-disable-next-line */
export interface ButtonProps {
  hitSlop?: number;
  dataSet?: DataSet;
  icon?: IconType;
  iconPosition?: 'left' | 'right' | 'icon-only';
  type?: ButtonPropType;
  display?: ButtonPropDisplay;
  loading?: boolean;
  size?: ButtonSizeType;
  // If 'horizontal', adds Sizes.GENERAL_SPACING to left margin
  // If 'vertical', adds Sizes.GENERAL_SPACING to top margin
  // If not set, does not add margin
  margin?: ButtonMarginType;
  disabled?: boolean;
  onPress: () => void;
  color?: ButtonPropColor;
  customTextColor?: string;
  children: string;
  shape?: 'round' | 'square';
  noShadow?: boolean;
  // CSS width (e.g. 80px). If provided, overrules all other button width properties
  width?: string;
  noPadding?: boolean;
}

export type ButtonPropType = 'outline' | 'solid' | 'clear';
export type ButtonPropColor = 'primary' | 'secondary' | 'tertiary' | 'danger';
export type ButtonPropDisplay = 'block' | 'inline' | 'inline-narrow';

export type PressableState = Readonly<{
  pressed: boolean;
  hovered?: boolean;
  focused?: boolean;
}>;

const StyledButton = styled.View<{
  background: string;
  marginProp?: ButtonMarginType;
  outline: string;
  display: ButtonPropDisplay;
  backgroundOpacity?: number;
  noShadow?: boolean;
  natural?: boolean;
  roundedBorders?: boolean;
  size?: ButtonSizeType;
  type?: ButtonPropType;
  noPadding?: boolean;
}>`
  ${(props) =>
    props.size === 'small'
      ? ''
      : props.size === 'big'
      ? `height: ${2 * Sizes.BUTTON_HEIGHT}px;`
      : `height: ${Sizes.BUTTON_HEIGHT}px;`}
  display: flex;
  justify-content: center;
  flex-direction: row;
  ${(props) =>
    props.size === 'small'
      ? `padding: ${Sizes.GENERAL_SPACING / 4}px ${Sizes.GENERAL_SPACING / 2}px`
      : `padding: 0 ${Sizes.GENERAL_SPACING / 2}px`};
  ${({ marginProp }) =>
    marginProp === 'vertical' ? `margin-top: ${Sizes.GENERAL_SPACING}px;` : ''}
  ${({ marginProp }) =>
    marginProp === 'horizontal'
      ? `margin-left: ${Sizes.GENERAL_SPACING}px;`
      : ''}
  ${({ noPadding }) => (noPadding ? 'padding: 0' : '')};
  align-items: center;
  border-width: ${Sizes.NATIVE_BORDER_WIDTH}px;
  ${(props) =>
    props.roundedBorders
      ? 'border-radius:' + Sizes.BUTTON_BORDER_RADIUS + 'px'
      : ''};
  border-color: ${(props) => props.outline};
  background-color: ${(props) => props.background};
  ${webSpecificValue(
    `transition: background ease 0.2s, box-shadow ease 0.2s; position: static; z-index: unset;`
  )};

  align-self: ${(props) =>
    props.display === 'block' ? 'stretch' : 'flex-start'};
  ${(props) =>
    props.noShadow ? null : 'box-shadow:0px 2px 5px rgba(0, 0, 0, 0.20)'};
  ${(props) =>
    props.backgroundOpacity
      ? 'filter: opacity(' + props.backgroundOpacity + ')'
      : null}

  ${webSpecificValue(`user-select: none;`)};
  ${(props) => {
    // Clear buttons do not need a width
    if (props.type === 'clear') return '';
    if (props.display === 'inline') {
      return `width: ${Sizes.BUTTON_WIDTH}px;`;
    }
    return '';
  }};
`;

const StyledButtonText = styled.Text<{
  textColor: string;
  hidden: boolean;
  size?: 'small' | 'normal';
}>`
  color: ${(props) => props.textColor};
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  margin: 0 ${Sizes.GENERAL_SPACING / 2}px;
  font-weight: 500;
  font-weight: ${Sizes.BUTTON_FONT_WEIGHT};
  font-size: ${(props) =>
    props.size === 'small'
      ? Sizes.SMALLEST_FONT_SIZE
      : Sizes.BUTTON_FONT_SIZE}px;
`;

const StyledPressable = styled.Pressable<{ grow: boolean }>`
  ${webSpecificValue(`position: static; z-index: unset;`)};
  justify-content: center;
  ${(props) => (props.grow ? 'flex-grow:1;' : '')}
`;

const StyledIconContainer = styled.View`
  width: 12px;
  height: 12px;
`;

const getColor = (
  color?: 'primary' | 'secondary' | 'danger' | 'tertiary',
  pressed?: boolean,
  hovered?: boolean,
  type?: ButtonPropType
) => {
  if (color === 'danger') return Colors.DANGER;
  else if (color === 'secondary' || color === 'tertiary') {
    if (pressed) return Colors.PRESS_EXTRA_BUTTON;
    else if (hovered) return Colors.HOVER_EXTRA_BUTTON;
    else return type === 'clear' ? Colors.BRAND_SUCCESS : Colors.BRAND_WHITE;
  } else return Colors.BRAND_SUCCESS;
};

const getColors = (props: ButtonProps, pressed: boolean, hovered: boolean) => {
  if (props.disabled) {
    return {
      background: Colors.TRANSPARENT,
      text: Colors.BRAND_TERTIARY,
      outline:
        props.type === 'clear' ? Colors.TRANSPARENT : Colors.BRAND_TERTIARY,
    };
  }

  let background = Colors.TRANSPARENT;
  if (!props.type || props.type === 'solid') {
    background = getColor(props.color, pressed, hovered);
    if (!props.color || props.color === 'primary') {
      if (pressed) background = Colors.PRESS_SOLID_PRIMARY_BUTTON;
      else if (hovered) background = Colors.HOVER_SOLID_PRIMARY_BUTTON;
    } else if (props.color === 'secondary' || props.color === 'tertiary') {
      if (pressed) background = Colors.PRESS_SOLID_SECONDARY_BUTTON;
      else if (hovered) background = Colors.HOVER_SOLID_SECONDARY_BUTTON;
    } else if (props.color === 'danger') {
      if (pressed) background = Colors.PRESS_SOLID_DANGER_BUTTON;
      else if (hovered) background = Colors.HOVER_SOLID_DANGER_BUTTON;
    }
  } else if (props.type === 'outline') {
    if (!props.color || props.color === 'primary') {
      if (pressed) background = Colors.PRESS_SUCCESS_BUTTON;
      else if (hovered) background = Colors.HOVER_SUCCESS_BUTTON;
    } else if (props.color === 'secondary' || props.color === 'tertiary') {
      if (pressed) background = Colors.PRESS_PRIMARY_BUTTON;
      else if (hovered) background = Colors.HOVER_PRIMARY_BUTTON;
    }
  }

  let outline;
  if (props.type === 'clear') outline = Colors.TRANSPARENT;
  else outline = getColor(props.color);
  let text = '';
  if (props.type === 'clear') {
    text =
      props.color === 'tertiary'
        ? Colors.BRAND_SUCCESS
        : props.color === 'secondary'
        ? Colors.BRAND_WHITE
        : Colors.BRAND_PRIMARY;
  } else {
    text =
      props.type === 'outline'
        ? outline
        : props.color === 'secondary'
        ? Colors.BRAND_SUCCESS
        : props.color === 'tertiary'
        ? Colors.BRAND_PRIMARY
        : Colors.BRAND_WHITE;
  }

  return { background, text, outline };
};

export function Button(props: ButtonProps) {
  return (
    <StyledPressable
      {...getDatasetProp(props.dataSet)}
      hitSlop={props.hitSlop}
      grow={props.display === 'block'}
      onPress={() =>
        !props.disabled && !props.loading && props.onPress && props.onPress()
      }
      pointerEvents={props.disabled ? 'none' : 'auto'}
    >
      {({ pressed, hovered, focused }: PressableState): ReactElement => {
        const { background, text, outline } = getColors(
          props,
          pressed,
          hovered
        );

        return (
          <StyledButton
            background={background}
            size={props.size}
            marginProp={props.margin}
            outline={outline}
            display={props.display || 'inline'}
            noShadow={
              pressed ||
              hovered ||
              props.noShadow ||
              props.disabled ||
              props.type === 'clear'
            }
            roundedBorders={props.shape !== 'square' ? true : false}
            type={props.type}
            noPadding={props.noPadding}
          >
            {props.icon && props.iconPosition === 'left' ? (
              <StyledIconContainer>
                <Icon
                  size="parent"
                  customFill={props.loading ? Colors.TRANSPARENT : text}
                  icon={props.icon}
                />
              </StyledIconContainer>
            ) : null}

            {props.loading ? (
              <ActivityIndicator
                style={{ position: 'absolute' }}
                size="small"
                color={props.color === 'danger' ? '#fff' : text}
              />
            ) : null}
            {props.iconPosition !== 'icon-only' ? (
              <StyledButtonText
                size={props.size}
                numberOfLines={1}
                hidden={props.loading}
                textColor={text}
              >
                {props.children}
              </StyledButtonText>
            ) : null}

            {props.icon &&
            (!props.iconPosition || props.iconPosition !== 'left') ? (
              <StyledIconContainer>
                <Icon
                  size="parent"
                  customFill={props.loading ? Colors.TRANSPARENT : text}
                  icon={props.icon}
                />
              </StyledIconContainer>
            ) : null}
          </StyledButton>
        );
      }}
    </StyledPressable>
  );
}

export default Button;
