import { ObjectiveFindingDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const objectiveFindingEndpoints = (builder: EndpointBuilderType) => ({
  getObjectiveFindings: builder.query<ObjectiveFindingDto[], void>({
    query: () => 'objective-findings',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'OBJECTIVE_FINDINGS', id: 'LIST' }],
  }),
});
