import { DAHTUserEnrollmentDto } from '@dermloop/api-dtos';
import {
  DAHTDermoscopyRelevance,
  DAHTRelevance,
} from '@dermloop/static-values';
import { Button, Typography } from '@dermloop/ui/components';
import { Card, LoadingSpinner } from '@dermloop/ui/web/components';
import {
  Alert,
  Checkbox,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Select,
} from 'antd';
import { orderBy } from 'lodash';
import { useRef } from 'react';
import styled from 'styled-components';
import countries from 'world-countries';
import { dermloopApi } from '../../store/api';

/* eslint-disable-next-line */
export interface TrialRegistrationProps {
  children: JSX.Element;
}

const StyledTrialRegistration = styled.div`
  width: 600px;
`;

/**
 *
 * @param props
 */
export function TrialRegistrationGuard(props: TrialRegistrationProps) {
  const form = useRef<FormInstance>();
  const { data: currentUser, isLoading: fetchingCurrentUser } =
    dermloopApi.useGetCurrentUserQuery();
  const { data: genders } = dermloopApi.useGetGendersQuery();
  const [enrollUser, { isLoading: enrollingUser, error: enrollingUserError }] =
    dermloopApi.useEnrollUserToDAHTMutation();

  if (!currentUser || fetchingCurrentUser) return <LoadingSpinner />;

  return !currentUser.dahtDetails ? (
    <StyledTrialRegistration>
      <Card>
        <Typography size="large" weight="bold">
          Dermoscopy Augmented Histology Trial - A part of AISC Research
        </Typography>
        <br />
        <Typography size="normal">
          Before you can participate in the study we need a bit of information
          about you.
        </Typography>
        <br />
        <Form<DAHTUserEnrollmentDto>
          disabled={false}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={async (values) => {
            enrollUser({
              userId: currentUser.id,
              ...values,
            });
          }}
          onSubmitCapture={() => {}}
          onFinishFailed={() => {}}
          autoComplete="off"
          ref={form}
        >
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Missing first name',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Missing lastname',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Sex"
            name="genderId"
            rules={[
              {
                required: true,
                message: 'Missing sex',
              },
            ]}
          >
            <Select placeholder="Select sex" allowClear>
              {orderBy(
                genders?.filter((g) => g.name !== 'Other'),
                (g) => g.name
              )?.map((g) => (
                <Select.Option value={g.id}>{g.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Country"
            name="countryCode"
            rules={[
              {
                required: true,
                message: 'Missing country',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select country"
              allowClear
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {orderBy(countries, (c) => c.name.common)?.map((c) => (
                <Select.Option
                  value={c.cca2}
                >{`${c.flag} ${c.name.common}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Number of years interpreting skin lesions"
            name="skinLesionInterpretationExperienceYears"
            rules={[
              {
                required: true,
                message: 'Missing number of years interpreting skin lesions',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Caseload of melanocytic lesions per month"
            name="caseloadMelanocyticLesionsMonthly"
            rules={[
              {
                required: true,
                message: 'Missing caseload of melanocytic lesions per month',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Percentage of caseload from melanocytic lesions"
            name="caseloadMelanocyticLesionsMonthlyPercentage"
            rules={[
              {
                required: true,
                message: 'Missing percentage of melanocytic lesions per month',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Number of second opinion assessments per month"
            name="secondOpinionAssessmentsMelanocyticLesionsMonthly"
            rules={[
              {
                required: true,
                message:
                  'Missing number of second opinion assessments per month',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Number of second opinion referrals per month"
            name="secondOpinionReferralsMelanocyticLesionsMonthly"
            rules={[
              {
                required: true,
                message: 'Missing number of second opinion referrals per month',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Have you had former training in dermoscopy?"
            name="formerDermoscopyTraining"
            rules={[
              {
                required: true,
                message: 'Missing former dermoscopy training',
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Do you routinely use digitized slides when diagnosing skin
              lesions?"
            name="routinelyUseDigitizedSlides"
            rules={[
              {
                required: true,
                message: 'Missing digitized slides routine',
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item<DAHTRelevance>
            label="Perceived relevance of dermoscopic images during histopathological evaluation"
            name="percievedRelevanceOfDermoscopyImages"
            rules={[
              {
                required: true,
                message:
                  'Missing Perceived relevance of dermoscopic images during histopathological evaluation',
              },
            ]}
          >
            <Select<DAHTRelevance> placeholder="Select relevance" allowClear>
              {DAHTDermoscopyRelevance.map((r) => (
                <Select.Option value={r.value}>{r.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="Privacy policy"
            valuePropName="checked"
            rules={[
              {
                required: true,
                transform: (value) => value || undefined,
                type: 'boolean',
                message: 'Please agree to the terms and conditions.',
              },
            ]}
          >
            <Checkbox>
              Please be aware that by signing up you confirm that you have read
              and understood Melatech’s{' '}
              <a
                target="_blank"
                href="https://melatech.io/privacy-policy/"
                rel="noreferrer"
              >
                data privacy policy
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                href="https://melatech.io/terms-of-use/"
                rel="noreferrer"
              >
                terms of use.
              </a>
            </Checkbox>
          </Form.Item>
        </Form>

        {enrollingUserError ? (
          <Alert
            message={
              'Error occured while enrolling you to study. Please try again.'
            }
            type="error"
            showIcon
          />
        ) : null}

        <Button
          loading={enrollingUser}
          type="outline"
          onPress={() => {
            if (form.current) {
              form.current.submit();
            }
          }}
        >
          Enroll
        </Button>
      </Card>
    </StyledTrialRegistration>
  ) : (
    props.children
  );
}

export default TrialRegistrationGuard;
