import {
  AddUserRoleDto,
  CreateUserDto,
  MfaAdminRequestRecoveryLinkForUserDto,
  MfaAdminRequestRecoveryLinkForUserResponseDto,
  Page,
  UpdateUserDto,
  UserDto,
  UserRoleDto,
  UserSearchQueryDto,
} from '@dermloop/api-dtos';
import { EndpointBuilderType } from '../constants';

export const userEndpoints = (builder: EndpointBuilderType) => ({
  getCurrentUser: builder.query<UserDto, void>({
    query: () => 'users/current',
    providesTags: (result) => [{ type: 'USERS', id: result?.id }],
  }),
  updateCurrentUser: builder.mutation<UserDto, Partial<UserDto>>({
    query: (data) => ({
      url: 'users/current',
      method: 'PUT',
      body: data,
    }),
    invalidatesTags: (result, error, arg) => [
      { type: 'MEDICAL_UNIT_USER_AFFILIATIONS' },
      { type: 'USERS' },
    ],
  }),
  countUsers: builder.query<number, void>({
    query: () => 'users/count',
    providesTags: (result) => [{ type: 'USERS', id: 'COUNT' }],
  }),
  countDeleteRequestedUsers: builder.query<number, void>({
    query: () => 'users/count-delete-requests',
    providesTags: (result) => [{ type: 'USERS', id: 'COUNT_DELETE_REQUESTED' }],
  }),
  getUsers: builder.query<Page<UserDto>, { data: UserSearchQueryDto }>({
    query: ({ data }) => ({
      url: 'users',
      params: data,
    }),
    providesTags: (result) => [{ type: 'USERS', id: 'LIST' }],
  }),
  getUser: builder.query<UserDto, { id: string }>({
    query: ({ id }) => ({
      url: `users/${id}`,
    }),
    providesTags: (result) => [{ type: 'USERS', id: result?.id }],
  }),
  addUserRole: builder.mutation<UserRoleDto, { data: AddUserRoleDto }>({
    query: ({ data }) => ({
      url: 'users/user-role',
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (res, error, arg) => {
      if (res?.id) {
        return [{ type: 'USERS', id: arg?.data?.userId }];
      }
      // If something goes wrong, invalidate everything
      return [{ type: 'USERS' }];
    },
  }),
  removeUserRole: builder.mutation<UserRoleDto, { id: string; userId: string }>(
    {
      query: ({ id }) => ({
        url: `users/user-role/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, error, arg) => {
        if (res?.id) {
          return [{ type: 'USERS', id: arg?.userId }];
        }
        // If something goes wrong, invalidate everything
        return [{ type: 'USERS' }];
      },
    }
  ),
  // Create user deactivated as part of issue #2323
  // createUser: builder.mutation<UserDto, { data: CreateUserDto }>({
  //   query: ({ data }) => ({
  //     url: `users`,
  //     method: 'POST',
  //     body: data,
  //   }),
  //   invalidatesTags: (res, error, arg) => {
  //     if (res?.id) {
  //       return [
  //         { type: 'USERS', id: res?.id },
  //         { type: 'USERS', id: 'LIST' },
  //       ];
  //     }
  //     return [{ type: 'USERS', id: 'LIST' }];
  //   },
  // }),
  updateUser: builder.mutation<UserDto, { id: string; data: UpdateUserDto }>({
    query: ({ id, data }) => ({
      url: `users/${id}`,
      method: 'PATCH',
      body: data,
    }),
    invalidatesTags: (res, error, arg) => {
      if (res?.id) {
        return [
          { type: 'USERS', id: res?.id },
          { type: 'USERS', id: 'LIST' },
        ];
      }
      return [{ type: 'USERS', id: 'LIST' }];
    },
  }),
  requestRecoveryLinkForUser: builder.mutation<
    MfaAdminRequestRecoveryLinkForUserResponseDto,
    MfaAdminRequestRecoveryLinkForUserDto
  >({
    query: (data) => ({
      url: `users/request-recovery-link-for-user`,
      method: 'POST',
      body: data,
    }),
  }),
});
