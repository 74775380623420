import { Colors } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import Button from '../button/button';

/* eslint-disable-next-line */
export interface FormControlSaveButtonProps {
  onPress: () => void;
  title?: string;
  disabled?: boolean;
}

const StyledFormControlSaveButton = styled.View`
  align-items: center;
  justify-content: center;
  height: 59px;
  background-color: ${Colors.BRAND_WHITE};
  shadow-color: ${Colors.BRAND_BLACK};
  shadow-offset: 0 2px;
  shadow-radius: 5px;
  shadow-opacity: 0.25;
  margin-bottom: 20px;
`;

export function FormControlSaveButton(props: FormControlSaveButtonProps) {
  return (
    <StyledFormControlSaveButton>
      <Button type="outline" onPress={props.onPress} disabled={props?.disabled}>
        {props.title || 'Save'}
      </Button>
    </StyledFormControlSaveButton>
  );
}

export default FormControlSaveButton;
