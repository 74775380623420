export const NOTCH_SIZE = 10;
export const FONT_SIZE = 15;
export const SMALLEST_FONT_SIZE = 12;
export const HEADING_FONT_SIZE = 20;
export const SUB_HEADING_FONT_SIZE = 18;
export const EXTRA_LARGE_FONT_SIZE = 32;
export const LARGE_HEADING_FONT_SIZE = 25;
export const DEFAULT_TALL_ELEMENT_HEIGHT = 70;
export const HEADING_SMALL_FONT_SIZE = 12;

export const LARGE_TITLE_SIZE = 30;
export const MEDIUM_WEIGHT = '500';
export const SEMI_BOLD_WEIGHT = '600';
export const BOLD_WEIGHT = '700';
export const REGULAR_WEIGHT = '400';

export const DESKTOP_HEADER_HEIGHT = 80;
export const BORDER_RADIUS = 5;
export const BORDER_WIDTH = 2;
export const NATIVE_BORDER_WIDTH = 1;
export const HEADING_NAV_ITEM_BORDER_RADIUS = 2;
export const DESKTOP_TABLE_WIDTH = 1400;

export const GENERAL_SPACING = 16;
export const HALF_GENERAL_SPACING = GENERAL_SPACING * 0.5;
export const QUARTER_GENERAL_SPACING = GENERAL_SPACING * 0.25;
export const DOUBLE_GENERAL_SPACING = GENERAL_SPACING * 2;

export const HEADER_HEIGHT = 206;

/**
 * Tag sizes
 */
export const TAG_WIDTH = 100;
export const TAG_HEIGHT = 25;

/**
 * Button sizes
 */
export const BUTTON_BORDER_RADIUS = 99999;
export const BUTTON_HEIGHT = 35;
export const BUTTON_WIDTH = 165;
export const BUTTON_NARROW_WIDTH = 135;

export const BUTTON_FONT_SIZE = 14;
export const BUTTON_CLEAR_FONT_SIZE = 15;
export const BUTTON_FONT_WEIGHT = 500;
export const LARGE_BUTTON_PADDING = 65;
export const MEDIUM_BUTTON_PADDING = 40;
export const SMALL_BUTTON_PADDING = 25;

export const INPUT_HEIGHT = 40;

export const SCREEN_SIZES = {
  MobileS: '320px',
  MobileM: '375px',
  MobileL: '425px',
  Tablet: '768px',
  Laptop: '1024px',
  LaptopL: '1440px',
  Desktop: '2560px',
};
