import { IsUUID } from 'class-validator';

export class WsLesionTriageUpdateDto {
  @IsUUID()
  userId: string;

  @IsUUID()
  medicalUnitId: string;

  @IsUUID()
  lesionId: string;

  @IsUUID()
  lesionTriageId: string;

  @IsUUID()
  healthRecordId: string;
}
