import {
  IsArray,
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Min,
} from 'class-validator';
import { TrainingAssessmentIndexDataDto } from '../index-dtos/training-assessment.index-dto';

/**
 * DTO used for searching for indexed training assessments.
 */
export class SearchIndexedTrainingAssessmentsDto {
  // User owning training assessments
  @IsString()
  @IsNotEmpty()
  userId: string;

  // Date training assessments should be before
  @IsDate()
  beforeDate: Date;

  // Optional date that training assessments should be after. If none provided, there is no lower bound
  @IsOptional()
  @IsDate()
  afterDate?: Date;

  // How many training assessments to return
  @IsNumber()
  @Min(1)
  count: number;

  // List of diagnosis ids to additionally search for for user
  @IsArray()
  diagnosisIds: string[];
}

/**
 * Result for SearchIndexedTrainingAssessmentsDto.
 * { [diagnosisId | userId]: { trainingAssesssments: TrainingAssessmentIndexDataDto[], totalAssessmentCount: number } - userId is used as key for the latest training assessments.
 * Note that totalAssessmentCount is the total number of assessments a user has done for this diagnosisId.
 */
export interface SearchIndexedTrainingAssessmentsResultDto {
  [key: string]: {
    trainingAssessments: TrainingAssessmentIndexDataDto[];
    totalAssessmentCount: number;
  };
}
