import React, { useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Alert, { AlertProps } from './alert';

interface AlertContextInterface {
  display: (alert: Omit<AlertProps, 'onClose' | 'edgeInsets'>) => void;
  hide: () => void;
}

const AlertContext = React.createContext<AlertContextInterface | null>(null);

export const useAlert = () => {
  return React.useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState<AlertProps>();
  const insets = useSafeAreaInsets();
  return (
    <AlertContext.Provider
      value={{
        display: (alertToShow) =>
          setAlert({
            ...alertToShow,
            edgeInsets: insets,
            onClose: () => setAlert(null),
          }),
        hide: () => setAlert(null),
      }}
    >
      {children}
      {alert ? <Alert {...alert} /> : null}
    </AlertContext.Provider>
  );
};
