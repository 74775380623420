import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/react';
import { Logger } from '@dermloop/shared';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { authenticationActions, useRtkNullDataIfSkip } from '@dermloop/redux';
import { dermloopApi } from '../store/api';

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();
  const onRedirectCallback = (appState) => {
    Logger.info({
      message: 'Rediret callback' + appState?.returnTo,
      extras: { appState },
    });

    history.push(appState?.returnTo);
  };
  return (
    <Auth0Provider
      domain={environment.AUTH0_DOMAIN}
      clientId={environment.AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope={'openid profile email'}
      audience={environment.AUTH0_AUDIENCE}
    >
      <AuthTokenFetcher>{children}</AuthTokenFetcher>
    </Auth0Provider>
  );
};

const AuthTokenFetcher = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const { token } = useAppSelector((s) => s.authentication);

  const currentUserQuery = useRtkNullDataIfSkip({
    query: dermloopApi.useGetCurrentUserQuery,
    queryData: null,
    skipPredicate: () => !token,
  });
  const currentUser = currentUserQuery?.data;

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        dispatch(authenticationActions.authenticate({ token }));
      });

      if (currentUser) {
        Sentry.setUser({ email: currentUser.email, id: currentUser.id });
      }
    }
  }, [currentUser, dispatch, getAccessTokenSilently, isAuthenticated]);

  return children;
};
export default Auth0ProviderWithHistory;
