import { DermloopUserRoleEnum } from '@dermloop/static-values';
import {
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import { MedicalUnitDto } from './medical-unit.dtos';

export class CreateUserRoleDto {
  @IsEnum(DermloopUserRoleEnum)
  role: DermloopUserRoleEnum;

  @IsString()
  @IsNotEmpty()
  @IsUUID()
  userId: string;

  @IsOptional()
  @IsString()
  medicalUnitId: string;
}

export class UpdateUserRoleDto {
  @IsOptional()
  @IsEnum(DermloopUserRoleEnum)
  role: DermloopUserRoleEnum;

  @IsOptional()
  @IsString()
  @IsUUID()
  userId: string;

  @IsOptional()
  @IsString()
  medicalUnitId: string;
}

export class UserRoleDto {
  @IsUUID()
  id: string;

  @IsOptional()
  @IsEnum(DermloopUserRoleEnum)
  role?: DermloopUserRoleEnum;

  @IsOptional()
  @IsString()
  @IsUUID()
  userId?: string;

  @IsOptional()
  @ValidateNested()
  medicalUnit?: MedicalUnitDto;
}
