import { MinimalHealthRecordDto, SkinTypeDto } from '@dermloop/api-dtos';
import { formatSSN } from '@dermloop/shared';
import { Icon } from '@dermloop/ui/components';
import { DESKTOP_HEADER_HEIGHT, SkinTypes } from '@dermloop/ui/util';
import styled from 'styled-components';
import { Colored } from '../colored/colored';
import ImageMedia from '../image-media/image-media';
import Title from '../title/title';

/* eslint-disable-next-line */
export interface PatientHeaderProps {
  healthRecord: MinimalHealthRecordDto;
  onClick: () => void;
  skinTypes: SkinTypeDto[];
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  height: ${DESKTOP_HEADER_HEIGHT}px;
`;

const Avatar = styled.div`
  padding: 0.25rem;
  display: flex;
  margin-right: 1rem;
  width: 56px;
  height: 56px;
  cursor: pointer;
`;

export function PatientHeader(props: PatientHeaderProps) {
  const { healthRecord, onClick } = props;
  const patientSkinType = props.skinTypes?.find(
    (s) => s.id === healthRecord?.patientDetails?.skinTypeId
  );

  if (!healthRecord) return null;

  const avatar = SkinTypes.getPatientAvatar(
    props.healthRecord?.gender?.name,
    props.healthRecord?.ssn,
    patientSkinType?.name
  );
  return (
    <Container>
      <Avatar onClick={onClick}>
        {avatar ? <ImageMedia src={avatar} /> : <Icon icon="AvatarMissing" />}
      </Avatar>
      <Title size="large" data-cy="patientHeaderTitle">
        {props.healthRecord?.gender?.name}
        <Colored.SECONDARY>
          {' '}
          • {formatSSN(props.healthRecord?.ssn)}
        </Colored.SECONDARY>
      </Title>
    </Container>
  );
}

export default PatientHeader;
