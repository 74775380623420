import {
  LesionDto,
  LesionSearch2ResultDto,
  LesionSearchResultDto,
  Page,
  SearchLesionDto,
  SearchLesionDtoUser,
  UpdateLesionDto,
} from '@dermloop/api-dtos';
import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { dermloopBaseApi } from '../api';
import { EndpointBuilderType } from '../constants';
import { DermloopBaseApiTag } from '../tag-types';

export const lesionEndpoints = (builder: EndpointBuilderType) => ({
  searchLesions: builder.query<Page<LesionSearchResultDto>, SearchLesionDto>({
    query: (query) => ({
      url: 'lesions/search',
      method: 'POST',
      body: query,
    }),

    providesTags: (result, error, args) => {
      const tags: TagDescription<DermloopBaseApiTag>[] = [
        { type: 'LESION', id: 'LIST' },
      ];
      if (args.users === SearchLesionDtoUser.Own) {
        tags.push({ type: 'RECENT_LESIONS' });
      }
      return tags;
    },
  }),
  searchLesions2: builder.query<Page<LesionSearch2ResultDto>, SearchLesionDto>({
    query: (query) => ({
      url: 'lesions/search2',
      method: 'POST',
      body: query,
    }),

    providesTags: (result, error, args) => {
      const tags: TagDescription<DermloopBaseApiTag>[] = [
        { type: 'LESION2', id: 'LIST' },
      ];
      if (args.users === SearchLesionDtoUser.Own) {
        tags.push({ type: 'RECENT_LESIONS2' });
      }
      return tags;
    },
  }),
  updateLesion: builder.mutation<
    LesionDto,
    { id: string; update: UpdateLesionDto; healthRecordId?: string }
  >({
    query: ({ id, update }) => ({
      url: `lesions/${id}`,
      method: 'PUT',
      body: update,
    }),
    onQueryStarted: async ({ id, update, healthRecordId }, { dispatch }) => {
      if (healthRecordId) {
        // Optimistically update health-records lesion cache and let cache invalidation handle revert on error.
        dispatch(
          dermloopBaseApi.util.updateQueryData(
            'getHealthRecord',
            healthRecordId,
            (draft) => {
              const lesions = draft.lesions?.map((l) =>
                l.id === id ? { ...l, ...update } : l
              );
              draft.lesions = lesions;
              return draft;
            }
          )
        );
      }
    },
    invalidatesTags: (result, error, args) => {
      const tags: TagDescription<DermloopBaseApiTag>[] = [
        { type: 'HEALTH_RECORD', id: result?.healthRecordId },
      ];
      return tags;
    },
  }),
  deleteLesion: builder.mutation<void, string>({
    query: (lesionId) => ({
      url: `lesions/${lesionId}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, lesionId) => [
      { type: 'LESION2', id: lesionId },
      { type: 'LESION2', id: 'LIST' },
    ],
  }),
});
