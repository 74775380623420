import { Exclude } from 'class-transformer';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
} from 'class-validator';
import { BaseCreateDto, BaseDto, BaseUpdateDto } from './base.dto';
import { CountryDto } from './region-info/country.dtos';

export interface JurisdictionalGroupDto extends BaseDto {
  name: string;
  country?: CountryDto;
  countryId?: string;
  requirePatientConsent?: boolean;
}

export class CreateJurisdictionalGroupDto
  extends BaseCreateDto
  implements Partial<JurisdictionalGroupDto>
{
  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsUUID()
  countryId?: string;

  @Exclude()
  country?: never;

  @IsOptional()
  @IsBoolean()
  requirePatientConsent?: boolean;
}

export class UpdateJurisdictionalGroupDto
  extends BaseUpdateDto
  implements Partial<JurisdictionalGroupDto>
{
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  name?: string;

  @IsOptional()
  @IsUUID()
  countryId?: string;

  @IsOptional()
  @IsBoolean()
  requirePatientConsent?: boolean;

  @Exclude()
  country?: never;
}

/**
 * DTO for querying jurisdictional groups.
 */
export class JurisdictionalGroupSearchQueryDto {
  @IsOptional()
  @IsNumber()
  @Min(0)
  skip?: number;

  @IsOptional()
  @IsNumber()
  @Min(1)
  take?: number;

  @IsOptional()
  @IsString()
  searchText?: string;
}
