import { Logger } from '@dermloop/shared';
import axios, { AxiosError, AxiosResponse } from 'axios';

export const Api = axios.create({});

export const configureApi = (options: {
  token?: string;
  baseUrl?: string;
  medicalUnitId?: string;
  onUnauthorized?: (error: AxiosError) => void;
  onRemoveInterceptor?: boolean;
}) => {
  if ('baseUrl' in options) {
    Api.defaults.baseURL = options.baseUrl;
  }

  if ('token' in options) {
    Api.defaults.headers.Authorization = `Bearer ${options.token}`;
  }

  if ('medicalUnitId' in options) {
    Api.defaults.headers['x-medical-unit'] = options.medicalUnitId;
  }

  if (options.onUnauthorized) {
    Api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error.response && error?.response?.status === 401) {
          options.onUnauthorized(error);
        }

        return Promise.reject(error);
      }
    );
  }
  console.log('configureApi', Api.defaults.headers);
};

export type RequestResponse<T> =
  | AxiosError<{ message: string | string[] }>
  | AxiosResponse<T>;

export async function makeSafeRequest<T>(
  request: Promise<AxiosResponse<T>>
): Promise<RequestResponse<T>> {
  try {
    return await request;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return err;
    }
    throw err;
  }
}

/**
 * Retries a request a number of times with a specified delay. Checks whether response is axios error or not.
 * @param request function to make request
 * @param retriesLeft maximum number of retries
 * @param interval the interval in ms to wait before making new request
 * @returns the final response from axios
 */
export function retryRequest<T>(
  request: () => Promise<RequestResponse<T>>,
  retriesLeft = 3,
  interval = 200
) {
  return new Promise<RequestResponse<T>>((resolve, reject) => {
    return request().then((res) => {
      if (!axios.isAxiosError(res)) {
        resolve(res);
        return;
      }

      if (retriesLeft === 1) {
        Logger.error({ message: 'Maximum retries reached', error: res });
        resolve(res);
        return;
      }

      Logger.warn({
        message: `Request failed. Will reattempt in ${interval}ms ${retriesLeft}`,
        error: res,
      });

      setTimeout(() => {
        retryRequest(request, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
  });
}
