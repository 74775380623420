import { Colors } from '@dermloop/ui/util';

import styled, { keyframes } from 'styled-components';

/* eslint-disable-next-line */
export interface LoadingSpinnerProps {}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const thickness = '5px';
const StyledLoadingSpinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: ${thickness} solid ${Colors.BRAND_GRAY_MEDIUM};
  border-right: ${thickness} solid ${Colors.BRAND_GRAY_MEDIUM};
  border-bottom: ${thickness} solid ${Colors.BRAND_GRAY_MEDIUM};
  border-left: ${thickness} solid ${Colors.BRAND_BROWN_DARK};
  background: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export function LoadingSpinner(props: LoadingSpinnerProps) {
  return <StyledLoadingSpinner />;
}

export default LoadingSpinner;
