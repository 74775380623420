import {
  DAHTLesionDto,
  DAHTLesionSearchDto,
  DAHTSessionDto,
  DAHTUserEnrollmentDto,
  Page,
  UpdateDAHTAssessmentDto,
  UpdateDAHTLesionDto,
} from '@dermloop/api-dtos';
import { dermloopBaseApi } from '../api';
import { EndpointBuilderType } from '../constants';

export const dahtEndPoints = (builder: EndpointBuilderType) => ({
  enrollUserToDAHT: builder.mutation<
    DAHTUserEnrollmentDto,
    DAHTUserEnrollmentDto
  >({
    query: (data) => ({
      url: 'daht/user-enrollment',
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (res, error, arg) => {
      return [{ type: 'USERS', id: arg?.userId }, { type: 'DAHT_SESSION' }];
    },
  }),
  getDAHTSession: builder.query<DAHTSessionDto, void>({
    query: () => 'daht/session',
    providesTags: (result) => [],
  }),
  updateDAHTAssessment: builder.mutation<
    UpdateDAHTAssessmentDto,
    { id: string; update: UpdateDAHTAssessmentDto }
  >({
    query: (data) => ({
      url: `daht/assessment/${data.id}`,
      method: 'PATCH',
      body: data.update,
    }),
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      //Assume that assessment updates are making assessments
      //Update session immideatly and assume that the assessment update works as intended.
      const patchResult = dispatch(
        dermloopBaseApi.util.updateQueryData(
          'getDAHTSession',
          undefined,
          (draft) => {
            const assessments = draft.assessments?.map((a) =>
              a.id === arg.id
                ? { ...a, ...arg.update, assessedDate: new Date() }
                : a
            );
            draft.assessments = assessments;
            return draft;
          }
        )
      );
      try {
        await queryFulfilled;
      } catch {
        patchResult.undo();
      }
    },
    invalidatesTags: (res, error, arg) => {
      return [];
    },
  }),
  searchDAHTLesions: builder.query<Page<DAHTLesionDto>, DAHTLesionSearchDto>({
    query: (data) => ({
      url: `daht-lesions/search`,
      method: 'POST',
      body: data,
    }),
    providesTags: (res, error, arg) => {
      return [{ type: 'DAHT_LESIONS', id: 'LIST' }];
    },
  }),
  getDAHTLesion: builder.query<DAHTLesionDto, { id: string }>({
    query: (data) => ({
      url: `daht-lesions/${data?.id}`,
      method: 'GET',
    }),
    providesTags: (res, error, arg) => {
      return [{ type: 'DAHT_LESIONS', id: res?.id }];
    },
  }),
  updateDAHTLesion: builder.mutation<
    DAHTLesionDto,
    { id: string; update: UpdateDAHTLesionDto }
  >({
    query: (data) => ({
      url: `daht-lesions/${data.id}`,
      method: 'PATCH',
      body: data.update,
    }),
    invalidatesTags: (res, error, arg) => {
      return [{ type: 'DAHT_LESIONS', id: res?.id }];
    },
  }),
  createBaselineLesions: builder.mutation<void, void>({
    query: () => ({
      url: `daht/baseline-lesions`,
      method: 'POST',
    }),
    invalidatesTags: (res, error, arg) => {
      return [];
    },
  }),
});
