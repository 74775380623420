import { ReactNode } from 'react';
import { LayoutRectangle, View } from 'react-native';

import { Colors, IconType, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import { Typography } from '../typography/typography';

import Button from '../button/button';
import FormControlHeader from '../form-control-header/form-control-header';
import { webSpecificValue } from '../utils';

/* eslint-disable-next-line */
export interface FormControlGroupProps {
  title?: string;
  mandatory?: boolean;
  subtitle?: string;
  onPress?: () => void;
  open?: boolean;
  onCollapse?: () => void;
  previewValue?: string | JSX.Element;
  previewValueColor?: 'medium' | 'success' | 'danger' | 'warning' | 'dark';
  children: JSX.Element | JSX.Element[] | ReactNode;
  showCollapseButton?: boolean;
  collapseButtonTitle?: string;
  disableCollapse?: boolean;
  shadow?: boolean;
  openedIcon?: IconType;
  hideClosedIcon?: boolean;
  // If true, no icon will be shown for the header
  hideIcon?: boolean;
  onButtonPress?: () => void;
  rightSideHeaderContent?: JSX.Element;
  onLayout?: (layout: LayoutRectangle) => void;
}

const StyledFormControlGroup = styled.Pressable<{
  shadow: boolean;
  isOpen: boolean;
}>`
  background: ${Colors.BRAND_WHITE};
  display: flex;
  border-bottom-color: ${Colors.BRAND_GRAY_MEDIUM};
  border-bottom-width: ${(props) => (props.isOpen && props.shadow ? 1 : 0)}px;
  shadow-color: ${Colors.BRAND_BLACK};
  shadow-offset: 0 2px;
  shadow-radius: 5px;
  shadow-opacity: ${(props) => (props.shadow ? 0.25 : 0)};
  margin-bottom: ${(props) => (props.shadow ? 10 : 0)}px;
  ${webSpecificValue(`cursor: '';`)};
  ${webSpecificValue(`z-index: unset;`)};
`;
const StyledFormControlGroupBody = styled.View`
  display: flex;
  flex-direction: column;
  ${webSpecificValue(`z-index: unset;`)};
`;

const StyledFormControlPreviewString = styled.View`
  padding: 0 ${Sizes.GENERAL_SPACING}px ${Sizes.GENERAL_SPACING}px
    ${Sizes.GENERAL_SPACING}px;
  ${webSpecificValue(`z-index: unset;`)};
`;
const StyledFormControlPreviewElement = styled.View`
  padding: 0 ${Sizes.GENERAL_SPACING}px ${Sizes.GENERAL_SPACING}px
    ${Sizes.GENERAL_SPACING}px;
  color: ${Colors.BRAND_SUCCESS};
  ${webSpecificValue(`z-index: unset;`)};
`;

/**
 *
 * @param props
 */
export function FormControlGroup(props: FormControlGroupProps) {
  const isOpen = props.open || !props.previewValue;
  const closedIcon = props.hideClosedIcon ? null : 'SegueDown';

  return (
    <StyledFormControlGroup
      shadow={props.shadow}
      isOpen={props.open}
      onLayout={(event) =>
        props.onLayout ? props.onLayout(event.nativeEvent.layout) : null
      }
    >
      <FormControlHeader
        title={props.title}
        mandatory={props.mandatory}
        subtitle={props.subtitle}
        icon={props.hideIcon ? null : !isOpen ? closedIcon : props.openedIcon}
        rightSideContent={props.rightSideHeaderContent}
        onButtonPress={props.onButtonPress}
        onPress={() => {
          props.onPress && props.onPress();
        }}
      />
      <StyledFormControlGroupBody>
        {!props.open && props.previewValue ? (
          typeof props.previewValue === 'string' ? (
            <StyledFormControlPreviewString>
              <Typography
                color={
                  props.previewValueColor ? props.previewValueColor : 'success'
                }
                size="normal"
              >
                {props.previewValue}
              </Typography>
            </StyledFormControlPreviewString>
          ) : (
            <StyledFormControlPreviewElement>
              {props.previewValue}
            </StyledFormControlPreviewElement>
          )
        ) : (
          props.children
        )}
      </StyledFormControlGroupBody>
      {props.showCollapseButton && props.open ? (
        <View
          style={{
            height: 48,
            borderTopWidth: Sizes.NATIVE_BORDER_WIDTH,
            borderTopColor: Colors.BRAND_GRAY_MEDIUM,
          }}
        >
          <Button
            onPress={() => props.onCollapse && props.onCollapse()}
            type="clear"
            display="block"
            color="tertiary"
            shape="square"
            disabled={props.disableCollapse}
            noShadow
          >
            {props.collapseButtonTitle || 'Collapse'}
          </Button>
        </View>
      ) : null}
    </StyledFormControlGroup>
  );
}

export default FormControlGroup;
