import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@dermloop/ui/components';
import { Colors } from '@dermloop/ui/util';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../../store/hooks';

/* eslint-disable-next-line */
export interface BannerProps {}

const StyledBanner = styled.div`
  background: ${Colors.BRAND_SUCCESS};
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTextContainer = styled.div`
  max-width: 30%;
  margin: 24px 0 32px 0;
  text-align: center;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

/**
 *
 * @param props
 */
export function Banner(props: BannerProps) {
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <StyledBanner>
      <Typography center weight="medium" size="extra-large" color="light">
        {`DAHT`}
      </Typography>
      <Typography center weight="medium" size="extra-large" color="light">
        {`Consensus agreement`}
      </Typography>
      <Typography center weight="medium" size="extra-large" color="light">
        {`A part of AISC research`}
      </Typography>
      <StyledTextContainer>
        <Typography size="normal" color="light">
          The DAHT consensus trial strives to establish a gold standard
          diagnosis for all DAHT cases through a four-phased Delphi-like
          process.
        </Typography>
      </StyledTextContainer>
      <StyledActionsContainer>
        {isAuthenticated ? (
          <Button
            onPress={() => {
              history.push('/overview');
            }}
            color="secondary"
            type="outline"
          >
            Go to trial
          </Button>
        ) : (
          <Button
            dataSet={{ cy: 'sign-up' }}
            loading={isLoading}
            onPress={() => {
              loginWithRedirect({
                appState: {
                  returnTo: window.location.pathname + 'overview',
                },
              });
            }}
            color="secondary"
            type="outline"
          >
            Signup or sign in
          </Button>
        )}
      </StyledActionsContainer>
    </StyledBanner>
  );
}

export default Banner;
