import { AnnotationFilterStatus } from '@dermloop/static-values';
import { Type } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateNested,
} from 'class-validator';
import { TrainingLesionIndexDataDto } from '../index-dtos';

// DTO for ordering training lesion search results.
export class SearchIndexedTrainingLesionsOrderDto {
  @IsString()
  order: keyof TrainingLesionIndexDataDto;

  // If no direction provided, order is ascending
  @IsOptional()
  @IsString()
  orderDirection?: 'asc' | 'desc';
}

/**
 * DTO for searching for training lesions.
 */

export class SearchIndexedTrainingLesionsDto {
  // Elastic has a limit of 10000 for skip + take. Therefore we limit these options
  // See: https://www.elastic.co/guide/en/elasticsearch/guide/current/pagination.html
  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1000)
  skip?: number;

  @IsNumber()
  @Min(1)
  @Max(1000)
  take: number;

  // Optional ordering of results. First ordering has precedence
  // If no ordering provided, results will be ordered by id ascending
  @IsOptional()
  @Type(() => SearchIndexedTrainingLesionsOrderDto)
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  orders?: SearchIndexedTrainingLesionsOrderDto[];

  @IsOptional()
  @IsUUID('all', { each: true })
  statusIds?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  locationIds?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  diagnosisIds?: string[];

  @IsOptional()
  @IsUUID('all', { each: true })
  secondaryDiagnosisIds?: string[];

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  dermoscopicImageDifficultyIndexMin?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  dermoscopicImageDifficultyIndexMax?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  assessmentsCountMin?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  assessmentsCountMax?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  patientAgeYearsMin?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  patientAgeYearsMax?: number;

  @IsOptional()
  @IsString()
  annotationStatus?: AnnotationFilterStatus;

  @IsOptional()
  @IsNumber()
  @Min(0)
  annotationRequestsCountMin?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  annotationRequestsCountMax?: number;

  @IsOptional()
  @IsUUID('all', { each: true })
  skinTypeIds?: string[];
}
