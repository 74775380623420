import { keyframes } from 'styled-components';

/**
 * Slides left from right screen edge, showing element.
 */
export const slideLeftFromEdge = keyframes`

  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

/**
 * Slides right to right screen edge, hiding element.
 */
export const slideRightToEdge = keyframes`

  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;
