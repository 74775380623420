import {
  DermloopAlternativeImageType,
  PhotoType,
  PhotoTypes,
  ThumbnailProcessingState,
} from '@dermloop/static-values';
import { Transform } from 'class-transformer';
import {
  IsEnum,
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator';
import { v4 as uuidv4 } from 'uuid';
import { BaseDto } from '../base.dto';

//TODO find better location for this placement
//Create temporary ids that are used when uploading images.
export const createTempImageId = () => `temp-${uuidv4()}`;
export const isTempImageId = (id: string) => !!id?.startsWith('temp-');

export class CreateLesionImageDto {
  @IsOptional()
  @IsNumber()
  focalPointX: number;

  @IsOptional()
  @IsNumber()
  focalPointY: number;

  //TODO remove this when next version of apps are released with fixes for this validation error.
  @Transform(({ value }) => (value === 'dermoscopic' ? 'Dermoscopy' : value), {
    toClassOnly: true,
  })
  @IsNotEmpty()
  @IsIn(PhotoTypes)
  type: PhotoType;

  @IsOptional()
  @IsNumber()
  sharpness?: number;

  @IsOptional()
  @IsNumber()
  alternativeImageSharpness?: number;

  @IsOptional()
  @IsEnum(DermloopAlternativeImageType)
  alternativeImageType?: DermloopAlternativeImageType;

  @IsString()
  @IsNotEmpty()
  lesionTriageId: string;
}

export class UpdateLesionImageDto {
  @IsOptional()
  @IsNumber()
  focalPointX?: number;

  @IsOptional()
  @IsNumber()
  focalPointY?: number;

  @IsString()
  @IsNotEmpty()
  lesionTriageId: string;
}
/**
 * @description Legacy urls with old naming convention.
 * @deprecated Should not be used in any new work with images.
 */
class LesionImageDtoLegacyUrls extends BaseDto {
  constructor(lesionImageDto: Partial<LesionImageDto>) {
    super();
    if (lesionImageDto) {
      this.thumbnailXsIdentifier = lesionImageDto.thumbnailXsUrl;
      this.thumbnailSIdentifier = lesionImageDto.thumbnailSUrl;
      this.thumbnailMIdentifier = lesionImageDto.thumbnailMUrl;
      this.thumbnailLIdentifier = lesionImageDto.thumbnailLUrl;
      this.thumbnailXLIdentifier = lesionImageDto.thumbnailXlUrl;

      this.alternativeImageThumbnailXsIdentifier =
        lesionImageDto.alternativeImageThumbnailXsUrl;
      this.alternativeImageThumbnailSIdentifier =
        lesionImageDto.alternativeImageThumbnailSUrl;
      this.alternativeImageThumbnailMIdentifier =
        lesionImageDto.alternativeImageThumbnailMUrl;
      this.alternativeImageThumbnailLIdentifier =
        lesionImageDto.alternativeImageThumbnailLUrl;
      this.alternativeImageThumbnailXLIdentifier =
        lesionImageDto.alternativeImageThumbnailXlUrl;
    }
  }
  /**
   * @deprecated legacy identifier use urls instead
   */
  thumbnailXsIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  thumbnailSIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  thumbnailMIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  thumbnailLIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  thumbnailXLIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  alternativeImageThumbnailXsIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  alternativeImageThumbnailSIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  alternativeImageThumbnailMIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  alternativeImageThumbnailLIdentifier?: string;
  /**
   * @deprecated legacy identifier use urls instead
   */
  alternativeImageThumbnailXLIdentifier?: string;
}

export class LesionImageDto extends LesionImageDtoLegacyUrls {
  constructor(data?: Partial<LesionImageDto>) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }

  @IsOptional()
  @IsString()
  thumbnailXsUrl?: string;

  @IsOptional()
  @IsString()
  thumbnailSUrl?: string;

  @IsOptional()
  @IsString()
  thumbnailMUrl?: string;

  @IsOptional()
  @IsString()
  thumbnailLUrl?: string;

  @IsOptional()
  @IsString()
  thumbnailXlUrl?: string;

  @IsOptional()
  @IsString()
  thumbnailProcessingState?: ThumbnailProcessingState;

  @IsOptional()
  @IsString()
  url?: string;

  @IsOptional()
  @IsString()
  uploadUrl?: string;

  @IsOptional()
  @IsString()
  alternativeImageThumbnailXsUrl?: string;

  @IsOptional()
  @IsString()
  alternativeImageThumbnailSUrl?: string;

  @IsOptional()
  @IsString()
  alternativeImageThumbnailMUrl?: string;

  @IsOptional()
  @IsString()
  alternativeImageThumbnailLUrl?: string;

  @IsOptional()
  @IsString()
  alternativeImageThumbnailXlUrl?: string;

  @IsOptional()
  @IsString()
  alternativeImageThumbnailProcessingState?: ThumbnailProcessingState;

  @IsOptional()
  @IsString()
  alternativeImageUrl?: string;

  @IsOptional()
  @IsString()
  alternativeImageUploadUrl?: string;

  @IsOptional()
  @IsEnum(DermloopAlternativeImageType)
  alternativeImageType?: DermloopAlternativeImageType;

  @IsOptional()
  @IsNumber()
  focalPointX?: number;

  @IsOptional()
  @IsNumber()
  focalPointY?: number;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  imageTypeId?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  medicalUnitId?: string;

  lesionTriageId?: string;

  @IsOptional()
  @IsNumber()
  sharpness?: number;

  @IsOptional()
  @IsNumber()
  alternativeImageSharpness?: number;
}

/**
 * DTO for the lesion image urls
 */
export interface LesionImageUrlsDto {
  url?: string;
  thumbnailXsUrl?: string;
  thumbnailSUrl?: string;
  thumbnailMUrl?: string;
  thumbnailLUrl?: string;
  thumbnailXlUrl?: string;
}

export type LesionImageFocalPoint = Pick<
  LesionImageDto,
  'focalPointX' | 'focalPointY'
>;
