import { IsGreaterThan, SumEquals } from '@dermloop/utils';
import { Exclude, Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateNested,
} from 'class-validator';
import { BaseCreateDto, BaseDto, BaseUpdateDto } from '../base.dto';

export interface TrainingLesionDifficultyLevelDto extends BaseDto {
  name: string;

  minDifficulty: number;

  maxDifficulty: number;

  progressionAccuracy?: number;

  downgradeAccuracy?: number;

  mixinCurrentDifficulty: number;

  mixinPreviousDifficulties: number;

  mixinNoDifficulty: number;
  difficultyLevelGroupingId: string;
  notes?: string;
}

/**
 * DTO for batch creating and updating lesion difficulty levels.
 */
export class BatchTrainingLesionDifficultyLevelDto extends BaseCreateDto {
  @IsUUID()
  difficultyLevelGroupingId: string;

  @IsOptional()
  @Type(() => CreateTrainingLesionDifficultyLevelDto)
  @ValidateNested({ each: true })
  createDifficultyLevels?: CreateTrainingLesionDifficultyLevelDto[];

  @IsOptional()
  @Type(() => UpdateTrainingLesionDifficultyLevelDto)
  @ValidateNested({ each: true })
  updateDifficultyLevels?: UpdateTrainingLesionDifficultyLevelDto[];
}

export class CreateTrainingLesionDifficultyLevelDto
  extends BaseCreateDto
  implements Partial<TrainingLesionDifficultyLevelDto>
{
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsNumber()
  @Min(0)
  @Max(1)
  minDifficulty: number;

  @IsNumber()
  @Min(0)
  @Max(1)
  @IsGreaterThan<CreateTrainingLesionDifficultyLevelDto>('minDifficulty', {
    message: 'maxDifficulty must be greater than minDifficulty',
  })
  maxDifficulty: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  progressionAccuracy: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  downgradeAccuracy?: number;

  @IsNumber()
  @Min(0)
  @Max(1)
  mixinCurrentDifficulty: number;

  @IsNumber()
  @Min(0)
  @Max(1)
  mixinPreviousDifficulties: number;

  @IsNumber()
  @Min(0)
  @Max(1)
  @SumEquals<CreateTrainingLesionDifficultyLevelDto>(
    1,
    ['mixinCurrentDifficulty', 'mixinPreviousDifficulties'],
    { message: 'All mixin difficulties must sum to 1' }
  )
  mixinNoDifficulty: number;

  @Exclude()
  difficultyLevelGrouping: never;

  @IsUUID()
  difficultyLevelGroupingId: string;

  @IsOptional()
  @IsString()
  notes?: string;
}

export class UpdateTrainingLesionDifficultyLevelDto
  extends BaseUpdateDto
  implements Partial<TrainingLesionDifficultyLevelDto>
{
  @IsOptional()
  @IsString()
  @IsNotEmpty()
  name?: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  minDifficulty?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  @IsGreaterThan<CreateTrainingLesionDifficultyLevelDto>('minDifficulty')
  maxDifficulty?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  progressionAccuracy?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  downgradeAccuracy?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  mixinCurrentDifficulty?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  mixinPreviousDifficulties?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  @SumEquals<CreateTrainingLesionDifficultyLevelDto>(1, [
    'mixinCurrentDifficulty',
    'mixinPreviousDifficulties',
  ])
  mixinNoDifficulty?: number;

  @Exclude()
  difficultyLevelGrouping?: never;

  @Exclude()
  difficultyLevelGroupingId?: never;

  @IsOptional()
  @IsString()
  notes?: string;
}

/**
 * DTO for deleting a training lesion difficulty level. A new difficulty level id
 * must be provided, that existing users are moved to before delete.
 */
export class DeleteTrainingLesionDifficultyLevelQueryDto {
  @IsUUID()
  moveUsersToTrainingLesionDifficultyLevelId: string;
}
