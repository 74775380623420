import { Colors, IconType, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import Icon from '../icon/icon';
import Typography from '../typography/typography';

/* eslint-disable-next-line */
export interface ChipProps {
  title: string;
  icon: IconType;
  iconPosition?: 'left' | 'right';
  onPress: () => void;
  active?: boolean;
  disabled?: boolean;
}

const StyledChip = styled.Pressable<ChipProps>`
  display: flex;
  border-radius: ${Sizes.BUTTON_BORDER_RADIUS}px;
  background-color: ${(props) =>
    props.active && !props.disabled
      ? Colors.BRAND_SUCCESS
      : Colors.BRAND_WHITE};
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding-vertical: ${Sizes.GENERAL_SPACING / 2}px;
  padding-left: ${Sizes.GENERAL_SPACING / 2}px;
  padding-right: ${Sizes.GENERAL_SPACING}px;
`;

const ChipTitleWrapper = styled.View<ChipProps>`
  margin-left: ${(props) =>
    props.iconPosition !== 'right' ? Sizes.GENERAL_SPACING / 2 : 0}px;
  margin-right: ${(props) =>
    props.iconPosition === 'right' ? Sizes.GENERAL_SPACING / 2 : 0}px;
`;

function getColor(props: ChipProps) {
  if (props.disabled) return 'medium';
  if (props.active) return 'light';
  return 'success';
}

export function Chip(props: ChipProps) {
  return (
    <StyledChip {...props} onPress={() => !props.disabled && props.onPress()}>
      {props.iconPosition !== 'right' && <Icon icon={props.icon} fill="none" />}
      <ChipTitleWrapper {...props}>
        <Typography color={getColor(props)} size="normal" weight="medium">
          {props.title}
        </Typography>
      </ChipTitleWrapper>
      {props.iconPosition === 'right' && <Icon icon={props.icon} fill="none" />}
    </StyledChip>
  );
}

export default Chip;
