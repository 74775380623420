import { Colors, BUTTON_BORDER_RADIUS } from '@dermloop/ui/util';

export type ButtonFill = 'clear' | 'solid' | 'outline';
export type ButtonColor = 'light' | 'dark' | 'white' | 'success';
export type TextColor = 'light' | 'dark' | 'white' | 'success';
export interface Props {
  name: string;
  loading?: boolean;
  onPress?: () => void;
  fill: ButtonFill;
  color: ButtonColor;
  textColor: TextColor;
  cursorDisabled?: boolean;
}

const toColor = (color: ButtonColor) => {
  if (color === 'light') return Colors.BRAND_GRAY_MEDIUM;
  if (color === 'dark') return Colors.BRAND_BROWN_DARK;
  if (color === 'white') return Colors.BRAND_WHITE;
  if (color === 'success') return Colors.BRAND_SUCCESS;
  return null;
};

const toTextColor = (color: TextColor) => {
  if (color === 'light') return Colors.BRAND_GRAY_MEDIUM;
  if (color === 'dark') return Colors.BRAND_BROWN_DARK;
  if (color === 'white') return Colors.BRAND_WHITE;
  if (color === 'success') return Colors.BRAND_SUCCESS;
  return null;
};

export const styles = (
  props: Pick<Props, 'color' | 'fill' | 'textColor' | 'cursorDisabled'>
) => {
  return `
  border-radius:${BUTTON_BORDER_RADIUS}px;
  border-color:${toColor(props.color)};
  color:${toTextColor(props.textColor)};
  background-color:${
    props.fill === 'solid' ? toColor(props.color) : 'transparent'
  };
  border-style:solid;
  min-width:80px;
  height:38px;
  border-width:1px;
  cursor: ${props.cursorDisabled ? '' : 'pointer'};
`;
};
