import { IsOptional, IsString, IsUUID } from 'class-validator';

export class NamedEntityDto {
  @IsUUID()
  id: string;

  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  name_da?: string;
}
