/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei';
import { ReactNode, forwardRef } from 'react';
import maleDraco from './male-draco.glb';

const MaleModel = forwardRef<any, { children?: ReactNode | undefined }>(
  ({ ...props }, ref) => {
    const { nodes, materials } = useGLTF(maleDraco, true) as any;

    const material = nodes.StylizedBaseMesh.material;
    material.metalness = 0;
    return (
      <group {...props} dispose={null}>
        <mesh
          ref={ref}
          geometry={nodes.StylizedBaseMesh.geometry}
          material={material}
        >
          {props.children}
        </mesh>
      </group>
    );
  }
);

export default MaleModel;
