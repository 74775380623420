import { parseJsonElse, stringifyElse } from '../string/string-json.util';

/**
 * Available redaction types.
 */
export enum RedactValueTypes {
  // Removes 10-digit numbers
  SSN = 'ssn',
  SSN_WITH_DASH = 'ssn-with-dash',
}

/**
 * Conditional types for type safe return type.
 */
export type RedactReturnTypeStr = 'string' | 'object';
export type RedactReturnType<T, R> = R extends 'string' ? string : T;

/**
 * Redacts values from an object. Both in properties and property values.
 * NB! The object must not contain recursive structures. It has to be convertable
 * to json.
 * @param obj
 * @param returnType          Used for determining if output is object or result.
 * @param redactTypes         Array of redactions to use. If none provided, uses all redact value types.
 * @param jsonTextKeys        Array of json keys to redact.
 * @returns
 */
export const redactObjectValues = <
  T extends object,
  R extends RedactReturnTypeStr
>(
  obj: T,
  returnType: R,
  props?: {
    redactTypes?: RedactValueTypes[];
    jsonTextKeys?: string[];
  }
): RedactReturnType<T, R> => {
  if (!obj) return null;

  try {
    // Map types to regexes
    const regexes = [
      ...(props?.redactTypes ?? Object.values(RedactValueTypes)),
    ].map((t) => ({ regex: redactTypeToRegex(t), valueTyp: t }));

    // Stringify object. If instanceof Error, then we cannot stringify. Instead, we can call toString to get a JSON representation
    let strObj: string =
      obj instanceof Error
        ? obj?.['response']
          ? // In some cases, obj contains a response property. This is an object that can be stringified
            stringifyElse(obj['response'], null)
          : // If no response object, call toString
            `{ message: "${obj.toString()}" }`
        : stringifyElse(obj, null);

    // If creating string object fails, return without redaction
    if (!strObj) {
      return (
        returnType === 'string' ? obj?.toString() : obj
      ) as RedactReturnType<T, R>;
    }

    // Iterate through regexes and redact values with redact type name
    regexes.forEach((r) => {
      strObj = strObj.replace(r.regex, `<Redacted ${r.valueTyp}>`);
    });

    // If any json text keys provided, redact those
    if (props?.jsonTextKeys?.length) {
      props.jsonTextKeys.forEach((key) => {
        // Replace key without quote escapes
        strObj = strObj.replace(
          jsonKeyTextRegex(key),
          `"${key}": "<Redacted>"`
        );
        // Replace key with quote escapes
        strObj = strObj.replace(
          jsonKeyTextRegexQuoteEscape(key),
          `\\"${key}\\": \\"<Redacted>\\"`
        );
      });
    }

    // Optionally return object as string
    if (returnType === 'string') return strObj as RedactReturnType<T, R>;

    // Parse stringified object and return
    const parsedStrObj = parseJsonElse(strObj, false);
    return {
      ...(parsedStrObj ?? { message: strObj }),
      // We add a to string function, to ensure we get a string when we stringify
      toString: () => strObj,
    };
  } catch (error) {
    return (
      returnType === 'string'
        ? obj.toString()
        : {
            ...obj,
            toString: obj.toString,
            message: `${
              'message' in obj ? `${(obj as any).message} ` : ''
            } ERROR ${error.toString()}`,
          }
    ) as RedactReturnType<T, R>;
  }
};

/**
 * Given a redact type, returns the relevant regex.
 * @param redactType
 * @returns
 */
const redactTypeToRegex = (redactType: RedactValueTypes): RegExp => {
  switch (redactType) {
    case RedactValueTypes.SSN:
      // [word boundary][date first digit][date second digit][month first digit][month second digit, year and 4-digit trail]
      return ssnRegex;
    case RedactValueTypes.SSN_WITH_DASH:
      return ssnWithDashRegex;
  }
};

const ssnRegex = /\b[0-3]\d[0-1]\d{7}\b/g;
const ssnWithDashRegex = /\b[0-3]\d[0-1]\d{3}-\d{4}\b/g;

// For the regex to contain '\\', it must be escaped twice
const quoteEscape = '\\\\';
/**
 * Get regex for finding json key and text, with quotes escaped.
 * @param jsonKey
 * @returns
 */
const jsonKeyTextRegexQuoteEscape = (jsonKey: string) =>
  new RegExp(
    `${quoteEscape}"${jsonKey}${quoteEscape}":\\s*${quoteEscape}"[^"]+${quoteEscape}"`,
    'gi'
  );
/**
 * Get regex for finding json key and text, without quotes escaped.
 * @param jsonKey
 * @returns
 */
const jsonKeyTextRegex = (jsonKey: string) =>
  new RegExp(`"${jsonKey}":\\s*"[^"]+"`, 'gi');
