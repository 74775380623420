import {
  Colors,
  DESKTOP_HEADER_HEIGHT,
  GENERAL_SPACING,
} from '@dermloop/ui/util';
import styled from 'styled-components';
import Spacer from '../spacer/spacer';

/* eslint-disable-next-line */
export interface SlottedPanesProps {
  mainContent: JSX.Element;
  titleContent: JSX.Element;
  leftSideContent: JSX.Element;
  rightSideContent?: JSX.Element;
  isSideBarHidden?: boolean;
}

const SideContent = styled.div<{ isSideBarHidden: boolean }>`
  top: ${(props) =>
    props.isSideBarHidden
      ? `${DESKTOP_HEADER_HEIGHT + GENERAL_SPACING}px`
      : 'unset'};
  pointer-events: none;
  position: ${(props) => (props.isSideBarHidden ? 'sticky' : 'relative')};
  padding-left: ${(props) => (props.isSideBarHidden ? '0' : '25vw')};
  width: ${(props) => (props.isSideBarHidden ? '25vw' : '50vw')};
  height: ${(props) => (props.isSideBarHidden ? '80vh' : 'unset')};
  z-index: 10;
`;

const LeftSideContent = styled.div`
  display: flex;
  position: relative;
  width: 25vw;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  z-index: 10;
  top: 80px;
`;

const TitleContent = styled.div`
  margin-left: 25vw;
  padding: 3rem 3rem 0 3rem;
`;

const MainContent = styled.div`
  display: flex;
  flex-grow: 1;
  padding-left: 25vw;
  width: 50vw;
  max-width: 1400px;
  position: relative;
  flex-direction: column;
  background-color: ${Colors.BACKGROUND_WHITE};
`;

const StyledMainContent = styled.div<{ isSideBarHidden: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-flow: ${(props) => (props.isSideBarHidden ? 'row' : 'column')};
`;

const StyledSlottedPanes = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const MainFlow = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export function SlottedPanes(props: SlottedPanesProps) {
  return (
    <StyledSlottedPanes>
      <TitleContent>{props.titleContent}</TitleContent>
      <MainFlow>
        <LeftSideContent>{props.leftSideContent}</LeftSideContent>
        <StyledMainContent isSideBarHidden={props.isSideBarHidden}>
          <MainContent>{props.mainContent}</MainContent>
          <SideContent isSideBarHidden={props.isSideBarHidden}>
            {props.rightSideContent}
          </SideContent>
          {!props.isSideBarHidden ? <Spacer /> : null}
        </StyledMainContent>
      </MainFlow>
    </StyledSlottedPanes>
  );
}

export default SlottedPanes;
