/**
 * Function for determining if a string is JSON.
 * @param str
 * @returns
 */
export function stringIsJson(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Function for parsing a string to json. If the string is not JSON, it returns the defaultValue argument.
 * @param str
 * @param defaultValue
 * @returns
 */
export function parseJsonElse(str: string, defaultValue: any): any {
  try {
    return JSON.parse(str);
  } catch (error) {
    return defaultValue;
  }
}

/**
 * Tries to stringify an object. If an error is thrown, the default value is returned.
 * @param obj
 * @param defaultValue
 * @returns
 */
export function stringifyElse(obj: any, defaultValue: any): any {
  try {
    JSON.stringify(obj);
  } catch (error) {
    return defaultValue;
  }
}
