import { DermloopUserRoleEnum } from '@dermloop/static-values';
import { Exclude, Transform } from 'class-transformer';
import {
  IsDate,
  IsEmail,
  IsEnum,
  IsOptional,
  IsUUID,
  MinDate,
} from 'class-validator';
import { BaseCreateDto, BaseDto, BaseUpdateDto } from './base.dto';
import { MedicalUnitDto } from './medical-unit.dtos';
import { OccupationDto } from './occupation.dtos';
import { SpecialityDto } from './speciality.dtos';
import { UserRoleDto } from './user-role.dtos';
import { UserDto } from './user.dto';

export interface MedicalUnitUserAffiliationDto extends BaseDto {
  invitationAccepted: boolean;

  invitationExpiryDate: Date;

  invitationRole: DermloopUserRoleEnum;

  email?: string;

  user?: UserDto;

  userId?: string;

  medicalUnit: MedicalUnitDto;

  medicalUnitId: string;

  expiry: Date;

  occupation?: OccupationDto;

  occupationId?: string;

  speciality?: SpecialityDto;

  specialityId?: string;

  userRoles?: UserRoleDto[];
}

export class CreateMedicalUnitUserAffiliationDto
  extends BaseCreateDto
  implements Partial<MedicalUnitUserAffiliationDto>
{
  @IsEmail()
  @Transform(({ value }) => value?.toLowerCase())
  email: string;

  @IsUUID()
  occupationId: string;

  @IsUUID()
  specialityId: string;

  @IsEnum(DermloopUserRoleEnum)
  invitationRole: DermloopUserRoleEnum;

  @IsDate()
  @MinDate(() => new Date())
  expiry: Date;

  @Exclude()
  invitationAccepted?: never;

  @Exclude()
  invitationExpiryDate?: never;

  @Exclude()
  user?: never;

  @Exclude()
  userId?: never;

  @Exclude()
  medicalUnit?: never;

  @Exclude()
  medicalUnitId?: never;

  @Exclude()
  occupation?: never;

  @Exclude()
  speciality?: never;

  @Exclude()
  userRoles?: never;
}

export class UpdateMedicalUnitUserAffiliationDto
  extends BaseUpdateDto
  implements Partial<MedicalUnitUserAffiliationDto>
{
  @IsOptional()
  @IsUUID()
  occupationId?: string;

  @IsOptional()
  @IsUUID()
  specialityId?: string;

  @IsOptional()
  @IsEnum(DermloopUserRoleEnum)
  invitationRole?: DermloopUserRoleEnum;

  @IsOptional()
  @IsDate()
  @MinDate(() => new Date())
  expiry?: Date;

  @Exclude()
  invitationAccepted?: never;

  @Exclude()
  invitationExpiryDate?: never;

  @Exclude()
  email?: never;

  @Exclude()
  user?: never;

  @Exclude()
  userId?: never;

  @Exclude()
  medicalUnit?: never;

  @Exclude()
  medicalUnitId?: never;

  @Exclude()
  occupation?: never;

  @Exclude()
  speciality?: never;
}

export interface DeleteMedicalUnitUserAffiliationResultDto {
  success: boolean;
}

export class SearchMedicalUnitUserAffiliationDto {
  @IsOptional()
  @IsEnum(DermloopUserRoleEnum, { each: true })
  roles?: DermloopUserRoleEnum[];
}
