import { Colors, IconType, Sizes } from '@dermloop/ui/util';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import ButtonIcon from '../button-icon/button-icon';
import Icon from '../icon/icon';
import { webSpecificValue } from '../utils';

/* eslint-disable-next-line */
export interface FormControlHeaderProps {
  title: string;
  mandatory?: boolean;
  subtitle?: string;
  icon?: IconType;
  rightSideContent?: JSX.Element;
  onButtonPress?: () => void;
  onPress?: () => void;
}

const StyledFormControlHeader = styled.View`
  display: flex;
  flex-direction: row;
  padding: ${Sizes.GENERAL_SPACING}px;
  align-items: center;
  justify-content: space-between;
  ${webSpecificValue(`z-index: unset;`)};
`;

const StyledFormControlTitleView = styled.Pressable`
  display: flex;
  flex-direction: row;
  flex: 1;
  ${webSpecificValue(`cursor: auto;`)};
  ${webSpecificValue(`z-index: unset;`)};
`;

const StyledFormControlTitle = styled.Text`
  font-weight: 500;
  font-size: 15px;
  ${webSpecificValue(`z-index: unset;`)};
`;

const StyledFormControlSubtitle = styled.Text`
  font-weight: 300;
  font-size: 15px;
  ${webSpecificValue(`z-index: unset;`)};
`;

const StyledFormControlMandatory = styled(StyledFormControlSubtitle)`
  color: ${Colors.BRAND_MANDATORY};
`;

const ButtonIconContainer = styled.View`
  ${webSpecificValue(`padding-left: 0.5rem;`)}
  ${webSpecificValue(`min-width: 2.3rem;`)}
  ${webSpecificValue(`flex-shrink: 0;`)}
  ${Platform.OS !== 'web' && `width: 30px;`}
  ${Platform.OS !== 'web' && `height: 30px;`}
`;

const StyledIcon = styled.Pressable`
  padding-right: 0px;
  right: ${0}px;
`;

/**
 *
 * @param props
 */
export function FormControlHeader(props: FormControlHeaderProps) {
  return (
    <StyledFormControlHeader>
      <StyledFormControlTitleView
        onPress={() =>
          Platform.OS !== 'web' && props.onPress && props.onPress()
        }
      >
        <StyledFormControlTitle>{props.title}</StyledFormControlTitle>
        {props.mandatory ? (
          <StyledFormControlMandatory> ⦁ Mandatory</StyledFormControlMandatory>
        ) : props.subtitle ? (
          <StyledFormControlSubtitle>
            {' '}
            ⦁ {props.subtitle}
          </StyledFormControlSubtitle>
        ) : null}
      </StyledFormControlTitleView>
      {props.onButtonPress ? (
        <ButtonIconContainer>
          <ButtonIcon
            onPress={props.onButtonPress}
            size="natural"
            noPadding
            noMargin
          >
            {props.icon}
          </ButtonIcon>
        </ButtonIconContainer>
      ) : props.icon ? (
        <StyledIcon onPress={props.onPress} hitSlop={8}>
          <Icon icon={props.icon} />
        </StyledIcon>
      ) : null}
      {props.rightSideContent ? props.rightSideContent : null}
    </StyledFormControlHeader>
  );
}

export default FormControlHeader;
