import { IsNumber, IsOptional, Min } from 'class-validator';

export class DiagnosisGroupDistributionDto {
  id: string;
  distribution: number;
  trainingConfigurationId: string;
  diagnosisGroupId: string;
  diagnosisGroupName?: string;
}

export class UpdateDiagnosisGroupDistributionDto {
  @IsOptional()
  @IsNumber()
  @Min(0)
  distribution?: number;
}
