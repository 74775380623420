import { Sizes } from '@dermloop/ui/util';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { ItemType } from 'react-native-dropdown-picker';
import styled from 'styled-components/native';
import countries from 'world-countries';
import { BaseInputProps } from '../base-input-props';
import SelectInput from '../select-input/select-input';
import TextField from '../text-field/text-field';

/* eslint-disable-next-line */
export interface PhonenumberInputProps extends BaseInputProps {
  /**
   * phone prefix including +, e.g. +45
   */
  prefix: string;
  /**
   * Phonenumber excluding prefix
   */
  phonenumber: string;
  disabled?: boolean;
  onFocus?: (e?: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onBlur?: (e?: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onValueChanged: (prefix: string, phoneNumber: string) => void;
}
const selectItems: ItemType<string>[] = countries
  .filter((c) => ['DK', 'SE', 'US', 'TR', 'NO', 'AU', 'GR'].includes(c.cca2))
  .map((c) => {
    const code = `${c.idd.root}${
      c.idd.suffixes.length === 1 ? c.idd.suffixes[0] : ''
    }`;
    return {
      value: code,
      label: `${c.flag} ${code}`,
    };
  });

export function PhonenumberInput(props: PhonenumberInputProps) {
  return (
    <StyledPhoneNumberInputContainer>
      <StyledDropDownContainer>
        <SelectInput
          value={props.prefix}
          options={selectItems}
          onValueChanged={(v) => props.onValueChanged(v, props.phonenumber)}
        />
      </StyledDropDownContainer>

      <TextField
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        validationMessage={props.validationMessage}
        validationState={props.validationState}
        label={props.label}
        value={props.phonenumber}
        onValueChange={(v) => props.onValueChanged(props.prefix, v)}
      />
    </StyledPhoneNumberInputContainer>
  );
}

export default PhonenumberInput;

const StyledPhoneNumberInputContainer = styled.View`
  flex-direction: row;
`;

const StyledDropDownContainer = styled.View`
  width: 110px;
  margin-right: ${Sizes.GENERAL_SPACING / 2}px;
`;
