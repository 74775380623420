import { Type } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { SearchIndexMetadataDto } from './index-metadata.dto';

/**
 * DTO for indexing a training assessment.
 */
export class TrainingAssessmentIndexDto {
  @Type(() => TrainingAssessmentIndexDataDto)
  @ValidateNested({ each: true })
  @ArrayNotEmpty()
  assessments: TrainingAssessmentIndexDataDto[];

  @Type(() => SearchIndexMetadataDto)
  @IsOptional()
  @ValidateNested()
  metadata?: SearchIndexMetadataDto;
}

export class TrainingAssessmentIndexDataDto {
  // Training assessment id
  @IsUUID()
  id: string;

  @IsUUID()
  userId: string;

  @IsDate()
  assessedDate: Date;

  @IsUUID()
  trainingSessionId: string;

  @IsNumber()
  @IsPositive()
  timeSpentMs: number;

  @IsBoolean()
  correct: boolean;

  @IsUUID()
  lesionId: string;

  @IsUUID()
  initialDiagnosisId: string; // training-lesion.initialDiagnosis.diagnosisgrouping.diagnosisgroup.id

  @IsString()
  initialDiagnosisName: string; // training-lesion.initialDiagnosis.diagnosisgrouping.diagnosisgroup.name

  @IsOptional()
  @IsUUID()
  revisedDiagnosisId?: string; // training-lesion.revisedDiagnosis.diagnosisgrouping.diagnosisgroup.id

  @ValidateIf((o) => o.revisedDiagnosisId?.length > 0)
  @IsNotEmpty()
  @IsString()
  revisedDiagnosisName?: string; // training-lesion.revisedDiagnosis.diagnosisgrouping.diagnosisgroup.name

  @IsUUID()
  answeredId: string; // diagnosisgroup.id

  @IsNotEmpty()
  @IsString()
  answered: string; // diagnosisgroup.name

  // The correct diagnosis group name that the user should have answered
  @IsOptional()
  @IsUUID()
  correctDiagnosisGroupId: string;

  @IsOptional()
  @IsString()
  correctDiagnosisGroupName: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  lesionComplexity?: number;

  @IsOptional()
  @IsNumber()
  patientAgeYears?: number;

  @IsUUID()
  lesionPatientGenderId: string;
}
