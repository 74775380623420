import { DermloopUserRoleEnum } from '@dermloop/static-values';
import { Type } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsBoolean,
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsNumberString,
  IsOptional,
  IsString,
  Length,
  MaxDate,
  ValidateNested,
} from 'class-validator';

export class SeedDto {
  // User roles for CI user
  @ArrayNotEmpty()
  @IsEnum(DermloopUserRoleEnum, { each: true })
  ciUserRoles: DermloopUserRoleEnum[];

  // Optional patients to create
  @IsOptional()
  @Type(() => SeedPatientDto)
  @ValidateNested({ each: true })
  patients?: SeedPatientDto[];
}

export enum SeedGenderEnum {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export class SeedPatientDto {
  // CPR of patient. If none provided, generates random cpr
  @IsOptional()
  @IsNumberString()
  @Length(10, 10)
  ssn?: string;

  // If no gender provided, uses random gender
  @IsOptional()
  @IsEnum(SeedGenderEnum)
  gender?: SeedGenderEnum;

  // Lesions to create for patient
  @Type(() => SeedLesionDto)
  @ValidateNested({ each: true })
  lesions: SeedLesionDto[];

  // Optional health record information. Values not provided are generated at random
  @IsOptional()
  @IsBoolean()
  familyHistoryWithMelanoma?: boolean;

  @IsOptional()
  @IsBoolean()
  formerSkinCancer?: boolean;

  @IsOptional()
  @Type(() => SeedRiskFactorDto)
  @ValidateNested({ each: true })
  riskFactors?: SeedRiskFactorDto[];
}

export class SeedLesionDto {
  // Date of lesion. If not provided, uses random past date
  @IsOptional()
  @IsDate()
  // Dates cannot be in the future
  @MaxDate(new Date())
  date?: Date;

  @Type(() => SeedLesionTriageDto)
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  triages: SeedLesionTriageDto[];
}

export class SeedRiskFactorDto {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsNotEmpty()
  name_da: string;
}

export enum SeedLesionActionEnum {
  REFERRAL = 'Referral',
  FOLLOW_UP = 'FollowUp',
  DISMISSAL = 'Dismissal',
  BIOPSY = 'Biopsy',
  NEW_IMAGE_REGISTRATION = 'NewImageRegistration',
  NO_ACTION = 'NoAction',
}

export class SeedLesionTriageDto {
  // Date of lesion triage. If not provided, uses random past date
  @IsOptional()
  @IsDate()
  // Dates cannot be in the future
  @MaxDate(new Date())
  date?: Date;

  @IsOptional()
  @IsEnum(SeedLesionActionEnum)
  action?: SeedLesionActionEnum;

  // Creates diagnosis with name and sets for corresponding action
  // If no diagnosis name is provided, action will not have a diagnosis
  @IsOptional()
  @IsString()
  @IsNotEmpty()
  diagnosisName?: string;

  // Creates diagnosis with name and sets for corresponding action
  // If no diagnosis name is provided, action will not have a diagnosis
  @IsOptional()
  @IsString()
  @IsNotEmpty()
  secondaryDiagnosisName?: string;

  // Creates diagnosis with name and sets for corresponding action
  // If no diagnosis name is provided, action will not have a diagnosis
  @IsOptional()
  @IsString()
  @IsNotEmpty()
  tertiaryDiagnosisName?: string;
}
