import { DermloopUserRoleEnum } from '@dermloop/static-values';
import {
  IsBoolean,
  IsDate,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Matches,
  Min,
  ValidateIf,
} from 'class-validator';
import { BaseDto } from './base.dto';
import { GenderDto } from './clinical-details';
import { MedicalUnitUserAffiliationDto } from './medical-unit-user-affiliation.dtos';
import { OccupationDto } from './occupation.dtos';
import { PushDeviceDto } from './push-device.dto';
import { StudyParticipationDto } from './studies/study-participation.dto';
import { UserRoleDto } from './user-role.dtos';

export enum DermloopUserMustResetPasswordEnum {
  UserMustReset = 'UserMustReset',
  AdminMustReset = 'AdminMustReset',
}

export interface UserDto extends BaseDto {
  auth0Sub?: string;

  kratosId?: string;

  legacyIdentifier?: string;

  // Has the user in the current session been MFA authenticated?
  mfaActive?: boolean;

  firstName?: string;

  lastName?: string;

  birthDate?: Date;

  email?: string;

  emailVerified?: boolean;

  phoneNumberPrefix?: string;

  phoneNumber?: string;

  profileImageIdentifier?: string;

  occupation?: OccupationDto;
  dermoscopyExperienceMonths?: number;
  studies?: StudyParticipationDto[];

  pushDevices?: PushDeviceDto[];
  skinLesionDiagnosticExperienceMonths?: number;

  gender?: GenderDto;

  userRoles?: UserRoleDto[];

  consentGiven?: boolean;

  consentGivenDate?: Date;

  mustReceiveTeleReferralMail?: boolean;

  dahtDetails?: unknown;

  deleteRequestedDate?: Date;

  migratedToKratos?: boolean;
  mustResetPassword?: DermloopUserMustResetPasswordEnum;
}

/**
 * DTO for adding a UserRole to a user.
 */
export class AddUserRoleDto {
  @IsNotEmpty()
  @IsUUID()
  userId: string;

  @IsEnum(DermloopUserRoleEnum)
  role: DermloopUserRoleEnum;

  @IsOptional()
  @IsUUID()
  medicalUnitId?: string;
}

/**
 * DTO for querying users.
 */
export class UserSearchQueryDto {
  @IsNumber()
  @Min(0)
  skip: number;

  @IsNumber()
  @Min(1)
  take: number;

  @IsOptional()
  @IsString()
  searchText?: string;
}

export class CreateUserDto {
  @IsOptional()
  @IsString()
  firstName?: string;

  @IsOptional()
  @IsString()
  lastName?: string;

  @IsOptional()
  @IsDate()
  birthDate?: Date;

  @IsEmail()
  email: string;

  @IsString()
  phoneNumberPrefix?: string;

  @IsString()
  phoneNumber?: string;

  @IsOptional()
  @Min(0)
  dermoscopyExperienceMonths?: number;

  @IsOptional()
  @Min(0)
  skinLesionDiagnosticExperienceMonths?: number;

  @IsOptional()
  @IsUUID()
  genderId?: string;

  @IsOptional()
  @IsBoolean()
  consentGiven?: boolean;

  @IsOptional()
  @IsBoolean()
  mustReceiveTeleReferralMail?: boolean;
}

export class UpdateUserDto {
  @IsOptional()
  @IsString()
  firstName?: string;

  @IsOptional()
  @IsString()
  lastName?: string;

  @IsOptional()
  @IsDate()
  birthDate?: Date;

  @IsOptional()
  @IsEmail()
  email?: string;

  @ValidateIf((dto) => !!dto.phoneNumber)
  @Matches('^\\+\\d+$')
  @IsString()
  phoneNumberPrefix?: string;

  @ValidateIf((dto) => !!dto.phoneNumberPrefix)
  @IsString()
  phoneNumber?: string;

  @IsOptional()
  @Min(0)
  dermoscopyExperienceMonths?: number;

  @IsOptional()
  @Min(0)
  skinLesionDiagnosticExperienceMonths?: number;

  @IsOptional()
  @IsUUID()
  genderId?: string;

  @IsOptional()
  @IsBoolean()
  consentGiven?: boolean;

  @IsOptional()
  @IsBoolean()
  mustReceiveTeleReferralMail?: boolean;
}
