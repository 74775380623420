import { GENERAL_SPACING } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import Button, { ButtonPropType } from '../button/button';

export interface PanelNavigationButtonProps {
  // Optional entityId. Must be set if entityIdList is set
  entityId?: string;
  // Optional entity id list, used for finding current indexing and navigating to specific index
  entityIdList?: string[];
  // Optional current index. Can be set if entityIdList is not set
  index?: number;
  // Optional max index. Can be set if entityIdList is not set
  maxIndex?: number;
  // Disable save button
  disabled: boolean;
  onSave: () => void;
  navigateTo: (index: number) => void;
  title?: string;
  // Main button type. If none provided, uses 'solid'
  mainButtonType?: ButtonPropType;
  // Specify if the main button should show a loading animation
  loading?: boolean;
}

const StyledPanelNavigationButton = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: ${GENERAL_SPACING}px;
`;

export function PanelNavigationButton(props: PanelNavigationButtonProps) {
  // If entityIdList provided, use this for index. Else use props.index
  const index = props.entityIdList?.length
    ? props.entityIdList.indexOf(props.entityId)
    : props.index;
  // If entityIdList provided, use this for maxIndex. Else use props.maxIndex
  const maxIndex = props.entityIdList?.length
    ? props.entityIdList.length - 1
    : props.maxIndex;

  return (
    <StyledPanelNavigationButton>
      <Button
        onPress={() => props.navigateTo(Math.max(0, index - 1))}
        type="outline"
        display="inline-narrow"
        disabled={index <= 0}
      >
        {'<'}
      </Button>
      <Button
        onPress={() => props.onSave()}
        disabled={props.disabled}
        type={props.mainButtonType || 'solid'}
        color="primary"
        loading={props.loading}
      >
        {props.title || 'Save and continue'}
      </Button>
      <Button
        onPress={() => props.navigateTo(Math.min(index + 1, maxIndex))}
        type="outline"
        display="inline-narrow"
        disabled={index >= maxIndex}
      >
        {'>'}
      </Button>
    </StyledPanelNavigationButton>
  );
}

export default PanelNavigationButton;
