import { AppearanceChangeDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const appearanceChangeEndpoints = (builder: EndpointBuilderType) => ({
  getAppearanceChanges: builder.query<AppearanceChangeDto[], void>({
    query: () => 'appearance-changes',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'APPEARANCE_CHANGES', id: 'LIST' }],
  }),
});
