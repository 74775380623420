import { ApiNameType, dermloopBaseApi } from '@dermloop/redux';
import { Logger } from '@dermloop/shared';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { environment } from '../../environments/environment';

/**
 * Interface for select API query result parameters.
 */
export interface ApiQueryResult<T> {
  data: T;
  error: FetchBaseQueryError;
  isLoading: boolean;
}

Logger.setLevel('debug');
Logger.info({ message: 'Starting application 🚀', extras: { environment } });
export const reducerPath: ApiNameType = 'dermloopApi';
export const dermloopApi = dermloopBaseApi;
