// Default content type used for images
export const DefaultImageContentType = 'image/jpeg';

/**
 * Image thumbnail sizes
 */
export enum ImageThumbnailSize {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  ORIG = 'original',
}

/**
 * Image anonymization types.
 */
export enum ImageAnonymizationType {
  // Upscale -> Downscale -> Flop
  UDF = 'udf',

  // Normalize -> Sharpen
  NS = 'ns',
}

// Thumbnail sizes. They account for both width and height, specifying max values.
// Ex: image with width x height of 2000 x 1000 would get the size 'm' of 300 x 150
// image with width x height of 1000 x 2000 would get the size 'm' of 150 x 300
export const ThumbnailSize: { [key in ImageThumbnailSize]: number | null } = {
  [ImageThumbnailSize.XS]: 50,
  [ImageThumbnailSize.S]: 100,
  [ImageThumbnailSize.M]: 300,
  [ImageThumbnailSize.L]: 600,
  [ImageThumbnailSize.XL]: 1920,
  // Size not known for original images
  [ImageThumbnailSize.ORIG]: null,
};

/**
 * Suffixes used for identifiers of different versions of an image.
 */
export const ImageThumbnailIdentifierSuffixes: {
  [key in ImageThumbnailSize]: string;
} = {
  [ImageThumbnailSize.XS]: ThumbnailSize[ImageThumbnailSize.XS].toString(),
  [ImageThumbnailSize.S]: ThumbnailSize[ImageThumbnailSize.S].toString(),
  [ImageThumbnailSize.M]: ThumbnailSize[ImageThumbnailSize.M].toString(),
  [ImageThumbnailSize.L]: ThumbnailSize[ImageThumbnailSize.L].toString(),
  [ImageThumbnailSize.XL]: ThumbnailSize[ImageThumbnailSize.XL].toString(),
  [ImageThumbnailSize.ORIG]: 'orig', // Used for training lesion images
};

/**
 * Igen an image id and a size, returns the correct thumbnail identifier
 * @param imageId
 * @param size
 * @returns
 */
export const getImageThumbnailIdentifier = (
  imageId: string,
  size: ImageThumbnailSize
) => {
  return `${imageId}_${ImageThumbnailIdentifierSuffixes[size]}`;
};
