import { ReactNode } from 'react';

import styled from 'styled-components';

/* eslint-disable-next-line */
export interface PageProps {
  children?: ReactNode;
}

const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
`;

export function Page(props: PageProps) {
  return <StyledPage>{props.children}</StyledPage>;
}

export default Page;
