import { IsArray, IsDate, IsNotEmpty, IsUUID } from 'class-validator';

export class UserCommonMistakesQueryDto {
  @IsNotEmpty()
  @IsUUID()
  userId: string;

  @IsNotEmpty()
  @IsArray()
  @IsUUID(4, { each: true })
  diagnosisIds: string[];
}

/**
 * DTO used for getting user statistics.
 */
export class SearchGetUserStatisticsDto {
  @IsNotEmpty()
  @IsUUID()
  userId: string;

  // Date to fetch statistics for
  @IsDate()
  statisticsDate: Date;
}
