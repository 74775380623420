import {
  InterBold,
  InterMedium,
  InterRegular,
  InterSemiBold,
} from '@dermloop/ui/util';
import { createGlobalStyle } from 'styled-components';

export const CustomFonts = createGlobalStyle`
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      src: url(${InterRegular});
    }

    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      src: url(${InterMedium});
    }

    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      src: url(${InterSemiBold});
    }

    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      src: url(${InterBold});
    }
`;
