import { Colors, GENERAL_SPACING, IconType } from '@dermloop/ui/util';
import { Fragment, ReactNode } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import Badge, { BadgeProps } from '../badge/badge';
import { Button } from '../button/button';
import { Icon } from '../icon/icon';
import { Image } from '../image/image';
import { Tag, TagProps } from '../tag/tag';
import { Typography } from '../typography/typography';
import { CompositeTitle, CompositeTypography } from './composite-typography';

export interface ListItemThumbnail {
  imageUrl?: string;
  element?: JSX.Element;
}

/* eslint-disable-next-line */
export interface ListItemProps {
  thumbnails?: [ListItemThumbnail, ListItemThumbnail?];
  onPress?: () => void;
  label?: string;
  heading?: string | CompositeTitle;
  subheading?: string | CompositeTitle;
  description?: string | CompositeTitle;
  extraDescription?: string | CompositeTitle;
  /**
   * Custom react element. Will be shown just below the extra description if provided.
   */
  extraContent?: ReactNode;
  tag?: TagProps;
  badge?: BadgeProps;
  icon?: IconType;
  showIcon?: boolean;
  borderTop?: boolean;
  noBorderBottom?: boolean;
  noShadow?: boolean;
  actionButton?: { title: string; onPress: () => void };
}

const StyledListItem = styled.Pressable<ListItemProps>`
  border-bottom-color: ${Colors.BRAND_TERTIARY};
  ${(props) => (!props.noBorderBottom ? `border-bottom-width:1px;` : '')}
  ${(props) =>
    props.borderTop ? `border-top-color:${Colors.BRAND_TERTIARY};` : ''}
  ${(props) => (props.borderTop ? `border-top-width:1px;` : '')}
  background: ${Colors.BRAND_WHITE};
  padding: ${GENERAL_SPACING}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  shadow-color: ${Colors.BRAND_BLACK};
  shadow-offset: 0 5px;
  shadow-radius: 5px;
  shadow-opacity: ${({ noShadow }) => (noShadow ? 0 : 0.1)};
  position: relative;
`;

const StyledListItemMainContent = styled.View`
  flex-direction: row;
  position: relative;
  padding-right: ${GENERAL_SPACING}px;
  flex: 1;
  min-height: 50px;
`;

const StyledThumbnails = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledIcon = styled.View`
  width: 12px;
  height: 12px;
`;

const StyledTagContainer = styled.View`
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

const ActionButtonContainer = styled.View`
  width: 30%;
`;

export function ListItem(props: ListItemProps) {
  return (
    <StyledListItem
      {...props}
      onPress={() => {
        if (props.onPress) props.onPress();
      }}
    >
      {props.badge ? <Badge {...props.badge} /> : null}

      <StyledListItemMainContent>
        {props.thumbnails?.length ? (
          <StyledThumbnails>
            {props.thumbnails.map((t) => (
              <Fragment key={t.element?.key || t.imageUrl}>
                <View style={{ width: 75, height: 75 }}>
                  {/* <View> */}
                  {t.element ? (
                    t.element
                  ) : (
                    <Image srcs={[t.imageUrl]} aspectRatio={1} />
                  )}
                </View>
                <View style={{ width: GENERAL_SPACING }} />
              </Fragment>
            ))}
          </StyledThumbnails>
        ) : null}

        <View style={{ flex: 1, justifyContent: 'center' }}>
          {props.label ? (
            <Typography size="small" color="medium">
              {props.label}
            </Typography>
          ) : null}
          <CompositeTypography
            typography={props.heading}
            titleStyles={{ weight: 'semi-bold', size: 'medium-small' }}
            subtitlesStyles={{ size: 'medium-small' }}
            baseStyles={{ size: 'normal' }}
          />
          <CompositeTypography
            typography={props.subheading}
            titleStyles={{ size: 'medium-small' }}
            subtitlesStyles={{ size: 'medium-small' }}
            baseStyles={{ size: 'normal' }}
          />

          <CompositeTypography
            typography={props.description}
            titleStyles={{ weight: 'semi-bold', size: 'small' }}
            subtitlesStyles={{ size: 'small' }}
            baseStyles={{ size: 'small', weight: 'regular' }}
          />
          <CompositeTypography
            typography={props.extraDescription}
            titleStyles={{ weight: 'regular', size: 'small' }}
            subtitlesStyles={{ size: 'small' }}
            baseStyles={{
              size: 'small',
              weight: 'regular',
              color: 'medium',
            }}
          />
          {props.extraContent ? props.extraContent : null}
        </View>
        {props.tag ? (
          <StyledTagContainer>
            <Tag {...props.tag} />
          </StyledTagContainer>
        ) : null}
      </StyledListItemMainContent>
      {props.actionButton ? (
        <ActionButtonContainer>
          <Button onPress={props.actionButton.onPress} display="block">
            {props.actionButton.title}
          </Button>
        </ActionButtonContainer>
      ) : props.showIcon ? (
        <StyledIcon>
          <Icon icon={props.icon || 'ArrowRight'} size="parent" />
        </StyledIcon>
      ) : null}
    </StyledListItem>
  );
}

export default ListItem;
