import { DermloopReportTypeSubjectEnum } from '@dermloop/static-values';
import { Exclude } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { BaseCreateDto, BaseDto, BaseUpdateDto } from '../base.dto';
import { ReportDto } from './report.dtos';

export interface ReportTypeDto extends BaseDto {
  name: string;
  adminNote?: string;
  subject: DermloopReportTypeSubjectEnum;
  reports?: ReportDto[];
}

export class CreateReportTypeDto
  extends BaseCreateDto
  implements Partial<ReportTypeDto>
{
  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  adminNote?: string;

  @IsEnum(DermloopReportTypeSubjectEnum)
  subject: DermloopReportTypeSubjectEnum;

  @Exclude()
  reports?: never[];
}

export class UpdateReportTypeDto
  extends BaseUpdateDto
  implements Partial<ReportTypeDto>
{
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  adminNote?: string;

  @Exclude()
  subject?: never;

  @Exclude()
  reports?: never[];
}
