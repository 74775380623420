import { Exclude } from 'class-transformer';
import { UserDto } from './user.dto';

export class BaseDto {
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;

  created?: string | Date;
  updated?: string | Date;
  deleted?: string | Date;
}

class BaseMutationDto implements Partial<BaseDto> {
  @Exclude()
  id?: never;

  @Exclude()
  createdByUser?: never;

  @Exclude()
  updatedByUser?: never;

  @Exclude()
  deletedByUser?: never;

  @Exclude()
  created?: never;

  @Exclude()
  updated?: never;

  @Exclude()
  deleted?: never;
}

export class BaseCreateDto extends BaseMutationDto {}

export class BaseUpdateDto extends BaseMutationDto {}
