import { Colors, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components';

export interface HeaderProps {
  title?: string;
  context?: JSX.Element;
  content?: JSX.Element;
}

const StyledHeader = styled.header`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  background: ${Colors.BRAND_BROWN_DARK};
  height: ${Sizes.DESKTOP_HEADER_HEIGHT}px;
  color: ${Colors.BRAND_WHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  z-index: 1000;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
`;

const HeaderContent = styled.div`
  margin: auto;
  display: contents;
`;

const HeaderContext = styled.div``;

export function Header(props: HeaderProps) {
  return (
    <StyledHeader>
      {props.title ? <Title>{props.title}</Title> : null}
      {props.context ? <HeaderContext>{props.context}</HeaderContext> : null}
      <HeaderContent>{props.content}</HeaderContent>
    </StyledHeader>
  );
}

export default Header;
