import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
  ValidateNested,
} from 'class-validator';
import { LessonDto } from './lesson.dtos';

export interface LevelDto {
  id: string;
  courseId?: string;
  title: string;
  description?: string;
  duration?: string;
  published: boolean;
  levelNumber: number;
  lessons?: LessonDto[];
  userLevelId?: string;
  started?: boolean;
  completed?: boolean;
}

export class StartLevelDto {
  @IsNotEmpty()
  @IsUUID()
  levelId: string;

  @IsNotEmpty()
  @IsUUID()
  userCourseId: string;
}

export class CreateLevelDto {
  @IsNotEmpty()
  @IsUUID()
  courseId: string;

  @IsNotEmpty()
  @IsString()
  title: string;

  @IsNotEmpty()
  @IsString()
  description: string;

  @IsNotEmpty()
  @IsString()
  duration: string;

  @IsOptional()
  @IsBoolean()
  published?: boolean;
}

export class UpdateLevelDto {
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  title?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  description?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  duration?: string;

  @IsOptional()
  @IsBoolean()
  published?: boolean;
}

/**
 * DTO for changing order of courses.
 */
export class ChangeLevelOrderDto {
  @Type(() => LevelOrderDto)
  @ValidateNested({ each: true })
  levels: LevelOrderDto[];
}

/**
 * DTO for changing the order of a single course.
 */
export class LevelOrderDto {
  @IsNotEmpty()
  @IsUUID()
  levelId: string;

  @IsNotEmpty()
  @IsNumber()
  @Min(1)
  levelNumber: number;
}

/**
 * DTO for changing level publish state.
 */
export class LevelPublishDto {
  @IsNotEmpty()
  @IsUUID()
  levelId: string;

  @IsBoolean()
  published: boolean;

  // If true, all lessons are also updated
  @IsBoolean()
  updateLessons: boolean;
}
