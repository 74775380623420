import {
  BiopsySearchRequestDto,
  BiopsySearchResultItemDto,
  ClinicalManagementActionDto,
  CreateTeledermatologicalAssessmentDto,
  ImageQualityRatingDto,
  ImageQualityRatingReasonDto,
  Page,
  TeledermatologicalAssessmentDto,
  TeledermatologicalReferralSearchDto,
  TeledermatologicalReferralSearchResultItemDto,
} from '@dermloop/api-dtos';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const actionsDefaultPageSize = 20;

export type ClinicalActionsSlice = {
  biopsies?: Page<BiopsySearchResultItemDto>;
  fetchingBiopsies?: boolean;
  biopsySearchRequest?: BiopsySearchRequestDto;
  teledermatologicalReferrals?: Page<TeledermatologicalReferralSearchResultItemDto>;
  fetchingTeledermatologicalReferrals?: boolean;
  teledermatologicalReferralsQuery?: TeledermatologicalReferralSearchDto;
  fetchingTeledermatologicalAssessmentRequests?: boolean;
  teledermatologicalAssessment?: TeledermatologicalAssessmentDto;
  fetchingTeledermatologicalAssessment?: boolean;
  fetchedTeledermatologicalAssessment?: boolean;
  isEditingTeledermatologicalAssessment?: boolean;
  savingTeledermatologicalAssessment?: boolean;
  imageQualityRatings?: ImageQualityRatingDto[];
  imageQualityRatingReasons?: ImageQualityRatingReasonDto[];
  clinicalManagementActions?: ClinicalManagementActionDto[];
};

const initialState: ClinicalActionsSlice = {
  biopsySearchRequest: { take: actionsDefaultPageSize, skip: 0 },
  teledermatologicalReferralsQuery: {
    take: actionsDefaultPageSize,
    skip: 0,
    urgentReferrals: true,
  },
};

const slice = createSlice({
  name: 'clinicalActions',
  initialState,
  reducers: {
    fetchDefaultValues: (state) => {
      return { ...state };
    },
    getTeledermatologicalAssessment: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      return {
        ...state,
        teledermatologicalAssessment: undefined,
        fetchingTeledermatologicalAssessment: true,
        fetchedTeledermatologicalAssessment: false,
      };
    },
    searchBiopsies: (
      state,
      action: PayloadAction<{
        biopsySearchRequestDto: BiopsySearchRequestDto;
        onSuccess?: (results: Page<BiopsySearchResultItemDto>) => void;
        dontPersist?: boolean;
      }>
    ) => {
      return {
        ...state,
        fetchingBiopsies: true,
        biopsySearchRequest: action.payload.dontPersist
          ? state.biopsySearchRequest
          : action.payload.biopsySearchRequestDto,
      };
    },
    searchTeledermatologicalReferrals: (
      state,
      action: PayloadAction<{
        searchTeledermatologicalAssessmentRequestDto: TeledermatologicalReferralSearchDto;
        dontPersist?: boolean;
        onSuccess?: (
          results: Page<TeledermatologicalReferralSearchResultItemDto>
        ) => void;
      }>
    ) => {
      return {
        ...state,
        fetchingTeledermatologicalReferrals: true,
        teledermatologicalReferralsQuery: action.payload.dontPersist
          ? state.teledermatologicalReferralsQuery
          : action.payload.searchTeledermatologicalAssessmentRequestDto,
      };
    },
    update: (
      state,
      action: PayloadAction<{ update: Partial<ClinicalActionsSlice> }>
    ) => {
      return { ...state, ...action.payload?.update };
    },
    updateTeleAssessment: (
      state,
      action: PayloadAction<Partial<TeledermatologicalAssessmentDto>>
    ) => {
      // Should update teleAssessment and only on save create new teleAssessment
      return {
        ...state,
        teledermatologicalAssessment: action.payload
          ? { ...state.teledermatologicalAssessment, ...action.payload }
          : state.teledermatologicalAssessment,
      };
    },
    persistTeleAssessment: (
      state,
      action: PayloadAction<CreateTeledermatologicalAssessmentDto>
    ) => {
      return {
        ...state,
        savingTeledermatologicalAssessment: true,
      };
    },
    error: (
      state,
      action: PayloadAction<{ message: string; error?: Error }>
    ) => {
      return {
        ...state,
        errorMessage: action.payload.message,
        error: action.payload.error,
      };
    },
  },
});

export const clinicalActionsReducer = slice.reducer;
export const clinicalActionsActions = slice.actions;
