/**
 * Websocket connect query keys
 */
export enum WsConnectQueryKey {
  JurisdictionalGroupId = 'jurisdictionalGroupId',
  MedicalUnitId = 'medicalUnitId',
  UserId = 'userId',
}
// export const WsConnectQueryJurisdictionalGroupId = 'jurisdictionalGroupId';
// export const WsConnectQueryMedicalUnitId = 'medicalUnitId';
// export const WsConnectQueryUserId = 'userId';

/**
 * Websocket room names. Functions ensuring correct values are used.
 */
export const WsRoom = {
  // Event when a lesion triage is updated by a user with kratos id. In future, this can also be extended to include medical units
  lesionTriageUser: (kratosId: string) => `user:${kratosId}`,
};

/**
 * Websocket namespaces.
 */
export enum WsNamespace {
  LesionTriage = 'lesion-triage',
}

/**
 * Websocket event names.
 */
export enum WsEvent {
  /**
   * Lesion triage events
   */
  LesionTriageUpdate = 'lesion-triage:updated',
}
