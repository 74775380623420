import { FieldConfigurationDto } from '@dermloop/api-dtos';
import { EndpointBuilderType } from '../constants';

export const fieldConfigurationEndpoints = (builder: EndpointBuilderType) => ({
  getFieldConfiguration: builder.query<FieldConfigurationDto, void>({
    query: () => `field-configurations/current`,
    providesTags: (result, error, arg) => {
      if (result) {
        return [
          { type: 'FIELD_CONFIGURATION', id: result.id },
          { type: 'FIELD_CONFIGURATION', id: 'LIST' },
        ];
      }
      return [{ type: 'FIELD_CONFIGURATION', id: 'LIST' }];
    },
  }),
});
