import { MedicalUnitDto, UserDto } from '@dermloop/api-dtos';
import { DermloopUserRoleEnum } from '@dermloop/static-values';
import { ArrayUtilities } from '@dermloop/utils';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Credentials = {
  username: string;
  password: string;
};
export interface AuthenticationSlice {
  hasTokenInKeyChain?: boolean;
  token?: string;
  absoluteExpiration?: number;
  refreshToken?: string;
  refreshingToken?: boolean;
  loading: boolean;
  updating?: boolean;
  loginWithRedirect?: boolean;
  errorMessage?: string;
  currentUser?: UserDto;
  medicalUnit?: MedicalUnitDto;
  authenticated: boolean;
  confirmed?: boolean;
  userRoles?: DermloopUserRoleEnum[];
  // Used for determining if the user has just requested account deletion
  deleteRequestedDate?: Date;
}

const initialState: AuthenticationSlice = {
  loading: false,
  authenticated: false,
};

export interface StateSlice {
  authentication: AuthenticationSlice;
}

type ErrorPayload = { error: Error; message: string };

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: (state, action: PayloadAction<{ clearCurrentUser: boolean }>) => {
      const stateCopy = { ...state, token: undefined, refreshToken: null };
      if (action.payload.clearCurrentUser) {
        stateCopy.currentUser = undefined;
      }
      return {
        ...stateCopy,
        authenticated: false,
      };
    },
    login: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loading: false,
        hasTokenInKeyChain: true,
        token: action.payload,
      };
    },
    refreshToken: (
      state,
      action: PayloadAction<{
        update: Partial<AuthenticationSlice>;
        success?: () => void;
      }>
    ) => {
      return {
        ...state,
        refreshingToken: true,
      };
    },
    loginWithRedirect: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loginWithRedirect: true,
      };
    },
    fetchCurrentUser: (state, _: Action) => {
      return {
        ...state,
      };
    },
    fetchedCurrentUser: (state, action: PayloadAction<UserDto>) => {
      const medicalUnits = action.payload.userRoles
        ?.filter((ur) => ur.medicalUnit)
        ?.map((mr) => mr.medicalUnit);

      const distinctMedicalUnits = ArrayUtilities.distinct(
        medicalUnits,
        (unit) => unit.id
      );
      return {
        ...state,
        currentUser: action.payload,
        medicalUnit:
          distinctMedicalUnits && distinctMedicalUnits.length === 1
            ? distinctMedicalUnits[0]
            : undefined,
        userRoles: action.payload?.userRoles?.map((r) => r.role),
      };
    },
    updateUser: (
      state,
      action: PayloadAction<{ update: Partial<UserDto> }>
    ) => {
      return {
        ...state,
        errorMessage: null,
        updating: true,
      };
    },
    error: (state, action: PayloadAction<ErrorPayload>) => {
      return {
        ...state,
        errorMessage: action?.payload?.message,
        authenticating: false,
        refreshingToken: false,
        updating: false,
      };
    },
    update: (state, action: PayloadAction<Partial<AuthenticationSlice>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    authenticate: (
      state,
      action: PayloadAction<{
        token: string;
        refreshToken?: string;
        absoluteExpiration?: number;
      }>
    ) => {
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken
          ? action.payload.refreshToken
          : state.refreshToken,
        absoluteExpiration: action.payload.absoluteExpiration,
        errorMessage: '',
        confirmed: true,
        refreshingToken: false,
        authenticated: false,
      };
    },
    authenticated: (state, action) => {
      return { ...state, authenticated: true };
    },
  },
});

export const authenticationActions = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
