import { Option } from '../multi-option-picker/multi-option-picker';
import OptionPicker from '../option-picker/option-picker';
/* eslint-disable-next-line */
export interface TrueFalsePickerProps {
  yesNoNames?: { yes: string; no: string };
  readonly?: boolean;
  selectedValue?: boolean;
  validationState?: 'valid' | 'invalid' | 'none';
  onOptionSelected: (option?: boolean) => void;
}

export function TrueFalsePicker(props: TrueFalsePickerProps) {
  const trueFalseOptions: Option<boolean>[] = [
    { name: props?.yesNoNames?.no || 'No', value: false },
    { name: props?.yesNoNames?.yes || 'Yes', value: true },
  ];
  return (
    <OptionPicker<boolean>
      readonly={props.readonly}
      selectedValue={props.selectedValue}
      options={trueFalseOptions}
      onOptionSelected={(option) => {
        if (!option) {
          props.onOptionSelected(null);
          return;
        }
        props.onOptionSelected(option.value);
      }}
    />
  );
}

export default TrueFalsePicker;
