import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

export interface TrainingLesionImagesMediaUploadUrlDto {
  fileName: string;
  contentType: string;
}

export interface TrainingLesionImagesMediaUploadUrlResultDto {
  url: string;
  fileId: string;
  fileName: string;
}

/**
 * DTO used for uploading an image to the blob storage for a specific
 * file id. This file id corresponds to the name of the blob in object storage.
 */
export class TrainingLesionImagesMediaUploadExistingUrlDto {
  @IsUUID()
  fileId: string;

  @IsString()
  @IsNotEmpty()
  contentType: string;
}

/**
 * Result DTO for the download URL of a blob in object storage.
 */
export interface TrainingLesionImagesMediaUploadExistingUrlResultDto {
  fileId: string;
  url: string;
}

export interface TrainingLesionImagesMediaDeleteBlobResultDto {
  success: boolean;
}
