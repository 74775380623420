import { HotspotType } from '@dermloop/ui/components';
import * as THREE from 'three';

export function getPointVisibility(
  hotspots: HotspotType[],
  model: THREE.Object3D,
  scene: THREE.Scene,
  cameraPosition: THREE.Vector3
) {
  const visibility = hotspots.map((p) => {
    if (model) {
      const hotspot = scene.getObjectByName(p.identifier);
      if (!hotspot) {
        return false;
      }
      const view = new THREE.Vector3().copy(cameraPosition);
      const normalworld = new THREE.Vector3(
        p.point.normal.x,
        p.point.normal.y,
        p.point.normal.z
      ).transformDirection(scene.matrixWorld);

      if (view.dot(normalworld) < 0) return false;
      else return true;
    }
    return true;
  });
  return visibility;
}

export function computeBoundingSphere(object3d: THREE.Object3D) {
  const _box = new THREE.Box3();
  const _vec3 = new THREE.Vector3();
  // var meshCount = 0;
  const points = [];
  const boundingSphere = new THREE.Sphere();
  const center = boundingSphere.center;

  // find the center
  object3d.traverse(function (object) {
    if (object['isMesh']) {
      _box.expandByObject(object);
    }
  });
  _box.getCenter(center);

  // find the radius
  let maxRadiusSq = 0;
  object3d.traverse(function (object: THREE.Mesh) {
    if (object['isMesh']) {
      const position = object.geometry.attributes.position;

      for (let i = 0, l = position.count; i < l; i++) {
        _vec3.fromBufferAttribute(position, i);
        maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(_vec3));
      }
    }
  });

  boundingSphere.radius = Math.sqrt(maxRadiusSq);
  return boundingSphere;
}
