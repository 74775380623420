import {
  IsArray,
  IsBoolean,
  IsNumber,
  IsOptional,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import {
  AppearanceChangeDto,
  GenderDto,
  SymptomDto,
  TreatmentDto,
} from '../clinical-details';
import { ModelViewerLocation } from '../model-viewer-location.dto';

export interface DAHTLesionDto {
  id: string;
  lesionIdentifier: string;
  gender: GenderDto;
  clinicalImageFocalPointX?: number;
  clinicalImageFocalPointY?: number;
  preciseLocation: ModelViewerLocation;
  patientAgeYears: number;
  lesionAgeLessThanMonths: number;
  hasHeSlide: boolean;
  hasIhSlide: boolean;
  clinicalImageUrl?: string;
  dermoscopicImageUrl?: string;
  sizeX?: number;
  sizeY?: number;
  recurrence: boolean;
  ulceration: boolean;
  sunExposure: boolean;
  recentPregnancy: boolean;
  familyHistoryOfMelanoma: boolean;
  formerMelanoma: boolean;
  formerMelanomaDetails?: string;
  symptoms?: SymptomDto[];
  treatments?: TreatmentDto[];
  appearanceChanges?: AppearanceChangeDto[];
}

export class UpdateDAHTLesionDto
  implements Omit<DAHTLesionDto, 'id' | 'lesionIdentifier'>
{
  symptoms?: SymptomDto[];
  treatments?: TreatmentDto[];
  appearanceChanges?: AppearanceChangeDto[];

  gender: GenderDto;

  clinicalImageFocalPointX?: number;
  clinicalImageFocalPointY?: number;
  preciseLocation: ModelViewerLocation;
  patientAgeYears: number;
  hasHeSlide: boolean;
  hasIhSlide: boolean;
  originalClinicalImageUrl?: string;
  originalDermoscopicImageurl?: string;

  @IsNumber()
  @IsOptional()
  lesionAgeLessThanMonths: number;

  @IsNumber()
  @IsOptional()
  sizeX?: number;

  @IsNumber()
  @IsOptional()
  sizeY?: number;

  @IsOptional()
  @IsBoolean()
  familyHistoryOfMelanoma: boolean;

  @IsOptional()
  @IsBoolean()
  formerMelanoma: boolean;

  @IsOptional()
  @IsBoolean()
  ulceration: boolean;

  @IsOptional()
  @IsBoolean()
  recurrence: boolean;

  @IsOptional()
  @IsBoolean()
  sunExposure: boolean;

  @IsOptional()
  @IsBoolean()
  recentPregnancy: boolean;

  @IsArray()
  @IsUUID('all', { each: true })
  symptomsIds: string[];

  @IsArray()
  @IsUUID('all', { each: true })
  appearanceChangeIds: string[];

  @IsArray()
  @IsUUID('all', { each: true })
  treatmentIds: string[];
}
