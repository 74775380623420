import { get, post } from '../requests';

export interface TokenAuthentication {
  token: string;
}

export class BaseService {
  protected apiUrl: string;

  constructor(private readonly jwt: string) {
    this.apiUrl = process.env.NX_LEGACY_API_URL;
  }

  protected get<T>(url: string) {
    return get<T>(this.apiUrl + url, this.jwt);
  }

  protected post<T>(url: string, body: any) {
    return post<T>(this.apiUrl + url, body, this.jwt);
  }
}
