import { HotspotType, Point } from '@dermloop/ui/components';

import { Camera, Canvas, useThree } from '@react-three/fiber';

import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as THREE from 'three';
import { MathUtils, Vector3 } from 'three';
import { v4 as uuidv4 } from 'uuid';

import { Colors } from '@dermloop/ui/util';
import CC from 'camera-controls';
import { CameraControls } from './camera-controls';
import FemaleModel from './female';
import MaleModel from './male';
import { PositionedHotspot } from './positioned-hotspot';
import { computeBoundingSphere, getPointVisibility } from './utils';

const moveCamera = async (
  camera: Camera,
  controls: CameraControls,
  hitpoint: THREE.Vector3,
  hitnormal: THREE.Vector3
) => {
  if (camera.zoom < 3) controls.zoomTo(3, true);
  const position = controls.getPosition(new THREE.Vector3());
  const distance = position.distanceTo(hitpoint);

  //Move camera to current distance from the normal of the hit point
  const newPos = hitpoint
    .clone()
    .add(hitnormal?.clone()?.normalize()?.multiplyScalar(distance));

  //Look a directly at hitpoint from normal.
  controls.setLookAt(
    newPos.x,
    newPos.y,
    newPos.z,
    hitpoint.x,
    hitpoint.y,
    hitpoint.z,
    true
  );
};

function CanvasContent({
  selectedVector,
  debug,
  hotspots,
  center,
  disableNavigationByPress,
  onInteractionEnd,
  onInteractionStart,
  onHotspotClick,
  onHotspotHover,
  onHotspotCreated,
  gender,
}: {
  selectedVector: THREE.Vector2;
  debug?: boolean;
  onInteractionStart: () => void;
  onInteractionEnd: () => void;
  center?: Point;
  disableNavigationByPress?: boolean;
  onHotspotHover: (identifier: string) => void;
  onHotspotClick: (identifier: string) => void;
  onHotspotCreated: (hotspot: HotspotType) => void;
  hotspots: HotspotType[];
  gender: 'male' | 'female';
}) {
  const { camera, scene } = useThree();
  const [visiblePoints, setVisiblePoints] = useState<boolean[]>([]);
  const [model, setModel] = useState<THREE.Object3D>(null);
  const modelSphere = useRef<THREE.Sphere>(null);
  const [controls, setControls] = useState<CameraControls>(null);
  const [readyToNavigate, setReadyToNavigate] = useState(false);

  useEffect(() => {
    if (center?.normal && center?.position && controls && camera) {
      if (readyToNavigate) {
        moveCamera(
          camera,
          controls,
          new Vector3(center.position.x, center.position.y, center.position.z),
          new Vector3(center.normal.x, center.normal.y, center.normal.z)
        );
      }
    }
  }, [center, readyToNavigate]);

  useEffect(() => {
    if (model && selectedVector) {
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(selectedVector, camera);

      const intersction = raycaster.intersectObject(model);
      if (intersction.length) {
        const hit = intersction[0];

        if (hit.face && readyToNavigate) {
          if (readyToNavigate && !disableNavigationByPress) {
            moveCamera(camera, controls, hit.point, hit.face?.normal);
          }

          onHotspotCreated({
            point: {
              normal: { ...hit.face.normal },
              position: { ...hit.point },
            },
            size: 'normal',
            identifier: uuidv4(),
            removed: false,
            active: true,
          });

          setVisiblePoints([...visiblePoints, true]);
        }
      }
    }
  }, [selectedVector]);

  const handleModelRef = useCallback((ref: THREE.Object3D) => {
    setModel(ref);
  }, []);

  const handleControlsRef = useCallback((ref: CameraControls) => {
    setControls(ref);
  }, []);

  useEffect(() => {
    if (model) {
      const sphere = computeBoundingSphere(model);
      modelSphere.current = sphere;
    }

    if (controls && model && modelSphere.current) {
      const center = modelSphere.current.center;
      controls.minDistance = modelSphere.current.radius + 0.1;
      controls.setBoundary(
        modelSphere.current.getBoundingBox(new THREE.Box3())
      );
      controls.minZoom = 1;
      controls.maxZoom = 8;

      controls.mouseButtons.wheel = CC.ACTION.ZOOM;
      controls.touches.two = CC.ACTION.TOUCH_ZOOM_TRUCK;
      let initialRotation = 0;

      if (hotspots.length) {
        const { xs, ys, zs } = hotspots
          .map((h) => h.point.normal)
          .reduce(
            ({ xs, ys, zs }, { x, y, z }) => ({
              xs: xs + x,
              ys: ys + y,
              zs: zs + z,
            }),
            { xs: 0, ys: 0, zs: 0 }
          );

        const avgVector = new THREE.Vector3(
          xs / hotspots.length,
          ys / hotspots.length,
          zs / hotspots.length
        ).normalize();

        const frontAngle = new THREE.Vector3(0, 0, -1).angleTo(avgVector);
        const backAngle = new THREE.Vector3(0, 0, 1).angleTo(avgVector);
        initialRotation = backAngle > frontAngle ? 180 : initialRotation;
      }

      const setupCamera = async () => {
        await controls.fitToBox(model, initialRotation === 0);
        await controls.rotate(MathUtils.degToRad(initialRotation), 0, true);
        await controls.setOrbitPoint(center.x, center.y, center.z);
      };

      setupCamera();
    }
  }, [model, controls]);

  useEffect(() => {
    const keyDownHandle = (event: KeyboardEvent) => {
      if (event.code === 'ShiftRight' || event.code === 'ShiftLeft') {
        controls.mouseButtons.left = CC.ACTION.TRUCK;
      }
    };
    const keyUpHandle = (event: KeyboardEvent) => {
      if (event.code === 'ShiftRight' || event.code === 'ShiftLeft') {
        controls.mouseButtons.left = CC.ACTION.ROTATE;
      }
    };
    document.addEventListener('keydown', keyDownHandle);
    document.addEventListener('keyup', keyUpHandle);
    return () => {
      document.removeEventListener('keydown', keyDownHandle);
      document.removeEventListener('keyup', keyUpHandle);
    };
  }, [controls]);

  const onControl = useCallback(
    (controls: CameraControls) => {
      const modelBoundingSphere = modelSphere.current;
      const cameraPosition = new THREE.Vector3();
      controls.getPosition(cameraPosition);

      if (modelBoundingSphere) {
        if (modelBoundingSphere.containsPoint(cameraPosition)) {
          controls.reset();
        } else {
          controls.saveState();
        }
      }
    },
    [model, scene]
  );

  const onUpdate = useCallback(
    (controls: CameraControls) => {
      const cameraPosition = new THREE.Vector3();
      controls.getPosition(cameraPosition);
      setVisiblePoints(
        getPointVisibility(hotspots, model, scene, cameraPosition)
      );
    },
    [hotspots, model, scene]
  );

  //Update hotspots visibility on hotspots change.
  useEffect(() => {
    if (controls) onUpdate(controls);
  }, [onUpdate, controls]);

  return (
    <>
      <CameraControls
        onRest={() => {
          setReadyToNavigate(true);
        }}
        onControlStop={() => onInteractionEnd()}
        onControlStart={() => onInteractionStart()}
        onControl={onControl}
        onUpdate={onUpdate}
        ref={handleControlsRef}
      />
      <Suspense fallback={null}>
        <mesh>
          <group>
            {gender === 'male' ? (
              <MaleModel ref={handleModelRef} />
            ) : (
              <FemaleModel ref={handleModelRef} />
            )}
          </group>

          {hotspots
            .filter((h) => h?.point)
            .map((p, i) => {
              return (
                <Fragment key={p.identifier}>
                  <PositionedHotspot
                    size={p.size}
                    onHover={onHotspotHover}
                    onClick={onHotspotClick}
                    removed={p.removed}
                    active={p.active}
                    visible={visiblePoints[i]}
                    point={p.point.position}
                    identifier={p.identifier}
                  />
                  {debug ? (
                    <arrowHelper
                      args={[
                        new THREE.Vector3(
                          p.point.normal.x,
                          p.point.normal.y,
                          p.point.normal.z
                        ),
                        new THREE.Vector3(
                          p.point.position.x,
                          p.point.position.y,
                          p.point.position.z
                        ),
                      ]}
                    />
                  ) : null}
                </Fragment>
              );
            })}
        </mesh>

        {model && debug ? <boxHelper args={[model, 'red']} /> : null}

        <ambientLight color="0x404040" intensity={0.2} />
        <pointLight position={[10, 10, 10]} color={0xffffff} intensity={0.4} />
        <pointLight
          position={[-20, -20, -20]}
          color={0xffffff}
          intensity={0.4}
        />
      </Suspense>
    </>
  );
}
export interface ModelViewerProps {
  gender: 'male' | 'female';
  style?: 'light' | 'dark';
  readonly?: boolean;
  onHotspotHover: (identifier: string) => void;
  onHotspotClick: (identifier: string) => void;
  hotspots: HotspotType[];
  onHotspotCreated: (hotspot: HotspotType) => void;
  debug?: boolean;
  center?: Point;
  disableNavigationByPress?: boolean;
}

export function ModelViewer(props: ModelViewerProps) {
  const [selectedVector, setSelectedVector] = useState<THREE.Vector2>();
  const canvasRef = useRef<HTMLCanvasElement>();
  const [interactionStartDate, setInteractionStartDate] = useState<Date>();
  return (
    <div
      style={{
        background:
          props.style === 'dark' ? Colors.BRAND_PRIMARY : Colors.BRAND_WHITE,
        width: '100%',
        position: 'relative',
        height: '100%',
      }}
      onClick={(event) => {
        if (canvasRef.current) {
          const canvas = canvasRef.current;
          const coords = new THREE.Vector2();
          const { width, height, x, y } = canvas.getBoundingClientRect();
          coords.x = ((event.clientX - x) / width) * 2 - 1;
          coords.y = ((event.clientY - y) / height) * -2 + 1;
          setSelectedVector(coords);
        }
      }}
    >
      <Canvas
        ref={canvasRef}
        dpr={Math.max(window.devicePixelRatio, 2)}
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
      >
        <CanvasContent
          disableNavigationByPress={props.disableNavigationByPress}
          debug={props.debug}
          center={props.center}
          gender={props.gender}
          onInteractionEnd={() => {
            //Wait a bit before reset interactionDate to prevent interactions being understood as clicks.
            setTimeout(() => {
              setInteractionStartDate(null);
            }, 100);
          }}
          onInteractionStart={() => {
            setInteractionStartDate((s) => (s ? s : new Date()));
          }}
          onHotspotClick={props.onHotspotClick}
          onHotspotHover={props.onHotspotHover}
          hotspots={props.hotspots}
          onHotspotCreated={(hotspot) => {
            //If interaction was less than 200ms assume it was a click
            const safeToAddHotspot =
              !interactionStartDate ||
              new Date().getTime() - interactionStartDate.getTime() < 200;
            if (!props.readonly && safeToAddHotspot) {
              props.onHotspotCreated(hotspot);
            }
          }}
          selectedVector={selectedVector}
        ></CanvasContent>
        {props.debug ? (
          <>
            <axesHelper />
            <gridHelper />
          </>
        ) : null}
      </Canvas>
    </div>
  );
}

export default ModelViewer;
