import { Type } from 'class-transformer';
import { IsNumber, IsOptional, Min, ValidateNested } from 'class-validator';

/**
 * Batch metadata.
 */
export class SearchIndexMetadataBatchDto {
  @IsNumber()
  @Min(0)
  skip: number;

  @IsNumber()
  @Min(1)
  take: number;
}
/**
 * Metadata interface for search indexing.
 */
export class SearchIndexMetadataDto {
  @Type(() => SearchIndexMetadataBatchDto)
  @IsOptional()
  @ValidateNested()
  batching?: SearchIndexMetadataBatchDto;
}
