import { Colors } from '@dermloop/ui/util';
import { Ref } from 'react';
import styled from 'styled-components';
import ImageMedia from '../image-media/image-media';

export interface ImageCompareContainerProps {
  forwardedRef?: Ref<HTMLDivElement>;
  onClick?: () => void;
  imageId: string;
  srcs: string[];
  details?: string[];
  isThreeImageViewEnable?: boolean;
}

const StyledImageCompareContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  padding: 0 1rem;
`;

const ImageText = styled.div`
  margin: auto;
  color: ${Colors.BRAND_WHITE};
`;

const DetailItem = styled.div`
  display: flex;
  color: ${Colors.BRAND_WHITE};
`;

const ImageContainer = styled.div<{ isThreeImageViewEnable: boolean }>`
  border: 1px solid ${Colors.BRAND_WHITE};
  display: flex;
  margin-bottom: 1rem;
  height: ${(props) => (props.isThreeImageViewEnable ? '30vw' : '40vw')};
  width: ${(props) => (props.isThreeImageViewEnable ? '30vw' : '40vw')};
`;

export function ImageCompareContainer(props: ImageCompareContainerProps) {
  return (
    <StyledImageCompareContainer>
      <ImageContainer
        ref={props.forwardedRef}
        isThreeImageViewEnable={props.isThreeImageViewEnable}
      >
        {props.imageId ? (
          <ImageMedia
            imageId={props.imageId}
            src={props.srcs?.[0]}
            srcs={props.srcs}
            usesOpenSeaDragon={true}
          ></ImageMedia>
        ) : (
          <ImageText>Drag and drop to display image</ImageText>
        )}
      </ImageContainer>
      {props.imageId
        ? props.details?.map((detail, idx) => (
            <DetailItem
              key={'image-comapare-detail-' + props.imageId + '-' + idx}
            >
              {detail}
            </DetailItem>
          ))
        : null}
    </StyledImageCompareContainer>
  );
}

export default ImageCompareContainer;
