import {
  DiagnosisDto,
  DiagnosisGroupDto,
  LesionTriageDto,
  FieldConfigurationDto,
  LatestLesionInfoDto,
} from '@dermloop/api-dtos';

const inflateGroups = (
  diagnosisGroupIds: string[],
  diagnosisGroups: DiagnosisGroupDto[],
  acc: DiagnosisGroupDto[]
): DiagnosisGroupDto[] => {
  const group = diagnosisGroups?.find((dg) =>
    diagnosisGroupIds?.find((g) => dg.id === g)
  );

  if (group) acc.push(group);

  if (diagnosisGroupIds?.length > 0 && group?.childDiagnosisGroups) {
    return inflateGroups(
      diagnosisGroupIds.filter((id) => id !== group.id),
      group.childDiagnosisGroups,
      acc
    );
  }

  return acc;
};

export const flattenGroups = (
  groups: DiagnosisGroupDto[]
): DiagnosisGroupDto[] => {
  const subGroups = groups.map((g) => flattenGroups(g.childDiagnosisGroups));
  return groups.concat(...subGroups);
};

export const isValidDiagnosisSelection = (
  fieldConfiguration: FieldConfigurationDto,
  selectedDiagnosisGroupIds: string[],
  selectedDiagnosisId: string
) => {
  const inflated = inflateDiagnosis(
    selectedDiagnosisGroupIds,
    selectedDiagnosisId,
    fieldConfiguration?.diagnosisTree
  );

  return !!(
    inflated &&
    (inflated.diagnosis ||
      inflated.inflatedGroups.length >=
        fieldConfiguration.mandatoryDiagnosisTreeDepth ||
      inflated.inflatedGroups.find(
        (d) => !d.childDiagnosisGroups || d.childDiagnosisGroups.length === 0
      ))
  );
};

/**
 * Naive and slow search for a path to a given diagnosisId in a tree
 * @param diagnosisGroupId diagnosisId to find the path for
 * @param tree tree in which to find the diagnosisId
 * @returns the ordered diagnosis group path from top to bottom including the diagnosisgroup matching the diagnosisid
 */
export function getPathToGroup(
  diagnosisGroupId: string,
  tree: DiagnosisGroupDto[]
) {
  const groups = flattenGroups(tree);

  const findPathRecursive = (
    id: string,
    acc: DiagnosisGroupDto[]
  ): DiagnosisGroupDto[] => {
    const group = groups.find((g) => g.id === id);
    if (!group) return acc;
    return [
      ...(group.parentDiagnosisGroupId
        ? findPathRecursive(group.parentDiagnosisGroupId, acc)
        : acc),
      group,
    ];
  };

  return findPathRecursive(diagnosisGroupId, []);
}

const inflateDiagnosis = (
  selectedDiagnosisGroupIds: string[],
  selectedDiagnosisId: string,
  availableDiagnosisGroupTree: DiagnosisGroupDto
) => {
  if (!selectedDiagnosisId && !selectedDiagnosisGroupIds?.length)
    return undefined;

  if (selectedDiagnosisId && !selectedDiagnosisGroupIds.length) {
    return {
      inflatedGroups: [],
      diagnosis: availableDiagnosisGroupTree?.diagnoses?.find(
        (d) => d.id === selectedDiagnosisId
      ),
    };
  }

  const groups = inflateGroups(
    selectedDiagnosisGroupIds,
    availableDiagnosisGroupTree.childDiagnosisGroups,
    []
  );

  const diagnosis = groups[groups.length - 1]?.diagnoses?.find(
    (d) => d.id === selectedDiagnosisId
  );
  return { inflatedGroups: groups, diagnosis: diagnosis };
};

export function displayLatestDiagnosis(lesion: LatestLesionInfoDto) {
  return lesion.lesionLatestDiagnosisName || 'No registered diagnosis';
}

export const getDiagnosisDisplayName = (
  selectedDiagnosisGroupIds: string[],
  selectedDiagnosisId: string,
  availableDiagnoses: DiagnosisDto[],
  availableDiagnosisGroupTree?: DiagnosisGroupDto,
  abbreviate?: boolean
) => {
  if (selectedDiagnosisId && !selectedDiagnosisGroupIds?.length) {
    return availableDiagnoses?.find((d) => d.id === selectedDiagnosisId)?.name;
  }

  if (!availableDiagnosisGroupTree) {
    return null;
  }

  const inflated = inflateDiagnosis(
    selectedDiagnosisGroupIds,
    selectedDiagnosisId,
    availableDiagnosisGroupTree
  );
  const initialDiagnosisGroups = inflated?.inflatedGroups;
  const initialDiagnosis = inflated?.diagnosis;
  const names = [
    ...(initialDiagnosisGroups || []).map((g) => g.name),
    initialDiagnosis?.name,
  ].filter((n) => n);

  return abbreviate ? names[names.length - 1] : names.join(' ➞ ');
};

export function getMostAccurateTriageDiagnosisDisplayName(
  lesionTriage: LesionTriageDto,
  diagnoses: DiagnosisDto[]
) {
  if (!lesionTriage) return 'No registered lesion';
  if (lesionTriage?.biopsyAction?.latestPathologicalAssessment) {
    return diagnoses?.find(
      (elem) =>
        elem.id ===
        lesionTriage.biopsyAction?.latestPathologicalAssessment?.diagnosisId
    )?.name;
  } else if (
    lesionTriage.diagnosisGroupIds?.length ||
    lesionTriage.diagnosisId
  ) {
    return (
      getDiagnosisDisplayName(
        lesionTriage.diagnosisGroupIds,
        lesionTriage.diagnosisId,
        diagnoses,
        lesionTriage.fieldConfiguration?.diagnosisTree,
        true
      ) +
      (!lesionTriage?.diagnosisId && lesionTriage?.diagnosisGroupIds.length
        ? ' (unspecified)'
        : '')
    );
  } else return 'No diagnosis';
}
