import {
  Colors,
  GENERAL_SPACING,
  NATIVE_BORDER_WIDTH,
} from '@dermloop/ui/util';
import { ListRenderItem, View } from 'react-native';
import styled from 'styled-components/native';

/* eslint-disable-next-line */
export interface ScrollableBannerProps<T> {
  items: T[];
  renderItem: ListRenderItem<T>;
}

const StyledScrollableBanner = styled.View``;

const StyledBannerList = styled.FlatList`
  background-color: ${Colors.BRAND_WHITE};
  padding-horizontal: ${GENERAL_SPACING}px;
  padding-vertical: ${GENERAL_SPACING / 2}px;
  border-bottom-width: ${NATIVE_BORDER_WIDTH}px;
  border-bottom-color: ${Colors.BRAND_SECONDARY};
`;

export function ScrollableBanner<T>(props: ScrollableBannerProps<T>) {
  return (
    <StyledScrollableBanner>
      <StyledBannerList
        showsHorizontalScrollIndicator={false}
        bounces={false}
        ItemSeparatorComponent={() => <View style={{ width: 10 }} />}
        horizontal
        data={props.items}
        renderItem={props.renderItem}
      />
    </StyledScrollableBanner>
  );
}

export default ScrollableBanner;
