import {
  DAHTAssessmentDto,
  DAHTSessionDto,
  DiagnosisGroupDto,
} from '@dermloop/api-dtos';

import {
  DAHTNumericalValue,
  DAHTNumericalValues,
  DAHTSecondOpinion,
} from '@dermloop/static-values';
import {
  Button,
  FormControl,
  FormControlGroup,
  FormControlHeader,
  OptionPicker,
  OptionTextField,
  PanelHeader,
  RangePointPicker,
  TrueFalsePicker,
} from '@dermloop/ui/components';
import { ImageMedia, Spacer } from '@dermloop/ui/web/components';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { dermloopApi } from '../../store/api';

import { getPathToGroup, Logger } from '@dermloop/shared';
import {
  BORDER_RADIUS,
  Colors,
  DESKTOP_HEADER_HEIGHT,
  GENERAL_SPACING,
} from '@dermloop/ui/util';
import { dropRight, last } from 'lodash';
import { toast } from 'react-toastify';
import FemaleModelPreview from '../../../assets/female-preview.png';
import MaleModelPreview from '../../../assets/male-preview.png';
import { LesionDetails } from './lesion-details';
export type Visual = 'clinical' | 'dermoscopy' | 'slide' | 'location';

/* eslint-disable-next-line */
export interface SessionSidebarProps {
  disableNextNavigation?: boolean;
  session: DAHTSessionDto;
  onAssessmentSaved?: () => void;
  currentAssessment: DAHTAssessmentDto;
  onChangeActiveVisual: (visual: Visual) => void;
  activeVisual: Visual;
}

interface LocalAssessmentState {
  selectedGroups?: DiagnosisGroupDto[];
  otherDiagnosis?: string;
  secondOpinion?: DAHTSecondOpinion;
  additionalStainsHelpful?: boolean;
  additionalStainsHelpfulDetails?: string;
  comment?: string;
  difficulty?: DAHTNumericalValue;
  confidence?: DAHTNumericalValue;
}
/**
 *
 * @param props
 */
export function SessionSidebar(props: SessionSidebarProps) {
  const [
    updateAssesment,
    { isLoading: updatingAssessment, error: errorUpdatingAssessment },
  ] = dermloopApi.useUpdateDAHTAssessmentMutation();
  const sidebarContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const now = new Date();
    setStartTimestamp(now.getTime());

    if (sidebarContent?.current) {
      sidebarContent?.current?.scrollTo({ top: 0 });
    }
    Logger.info({
      message: `Starting timestamp for ${props.currentAssessment.id}`,
      context: 'SessionSidebar',
      extras: { timestamp: now.toISOString() },
    });

    if (props.currentAssessment?.assessedDate) {
      setState({
        ...props.currentAssessment,
        selectedGroups: getPathToGroup(
          props.currentAssessment?.selectedDiagnosisGroupId,
          props.session?.options?.childDiagnosisGroups
        ),
      });
    }
  }, [props.currentAssessment]);

  const [
    {
      selectedGroups,
      otherDiagnosis,
      secondOpinion,
      additionalStainsHelpful,
      additionalStainsHelpfulDetails,
      comment,
      confidence,
      difficulty,
    },
    setState,
  ] = useState<LocalAssessmentState>({});

  const updateState = (update: Partial<LocalAssessmentState>) => {
    setState((s) => ({ ...s, ...update }));
  };

  const [startTimestamp, setStartTimestamp] = useState<number>();
  const lastSelectedGroup = last(selectedGroups);
  const finalGroup = !lastSelectedGroup?.childDiagnosisGroups?.length
    ? lastSelectedGroup
    : null;
  const slideThumbnailUrl = `https://slides.dermloop.io/he/he${props.currentAssessment?.lesion?.lesionIdentifier}_files/8/0_0.jpg`;
  const requireOtherDiagnosisDescription =
    finalGroup?.name === 'None of the above';
  const thumbnails: {
    url: string;
    visual: Visual;
  }[] = [
    {
      url: props.currentAssessment.lesion.dermoscopicImageUrl,
      visual: 'dermoscopy',
    },
    {
      url: props.currentAssessment.lesion.clinicalImageUrl,
      visual: 'clinical',
    },
    { url: slideThumbnailUrl, visual: 'slide' },
    {
      url:
        props.currentAssessment?.lesion?.gender?.name?.toLowerCase() ===
        'female'
          ? FemaleModelPreview
          : MaleModelPreview,
      visual: 'location',
    },
  ];
  return (
    <StyledSidebar>
      <PanelHeader
        title={`${
          props.currentAssessment.lesion.patientAgeYears
        } year old ${props.currentAssessment.lesion.gender.name.toLowerCase()}`}
        showProgress={true}
        entityId={props.currentAssessment.id}
        entityIdList={props.session.assessments.map((a) => a.id)}
        editable={true}
        readOnly={false}
      ></PanelHeader>
      <StyledSideBarContent ref={sidebarContent}>
        <FormControlGroup shadow open={true}>
          <Images>
            {thumbnails?.map((i, idx) => (
              <Image key={idx}>
                <ImageOutline active={props.activeVisual === i.visual}>
                  <ImageMedia
                    onClick={() => {
                      props.onChangeActiveVisual(i.visual);
                    }}
                    src={i.url}
                  />
                </ImageOutline>
                <Spacer />
              </Image>
            ))}
          </Images>
        </FormControlGroup>
        <LesionDetails
          key={props.currentAssessment.lesion.id}
          lesion={props.currentAssessment.lesion}
        />

        <FormControlGroup title={`Select diagnosis`}>
          <OptionPicker<DiagnosisGroupDto>
            options={props.session?.options?.childDiagnosisGroups?.map(
              (dg) => ({
                value: dg,
                name: dg.name,
              })
            )}
            selectedValue={selectedGroups?.[0]}
            onOptionSelected={(option) => {
              updateState({
                selectedGroups: option?.value ? [option.value] : [],
                otherDiagnosis: null,
              });
            }}
          />

          {selectedGroups
            ?.filter((g) => g.childDiagnosisGroups?.length)
            .map((g, i) => (
              <>
                <FormControlHeader title={g.name} />
                <OptionPicker<DiagnosisGroupDto>
                  options={g.childDiagnosisGroups.map((dg) => ({
                    value: dg,
                    name: dg.name,
                  }))}
                  selectedValue={selectedGroups?.[i + 1]}
                  onOptionSelected={(option) => {
                    if (option?.value) {
                      setState((v) => {
                        const toDrop = v?.selectedGroups?.length - (i + 1);
                        return {
                          ...v,
                          otherDiagnosis: null,
                          selectedGroups: [
                            ...dropRight(v.selectedGroups, toDrop),
                            option.value,
                          ],
                        };
                      });
                    }
                  }}
                />
              </>
            ))}

          {/* Hacky way to check if other diagnosis field should be shown */}
          {requireOtherDiagnosisDescription ? (
            <FormControl title="Other diagnosis">
              <OptionTextField
                placeholder="Write other diagnosis here"
                value={otherDiagnosis}
                onChangeText={(t) => updateState({ otherDiagnosis: t })}
                multiline
              />
            </FormControl>
          ) : null}
        </FormControlGroup>

        <div style={{ marginTop: 16 }}>
          <FormControlGroup
            shadow
            title="Please rate on the following scale your opinion of the level of diagnostic difficulty of this case"
            open={true}
          >
            <RangePointPicker<DAHTNumericalValue>
              onOptionSelected={(o) => {
                updateState({ difficulty: o?.value });
              }}
              selectedValue={difficulty}
              range={DAHTNumericalValues.map((v, i) => ({
                label: v,
                name:
                  i === 0
                    ? 'Very easy'
                    : i + 1 === DAHTNumericalValues.length
                    ? 'Very challenging'
                    : '',
                value: v,
              }))}
            />
          </FormControlGroup>
          <FormControlGroup
            shadow
            title="Please rate on the following scale your confidence in your assessment of this case"
            open={true}
          >
            <RangePointPicker<DAHTNumericalValue>
              onOptionSelected={(o) => {
                updateState({ confidence: o?.value });
              }}
              selectedValue={confidence}
              range={DAHTNumericalValues.map((v, i) => ({
                label: v,
                name:
                  i === 0
                    ? 'Completely confident'
                    : i + 1 === DAHTNumericalValues.length
                    ? 'Not at all confident'
                    : '',
                value: v,
              }))}
            />
          </FormControlGroup>
          <FormControlGroup
            shadow
            title="Would you ask for a second pathologists opinion of this case before finalizing the report?"
            open={true}
          >
            <OptionPicker<DAHTSecondOpinion>
              onOptionSelected={(o) => {
                updateState({ secondOpinion: o?.value });
              }}
              selectedValue={secondOpinion}
              options={[
                { name: 'No', value: DAHTSecondOpinion.No },
                {
                  name: 'Yes, because it is our policy to get second opinion on cases with this diagnosis',
                  value: DAHTSecondOpinion.YesPolicyRequirement,
                },
                {
                  name: 'Yes, because I would want a second pathologist’s opinion for diagnostic reasons',
                  value: DAHTSecondOpinion.Yes,
                },
              ]}
            />
          </FormControlGroup>

          <FormControlGroup
            shadow
            title="Would additional stains/tests help your assessment?"
            open={true}
          >
            <TrueFalsePicker
              onOptionSelected={(o) => {
                updateState({ additionalStainsHelpful: o });
              }}
              selectedValue={additionalStainsHelpful}
            />

            {additionalStainsHelpful ? (
              <FormControl title="Which stains/tests would be helpful?">
                <OptionTextField
                  placeholder="Describe helpful stains/tests here..."
                  value={additionalStainsHelpfulDetails}
                  onChangeText={(additionalStainsHelpfulDetails) =>
                    updateState({ additionalStainsHelpfulDetails })
                  }
                  multiline
                />
              </FormControl>
            ) : null}
          </FormControlGroup>

          <FormControlGroup shadow title="Additional comments" open={true}>
            <OptionTextField
              placeholder="Write additional comments here..."
              value={comment}
              onChangeText={(comment) => updateState({ comment })}
              multiline
            />
          </FormControlGroup>
        </div>
      </StyledSideBarContent>
      <StyledFooterButton>
        {errorUpdatingAssessment ? <p>Error occured</p> : null}
        <Button
          loading={updatingAssessment}
          disabled={
            !finalGroup ||
            (requireOtherDiagnosisDescription && !otherDiagnosis) ||
            !secondOpinion ||
            !confidence ||
            !difficulty ||
            additionalStainsHelpful == null ||
            (additionalStainsHelpful && !additionalStainsHelpfulDetails)
          }
          onPress={() =>
            updateAssesment({
              id: props.currentAssessment.id,
              update: {
                selectedDiagnosisGroupId: finalGroup.id,
                selectedDiagnosisGroup: finalGroup,
                otherDiagnosis: otherDiagnosis || null,
                timeSpentMs: new Date().getTime() - startTimestamp,
                confidence: confidence,
                difficulty: difficulty,
                secondOpinion: secondOpinion,
                additionalStainsHelpful: additionalStainsHelpful,
                additionalStainsHelpfulDetails:
                  additionalStainsHelpfulDetails || null,
                comment: comment || null,
              },
            }).then((res) => {
              if (!('error' in res)) {
                if (!props.disableNextNavigation) {
                  setState({
                    //Text fields must be set to empty string to retain control
                    comment: '',
                    additionalStainsHelpfulDetails: '',
                    otherDiagnosis: '',
                  });
                }

                if (props.onAssessmentSaved) {
                  props.onAssessmentSaved();
                }
              } else {
                toast(
                  `Error while saving lesion #${props.currentAssessment?.lesion?.lesionIdentifier}`,
                  {
                    hideProgressBar: true,
                    type: 'error',
                    position: 'bottom-right',
                  }
                );
              }
            })
          }
        >
          {props.disableNextNavigation ? 'Save' : 'Next lesion'}
        </Button>
      </StyledFooterButton>
    </StyledSidebar>
  );
}

export default SessionSidebar;

const Images = styled.div<{ wide?: boolean }>`
  justify-content: center;
  display: flex;
  padding-top: ${GENERAL_SPACING}px;
  padding-bottom: ${GENERAL_SPACING}px;
  padding-left: ${GENERAL_SPACING}px;
  flex-direction: row;
`;

const Image = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  position: relative;
`;

const ImageOutline = styled.div<{ active: boolean }>`
  display: flex;
  flex: 1;
  border-radius: ${BORDER_RADIUS}px;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  border: 1px solid
    ${(props) => (props.active ? Colors.BRAND_SUCCESS : Colors.TRANSPARENT)};
`;

const StyledFooterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  max-height: 70px;
  flex-shrink: 0;
  border-top: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  background-color: ${Colors.BRAND_WHITE};
`;

const StyledSideBarContent = styled.div`
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  display: flex;
`;

const StyledSidebar = styled.aside`
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  top: ${DESKTOP_HEADER_HEIGHT}px;
  flex-direction: column;
  background-color: ${Colors.BRAND_PRIMARY_BACKGROUND};
  border-left: 2px solid ${Colors.BRAND_GRAY_MEDIUM};
  width: 400px;
`;
