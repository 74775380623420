import { Colors, IconType } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import { Icon } from '../icon/icon';

export interface MenuTileProps {
  children: string;
  iconSelected: IconType;
  iconNotSelected: IconType;
  // True if pre-selected
  selected: boolean;
  // If true, shows a cursor representing that this menu tile is not selectable
  notSelectable?: boolean;
  onPress?: (children: string) => void;
}

const getFontColor = (selected: boolean) =>
  selected ? Colors.BRAND_SUCCESS : Colors.BRAND_BLACK;
const getBackgroundColor = (selected: boolean) =>
  selected ? Colors.BRAND_SUCCESS_BACKGROUND : Colors.BRAND_WHITE;

export function MenuTile(props: MenuTileProps) {
  return (
    <MenuTileStyle
      onPress={() => (props.onPress ? props.onPress(props.children) : null)}
      selected={props.selected}
      notSelectable={props.notSelectable}
    >
      {/* <MenuTileContentStyle> */}
      <IconStyle>
        <Icon
          icon={props.selected ? props.iconSelected : props.iconNotSelected}
        ></Icon>
      </IconStyle>
      <TitleStyle selected={props.selected}>{props.children}</TitleStyle>
      {/* </MenuTileContentStyle> */}
    </MenuTileStyle>
  );
}

const MenuTileStyle = styled.Pressable<{
  selected: boolean;
  notSelectable?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => getBackgroundColor(props.selected)};
  width: 100%;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
  cursor: ${(props) => (props.notSelectable ? 'not-allowed' : 'pointer')};
`;

const IconStyle = styled.View`
  margin-right: 15px;
`;

const TitleStyle = styled.Text<{ selected: boolean }>`
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => getFontColor(props.selected)};
`;
