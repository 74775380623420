import { cloneDeep } from 'lodash';
import { deleteNestedProperty } from './delete-properties.util';
import {
  redactObjectValues,
  RedactReturnType,
  RedactReturnTypeStr,
} from './redact-values.util';

export const scrubObjectKeys = [
  'firstName',
  'lastName',
  'password',
  'ssn',
  'cpr',
];

/**
 * Fully scrubs object for confidential information.
 * @param obj
 * @param returnType      Used for determining if output is object or result.
 * @returns
 */
export const scrubObject = <T extends object, R extends RedactReturnTypeStr>(
  obj: T,
  returnType: R
): RedactReturnType<T, R> => {
  if (!obj) return null;

  //Errors are weird and cannot be cloned. Otherwise copy object to avoid mutations of object that is being used in other code.
  const clonedObject = obj instanceof Error ? obj : cloneDeep(obj);

  // Scrub event for any properties that are sensitive
  const scrubbedEventChildren = deleteNestedProperty(
    clonedObject,
    new Set(scrubObjectKeys)
  );

  // Scrub event for any strings that are sensitive
  const scrubbedEvent = redactObjectValues(scrubbedEventChildren, returnType, {
    jsonTextKeys: scrubObjectKeys,
  });

  return scrubbedEvent;
};
