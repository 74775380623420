import { Api, makeSafeRequest } from '../api-client';
import {
  CreatePatientDetailsDto,
  UpdatePatientDetailsDto,
  PatientDetailsDto,
} from '@dermloop/api-dtos';

export function createPatientDetails(dto: CreatePatientDetailsDto) {
  return makeSafeRequest(Api.post<PatientDetailsDto>('patient-details', dto));
}

export function getPatientDetails(id: string) {
  return makeSafeRequest(Api.get<PatientDetailsDto>('patient-details/' + id));
}

export function updatePatientDetails(id: string, dto: UpdatePatientDetailsDto) {
  return makeSafeRequest(
    Api.put<PatientDetailsDto>('patient-details/' + id, {
      dto,
    })
  );
}
