import { Colors, Icons, Sizes } from '@dermloop/ui/util';
import { useState } from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../loading-indicator/loading-indicator';

/* eslint-disable-next-line */
export interface SearchBarProps {
  loading?: boolean;
  barSize?: 'small' | 'normal';
  results?: JSX.Element[];
  onSearch?: (query: string) => void;
  onTypeSearch?: (query: string) => void;
  headerSearchBar?: boolean;
  placeholder?: string;
  value?: string;
  onSearchClear?: () => void;
}

const Container = styled.div<{ hasResults: boolean }>`
  display: inline-block;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: ${(props) =>
    props.hasResults ? Sizes.BORDER_RADIUS : '25'}px;
  border-bottom-right-radius: ${(props) =>
    props.hasResults ? Sizes.BORDER_RADIUS : '25'}px;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  width: 100%;
  background-color: ${Colors.BRAND_WHITE};
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
`;

const RemoveIcon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  cursor: pointer;
  padding: 0.8rem;
`;

const StatusIndicator = styled.div`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
`;

const StyledSearchBar = styled.input<{
  barSize: 'small' | 'normal';
}>`
  line-height: 1;
  border: none;
  width: ${(props) => (props.barSize === 'normal' ? '500px' : 'unset')};
  flex-grow: 1;
  padding: ${(props) =>
    props.barSize === 'normal' ? '1rem 4rem 1rem 4rem' : '1rem 4rem 1rem 4rem'};

  &::placeholder {
    color: ${Colors.BRAND_GRAY_MEDIUM};
  }

  background: transparent;
  outline: none;
`;

const StyledHeaderSearchBar = styled.input`
  border-radius: ${Sizes.BUTTON_BORDER_RADIUS}px;
  border: none;
  width: 500px;
  padding: 1rem 1rem 1rem 4rem;

  &::placeholder {
    color: ${Colors.BRAND_GRAY_MEDIUM};
  }
  background-color: ${Colors.BRAND_BROWN_DARK};
  color: ${Colors.BRAND_GRAY_MEDIUM};
  margin-right: -2px;
`;

const Positioned = styled.div`
  position: relative;
  display: flex;
`;

const Result = styled.div`
  border-top: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
`;

const StyledResultsContainer = styled.div<{ hasResults: boolean }>`
  position: absolute;
  z-index: 1;
  background-color: ${Colors.BRAND_WHITE};
  width: inherit;
  margin-bottom: 0.4rem;
  max-height: 50vh;
  overflow-y: scroll;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-left: ${(props) =>
    props.hasResults ? '1px solid ' + Colors.BRAND_GRAY_MEDIUM : ''};
  border-bottom: ${(props) =>
    props.hasResults ? '1px solid ' + Colors.BRAND_GRAY_MEDIUM : ''};
  border-right: ${(props) =>
    props.hasResults ? '1px solid ' + Colors.BRAND_GRAY_MEDIUM : ''};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
`;

export function SearchBar(props: SearchBarProps) {
  const [value, setValue] = useState(props.value);

  return (
    <Container hasResults={props.results?.length > 0}>
      <Positioned>
        <SearchIcon src={Icons.SearchDark} />
        {props.loading ? (
          <StatusIndicator>
            <LoadingIndicator />
          </StatusIndicator>
        ) : null}
        {!props.headerSearchBar ? (
          <>
            <StyledSearchBar
              barSize={props.barSize}
              onKeyUp={(event) => {
                if (
                  (event.code === 'Enter' || event.code === 'NumpadEnter') &&
                  props.onSearch
                ) {
                  props.onSearch(event.currentTarget.value);
                } else if (props.onTypeSearch) {
                  props.onTypeSearch(event.currentTarget.value);
                }
              }}
              placeholder={props.placeholder}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              data-cy="searchBar"
            />
            {value && value !== '' ? (
              <RemoveIcon
                src={Icons.CloseButtonNoOutline}
                onClick={() => {
                  setValue('');

                  if (props.onSearchClear) props.onSearchClear();
                }}
              />
            ) : null}
          </>
        ) : (
          <>
            <StyledHeaderSearchBar
              onKeyDown={(event) => {
                if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                  props.onSearch(event.currentTarget.value);
                }
              }}
              placeholder={props.placeholder}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {value && value !== '' ? (
              <RemoveIcon
                src={Icons.CloseButtonWhiteNoOutline}
                onClick={() => {
                  setValue('');

                  if (props.onSearchClear) props.onSearchClear();
                }}
              />
            ) : null}
          </>
        )}
      </Positioned>

      <StyledResultsContainer hasResults={props.results?.length > 0}>
        {props.results?.map((result, index) => (
          <Result key={index}>{result}</Result>
        ))}
      </StyledResultsContainer>
    </Container>
  );
}

export default SearchBar;
