import {
  IsDate,
  IsJSON,
  IsNotEmpty,
  IsOptional,
  IsUUID,
  ValidateIf,
} from 'class-validator';
import { startOfToday } from 'date-fns';

/**
 * DTO for indexing a training assessment.
 */
export class UserStatisticsIndexDto {
  // User id
  @IsUUID()
  id: string;

  // The date these statistics are relevant for
  @IsDate()
  statisticsDate: Date = startOfToday();

  @IsOptional()
  @IsNotEmpty()
  @IsJSON()
  trainingStatistics?: string;

  @ValidateIf((o) => !!o.trainingStatistics)
  @IsDate()
  trainingStatisticsUpdated?: Date;

  @IsOptional()
  @IsNotEmpty()
  @IsJSON()
  caseStatistics?: string;

  @ValidateIf((o) => !!o.caseStatistics)
  @IsDate()
  caseStatisticsUpdated?: Date;

  @IsOptional()
  @IsNotEmpty()
  @IsJSON()
  forumStatistics?: string;

  @ValidateIf((o) => !!o.forumStatistics)
  @IsDate()
  forumStatisticsUpdated?: Date;
}
