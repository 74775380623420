import {
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import type { DiagnosisDto } from '../diagnoses/diagnosis.dto';

export class CreatePathologicalAssessmentDto {
  @IsOptional()
  @IsString()
  snomed?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsNotEmpty()
  @IsUUID('all')
  biopsyId: string;

  @IsString()
  @IsNotEmpty()
  difficultyId: string;

  @IsString()
  @IsNotEmpty()
  primaryDiagnosisId: string;

  @IsOptional()
  @IsString()
  secondaryDiagnosisId?: string;

  @IsOptional()
  @IsString()
  tertiaryDiagnosisId?: string;
}

export class UpdatePathologicalAssessmentDto {
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  snomed: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  description: string;

  @IsOptional()
  @IsUUID('all')
  @IsNotEmpty()
  biopsyId: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  primaryDiagnosisId: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  secondaryDiagnosisId: string;

  @IsOptional()
  @IsString()
  tertiaryDiagnosisId?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;
}

export class PathologicalAssessmentDto implements BaseDto {
  @IsNotEmpty()
  @IsUUID('all')
  id: string;

  @IsNotEmpty()
  @IsString()
  created: string;

  @IsOptional()
  @IsString()
  snomed?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsNotEmpty()
  @IsUUID('all')
  biopsyId: string;

  @IsNotEmpty()
  @IsUUID('all')
  difficultyId: string;

  @IsNotEmpty()
  @IsUUID('all')
  diagnosisId: string;

  @IsOptional()
  @ValidateNested()
  diagnosis?: DiagnosisDto;

  @IsOptional()
  @IsUUID('all')
  secondaryDiagnosisId?: string;

  @IsOptional()
  @IsUUID('all')
  tertiaryDiagnosisId?: string;

  @IsOptional()
  @IsString()
  createdByUserName?: string;
}
