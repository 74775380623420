import { DiagnosisDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const lesionLocationEndpoints = (builder: EndpointBuilderType) => ({
  getLesionLocations: builder.query<DiagnosisDto[], void>({
    query: () => 'lesion-locations',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'LESION_LOCATIONS', id: 'LIST' }],
  }),
});
