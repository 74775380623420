import { Exception } from '../../exception';
import { ExceptionCodeEnum } from '../../exception.constants';

export const MfaOtpExpiredExceptionMessage = 'MFA OTP expired';

/**
 * Exception thrown if the OTP has expired or does not exist.
 */
export class MfaOtpExpiredException extends Exception {
  constructor() {
    super(
      MfaOtpExpiredExceptionMessage,
      MfaOtpExpiredException.name,
      ExceptionCodeEnum.MfaExpiredOtp
    );
  }
}
