function choice<T>(weightSum: number, population: T[], weights: number[]) {
  let random = Math.floor(Math.random() * weightSum);
  for (let i = 0; i < population.length; i++) {
    random -= weights[i];

    if (random < 0) {
      return population[i];
    }
  }
  return null;
}

export function choices<T>(
  population: T[],
  weights: number[],
  sampleSize: number
) {
  const weightSum = weights.reduce((sum, v) => sum + v, 0);

  const selections: T[] = [];
  for (let i = 0; i < sampleSize; i++) {
    const selected = choice(weightSum, population, weights);
    selections.push(selected);
  }

  return selections;
}
