import { ApproximateAgeDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const approximateAgeEndpoints = (builder: EndpointBuilderType) => ({
  getApproximateAges: builder.query<ApproximateAgeDto[], void>({
    query: () => 'approximate-ages',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'APPROXIMATE_AGES', id: 'LIST' }],
  }),
});
