import { IsNotEmpty, IsString } from 'class-validator';

export interface CountryDto {
  id: string;
  name: string;
}

export class CreateCountryDto {
  @IsNotEmpty()
  @IsString()
  name: string;
}

export class UpdateCountryDto {
  @IsString()
  @IsNotEmpty()
  name: string;
}
