import { Colors, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import Typography, { Letters } from '../typography/typography';

type InfoListItem = {
  text: string;
  color?: InfoColor;
  weight?: 'regular' | 'semi-bold' | 'bold' | 'medium';
};
export type InfoList = {
  title: InfoListItem;
  subtitle?: InfoListItem;
  separator?: InfoListItem;
};
type InfoColor = 'dark' | 'success' | 'warning' | 'danger';
export interface ClickableInfoDisplayProps {
  multilineDescription?: boolean;
  infoList: InfoList[];
  noBorderTop?: boolean;
  noPaddingTop?: boolean;
}

const StyledClickableInfoDisplay = styled.Pressable<ClickableInfoDisplayProps>`
  padding: ${Sizes.GENERAL_SPACING}px;
  ${(props) => (props.noPaddingTop ? 'padding-top: 0;' : '')}
  border-top-width: ${(props) => (props.noBorderTop ? 0 : 1)}px;
  border-top-color: ${Colors.BRAND_GRAY_MEDIUM};
  cursor: default;
`;

const InfoDisplayView = styled.View`
  padding-vertical: 3px;
`;

/**
 *
 * @param props
 */
export function ClickableInfoDisplay(props: ClickableInfoDisplayProps) {
  return (
    <StyledClickableInfoDisplay {...props}>
      {props.infoList.map((info, index) => {
        return (
          <InfoDisplayView key={index}>
            <Typography size="normal">
              <Letters
                color={info.title.color ? info.title.color : 'dark'}
                weight={info.title.weight ? info.title.weight : 'medium'}
                preventShrink
              >
                {info.title.text}
              </Letters>
              {info.separator ? (
                <Letters
                  color={
                    info.separator.color ? info.separator.color : 'success'
                  }
                  weight={
                    info.separator.weight ? info.separator.weight : 'regular'
                  }
                >
                  {info.separator.text}
                </Letters>
              ) : (
                <Letters noTrailingSpace>{`\u200b`}</Letters>
              )}
              {info.subtitle ? (
                <Letters
                  color={info.subtitle.color ? info.subtitle.color : 'success'}
                  weight={
                    info.subtitle.weight ? info.subtitle.weight : 'regular'
                  }
                  singleLine={!props.multilineDescription}
                >
                  {info.subtitle.text}
                </Letters>
              ) : (
                <Letters>{`\u200b`}</Letters>
              )}
            </Typography>
          </InfoDisplayView>
        );
      })}
    </StyledClickableInfoDisplay>
  );
}

export default ClickableInfoDisplay;
