import { LesionImageDto } from '@dermloop/api-dtos';
import { getStyledDate } from '@dermloop/shared';
import { Typography } from '@dermloop/ui/components';
import { GENERAL_SPACING, Icons } from '@dermloop/ui/util';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import styled from 'styled-components';
import { ArrowsSlider } from '../arrows-slider/arrows-slider';
import ImageMedia from '../image-media/image-media';
import { ImageOverlay } from '../image-overlay/image-overlay';

export interface ImageCarouselProps {
  images: LesionImageDto[];
  imageClickable?: boolean;
  selectedImageId?: string;
  hasOutline?: boolean;
  usesOpenSeaDragon?: boolean;
  onChange?: (id: string) => void;
  isOverlay?: boolean;
  hasImageCompare?: boolean;
  titles?: Record<string, string>;
  onClickCompare?: () => void;
  imagePlaceholder?: string;
  setOverlayAlternativeImage?: (s: boolean) => void;
}

const StayledCarouselContainer = styled.div<{ fullwidth?: boolean }>`
  width: ${(props) =>
    props.fullwidth
      ? '100%'
      : '10%'}; //set some  default value less than the potential width
  flex-grow: 1;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: ${GENERAL_SPACING / 4}px;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const PlaceholderContainer = styled.div`
  margin-top: ${GENERAL_SPACING / 4}px;
`;

const ImageTopHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ImageBaseLineHeaderText = styled.div`
  flex-direction: column;
`;

const DownloadText = styled.div`
  display: flex;
  margin-left: auto;
  align-self: flex-end;
`;

export function ImageCarousel(props: ImageCarouselProps) {
  const [isOverlayActive, setIsOverlayActive] = useState(false);
  const [showOutline, setShowOutline] = useState(true);
  const [showAlternativeImage, setShowAlternativeImage] = useState(false);
  const [index, setIndex] = useState(0);

  const currentImage = props.images.find((i) => props.selectedImageId === i.id);

  // TODO should really refactor imageoverlay/imagecarousel. They depend on each other.
  function handleShowAlternativeImage() {
    setShowAlternativeImage((s) => {
      if (props.setOverlayAlternativeImage) {
        props.setOverlayAlternativeImage(!s);
      }
      return !s;
    });
  }
  return (
    <>
      <StayledCarouselContainer fullwidth={props.isOverlay}>
        {!props.isOverlay ? (
          <ImageTopHeader>
            <ImageBaseLineHeaderText>
              <Typography weight="medium" size="normal">
                {currentImage
                  ? props.titles[currentImage?.lesionTriageId]
                  : null}
              </Typography>
              <Typography size="small" color="medium">
                {props.images[index]
                  ? getStyledDate(props.images[index]?.created)
                  : null}
              </Typography>
            </ImageBaseLineHeaderText>
            <DownloadText>
              <img
                style={{ marginRight: '5px' }}
                src={Icons.Download}
                alt="download"
              />
              <a
                href={
                  showAlternativeImage
                    ? currentImage?.alternativeImageUrl
                    : currentImage?.url
                }
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  size={'normal'}
                  color="success"
                  decoration="underline"
                >
                  Download image
                </Typography>
              </a>
            </DownloadText>
          </ImageTopHeader>
        ) : null}
        {props.images.length ? (
          <Carousel
            selectedItem={props.images.findIndex(
              (img) => img.id === props.selectedImageId
            )}
            infiniteLoop={false}
            showThumbs={false}
            onChange={(i) => {
              if (props.onChange) props.onChange(props.images[i].id);
              setIndex(i);
            }}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            transitionTime={0}
          >
            {props.images.map((image, index) => {
              return (
                <ImageContainer key={image.id}>
                  {!showAlternativeImage ? (
                    <ImageMedia
                      onClick={() => setIsOverlayActive(true)}
                      imageId={image.id}
                      srcs={
                        props.usesOpenSeaDragon
                          ? [
                              image.thumbnailSUrl,
                              image.thumbnailLUrl,
                              image.url,
                            ]
                          : undefined
                      }
                      src={
                        props.imageClickable
                          ? image.thumbnailLUrl
                          : image.thumbnailXlUrl
                      }
                      hasOutline={props.hasOutline}
                      circleOverlayX={
                        props.hasOutline ? image.focalPointX : null
                      }
                      circleOverlayY={
                        props.hasOutline ? image.focalPointY : null
                      }
                      showOutline={showOutline}
                      usesOpenSeaDragon={props.usesOpenSeaDragon}
                    ></ImageMedia>
                  ) : (
                    <ImageMedia
                      onClick={() => setIsOverlayActive(true)}
                      imageId={image.id + '-alternative'}
                      srcs={
                        props.usesOpenSeaDragon
                          ? [
                              image.alternativeImageThumbnailSUrl,
                              image.alternativeImageThumbnailLUrl,
                              image.alternativeImageUrl,
                            ]
                          : undefined
                      }
                      src={
                        props.imageClickable
                          ? image.alternativeImageThumbnailLUrl
                          : image.alternativeImageThumbnailXlUrl
                      }
                      hasOutline={props.hasOutline}
                      circleOverlayX={
                        props.hasOutline ? image.focalPointX : null
                      }
                      circleOverlayY={
                        props.hasOutline ? image.focalPointY : null
                      }
                      showOutline={showOutline}
                      usesOpenSeaDragon={props.usesOpenSeaDragon}
                    ></ImageMedia>
                  )}
                </ImageContainer>
              );
            })}
          </Carousel>
        ) : (
          <PlaceholderContainer>
            <ImageMedia src={props.imagePlaceholder} />
          </PlaceholderContainer>
        )}

        <ArrowsSlider
          count={props.images.length}
          hasAlternativeImage={!!currentImage?.alternativeImageUrl}
          showAlternativeImage={showAlternativeImage}
          onClickAlternativeImage={() => handleShowAlternativeImage()}
          currentImageNo={
            props.images.findIndex((img) => img.id === props.selectedImageId) +
            1
          }
          onClickLeft={() => {
            const idx = props.images.findIndex(
              (img) => img.id === props.selectedImageId
            );
            if (idx < props.images.length)
              props.onChange(props.images[idx - 1].id);
          }}
          onClickRight={() => {
            const idx = props.images.findIndex(
              (img) => img.id === props.selectedImageId
            );
            if (idx >= 0) props.onChange(props.images[idx + 1].id);
          }}
          hasOutline={props.hasOutline}
          showOutline={showOutline}
          onClickOutline={() => setShowOutline(!showOutline)}
          hasImageCompare={props.hasImageCompare}
          onClickCompare={props.onClickCompare}
        />
      </StayledCarouselContainer>
      {props.imageClickable && isOverlayActive ? (
        <ImageOverlay
          images={props.images}
          titles={props.titles}
          selectedImageId={props.selectedImageId}
          onChange={props.onChange}
          hasOutline={props.hasOutline}
          onClose={() => setIsOverlayActive(false)}
          onClickCompare={props.onClickCompare}
        ></ImageOverlay>
      ) : null}
    </>
  );
}
