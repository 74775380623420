import styled from 'styled-components/native';

import { Logger } from '@dermloop/shared';
import { uniq } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Animated, Image as NativeImage } from 'react-native';
import FocalPoint from '../focal-point/focal-point';
import Typography from '../typography/typography';

/* eslint-disable-next-line */
export interface ImageProps {
  srcs: string[];
  aspectRatio?: number;
  focalPoint?: { x: number; y: number };
}

const StyledImage = styled.View<{ aspectRatio: number }>`
  overflow: hidden;
  position: relative;
  width: 100%;
  ${(props) => (props.aspectRatio ? `aspect-ratio:${props.aspectRatio};` : '')}
`;

const StyledPlaceHolder = styled.View<{ background?: string }>`
  flex: 1;
  position: relative;
  ${(props) => (props.background ? `background: ${props.background};` : null)}
  align-items: center;
  justify-content: center;
`;

function ImageRender({
  srcs,
  onError,
}: {
  srcs: string[];
  onError: (message: string, index: number) => void;
}) {
  const uniqueSources = uniq(srcs);
  const opacities = useRef<Animated.Value[]>(
    uniqueSources.map(() => new Animated.Value(0))
  );

  return (
    <StyledPlaceHolder>
      {uniqueSources.map((src, i) => {
        return (
          <Animated.Image
            key={src}
            resizeMode="contain"
            onError={(event) => {
              onError(event?.nativeEvent?.error?.toString(), i);
            }}
            onLoadEnd={() => {
              const currentOpacityAnimation = opacities.current[i];
              if (currentOpacityAnimation) {
                Animated.timing(currentOpacityAnimation, {
                  toValue: 1,
                  duration: i === 0 ? 0 : 1500,
                  useNativeDriver: true,
                }).start();
              } else {
                Logger.error({
                  message: 'Animation not found',
                  context: ImageRender.name,
                });
              }
            }}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              opacity: opacities.current[i],
            }}
            source={{ uri: src }}
          />
        );
      })}
    </StyledPlaceHolder>
  );
}

function BaseImage(props: ImageProps) {
  const [urls, setUrls] = useState<string[]>(props.srcs);
  const [aspectRatio, setAspectRatio] = useState(props.aspectRatio);
  const [error, setError] = useState<string>('');
  const [errors, setErrors] = useState<string[]>(
    Array(props.srcs?.length || 0)
  );

  useEffect(() => {
    if (props.srcs?.every((src) => urls.includes(src))) {
      setUrls(props.srcs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.srcs]);

  useEffect(() => {
    if (urls?.length) {
      // reset error to null if error is set
      setError(() => null);
      NativeImage.getSize(
        urls[0],
        (width, height) => {
          setAspectRatio(() => width / height);
        },
        (error) => {
          setAspectRatio(() => 1);
          setError(() => 'Error occured while getting image metadata');
        }
      );
    }
    if (errors?.filter((e) => e)?.length === urls?.length) {
      setError(() => 'All image sizes failed to load');
    }
  }, [urls, errors]);
  return (
    <StyledImage aspectRatio={aspectRatio}>
      {!error && aspectRatio && props.srcs ? (
        <>
          <ImageRender
            onError={(m, i) =>
              setErrors((errors) => {
                const newErrors = [...errors];
                newErrors[i] = m;
                return newErrors;
              })
            }
            srcs={props.srcs}
          />
          {props.focalPoint ? (
            <FocalPoint x={props.focalPoint.x} y={props.focalPoint.y} />
          ) : null}
        </>
      ) : error ? (
        <StyledPlaceHolder>
          <Typography size="medium-small" color="medium">
            {error}
          </Typography>
        </StyledPlaceHolder>
      ) : (
        <StyledPlaceHolder></StyledPlaceHolder>
      )}
    </StyledImage>
  );
}
export const Image = React.memo(BaseImage);
export default Image;
