import {
  CreateLesionTriageDto,
  LesionTriageDto,
  UpdateLesionTriageDto,
} from '@dermloop/api-dtos';
import { lesionTriageActions } from '../../lesion-triage/lesion-triage.slice';
import { EndpointBuilderType } from '../constants';

export const lesionTriageEndpoints = (builder: EndpointBuilderType) => ({
  getLesionTriage: builder.query<LesionTriageDto, string>({
    query: (id) => `lesion-triages/${id}`,
    providesTags: (result, error, arg) => [
      { type: 'LESION_TRIAGES', id: result.id },
      { type: 'LESION_TRIAGES', id: 'LIST' },
    ],
  }),
  createLesionTriage: builder.mutation<
    LesionTriageDto,
    { data: CreateLesionTriageDto }
  >({
    query: ({ data }) => ({
      url: 'lesion-triages',
      method: 'POST',
      body: data,
    }),
    async onQueryStarted(id, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        dispatch(
          lesionTriageActions.update({ selectedLesionTriageId: data.id })
        );
      } catch {
        console.error('Error creating lesion triage');
      }
    },
    invalidatesTags: (res) => {
      if (res?.id) {
        return [
          { type: 'HEALTH_RECORD', id: 'LIST' },
          { type: 'HEALTH_RECORD', id: res.healthRecordId },
          { type: 'LESION_HISTORY', id: 'LIST' },
          { type: 'LESION_TRIAGES', id: 'LIST' },
          { type: 'RECENT_LESIONS' },
          { type: 'RECENT_LESIONS2' },
        ];
      }
      return [{ type: 'LESION_TRIAGES', id: 'LIST' }];
    },
  }),
  updateLesionTriage: builder.mutation<
    LesionTriageDto,
    { id: string; data: UpdateLesionTriageDto }
  >({
    query: ({ id, data }) => ({
      url: `lesion-triages/${id}`,
      method: 'PATCH',
      body: data,
    }),
    invalidatesTags: (res) => {
      if (res?.id) {
        return [
          { type: 'HEALTH_RECORD', id: 'LIST' },
          { type: 'HEALTH_RECORD', id: res.healthRecordId },
          { type: 'LESION_HISTORY', id: 'LIST' },
          { type: 'LESION_TRIAGES', id: 'LIST' },
          { type: 'RECENT_LESIONS' },
          { type: 'RECENT_LESIONS2' },
        ];
      }
      return [{ type: 'LESION_TRIAGES', id: 'LIST' }];
    },
  }),
});
