import { DermloopPushDeviceType } from '@dermloop/static-values';
import {
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export class PushDeviceDto {
  @IsOptional()
  @IsUUID('all')
  id?: string;

  @IsNotEmpty()
  @IsString()
  deviceName?: string;

  @IsNotEmpty()
  @IsString()
  pushNotificationToken?: string;

  @IsNotEmpty()
  @IsString()
  appInstallationId?: string;

  @IsNotEmpty()
  @IsEnum(DermloopPushDeviceType)
  deviceType?: DermloopPushDeviceType;
}

export class UpdatePushDeviceDto {
  @IsOptional()
  @IsString()
  deviceName?: string;

  @IsOptional()
  @IsString()
  pushNotificationToken?: string;

  @IsOptional()
  @IsString()
  appInstallationId?: string;

  @IsOptional()
  @IsEnum(DermloopPushDeviceType)
  deviceType?: DermloopPushDeviceType;
}
