import { LesionHistoryDto } from '@dermloop/api-dtos';
import { EndpointBuilderType } from '../constants';

export const lesionHistoryEndpoints = (builder: EndpointBuilderType) => ({
  getLesionHistory: builder.query<LesionHistoryDto[], string>({
    query: (id) => `lesion-history/${id}`,
    providesTags: (result, error, arg) => [
      { type: 'LESION_HISTORY', id: 'LIST' },
    ],
  }),
});
