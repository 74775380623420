import { Logger } from '@dermloop/shared';
import { AlertProvider } from '@dermloop/ui/components';
import { CustomFonts } from '@dermloop/ui/web/util';
import { ObjectUtilities } from '@dermloop/utils';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Fragment, StrictMode } from 'react';
import { AppRegistry } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';
import { App } from './app/app';
import Auth0ProviderWithHistory from './app/auth0/auth0-provider-with-history';
import store from './app/store/store';
import { environment } from './environments/environment';

Logger.setLevel('trace');
if (environment.ENV !== 'development') {
  Sentry.init({
    dsn: 'https://fea5fbd4b3dc46d0bbdd39774b217c8f@o530277.ingest.sentry.io/5933873',
    environment: environment.ENV,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Event is unpacked, as enture object cannot be converted to json string and back again
    beforeSend: (event) => ({
      ...event,
      exception: ObjectUtilities.scrubObject(event.exception, 'object'),
    }),
  });
}
const GlobalStyles = createGlobalStyle`
  body {
    padding:0;
    overflow-y:scroll;
    font-family: Inter, Helvetica, Arial, sans-serif;
  }
  html, body, #root {
    height:100%;
    display:flex;
    width:100%;
    margin: 0;
  }

  #root > div {
    z-index:unset;
  }

  #root > div > div{
    z-index:unset;
  }
`;

function Root() {
  return (
    <Fragment>
      <GlobalStyles />
      <CustomFonts />
      <BrowserRouter>
        <StrictMode>
          <SafeAreaProvider>
            <AlertProvider>
              <Provider store={store}>
                <Auth0ProviderWithHistory>
                  <AlertProvider>
                    <App />
                    <ToastContainer />
                  </AlertProvider>
                </Auth0ProviderWithHistory>
              </Provider>
            </AlertProvider>
          </SafeAreaProvider>
        </StrictMode>
      </BrowserRouter>
    </Fragment>
  );
}

AppRegistry.registerComponent('main', () => Root);
AppRegistry.runApplication('main', {
  rootTag: document.getElementById('root'),
});
