import { Sizes } from '@dermloop/ui/util';

import styled from 'styled-components';
import Button from '../button/button';
import Page from '../page/page';

/* eslint-disable-next-line */
export interface ErrorPageProps {
  message?: string;
  actionText?: string;
  action?: () => void;
}

const Heading = styled.div`
  font-size: ${Sizes.LARGE_HEADING_FONT_SIZE};
`;

export function ErrorPage(props: ErrorPageProps) {
  return (
    <Page>
      <Heading>{props.message}</Heading>

      {props.action && props.actionText ? (
        <Button
          name={props.actionText}
          fill="outline"
          color="dark"
          textColor="dark"
          onPress={() => {
            if (props.action) props.action();
          }}
        />
      ) : null}
    </Page>
  );
}

export default ErrorPage;
