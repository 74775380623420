import { GENERAL_SPACING } from '@dermloop/ui/util';
import { format } from 'date-fns';
import { View } from 'react-native';
import styled from 'styled-components/native';
import Alert from '../alert/alert';
import { Letters, Typography } from '../typography/typography';

export interface UserRequestedDeleteAlertProps {
  // If not null, will show alert
  deleteRequestedDate: Date;
  // Function to call if user clicks the logout button in alert
  onLogout: () => void;
  // Function to call if user requests restore account in alert
  onRestore: () => void;
}

export function UserRequestedDeleteAlert(props: UserRequestedDeleteAlertProps) {
  return props.deleteRequestedDate != null ? (
    <Alert
      title={'Restore account?'}
      showCloseIcon={false}
      disableCloseOnBackdropPress={true}
      noContentPadding={false}
      onClose={props.onLogout}
      content={
        <View style={{ flex: 1 }}>
          <StyledFirstParagraph>
            <Typography size="normal">
              You have requested that we delete your account on
              <Letters weight="bold" noTrailingSpace>
                {format(
                  typeof props.deleteRequestedDate === 'string'
                    ? new Date(props.deleteRequestedDate)
                    : props.deleteRequestedDate,
                  'dd-MM-yyyy HH:mm'
                )}
              </Letters>
              .
            </Typography>
          </StyledFirstParagraph>
          <Typography size="normal">
            Your account has
            <Letters weight="bold">NOT</Letters>
            yet been deleted. You can therefore still restore your account with
            all data.
          </Typography>
        </View>
      }
      actions={[
        {
          title: 'Log out',
          color: 'primary',
          type: 'outline',
          onPress: (close) => {
            props.onLogout();
          },
        },
        {
          title: 'Restore',
          color: 'primary',
          onPress: (close) => {
            props.onRestore();
          },
        },
      ]}
    />
  ) : null;
}

const StyledFirstParagraph = styled.View`
  margin-bottom: ${GENERAL_SPACING}px;
`;
