import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { BaseDto } from '../base.dto';
import { UserDto } from '../user.dto';

export class CreateLesionDetailsDto {
  @IsNotEmpty()
  @IsString()
  lesionId: string;

  @IsNotEmpty()
  @IsString({ each: true })
  treatments: string[];

  @IsString()
  otherTreatments: string;

  @IsNotEmpty()
  @IsString({ each: true })
  symptoms: string[];

  @IsString()
  otherSymptoms: string;

  @IsNotEmpty()
  @IsString({ each: true })
  appearanceChanges: string[];

  @IsString()
  otherAppearanceChanges: string;

  @IsString()
  @IsNotEmpty()
  ageId: string;

  @IsString()
  @IsNotEmpty()
  appearanceChangeAgeId: string;

  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;
}

export class UpdateLesionDetailsDto {
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  lesionId: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString({ each: true })
  treatments: string[];

  @IsString()
  otherTreatments: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString({ each: true })
  symptoms: string[];

  @IsString()
  otherSymptoms: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString({ each: true })
  appearanceChanges: string[];

  @IsString()
  otherAppearanceChanges: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  ageId: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;
}

export class LesionDetailsDto implements BaseDto {
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;
  created?: string;
  updated?: string;
  deleted?: string;

  @IsNotEmpty()
  @IsString()
  lesionId: string;

  @IsNotEmpty()
  @IsBoolean()
  hasTreatments: boolean;

  @IsNotEmpty()
  @IsString({ each: true })
  treatments: string[];

  @IsNotEmpty()
  @IsString()
  otherTreatments: string;

  @IsNotEmpty()
  @IsBoolean()
  hasSymptoms: boolean;

  @IsNotEmpty()
  @IsString({ each: true })
  symptoms: string[];

  @IsNotEmpty()
  @IsString()
  otherSymptoms: string;

  @IsNotEmpty()
  @IsString()
  symptomAgeId: string;

  @IsNotEmpty()
  @IsBoolean()
  hasAppearanceChanges: boolean;

  @IsNotEmpty()
  @IsString({ each: true })
  appearanceChanges: string[];

  @IsNotEmpty()
  @IsString()
  otherAppearanceChanges: string;

  @IsNotEmpty()
  @IsString()
  otherAppearanceChangeAge: string;

  @IsNotEmpty()
  @IsString()
  appearanceChangeAgeId: string;

  @IsNotEmpty()
  @IsString()
  otherAge: string;

  @IsString()
  @IsNotEmpty()
  ageId: string;

  @IsNotEmpty()
  @IsOptional()
  @IsString({ each: true })
  macroscopicInspection: string[];

  @IsNotEmpty()
  @IsString()
  otherMacroscopicInspection: string;

  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;
}
