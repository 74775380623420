import { Colors, NATIVE_BORDER_WIDTH } from '@dermloop/ui/util';
import { useEffect, useState } from 'react';
import { LayoutRectangle } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import styled from 'styled-components/native';

/* eslint-disable-next-line */
export interface ToggleTab {
  element: JSX.Element;
}

export interface ToggleProps {
  toggleTabs: ToggleTab[];
  onChange: () => void;
  isToggleOn?: boolean;
  display?: 'inline' | 'block';
  color?: 'success' | 'tertiary';
}

type ToggleLayout = Omit<LayoutRectangle, 'x' | 'y'>;

const togglePadding = 3;

// eslint-disable-next-line jsdoc/require-jsdoc
export function Toggle(props: ToggleProps) {
  const { display, toggleTabs, onChange, isToggleOn, color } = props;
  const [toggleLayout, setToggleLayout] = useState<ToggleLayout>();

  const tabTranslate = useSharedValue(isToggleOn ? 1 : 0);

  // sync tabTranslate with outside state
  useEffect(() => {
    if (tabTranslate.value !== (isToggleOn ? 1 : 0))
      tabTranslate.value = isToggleOn ? 1 : 0;
  }, [isToggleOn, tabTranslate]);

  const activeToggleWidth: ToggleLayout = {
    // Width of toggle - padding and border on left and right / number of tabs
    width:
      ((toggleLayout?.width || 0) - (NATIVE_BORDER_WIDTH + togglePadding) * 2) /
      toggleTabs.length,
    // Height of toggle - padding and border on top and bottom
    height:
      (toggleLayout?.height || 0) - (NATIVE_BORDER_WIDTH + togglePadding) * 2,
  };

  const translateValue = useDerivedValue(() => {
    return withTiming(tabTranslate.value * activeToggleWidth.width, {
      duration: 150,
    });
  });

  const activeTabStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: translateValue.value }],
    };
  });

  return (
    <StyledToggle
      onLayout={({ nativeEvent }) =>
        setToggleLayout({
          width: nativeEvent.layout.width,
          height: nativeEvent.layout.height,
        })
      }
      {...props}
    >
      <ActiveToggle
        toggleLayout={activeToggleWidth}
        color={color}
        style={activeTabStyle}
      />

      {toggleTabs.map((tab, index) => (
        <Segment
          key={index}
          onPress={() => {
            if (tabTranslate.value === index) return;
            tabTranslate.value = index;
            onChange();
          }}
          display={display}
        >
          {tab.element}
        </Segment>
      ))}
    </StyledToggle>
  );
}

export default Toggle;

function getBorderColor(color: 'success' | 'tertiary') {
  switch (color) {
    case 'success':
      return Colors.BRAND_SUCCESS;
    case 'tertiary':
      return Colors.BRAND_TERTIARY;
    default:
      return Colors.BRAND_TERTIARY;
  }
}

const StyledToggle = styled.View<ToggleProps>`
  display: flex;
  flex-direction: row;
  border-radius: 100px;

  border-width: ${NATIVE_BORDER_WIDTH}px;
  border-color: ${({ color }) => getBorderColor(color)};
  padding: ${togglePadding}px;
  ${({ display }) => (display === 'block' ? 'width: 100%;' : '')}
`;

const Segment = styled.Pressable<{
  display: 'inline' | 'block';
}>`
  display: flex;
  ${({ display }) => (display === 'block' ? 'flex: 1' : '')}
  align-items: center;
  background-color: 'transparent';
  border-radius: 100px;
  border-width: 2px;
  border-color: transparent;
`;

const ActiveToggle = styled(Animated.View)<{
  toggleLayout: ToggleLayout;
  color: 'success' | 'tertiary';
}>`
  position: absolute;
  top: ${togglePadding}px;
  left: ${togglePadding}px;
  width: ${({ toggleLayout }) => toggleLayout.width}px;
  height: ${({ toggleLayout }) => toggleLayout.height}px;
  border-width: 2px;
  border-radius: 100px;
  border-color: ${({ toggleLayout, color }) =>
    toggleLayout.width <= 0 ? 'transparent' : getBorderColor(color)};
  background-color: ${({ toggleLayout, color }) =>
    toggleLayout.width <= 0
      ? 'transparent'
      : color === 'success'
      ? Colors.BRAND_SUCCESS
      : Colors.BRAND_WHITE};
`;
