import { IsBoolean } from 'class-validator';
import { NamedEntityDto } from '../clinical-details';

export class ImageQualityRatingReasonDto extends NamedEntityDto {
  @IsBoolean()
  dermoscopicImageReasonOnly: boolean;

  @IsBoolean()
  requiresUserInput: boolean;
}
