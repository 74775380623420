import { ClassConstructor, plainToClassFromExist } from 'class-transformer';
import { validateSync } from 'class-validator';
import { isEmpty } from 'lodash';
import Config, { NativeConfig } from 'react-native-config';
import { ConfigurationException } from '@dermloop/shared';

export function createAndValidateEnvironment<T extends object>(
  enviromentType: ClassConstructor<T>
) {
  const transformedEnvironment = new enviromentType();
  const allowedEnvironmentKeys = Object.getOwnPropertyNames(
    transformedEnvironment
  );

  const env = !isEmpty(Config) ? Config : process.env;
  console.log('Environment', env);
  const trimmedEnv = Object.keys(env).reduce((acc, key) => {
    const trimmedKey = key.replace('NX_', '');
    if (allowedEnvironmentKeys.includes(trimmedKey)) {
      acc[trimmedKey] = env[key];
    }

    return acc;
  }, {} as NativeConfig);

  plainToClassFromExist(transformedEnvironment, trimmedEnv);
  const errors = validateSync(transformedEnvironment);

  if (errors?.length) {
    throw new ConfigurationException(JSON.stringify(errors));
  }

  console.log('Created and validated environment', transformedEnvironment);

  return transformedEnvironment;
}
