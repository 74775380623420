import { Exclude } from 'class-transformer';
import {
  IsBoolean,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateNested,
} from 'class-validator';
import { BaseDto, BaseUpdateDto } from '../base.dto';
import { NamedEntityDto } from '../clinical-details/named.dto';
import { DiagnosisDto } from '../diagnoses';
import { ModelViewerLocation } from '../model-viewer-location.dto';
import { TrainingLesionImageDto } from './training-lesion-image.dto';
import { TrainingPatientDto } from './training-patient.dto';

export interface TrainingLesionDto extends BaseDto {
  legacyIdentifier?: string;
  patientAgeYears?: number;
  dermoscopyFollowTimeMonths?: number;
  preciseLocation?: ModelViewerLocation;
  histopathologyConducted: boolean;
  teledermatologyConducted: boolean;
  sentinelNodeBiopsyConducted: boolean;
  recurrence?: boolean;
  recurrenceTimeMonths?: number;
  totalTimeOfFollowUpMonths?: number;
  mcq: boolean;
  status: NamedEntityDto;
  skinType?: NamedEntityDto;
  patient: TrainingPatientDto;
  location?: NamedEntityDto;
  // Actual diagnosis of training lesion. Revised diagnosis takes precedence
  diagnosis?: DiagnosisDto;
  // Actual secondary diagnosis of training lesion. Revised secondary diagnosis takes precedence
  secondaryDiagnosis?: DiagnosisDto;
  source?: NamedEntityDto;
  dermoscopicImage?: TrainingLesionImageDto;
  clinicalImage?: TrainingLesionImageDto;
  images?: TrainingLesionImageDto[];
  anonymized?: boolean;
}

/**
 * DTO used for updating the focal point of a training lesion image.
 * Used in UpdateTrainingLesionDto.
 */
export class UpdateTrainingLesionFocalPointDto {
  @IsUUID()
  imageId: string;

  @IsNumber()
  @Min(0)
  @Max(1)
  x?: number;

  @IsNumber()
  @Min(0)
  @Max(1)
  y?: number;
}

/**
 * DTO for updating an existing training lesion.
 */
export class UpdateTrainingLesionDto
  extends BaseUpdateDto
  implements Partial<TrainingLesionDto>
{
  @IsOptional()
  @IsString()
  legacyIdentifier?: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  patientAgeYears?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  dermoscopyFollowTimeMonths?: number;

  @IsOptional()
  @ValidateNested()
  preciseLocation?: ModelViewerLocation;

  @IsOptional()
  @IsBoolean()
  histopathologyConducted?: boolean;

  @IsOptional()
  @IsBoolean()
  sentinelNodeBiopsyConducted?: boolean;

  @IsOptional()
  @IsBoolean()
  recurrence?: boolean;

  @IsOptional()
  @IsNumber()
  @Min(0)
  recurrenceTimeMonths?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  totalTimeOfFollowUpMonths?: number;

  @IsOptional()
  @IsBoolean()
  mcq?: boolean;

  @IsOptional()
  @IsUUID()
  statusId?: string;

  @Exclude()
  status?: never;

  @Exclude()
  patient?: never;

  // If set, updates the existing patients gender
  @IsOptional()
  @IsUUID()
  genderId?: string;

  @IsOptional()
  @IsUUID()
  locationId?: string;

  @Exclude()
  location?: never;

  @IsOptional()
  @IsUUID()
  revisedDiagnosisId?: string;

  @Exclude()
  diagnosis?: never;

  @IsOptional()
  @IsUUID()
  revisedSecondaryDiagnosisId?: string;

  @Exclude()
  secondaryDiagnosis?: never;

  @IsOptional()
  @IsUUID()
  skinTypeId?: string;

  @Exclude()
  skinType?: never;

  @Exclude()
  source?: never;

  @Exclude()
  dermoscopicImage?: never;

  @Exclude()
  clinicalImage?: never;

  @IsOptional()
  @IsBoolean()
  anonymized?: boolean;

  @IsOptional()
  @ValidateNested()
  updatedFocalPoint?: UpdateTrainingLesionFocalPointDto;
}
