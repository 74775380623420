import { IsArray, IsOptional, IsUUID } from 'class-validator';

export class NotificationPayloadDto {
  // Any pathological assessments associated with notification in notification overview
  @IsOptional()
  @IsArray()
  @IsUUID('all', { each: true })
  pathologicalAssessmentIds?: string[];

  @IsOptional()
  @IsArray()
  @IsUUID('all', { each: true })
  teledermatologicalAssessmentIds?: string[];

  @IsOptional()
  @IsArray()
  @IsUUID('all', { each: true })
  lesionIds?: string[];

  @IsOptional()
  @IsUUID()
  trainingAssessmentId?: string;
}
