import { Exclude } from 'class-transformer';
import { IsBoolean } from 'class-validator';
import { BaseCreateDto, BaseUpdateDto } from './base.dto';

export interface PolarizedImageDto {
  id: string;

  isPolarized: boolean;

  created: Date;

  uploadUrl?: string;

  downloadUrl?: string;
}

export class CreatePolarizedImageDto
  extends BaseCreateDto
  implements Partial<PolarizedImageDto>
{
  @IsBoolean()
  isPolarized: boolean;

  @Exclude()
  uploadUrl?: never;

  @Exclude()
  downloadUrl?: never;
}

export class UpdatePolarizedImageDto
  extends BaseUpdateDto
  implements Partial<PolarizedImageDto>
{
  @IsBoolean()
  isPolarized: boolean;

  @Exclude()
  uploadUrl?: never;

  @Exclude()
  downloadUrl?: never;
}
