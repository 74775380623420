import { SkinTypeDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const skinTypeEndpoints = (builder: EndpointBuilderType) => ({
  getSkinTypes: builder.query<SkinTypeDto[], void>({
    query: () => `skin-types`,
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result, error, arg) => [{ type: 'SKIN_TYPES', id: 'LIST' }],
  }),
});
