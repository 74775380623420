import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { hoursToSeconds, minutesToSeconds } from 'date-fns';
import { DermloopBaseApiTag } from './tag-types';

export type ApiNameType = 'dermloopApi';
export type EndpointBuilderType = EndpointBuilder<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {},
    FetchBaseQueryMeta
  >,
  DermloopBaseApiTag,
  ApiNameType
>;

export const CLINICAL_DETAILS_CACHE_TIME_SECONDS = hoursToSeconds(10);
export const CLINICAL_DETAILS_REFETCH_TIME_SECONDS = minutesToSeconds(30);
