import { UserDto } from '../user.dto';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class CreatePatientDetailsDto {
  @IsOptional()
  @IsBoolean()
  pregnancyWithinLast6Months?: boolean;

  @IsOptional()
  @IsBoolean()
  formerSkinCancer?: boolean;

  @IsOptional()
  @IsBoolean()
  formerMelanoma?: boolean;

  @IsOptional()
  @IsBoolean()
  formerCarcinoma?: boolean;

  @IsOptional()
  @IsString()
  otherFormerSkinCancer?: string;

  @IsOptional()
  @IsBoolean()
  familyHistoryOfMelanoma?: boolean;

  @IsOptional()
  @IsBoolean()
  hasRiskFactors?: boolean;

  @IsOptional()
  @IsString({ each: true })
  riskFactors?: string[];

  @IsOptional()
  @IsString()
  otherRisks?: string;

  @IsOptional()
  @IsBoolean()
  hasObjectiveFindings?: boolean;

  @IsOptional()
  @IsString({ each: true })
  objectiveFindings?: string[];

  @IsOptional()
  @IsString()
  otherObjectiveFindings?: string;

  @IsString()
  @IsNotEmpty()
  healthRecordId: string;

  @IsOptional()
  @IsString()
  skinTypeId?: string;
}

export class UpdatePatientDetailsDto {
  created?: string;

  @IsOptional()
  @IsBoolean()
  pregnancyWithinLast6Months?: boolean;

  @IsOptional()
  @IsBoolean()
  formerSkinCancer?: boolean;

  @IsOptional()
  @IsBoolean()
  formerMelanoma?: boolean;

  @IsOptional()
  @IsBoolean()
  formerCarcinoma?: boolean;

  @IsOptional()
  @IsString()
  otherFormerSkinCancer?: string;

  @IsOptional()
  @IsBoolean()
  familyHistoryOfMelanoma?: boolean;

  @IsOptional()
  @IsBoolean()
  hasRiskFactors?: boolean;

  @IsOptional()
  @IsString({ each: true })
  riskFactors?: string[];

  @IsOptional()
  @IsString()
  otherRisks?: string;

  @IsOptional()
  @IsBoolean()
  hasObjectiveFindings?: boolean;

  @IsOptional()
  @IsString({ each: true })
  objectiveFindings?: string[];

  @IsOptional()
  @IsString()
  otherObjectiveFindings?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  medicalUnitId?: string;

  @IsOptional()
  @IsString()
  skinTypeId?: string;
}

export class PatientDetailsDto {
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;
  created?: string;
  updated?: string;
  deleted?: string;

  @IsOptional()
  @IsBoolean()
  pregnancyWithinLast6Months?: boolean;

  @IsOptional()
  @IsBoolean()
  formerSkinCancer?: boolean;

  @IsOptional()
  @IsBoolean()
  formerMelanoma?: boolean;

  @IsOptional()
  @IsBoolean()
  formerCarcinoma?: boolean;

  @IsOptional()
  @IsString()
  otherFormerSkinCancer?: string;

  @IsOptional()
  @IsBoolean()
  familyHistoryOfMelanoma?: boolean;

  @IsOptional()
  @IsBoolean()
  hasRiskFactors?: boolean;

  @IsNotEmpty()
  @IsOptional()
  @IsString({ each: true })
  riskFactors?: string[];

  @IsOptional()
  @IsString()
  otherRisks?: string;

  @IsOptional()
  @IsBoolean()
  hasObjectiveFindings?: boolean;

  @IsOptional()
  @IsNotEmpty()
  @IsString({ each: true })
  objectiveFindings?: string[];

  @IsOptional()
  @IsString()
  otherObjectiveFindings?: string;

  @IsString()
  @IsNotEmpty()
  medicalUnitId: string;

  @IsString()
  @IsNotEmpty()
  healthRecordId: string;

  @IsNotEmpty()
  @IsString()
  skinTypeId: string;
}
