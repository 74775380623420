import { Colors, Sizes, useOnClickOutside } from '@dermloop/ui/util';
import { ReactElement, useRef, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';
import { PressableState } from '../button/button';
import Icon from '../icon/icon';
import { webSpecificValue } from '../utils';

/**
 * Button that contains a dropdown. When clicking on the button, the props.dropdownElement
 * is displayed under the button.
 * The dropdown element has height and width 0px, such that it overlays any other elements on the page.
 */

/* eslint-disable-next-line */
export interface ButtonDropdownProps {
  dropdownElement: JSX.Element;
  type?: 'outline' | 'solid' | 'clear';
  display?: 'block' | 'inline' | 'inline-narrow';
  loading?: boolean;
  margin?: 'vertical' | 'horizontal';
  disabled?: boolean;
  // Function called before showing dropdown element
  onPress?: () => void;
  color?: 'primary' | 'secondary' | 'danger';
  children: JSX.Element | string;
  shape?: 'round' | 'square';
  noShadow?: boolean;
  // If provided, overrules default outline color
  outlineColor?: string;
  // CSS width (e.g. 80px). If provided, overrules all other button width properties
  width?: string;
}

const StyledButtonDropdown = styled.View<{
  background: string;
  marginTop: boolean;
  outline: string;
  display: 'block' | 'inline' | 'inline-narrow';
  backgroundOpacity?: number;
  noShadow?: boolean;
  roundedBorders?: boolean;
  width?: string;
}>`
  height: ${Sizes.BUTTON_HEIGHT}px;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  align-items: center;
  ${webSpecificValue(`z-index: unset;`)}
  ${(props) =>
    props.marginTop ? `margin-top:${Sizes.GENERAL_SPACING}px;` : ''}
  border-width: ${Sizes.NATIVE_BORDER_WIDTH}px;
  ${(props) =>
    props.roundedBorders
      ? 'border-radius:' + Sizes.BUTTON_BORDER_RADIUS + 'px'
      : ''};
  border-color: ${(props) => props.outline};
  background-color: ${(props) => props.background};
  ${webSpecificValue(
    `transition: background ease 0.2s, box-shadow ease 0.2s;`
  )};

  align-self: ${(props) => (props.display === 'block' ? 'stretch' : 'auto')};
  ${(props) =>
    props.noShadow ? null : 'box-shadow:0px 2px 5px rgba(0, 0, 0, 0.20)'};
  ${(props) =>
    props.backgroundOpacity
      ? 'filter: opacity(' + props.backgroundOpacity + ')'
      : null}

  ${webSpecificValue(`user-select: none;`)};
  ${(props) => {
    if (props.width) {
      return `width: ${props.width};`;
    }
    if (props.display === 'inline') {
      return `width: ${Sizes.BUTTON_WIDTH}px;`;
    }
    return '';
  }};
`;

const StyledButtonDropdownWrapper = styled.View`
  position: relative;
  ${webSpecificValue(`z-index: unset;`)}
  margin-right: ${Sizes.GENERAL_SPACING}px;
`;

const StyledButtonDropdownText = styled.Text<{
  textColor: string;
  hidden: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.textColor};
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  font-weight: ${Sizes.BUTTON_FONT_WEIGHT};
  font-size: ${Sizes.BUTTON_FONT_SIZE}px;
`;
const StyledButtonDropdownIcon = styled.Text`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

/**
 * Styling that ensures that the dropdown does not affect the buttons width and parent container size.
 */
const StyledDropdownElement = styled.View`
  position: absolute;
  display: flex;
  background: ${Colors.BRAND_WHITE};
  min-width: 150px;
  z-index: 9999;
  top: ${Sizes.BUTTON_HEIGHT + Sizes.GENERAL_SPACING / 4}px;
`;

const StyledDropdownPressable = styled.Pressable`
  ${webSpecificValue(`z-index: unset;`)}
`;

const getColor = (
  type?: 'primary' | 'secondary' | 'danger',
  pressed?: boolean,
  hovered?: boolean
) => {
  if (type === 'danger') return Colors.DANGER;
  else if (type === 'secondary') {
    if (pressed) return Colors.PRESS_EXTRA_BUTTON;
    else if (hovered) return Colors.HOVER_EXTRA_BUTTON;
    else return Colors.BRAND_WHITE;
  } else return Colors.BRAND_SUCCESS;
};

const getColors = (
  props: ButtonDropdownProps,
  pressed: boolean,
  hovered: boolean
) => {
  if (props.disabled) {
    return {
      background: Colors.TRANSPARENT,
      text: Colors.BRAND_TERTIARY,
      outline:
        props.type === 'clear' ? Colors.TRANSPARENT : Colors.BRAND_TERTIARY,
    };
  }

  let background = Colors.TRANSPARENT;
  if (!props.type || props.type === 'solid') {
    background = getColor(props.color, pressed, hovered);
    if (!props.color || props.color === 'primary') {
      if (pressed) background = Colors.PRESS_SOLID_PRIMARY_BUTTON;
      else if (hovered) background = Colors.HOVER_SOLID_PRIMARY_BUTTON;
    } else if (props.color === 'secondary') {
      if (pressed) background = Colors.PRESS_SOLID_SECONDARY_BUTTON;
      else if (hovered) background = Colors.HOVER_SOLID_SECONDARY_BUTTON;
    } else if (props.color === 'danger') {
      if (pressed) background = Colors.PRESS_SOLID_DANGER_BUTTON;
      else if (hovered) background = Colors.HOVER_SOLID_DANGER_BUTTON;
    }
  } else if (props.type === 'outline') {
    if (!props.color || props.color === 'primary') {
      if (pressed) background = Colors.PRESS_SUCCESS_BUTTON;
      else if (hovered) background = Colors.HOVER_SUCCESS_BUTTON;
    } else if (props.color === 'secondary') {
      if (pressed) background = Colors.PRESS_PRIMARY_BUTTON;
      else if (hovered) background = Colors.HOVER_PRIMARY_BUTTON;
    }
  } else if (props.type === 'clear') {
    if (pressed) background = Colors.PRESS_SUCCESS_BUTTON;
    else if (hovered) background = Colors.HOVER_SUCCESS_BUTTON;
  }

  let outline;
  if (props.type === 'clear') outline = Colors.TRANSPARENT;
  else outline = getColor(props.color);

  const text =
    props.type === 'outline'
      ? outline
      : props.color === 'secondary' || !props.type
      ? Colors.BRAND_SUCCESS
      : Colors.BRAND_WHITE;

  return { background, text, outline };
};

const getDropdownSymbol = (expanded: boolean) =>
  expanded ? <Icon icon={'ArrowUp'} /> : <Icon icon={'ArrowDown'} />;

export function ButtonDropdown(props: ButtonDropdownProps) {
  // Used for keeping state of dropdown
  const [expanded, setExpanded] = useState(false);

  // Ref for the dropdown button, used for closing when user clicks outside
  const ref = useRef();

  // Start listening for clicks outside button
  useOnClickOutside(ref, () => setExpanded(false));

  const onPress = () => {
    setExpanded(!expanded);
    // Call the onPress function of props if provided
    if (props.onPress) {
      props.onPress();
    }
  };

  return (
    <StyledButtonDropdownWrapper ref={ref}>
      <StyledDropdownPressable
        onPress={() => !props.disabled && !props.loading && onPress()}
        pointerEvents={props.disabled ? 'none' : 'auto'}
      >
        {({ pressed, hovered, focused }: PressableState): ReactElement => {
          const { background, text, outline } = getColors(
            props,
            pressed,
            hovered
          );

          return (
            <StyledButtonDropdown
              background={background}
              marginTop={props.margin && props.margin === 'vertical'}
              outline={props.outlineColor || outline}
              display={props.display || 'inline'}
              noShadow={pressed || hovered || props.noShadow || props.disabled}
              roundedBorders={props.shape !== 'square' ? true : false}
              width={props.width}
            >
              {props.loading ? (
                <ActivityIndicator
                  style={{ position: 'absolute' }}
                  size="small"
                />
              ) : null}
              <StyledButtonDropdownText hidden={props.loading} textColor={text}>
                {props.children}

                <StyledButtonDropdownIcon>
                  {getDropdownSymbol(expanded)}
                </StyledButtonDropdownIcon>
              </StyledButtonDropdownText>
            </StyledButtonDropdown>
          );
        }}
      </StyledDropdownPressable>
      {expanded && props.dropdownElement ? (
        <StyledDropdownElement>{props.dropdownElement}</StyledDropdownElement>
      ) : null}
    </StyledButtonDropdownWrapper>
  );
}

export default ButtonDropdown;
