import {
  ArrayNotEmpty,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
} from 'class-validator';
import { DiagnosisDto } from './diagnosis.dto';

export interface DermoscopicCriteriaDto {
  id: string;
  name: string;
  altName?: string;
  diagnoses?: DiagnosisDto[];
}

export class DermoscopicCriteriaSearchQueryDto {
  @IsNumber()
  @Min(0)
  skip: number;

  @IsNumber()
  @Min(1)
  take: number;

  @IsOptional()
  @IsString()
  searchText?: string;
}

export class CreateDermoscopicCriteriaDto
  implements Partial<DermoscopicCriteriaDto>
{
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsOptional()
  @IsString()
  altName?: string;
}

export class UpdateDermoscopicCriteriaDto
  implements Partial<DermoscopicCriteriaDto>
{
  @IsOptional()
  @IsString()
  @IsNotEmpty()
  name?: string;

  @IsOptional()
  @IsString()
  altName?: string;
}

/**
 * DTO for adding diagnoses to a dermoscopic criteria.
 */
export class AddDiagnosisToDermoscopicCriteriaDto {
  @ArrayNotEmpty()
  @IsUUID('all', { each: true })
  diagnosisIds: string[];
}
