import { Colors, NATIVE_BORDER_WIDTH } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import { OptionTextFieldInput } from './option-text-field-input';

/* eslint-disable-next-line */
export interface OptionTextFieldProps {
  readonly?: boolean;
  placeholder: string;
  hideInput?: boolean;
  value?: string;
  onChangeText: (value: string) => void;
  multiline?: boolean;
}

export function OptionTextField(props: OptionTextFieldProps) {
  return (
    <StyledOptionTextField>
      <OptionTextFieldInput {...props} />
    </StyledOptionTextField>
  );
}

export default OptionTextField;

const StyledOptionTextField = styled.View`
  display: flex;
  flex-direction: row;
  border-top-width: ${NATIVE_BORDER_WIDTH}px;
  border-top-color: ${Colors.BRAND_GRAY_MEDIUM};
  border-bottom-width: ${NATIVE_BORDER_WIDTH}px;
  border-bottom-color: ${Colors.BRAND_GRAY_MEDIUM};
`;
