import { Colors, Sizes } from '@dermloop/ui/util';
import styled from 'styled-components';
import { OpenSeaDragonViewer } from '../open-sea-dragon-viewer/open-sea-dragon-viewer';

export interface ImageMediaProps {
  src?: string;
  /**
   * List of urls that are loaded in order. Used to show low quality image followed by higher qualities later.
   * Takes precendece over src if both specified.
   * Only supported with usesOpenSeaDragon enabled.
   */
  srcs?: string[];
  imageId?: string;
  bottomLeftOverlay?: JSX.Element;
  onClick?: () => void;
  hasOutline?: boolean;
  showOutline?: boolean;
  circleOverlayX?: number;
  circleOverlayY?: number;
  usesOpenSeaDragon?: boolean;
  style?;
}

const StyledImageMedia = styled.div`
  overflow: hidden;
  height: 0;
  width: 100%;
  background-size: cover;
  background-position: center;
  padding-top: 100%;
  background-repeat: no-repeat;
  border-radius: ${Sizes.BORDER_RADIUS}px;
`;

const StyledImageMediaContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;

const BottomLeftOverlay = styled.div`
  position: absolute;
  padding-bottom: 100%;
  left: 0.5rem;
  bottom: 0.5rem;
`;

const CircleOverlay = styled.div`
  position: absolute;
  padding: 5rem;
  border: 2px solid ${Colors.BRAND_WHITE};
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

export function ImageMedia(props: ImageMediaProps) {
  if (!props.src) return null;
  return (
    <StyledImageMediaContainer style={props.style}>
      {props.usesOpenSeaDragon ? (
        <OpenSeaDragonViewer
          imageId={props.imageId}
          imageUrl={props.src}
          imageUrls={props.srcs}
          focalPointX={props.circleOverlayX}
          focalPointY={props.circleOverlayY}
          hasOutline={props.hasOutline}
          showOutline={props.showOutline}
        />
      ) : (
        <StyledImageMedia
          style={{ backgroundImage: `url(${props.src})` }}
          onClick={() => props.onClick && props.onClick()}
        >
          {props.hasOutline && props.showOutline ? (
            <CircleOverlay
              style={{
                top: props.circleOverlayY * 100 + '%',
                left: props.circleOverlayX * 100 + '%',
              }}
            ></CircleOverlay>
          ) : null}
        </StyledImageMedia>
      )}
      {props.bottomLeftOverlay ? (
        <BottomLeftOverlay>{props.bottomLeftOverlay}</BottomLeftOverlay>
      ) : null}
    </StyledImageMediaContainer>
  );
}

export default ImageMedia;
