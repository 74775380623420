import { IsNotEmpty, IsString } from 'class-validator';
import { BaseDto } from '../base.dto';
import { StudyGroupDto } from './study-group.dto';

export class CreateStudyParticipation {
  @IsNotEmpty()
  @IsString()
  studyId: string;
}

export interface StudyParticipationDto extends BaseDto {
  studyId?: string;

  group: StudyGroupDto;
}
