import {
  BOLD_WEIGHT,
  Colors,
  EXTRA_LARGE_FONT_SIZE,
  FONT_SIZE,
  HEADING_FONT_SIZE,
  HEADING_SMALL_FONT_SIZE,
  MEDIUM_WEIGHT,
  REGULAR_WEIGHT,
  SEMI_BOLD_WEIGHT,
  SMALLEST_FONT_SIZE,
} from '@dermloop/ui/util';
import React from 'react';
import {
  Text as NativeNativeText,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import { Text as NativeWebText } from 'react-native-web';
import { v4 as uuid } from 'uuid';

const NativeText = Platform.OS === 'web' ? NativeWebText : NativeNativeText;

export const EMPTY_TEXT = '\u200b';

export interface TypographyProps {
  children: (JSX.Element | string)[] | string | JSX.Element;
  size?:
    | 'small'
    | 'medium-small'
    | 'normal'
    | 'large'
    | 'button'
    | 'extra-large'
    | number;
  singleLine?: boolean;
  marginVertical?: number;
  noTrailingSpace?: boolean;
  decoration?: 'underline' | 'none';
  selectable?: boolean;
  verticalAlign?: 'center' | 'bottom' | 'top';
  preventShrink?: boolean;
  color?:
    | 'light'
    | 'dark'
    | 'black'
    | 'info'
    | 'medium'
    | 'medium-light'
    | 'warning'
    | 'danger'
    | 'success'
    | 'disabled';
  weight?: 'regular' | 'semi-bold' | 'bold' | 'medium';
  fontStyle?: 'regular' | 'italic';
  unPadded?: boolean;
  onPress?: () => void;
  center?: boolean;
}

type LetterProps = Omit<TypographyProps, 'unPadded' | 'marginVertical'> & {
  addTrailingSpace?: boolean;
};

export function Letters(props: LetterProps) {
  const fontsize =
    typeof props.size === 'number'
      ? props.size
      : props.size === 'small'
      ? HEADING_SMALL_FONT_SIZE
      : props.size === 'medium-small'
      ? 14
      : props.size === 'normal'
      ? FONT_SIZE
      : props.size === 'button'
      ? SMALLEST_FONT_SIZE
      : props.size === 'extra-large'
      ? EXTRA_LARGE_FONT_SIZE
      : HEADING_FONT_SIZE;

  const styles = StyleSheet.create({
    base: {
      flexShrink: props.preventShrink ? 0 : 1,
      display: 'flex',

      maxWidth: '100%',
      lineHeight: fontsize + 6,
      textDecorationLine:
        props.decoration === 'underline' ? 'underline' : 'none',
      fontStyle: props.fontStyle === 'italic' ? 'italic' : 'normal',
      textAlign: props.center ? 'center' : undefined,
      color:
        props.color === 'light'
          ? Colors.TEXT_NEGATIVE
          : props.color === 'medium'
          ? Colors.BRAND_SECONDARY
          : props.color === 'medium-light'
          ? Colors.BRAND_TERTIARY
          : props.color === 'warning'
          ? Colors.WARNING
          : props.color === 'danger'
          ? Colors.DANGER
          : props.color === 'success'
          ? Colors.SUCCESS
          : props.color === 'disabled'
          ? Colors.BRAND_TERTIARY
          : props.color === 'black'
          ? Colors.BRAND_BLACK
          : props.color === 'info'
          ? Colors.BRAND_INFO
          : Colors.TEXT,
      fontWeight:
        props.weight === 'medium'
          ? MEDIUM_WEIGHT
          : props.weight === 'bold'
          ? BOLD_WEIGHT
          : props.weight === 'semi-bold'
          ? SEMI_BOLD_WEIGHT
          : REGULAR_WEIGHT,
      fontSize: fontsize,
    },
  });

  return (
    <NativeText
      onPress={props.onPress}
      numberOfLines={props.singleLine ? 1 : undefined}
      style={styles.base}
      selectable={props.selectable}
      dataSet={{ inline: '' }}
    >
      {/* {!props.children || typeof props.children === 'string' */}
      {!props.children || !Array.isArray(props.children)
        ? props.children
          ? props.children
          : EMPTY_TEXT
        : props.children
            .filter((c) => c) // Remove eventual null children being passed
            .map((child, _i) => {
              if (typeof child === 'string') {
                return (
                  <NativeText
                    dataSet={{ inline: '' }}
                    key={child}
                    style={styles.base}
                    selectable={props.selectable}
                  >
                    {child + ' '}
                  </NativeText>
                );
              } else {
                const combinedProps: TypographyProps & LetterProps = {
                  ...props,
                  ...child.props,
                  key: uuid(),
                };
                if (
                  typeof combinedProps.children === 'string' &&
                  !combinedProps.noTrailingSpace
                ) {
                  combinedProps.children = combinedProps.children + ' ';
                }
                return React.cloneElement(child, combinedProps);
              }
            })}
    </NativeText>
  );
}

export function Typography(props: TypographyProps) {
  return (
    <View
      style={{
        flexShrink: props.preventShrink ? 0 : 1,
        marginVertical: props.marginVertical,
        justifyContent:
          props.verticalAlign === 'bottom'
            ? 'flex-end'
            : props.verticalAlign === 'top'
            ? 'flex-start'
            : 'center',
      }}
    >
      <Letters {...props}>{props.children}</Letters>
    </View>
  );
}

export default Typography;
