import {
  ArrayNotEmpty,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
  ValidateNested,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { DiagnosisDto } from './diagnosis.dto';

export interface DiagnosisGroupDto extends BaseDto {
  name: string;
  ordering?: number;
  description?: string;
  articleId?: string;
  parentDiagnosisGroupId?: string;
  diagnoses?: DiagnosisDto[];
  childDiagnosisGroups?: DiagnosisGroupDto[];
  migrationDiagnosis?: DiagnosisDto;
}

export class CreateDiagnosisGroupDto {
  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsString()
  articleId?: string;

  @IsOptional()
  @IsUUID()
  migrationDiagnosisId?: string;
}

export class UpdateDiagnosisGroupDto {
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsString()
  articleId?: string;

  @IsOptional()
  @IsUUID()
  migrationDiagnosisId?: string;
}

/**
 * DTO for adding diagnoses to a diagnosis group.
 */
export class AddDiagnosisToDiagnosisGroupDto {
  @IsNotEmpty()
  @IsUUID()
  diagnosisGroupId: string;

  @ArrayNotEmpty()
  @IsUUID('all', { each: true })
  diagnosisIds: string[];
}

export class DiagnosisGroupValidateMigrationDto {
  // ID of diagnosis group to validate
  // If null, all main diagnosis groups will be validated
  @IsOptional()
  @IsUUID()
  diagnosisGroupId?: string;
}

export interface DiagnosisGroupValidateMigrationResultDto {
  [diagnosisGroupId: string]: boolean;
}

export class ChangeDiagnosisGroupOrderDto {
  @ValidateNested({ each: true })
  diagnosisGroups: DiagnosisGroupOrderDto[];
}

export class DiagnosisGroupOrderDto {
  @IsNotEmpty()
  @IsUUID()
  diagnosisGroupId: string;

  @IsNotEmpty()
  @IsNumber()
  @Min(1)
  ordering: number;
}
