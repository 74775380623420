import { SymptomDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const symptomsEndpoints = (builder: EndpointBuilderType) => ({
  getSymptoms: builder.query<SymptomDto[], void>({
    query: () => 'symptoms',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'SYMPTOMS', id: 'LIST' }],
  }),
});
