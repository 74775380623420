import { Colors, Sizes, useOnClickOutside } from '@dermloop/ui/util';
import { CssAnimations } from '@dermloop/ui/web/util';
import React, { useRef } from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../loading-indicator/loading-indicator';

const DEFAULT_SIDEBAR_WIDTH = 400;
const DEFAULT_HEADER_HEIGHT = 80;

const ANIMATION_DURATION = 0.1;

export interface SidebarProps {
  // Optional title. If no title and no headerAction provided, no header is shown
  title?: string;
  // Optional header action, shown to the right of the title. If no title and no headerAction provided, no header is shown
  headerAction?: React.ReactNode;
  // Content of sidebar
  children: React.ReactNode;

  visible: boolean;
  // Function to close the sidebar
  // If no function provided, sidebar will not be closed when backdrop is pressed
  hideSidebar?: () => void;

  // Optional width. If none provided, uses default
  width?: string;

  // If true, a loading indicator will be shown as content and header action will not be displayed
  loading?: boolean;

  // If provided, sticky footer is shown at the bottom of the sidebar
  stickyFooter?: React.ReactNode;
}

export function Sidebar(props: SidebarProps) {
  const sidebarRef = useRef();

  // If user clicks outside of sidebar, close sidebar if setVisible function provided
  useOnClickOutside(sidebarRef, () => {
    if (props.hideSidebar) {
      props.hideSidebar();
    }
  });

  return (
    <StyledBackdrop visible={props.visible}>
      <StyledSidebar
        ref={sidebarRef}
        width={props.width ?? DEFAULT_SIDEBAR_WIDTH}
        visible={props.visible}
      >
        <StyledSidebarContent>
          {props.title || props.headerAction ? (
            <StyledHeaderContainer>
              <StyledTitle>{props.title ? props.title : ''}</StyledTitle>
              {props.headerAction && !props.loading ? props.headerAction : null}
            </StyledHeaderContainer>
          ) : null}
          {props.loading ? (
            <StyledLoadingWrapper>
              <LoadingIndicator />
            </StyledLoadingWrapper>
          ) : (
            props.children
          )}
        </StyledSidebarContent>
        <StyledStickyFooter>
          {props.stickyFooter ? props.stickyFooter : null}
        </StyledStickyFooter>
      </StyledSidebar>
    </StyledBackdrop>
  );
}

const StyledBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: ${Sizes.DESKTOP_HEADER_HEIGHT}px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: ${Colors.DARK_TRANSPARENT_BACKGROUND};

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  animation: ${(props) =>
      props.visible ? CssAnimations.fadeIn : CssAnimations.fadeOut}
    ${ANIMATION_DURATION}s linear;
  transition: visibility ${ANIMATION_DURATION}s linear;
`;

const StyledSidebar = styled.div<{ width: number | string; visible: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${(props) =>
    typeof props.width === 'string' ? props.width : `${props.width}px`};
  background-color: ${Colors.BRAND_PRIMARY_BACKGROUND};
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  animation: ${(props) =>
      props.visible
        ? CssAnimations.slideLeftFromEdge
        : CssAnimations.slideRightToEdge}
    ${ANIMATION_DURATION}s linear;
  transition: visibility ${ANIMATION_DURATION}s linear;
`;

const StyledSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.GENERAL_SPACING}px;
  padding-bottom: ${Sizes.GENERAL_SPACING}px;

  overflow: auto;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: ${DEFAULT_HEADER_HEIGHT - Sizes.DOUBLE_GENERAL_SPACING}px;
  border-bottom: 1px solid ${Colors.BRAND_TERTIARY};
  background-color: ${Colors.BRAND_WHITE};
  padding: ${Sizes.GENERAL_SPACING}px;
`;

const StyledTitle = styled.div`
  font-size: ${Sizes.SUB_HEADING_FONT_SIZE}px;
  font-weight: ${Sizes.MEDIUM_WEIGHT};
`;

const StyledStickyFooter = styled.div`
  box-shadow: 4px 0px 5px ${Colors.MEDIUM_SHADOW_BACKGROUND};
`;

const StyledLoadingWrapper = styled.div`
  margin-top: ${Sizes.GENERAL_SPACING}px;
`;
