import { LesionImageDto } from '@dermloop/api-dtos';
import { EndpointBuilderType } from '../constants';

export const lesionImageEndpoints = (builder: EndpointBuilderType) => ({
  getLesionImage: builder.query<LesionImageDto, { lesionImageId: string }>({
    query: ({ lesionImageId }) => ({
      url: `lesion-images/${lesionImageId}`,
      method: 'GET',
    }),

    providesTags: (result, error, args) => [
      { type: 'LESION_IMAGE', id: result?.id },
    ],
  }),
});
