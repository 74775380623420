import { GenderDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const genderEndpoints = (builder: EndpointBuilderType) => ({
  getGenders: builder.query<GenderDto[], void>({
    query: () => 'genders',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'GENDERS', id: 'LIST' }],
  }),
});
