import { Type } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsBoolean,
  IsDate,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateNested,
} from 'class-validator';
import { SearchIndexMetadataDto } from './index-metadata.dto';

/**
 * DTO for indexing training lesions.
 */
export class TrainingLesionIndexDto {
  @Type(() => TrainingLesionIndexDataDto)
  @ValidateNested({ each: true })
  @ArrayNotEmpty()
  trainingLesions: TrainingLesionIndexDataDto[];

  @Type(() => SearchIndexMetadataDto)
  @IsOptional()
  @ValidateNested()
  metadata?: SearchIndexMetadataDto;
}

/**
 * DTO representing a training lesion for indexing.
 */
export class TrainingLesionIndexDataDto {
  @IsUUID()
  id: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  patientAgeYears?: number;

  @IsUUID()
  patientId: string;

  @IsOptional()
  @IsUUID()
  skinTypeId?: string;

  @IsOptional()
  @IsString()
  skinTypeName?: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  dermoscopyFollowTimeMonths?: number;

  @IsBoolean()
  histopathologyConducted: boolean;

  @IsBoolean()
  sentinelNodeBiopsyConducted: boolean;

  @IsOptional()
  @IsBoolean()
  recurrence?: boolean;

  @IsOptional()
  @IsNumber()
  @Min(0)
  recurrenceTimeMonths?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  totalTimeOfFollowUpMonths?: number;

  @IsBoolean()
  mcq: boolean;

  @IsUUID()
  statusId: string;

  @IsString()
  statusName: string;

  @IsOptional()
  @IsUUID()
  locationId?: string;

  @IsOptional()
  @IsString()
  locationName?: string;

  @IsUUID()
  diagnosisId: string;

  @IsString()
  diagnosisName: string;

  @IsOptional()
  @IsUUID()
  secondaryDiagnosisId?: string;

  @IsOptional()
  @IsString()
  secondaryDiagnosisName?: string;

  @IsOptional()
  @IsUUID()
  recurrenceLocationId?: string;

  @IsOptional()
  @IsString()
  recurrenceLocationName?: string;

  @IsUUID()
  clinicalImageId: string;

  @IsUUID()
  dermoscopicImageId: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  dermoscopicImageDifficultyIndex?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  dermoscopicImageAiDifficultyIndex?: number;

  @IsNumber()
  @Min(0)
  assessmentsCount: number;

  // If true, there exists an approved annotation
  // If false, there exists an annotation that is not approved
  // If null, there exists no annotations
  @IsOptional()
  @IsBoolean()
  hasApprovedAnnotation?: boolean;

  @IsNumber()
  randOrdering: number;

  @IsDate()
  created: Date;

  @IsNumber()
  @Min(0)
  annotationRequestsCount: number;
}
