import { IsNumber, IsOptional, IsString, Max, Min } from 'class-validator';

export class DAHTLesionSearchDto {
  @IsOptional()
  @IsString()
  query?: string;

  @IsNumber()
  @Max(30)
  take: number;

  @IsNumber()
  @Min(0)
  skip: number;
}
