import {
  DAHTDermoscopyRelevance,
  DAHTRelevance,
} from '@dermloop/static-values';
import {
  IsBoolean,
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsUUID,
} from 'class-validator';
import countries from 'world-countries';
export class DAHTUserEnrollmentDto {
  @IsUUID()
  @IsNotEmpty()
  userId: string;

  @IsString()
  @IsNotEmpty()
  firstName: string;

  @IsString()
  @IsNotEmpty()
  lastName: string;

  @IsString()
  @IsNotEmpty()
  @IsIn(countries?.map((c) => c.cca2))
  countryCode: string;

  @IsUUID()
  @IsNotEmpty()
  genderId: string;

  @IsNumber()
  @IsNotEmpty()
  skinLesionInterpretationExperienceYears: number;

  @IsNumber()
  @IsNotEmpty()
  caseloadMelanocyticLesionsMonthly: number;

  @IsNumber()
  @IsNotEmpty()
  caseloadMelanocyticLesionsMonthlyPercentage: number;

  @IsNumber()
  @IsNotEmpty()
  secondOpinionAssessmentsMelanocyticLesionsMonthly: number;

  @IsNumber()
  @IsNotEmpty()
  secondOpinionReferralsMelanocyticLesionsMonthly: number;

  @IsString()
  @IsIn(DAHTDermoscopyRelevance.map((v) => v.value))
  @IsNotEmpty()
  percievedRelevanceOfDermoscopyImages: DAHTRelevance;

  @IsBoolean()
  @IsNotEmpty()
  formerDermoscopyTraining: boolean;

  @IsBoolean()
  @IsNotEmpty()
  routinelyUseDigitizedSlides: boolean;
}
