import {
  IsArray,
  IsDate,
  IsDateString,
  IsJSON,
  IsNotEmpty,
  IsOptional,
  IsUUID,
  ValidateIf,
} from 'class-validator';

/**
 * DTO used for getting user statistics.
 */
export class GetCurrentUserStatisticsDto {
  // Date to fetch statistics for
  @IsOptional()
  @IsDateString()
  statisticsDate?: Date;
}

export class CommonMistakesQueryDto {
  @IsUUID('all', { each: true })
  @IsArray()
  diagnosisIds: string[];
}

/**
 * DTO representing user statistics.
 */
export class UserStatisticsDto {
  // User id
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsDate()
  statisticsDate: Date;

  @IsOptional()
  @IsNotEmpty()
  @IsJSON()
  trainingStatistics?: JSON;

  @ValidateIf((o) => !!o.trainingStatistics)
  @IsNotEmpty()
  @IsDate()
  trainingStatisticsUpdated?: Date;

  @IsOptional()
  @IsNotEmpty()
  @IsJSON()
  caseStatistics?: JSON;

  @ValidateIf((o) => !!o.caseStatistics)
  @IsNotEmpty()
  @IsDate()
  caseStatisticsUpdated?: Date;

  @IsOptional()
  @IsNotEmpty()
  @IsJSON()
  forumStatistics?: JSON;

  @ValidateIf((o) => !!o.forumStatistics)
  @IsNotEmpty()
  @IsJSON()
  forumStatisticsUpdated?: Date;
}
