import { Colors, GENERAL_SPACING } from '@dermloop/ui/util';
import React, { useEffect } from 'react';
import ReactToolTip from 'react-tooltip';
import styled from 'styled-components';

/**
 *
 * @param isDisabled Disable an option
 * @param isSelected Select an option
 * @param onPress Function to call when option is pressed
 * @param datatipOnDisable Text to show when hovering over disabled option, olnly works on web
 * @returns
 */
export interface OptionProps {
  children: React.ReactNode;
  isSelected: boolean;
  isDisabled: boolean;
  onPress: () => void;
  datatipOnDisable: string;
}

const StyledOption = styled.div<{ selected: boolean; disabled: boolean }>`
  display: flex;
  background: ${(props) =>
    props.selected ? Colors.BRAND_SUCCESS : Colors.BRAND_WHITE};
  flex-grow: 1;
  padding: ${GENERAL_SPACING}px ${GENERAL_SPACING}px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: ${Colors.BRAND_GRAY_MEDIUM};
  border-width: 1px 1px 0 0;
  max-width: 100%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export function Option(props: OptionProps) {
  const { children, isSelected, isDisabled, onPress, datatipOnDisable } = props;
  useEffect(() => {
    ReactToolTip.rebuild();
  }, []);
  return (
    <StyledOption
      data-tip={datatipOnDisable}
      data-tip-disable={!isDisabled}
      selected={isSelected}
      disabled={isDisabled}
      onClick={onPress}
    >
      {children}
    </StyledOption>
  );
}

export default Option;
