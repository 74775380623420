import { SCREEN_SIZES } from '@dermloop/ui/util';

export const device = {
  mobileS: `(min-width: ${SCREEN_SIZES.MobileS})`,
  mobileM: `(min-width: ${SCREEN_SIZES.MobileM})`,
  mobileL: `(min-width: ${SCREEN_SIZES.MobileL})`,
  tablet: `(min-width: ${SCREEN_SIZES.Tablet})`,
  laptop: `(min-width: ${SCREEN_SIZES.Laptop})`,
  laptopL: `(min-width: ${SCREEN_SIZES.LaptopL})`,
  desktop: `(min-width: ${SCREEN_SIZES.Desktop})`,
};
