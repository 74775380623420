import { IsBoolean, IsNotEmpty, IsString, IsUUID } from 'class-validator';

/**
 * Response DTO when a user has requested an OTP.
 */
export interface MfaRequestOtpResponseDto {
  anonymousPhoneNumber: string;
}

/**
 * Response DTO when a user has requested for a new OTP.
 */
export interface MfaResendOtpResponseDto {
  success: boolean;
}

/**
 * DTO for verifying an OTP.
 */
export class MfaVerifyOtpDto {
  @IsString()
  code: string;

  @IsBoolean()
  rememberDevice: boolean;
}

/**
 * Response DTO when user has verified an OTP.
 */
export interface MfaVerifyOtpResponseDto {
  success: boolean;
}

/**
 * DTO for requesting a recovery link for a user.
 */
export class MfaAdminRequestRecoveryLinkForUserDto {
  @IsUUID()
  userId: string;
}

/**
 * Response DTO when a recovery link has been requested for a user.
 */
export interface MfaAdminRequestRecoveryLinkForUserResponseDto {
  recoveryLink: string;
}
