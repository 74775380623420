import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';
import { sum } from 'lodash';

/**
 * Class validator that checks if the sum current value and a list of other properties equals some value.
 * @param properties          Property names to compare to.
 * @param validationOptions
 * @returns
 */
export function SumEquals<T>(
  targetSum: number,
  properties: (keyof T)[],
  validationOptions?: ValidationOptions
) {
  return (object: unknown, propertyName: string) =>
    registerDecorator({
      name: 'sumEquals',
      target: object.constructor,
      propertyName,
      constraints: properties as unknown[],
      options: validationOptions,
      validator: {
        validate(value: unknown, args: ValidationArguments) {
          const relatedPropertyNames = args.constraints;
          const relatedPropertyValues = relatedPropertyNames.map(
            (name) => (args.object as unknown)[name]
          );

          // Validate that all properties are numbers
          const validatePropertyTypes =
            typeof value === 'number' &&
            relatedPropertyValues.every((v) => typeof v === 'number');
          if (!validatePropertyTypes) return false;

          // Calculate the sum of value and all related property values
          const valuesSum = value + sum(relatedPropertyValues);

          return valuesSum === targetSum;
        },
      },
    });
}
