import { Colors, GENERAL_SPACING, SMALLEST_FONT_SIZE } from '@dermloop/ui/util';
import { useRef } from 'react';
import { TextInput } from 'react-native';
import styled from 'styled-components/native';
import { OptionTextFieldProps } from './option-text-field';

export const OptionTextFieldInput = (props: OptionTextFieldProps) => {
  const inputField = useRef<TextInput>();
  const updateTextAreaHeight = (area: TextInput) => {
    if (area instanceof HTMLTextAreaElement) {
      area.style.height = '0';
      area.style.height = `${area.scrollHeight}px`;
    }
  };

  return (
    <StyledInput
      editable={!props.readonly}
      returnKeyType="done"
      selectTextOnFocus={!props.readonly}
      ref={(r) => {
        inputField.current = r;
        updateTextAreaHeight(inputField.current);
      }}
      value={props.value}
      secureTextEntry={props.hideInput}
      autoCapitalize="none"
      placeholderTextColor={Colors.TEXT}
      onChangeText={(text) => {
        props.onChangeText(text || null);
      }}
      placeholder={props.placeholder}
      multiline={props.multiline}
      multilineEnabled={props.multiline}
      onChange={(e) => {
        if (props.multiline) {
          updateTextAreaHeight(inputField.current);
        }
      }}
    />
  );
};

const StyledInput = styled.TextInput<{
  multilineEnabled: boolean;
}>`
  font-size: ${SMALLEST_FONT_SIZE}px;
  width: 100%;
  padding-horizontal: ${GENERAL_SPACING}px;
  outline-color: ${Colors.BRAND_SUCCESS};
  padding-top: ${(props) => (props.multilineEnabled ? 17 : 0)}px;
  padding-bottom: ${(props) => (props.multilineEnabled ? 17 : 0)}px;
  border-width: 0.5px;
  border-color: transparent;
`;
