import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  ListItem,
  ProgressBar,
  Tag,
  Typography,
} from '@dermloop/ui/components';
import { GENERAL_SPACING, HEADER_HEIGHT } from '@dermloop/ui/util';
import { Card, Header } from '@dermloop/ui/web/components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { minutesToMilliseconds } from 'date-fns';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TrialRegistrationGuard } from '../../components/trial-registration/trial-registration';
import { dermloopApi } from '../../store/api';

/* eslint-disable-next-line */
export interface OverviewPageProps {}

const StyledOverviewPage = styled.div`
  display: flex;
  padding-top: ${HEADER_HEIGHT}px;
  justify-content: center;
`;

const StyledContent = styled.div`
  max-width: 600px;
  padding: ${GENERAL_SPACING}px;
`;

const StyledActionContainer = styled.div`
  padding: ${GENERAL_SPACING}px 0px;
  display: flex;
  justify-content: flex-end;
`;

/**
 *
 * @param props
 */
export function OverviewPage(props: OverviewPageProps) {
  const history = useHistory();
  const { logout } = useAuth0();
  const { data: currentUser } = dermloopApi.useGetCurrentUserQuery();
  const { isLoading: sessionLoading, data: dahtSession } =
    dermloopApi.useGetDAHTSessionQuery(
      !currentUser?.dahtDetails ? skipToken : undefined,
      {
        // Require session to update after some time to avoid cached assessments have expired urls
        pollingInterval: minutesToMilliseconds(15),
      }
    );
  const [showAll, setShowAll] = useState(false);

  const activeAssessment = dahtSession?.assessments?.find(
    (a) => !a.assessedDate
  );

  return (
    <StyledOverviewPage>
      <Header
        title="DAHT"
        content={
          <Button
            children="Log out"
            onPress={() => {
              logout({
                returnTo: window.location.origin,
              });
            }}
            type="outline"
            color="secondary"
            display="inline-narrow"
          ></Button>
        }
      ></Header>
      <StyledContent>
        <TrialRegistrationGuard>
          <>
            <Card>
              <Typography size="large" weight="bold">
                Dermoscopy Augmented Histology Trial - Consensus agreement
              </Typography>
              <br />
              <Typography size="normal">
                Thank you for signing up. You are now ready to assess all DAHT
                cases.
              </Typography>
              <br />
              {/* <Typography size="normal">
                Soon, you will receive an email about what to do next, depending
                on whether you are randomized to the control or intervention
                group.
              </Typography>
              <br /> */}
              <Typography size="normal">
                If you have any questions, please contact me by email:
              </Typography>
              <a href="mailto:louisa.boenloekke.nervil@regionh.dk">
                louisa.boenloekke.nervil@regionh.dk
              </a>
            </Card>
            <br />
            <Card>
              <Typography size="normal">Assessments progress</Typography>
              {dahtSession ? (
                activeAssessment ? (
                  <ProgressBar
                    staticProgress={
                      dahtSession?.assessments?.indexOf(activeAssessment) /
                      dahtSession?.assessments?.length
                    }
                    roundBorder
                    progressColor="success"
                    showProgress
                    maxValue={dahtSession?.assessments?.length}
                  />
                ) : (
                  <Typography size="normal" color="medium">
                    You have no more pending assessments
                  </Typography>
                )
              ) : null}
            </Card>
            <StyledActionContainer>
              <Button
                loading={sessionLoading}
                type="outline"
                onPress={() => {
                  history.push('/session');
                }}
              >
                Assess Lesions
              </Button>
            </StyledActionContainer>
            {dahtSession?.assessments?.length && showAll ? (
              <div>
                {orderBy(dahtSession.assessments, (a) =>
                  parseInt(a.lesion?.lesionIdentifier)
                ).map((a) => {
                  return (
                    <ListItem
                      extraContent={
                        a.assessedDate ? (
                          <Tag color="green">Assessed</Tag>
                        ) : null
                      }
                      heading={`Lesion #${a.lesion?.lesionIdentifier}`}
                      description={
                        a.otherDiagnosis || a.selectedDiagnosisGroup?.name
                      }
                      showIcon
                      onPress={() => {
                        history.push('/session/' + a.lesion?.lesionIdentifier);
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              <Typography
                size="small"
                color="medium"
                onPress={() => {
                  setShowAll(true);
                }}
              >
                Show all lesions
              </Typography>
            )}
          </>
        </TrialRegistrationGuard>
      </StyledContent>
    </StyledOverviewPage>
  );
}

export default OverviewPage;
