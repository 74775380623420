//Originally from https://github.com/gie3d/react-native-use-keyboard-height/blob/master/src/index.tsx

import { useEffect, useState } from 'react';
import {
  Keyboard,
  Platform,
  KeyboardEvent,
  PlatformOSType,
} from 'react-native';

export const useKeyboardHeight = (platforms: PlatformOSType[] = ['ios']) => {
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  useEffect(() => {
    if (platforms.includes(Platform.OS)) {
      const keyboardDidShowListener = Keyboard.addListener(
        'keyboardDidShow',
        keyboardDidShow
      );
      const keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        keyboardDidHide
      );

      // cleanup function
      return () => {
        keyboardDidShowListener.remove();
        keyboardDidHideListener.remove();
      };
    }
  }, []);

  const keyboardDidShow = (frames: KeyboardEvent) => {
    setKeyboardHeight(frames.endCoordinates.height);
  };

  const keyboardDidHide = () => {
    setKeyboardHeight(0);
  };

  return keyboardHeight;
};

export default useKeyboardHeight;
