import { SerializedError } from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseEndpointDefinition,
  QueryArgFrom,
  ResultTypeFrom,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  UseQuery,
  UseQuerySubscription,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  Id,
  Override,
  WithRequiredProp,
} from '@reduxjs/toolkit/dist/query/tsHelpers';

/**
 * Copied from the redux toolkit source code, as it cannot be imported.
 */
enum QueryStatus {
  uninitialized = 'uninitialized',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

/**
 * Helper function that evaluates the result of an rtk query. If the skip
 * condition is true, it returns null data.
 * This is useful if null data is intended in the skip scenario, where rtk
 * query by default shows the previous data before the skip predicate was true.
 *
 * If an error occurs from the rtk query, this is handled.
 * @param params
 * @returns
 */
export function useRtkNullDataIfSkip<
  // Query data input type
  D,
  // Query result type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends Record<string, any>
>(params: {
  query: UseQuery<QueryDefinitionType<D, R>>;
  // Data supplied to query
  queryData: D;
  // Predicate specifying if query should be skipped
  skipPredicate: () => boolean;
  // Any additional parameters to give to the query, besides the functions use of skip
  queryParams?: QueryParamsType<D, R>;
}): UseQueryStateDefaultResult<QueryDefinitionType<D, R>> &
  ReturnType<UseQuerySubscription<QueryDefinitionType<D, R>>> {
  const shouldSkip = params.skipPredicate();

  const res = params.query(params.queryData, {
    skip: shouldSkip,
    ...(params.queryParams ?? {}),
  });

  if (shouldSkip) {
    return {
      data: null,
      isLoading: false,
      error: null,
      fulfilledTimeStamp: 0,
      startedTimeStamp: 0,
      status: QueryStatus.fulfilled,
      currentData: null,
      isUninitialized: false,
      isFetching: false,
      isSuccess: true,
      isError: false,
      refetch: () => {
        throw new Error(
          'Refetch should not be called when using useRtkNullDataIfSkip'
        );
      },
    };
  }

  return res;
}

type QueryDefinitionType<D, R> = QueryDefinition<
  D,
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    object,
    FetchBaseQueryMeta
  >,
  string,
  R,
  'dermloopApi'
>;

type QueryParamsType<D, R> = {
  pollingInterval?: number;
  refetchOnReconnect?: boolean;
  refetchOnFocus?: boolean;
  skip?: boolean;
  refetchOnMountOrArgChange?: boolean | number;
  selectFromResult?: (
    result: UseQueryStateDefaultResult<QueryDefinitionType<D, R>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
};

/**
 * These types are taken directly from the rtk query source code. The types are not exported, and therefore
 * must be explicitly declared here.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseQueryStateBaseResult<D extends QueryDefinition<any, any, any, any>> =
  QuerySubState<D> & {
    /**
     * Where `data` tries to hold data as much as possible, also re-using
     * data from the last arguments passed into the hook, this property
     * will always contain the received data from the query, for the current query arguments.
     */
    currentData?: ResultTypeFrom<D>;
    /**
     * Query has not started yet.
     */
    isUninitialized: false;
    /**
     * Query is currently loading for the first time. No data yet.
     */
    isLoading: false;
    /**
     * Query is currently fetching, but might have data from an earlier request.
     */
    isFetching: false;
    /**
     * Query has data from a successful load.
     */
    isSuccess: false;
    /**
     * Query is currently in "error" state.
     */
    isError: false;
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseQueryStateDefaultResult<D extends QueryDefinition<any, any, any, any>> =
  Id<
    | Override<
        Extract<
          UseQueryStateBaseResult<D>,
          {
            status: QueryStatus.uninitialized;
          }
        >,
        {
          isUninitialized: true;
        }
      >
    | Override<
        UseQueryStateBaseResult<D>,
        | {
            isLoading: true;
            isFetching: boolean;
            data: undefined;
          }
        | ({
            isSuccess: true;
            isFetching: true;
            error: undefined;
          } & Required<
            Pick<UseQueryStateBaseResult<D>, 'data' | 'fulfilledTimeStamp'>
          >)
        | ({
            isSuccess: true;
            isFetching: false;
            error: undefined;
          } & Required<
            Pick<
              UseQueryStateBaseResult<D>,
              'data' | 'fulfilledTimeStamp' | 'currentData'
            >
          >)
        | ({
            isError: true;
          } & Required<Pick<UseQueryStateBaseResult<D>, 'error'>>)
      >
  > & {
    /**
     * @deprecated will be removed in the next version
     * please use the `isLoading`, `isFetching`, `isSuccess`, `isError`
     * and `isUninitialized` flags instead
     */
    status: QueryStatus;
  };

/**
 * The following types can be imported, however when running the application,
 * it fails. Therefore, they are explicitly declared here.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QuerySubState<D extends BaseEndpointDefinition<any, any, any>> = Id<
  | ({
      status: QueryStatus.fulfilled;
    } & WithRequiredProp<
      BaseQuerySubState<D>,
      'data' | 'fulfilledTimeStamp'
    > & {
        error: undefined;
      })
  | ({
      status: QueryStatus.pending;
    } & BaseQuerySubState<D>)
  | ({
      status: QueryStatus.rejected;
    } & WithRequiredProp<BaseQuerySubState<D>, 'error'>)
  | {
      status: QueryStatus.uninitialized;
      originalArgs?: undefined;
      data?: undefined;
      error?: undefined;
      requestId?: undefined;
      endpointName?: string;
      startedTimeStamp?: undefined;
      fulfilledTimeStamp?: undefined;
    }
>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseQuerySubState<D extends BaseEndpointDefinition<any, any, any>> = {
  /**
   * The argument originally passed into the hook or `initiate` action call
   */
  originalArgs: QueryArgFrom<D>;
  /**
   * A unique ID associated with the request
   */
  requestId: string;
  /**
   * The received data from the query
   */
  data?: ResultTypeFrom<D>;
  /**
   * The received error if applicable
   */
  error?:
    | SerializedError
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | (D extends QueryDefinition<any, infer BaseQuery, any, any>
        ? BaseQueryError<BaseQuery>
        : never);
  /**
   * The name of the endpoint associated with the query
   */
  endpointName: string;
  /**
   * Time that the latest query started
   */
  startedTimeStamp: number;
  /**
   * Time that the latest query was fulfilled
   */
  fulfilledTimeStamp?: number;
};
