import { Exclude } from 'class-transformer';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { BaseCreateDto, BaseDto } from '../base.dto';
import { TrainingAssessmentDto } from '../training';

export interface ArticleViewDto extends BaseDto {
  articleId: string;
  timeSpentMs: number;
  trainingAssessment?: TrainingAssessmentDto;
  trainingAssessmentId?: string;
}

export class CreateArticleViewDto
  extends BaseCreateDto
  implements Partial<ArticleViewDto>
{
  @IsNotEmpty()
  @IsString()
  articleId: string;

  @IsNotEmpty()
  @IsNumber()
  timeSpentMs: number;

  @IsOptional()
  @IsUUID()
  trainingAssessmentId?: string;

  @IsOptional()
  @IsBoolean()
  confirmedRead?: boolean;

  @IsOptional()
  @IsUUID()
  userLessonId?: string;

  @Exclude()
  trainingAssessment?: never;
}
