import { DiagnosticConfidence } from '@dermloop/static-values';
import { Exclude } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
} from 'class-validator';
import { BaseCreateDto, BaseDto, BaseUpdateDto } from '../base.dto';
import { SkinTypeConditionDto } from '../skin-type-condition.dtos';

export type DiagnosisInfo = {
  diagnosis?: string;
  confidence?: DiagnosticConfidence;
  diagnosisGroups: string[];
};

export interface DiagnosisDto extends BaseDto {
  name: string;
  shortName?: string;
  legacyIdentifier?: string;
  description?: string;
  snomed?: string;
  visible?: boolean;
  malignant?: boolean;
  severity?: number;
  skinTypeConditions?: SkinTypeConditionDto[];
  featuredForSkinTypeConditions?: SkinTypeConditionDto[];
  parentDiagnosis?: DiagnosisDto;
}

export class CreateDiagnosisDto
  extends BaseCreateDto
  implements Partial<DiagnosisDto>
{
  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  shortName?: string;

  @IsOptional()
  @IsString()
  legacyIdentifier?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsBoolean()
  visible: boolean;

  @IsBoolean()
  malignant: boolean;

  @IsOptional()
  @IsNumber()
  @Min(0)
  severity?: number;

  @IsUUID('all', { each: true })
  @ArrayNotEmpty()
  skinTypeConditionIds: string[];

  @Exclude()
  skinTypeConditions?: never;

  @IsOptional()
  @IsUUID('all', { each: true })
  featuredForSkinTypeConditionIds?: string[];

  @Exclude()
  featuredForSkinTypeConditions?: never;

  @IsOptional()
  @IsUUID()
  parentDiagnosisId?: string;
}

export class UpdateDiagnosisDto
  extends BaseUpdateDto
  implements Partial<DiagnosisDto>
{
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  shortName?: string;

  @IsOptional()
  @IsString()
  legacyIdentifier?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsBoolean()
  visible?: boolean;

  @IsOptional()
  @IsBoolean()
  malignant: boolean;

  @IsOptional()
  @IsNumber()
  @Min(0)
  severity?: number;

  @IsOptional()
  @IsUUID('all', { each: true })
  @ArrayNotEmpty()
  skinTypeConditionIds?: string[];

  @Exclude()
  skinTypeConditions?: never;

  @IsOptional()
  @IsUUID('all', { each: true })
  featuredForSkinTypeConditionIds?: string[];

  @Exclude()
  featuredForSkinTypeConditions?: never;

  @IsOptional()
  @IsUUID()
  parentDiagnosisId?: string;
}
