import {
  IsOptional,
  IsNumber,
  Min,
  IsArray,
  IsUUID,
  IsBoolean,
  Max,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { GenderDto, SkinTypeDto } from '../clinical-details';
import { DiagnosisDto } from '../diagnoses';
import { MedicalUnitDto } from '../medical-unit.dtos';
import { LesionImageDto } from './lesion-image.dtos';

export interface LesionImportDto extends BaseDto {
  age: number;

  gender: GenderDto;

  skipped: boolean;

  diagnosis: DiagnosisDto;

  secondaryDiagnosis?: DiagnosisDto;

  tertiaryDiagnosis?: DiagnosisDto;

  diagnosisType: 'Histopathology' | 'Teledermatology' | 'Clinical';

  clinicalImages: LesionImageDto[];

  dermoscopicImages: LesionImageDto[];

  medicalUnit: MedicalUnitDto;

  skinType: SkinTypeDto;
}

export interface LesionImportSearchResultDto extends BaseDto {
  id: string;
  diagnosis: DiagnosisDto;
  skinType: SkinTypeDto;
  skipped: boolean;
}

export class LesionImportSearchDto {
  @IsOptional()
  @IsNumber()
  @Min(0)
  skip?: number;

  @IsOptional()
  @IsNumber()
  @Min(1)
  take?: number;

  @IsOptional()
  @IsArray()
  @IsUUID('all', { each: true })
  diagnosisIds?: string[];

  @IsOptional()
  @IsArray()
  @IsUUID('all', { each: true })
  skinTypeIds?: string[];

  // Filtering to only skipped / not skipped. If null, both are included
  @IsOptional()
  @IsBoolean()
  skipped?: boolean;

  // Optionally exclude id. Used for iterating lesions, ensuring the same lesion is not returned
  @IsOptional()
  @IsUUID()
  excludeLesionId?: string;
}

/**
 * DTO for updating a lesion for lesion import.
 */
export class LesionImportUpdateDto {
  @IsOptional()
  @IsBoolean()
  importSkipped?: boolean;

  @IsOptional()
  @IsBoolean()
  importDiscarded?: boolean;
}

export class CreateLesionImportDto {
  @IsUUID()
  lesionId: string;

  @IsUUID()
  clinicalImageId: string;

  // If true, a cropped version of clinical image will be uploaded later
  @IsBoolean()
  clinicalImageHasCrop: boolean;

  @IsUUID()
  dermoscopicImageId: string;

  @IsOptional()
  @IsUUID()
  revisedDiagnosisId?: string;

  @IsOptional()
  @IsUUID()
  revisedSecondaryDiagnosisId?: string;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  updatedFocalPointX?: number;

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(1)
  updatedFocalPointY?: number;

  @IsOptional()
  @IsUUID()
  skinTypeId?: string;
}

/**
 * Result of creating a lesion import.
 * Note that training lesion id is not included, as we need to be 100% anonymous.
 */
export interface CreateLesionImportResultDto {
  // Id of lesion used for import
  lesionId: string;

  // Url for clinical image upload. Only set if clinicalImageHasCrop is true for request dto
  clinicalImageUploadUrl?: string;

  // Id of created clinical training lesion image. Only set if clinicalImageHasCrop is true for request dto
  clinicalTrainingLesionImageId?: string;
}
