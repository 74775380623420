import { Exception } from './exception';

/**
 * Exception thrown when some internal configuration is invalid. Could be due to wrong setup of training configuration in the database or in the medical unit configuration.
 * This is thrown as a last resort and should not be depended upon this indicates that something internally is wrong.
 */
export class ConfigurationException extends Exception {
  constructor(message: string) {
    super(message, ConfigurationException.name);
  }
}
