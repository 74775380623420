import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsUUID,
} from 'class-validator';
import { BaseDto } from '../base.dto';

export class TeleDifferentialDiagnosisDto extends BaseDto {
  @IsNotEmpty()
  @IsUUID('all')
  teleAssessmentId: string;

  @IsNotEmpty()
  @IsUUID('all')
  diagnosisId: string;

  @IsNotEmpty()
  @IsNumber()
  @IsPositive()
  order: number;
}

export class TeleDifferentialDiagnosisMutationDto
  implements Partial<TeleDifferentialDiagnosisDto>
{
  @IsOptional()
  @IsUUID('all')
  id?: string;

  @IsNotEmpty()
  @IsUUID('all')
  diagnosisId: string;

  @IsNotEmpty()
  @IsNumber()
  @IsPositive()
  order: number;

  @IsOptional()
  @IsBoolean()
  shouldDelete?: boolean;
}
