import { AnnotationPointDto } from '@dermloop/api-dtos';
import { isEqual } from 'lodash';

export interface ImageDimensions {
  width: number;
  height: number;
}
/**
 * Function to normalize a point based on image dimensions.
 * @param imageDimensions
 * @param point
 * @returns
 */
export const normalizePoint = (
  point: AnnotationPointDto,
  imageDimensions: ImageDimensions
): AnnotationPointDto => ({
  x: point.x / imageDimensions.width,
  y: point.y / imageDimensions.height,
});

/**
 * Function to de-normalize a point based on image dimensions.
 * @param imageDimensions
 * @param point
 * @returns
 */
export const denormalizePoint = (
  point: AnnotationPointDto,
  imageDimensions: ImageDimensions
): AnnotationPointDto => ({
  x: point.x * imageDimensions.width,
  y: point.y * imageDimensions.height,
});

/**
 * Function for making an array of lasso points a string compatible
 * with polyline.
 * @param points
 * @returns
 */
export const pointsToPolylineStr = (points: AnnotationPointDto[]): string => {
  // Ensure we have points
  if (!points?.length) return '';

  // Ensure that a line from the last point to the first point will be drawn
  const p = !isEqual(points[0], points[points.length - 1])
    ? [...points, points[0]]
    : points;

  // Create string in polyline format
  return p?.map(({ x, y }) => `${x},${y}`)?.join(' ');
};
