import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import type { DiagnosisGroupDto } from '../diagnoses/diagnosis-group.dto';
import type { UserDto } from '../user.dto';

export class FieldConfigurationDto implements BaseDto {
  @IsString()
  id: string;

  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;

  created?: string | Date;
  updated?: string | Date;
  deleted?: string | Date;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsOptional()
  @IsNotEmpty()
  diagnosisTree?: DiagnosisGroupDto;

  @IsNotEmpty()
  @IsNumber()
  mandatoryDiagnosisTreeDepth?: number;

  @IsOptional()
  @IsNumber()
  visibleDiagnosisTreeDepth?: number;

  @IsOptional()
  @IsBoolean()
  allowUrgentReferrals?: boolean;
}
