import DifficultyLevel1 from './difficulty-level-1.svg';
import DifficultyLevel2 from './difficulty-level-2.svg';
import DifficultyLevel3 from './difficulty-level-3.svg';
import DifficultyLevel4 from './difficulty-level-4.svg';
import DifficultyLevel5 from './difficulty-level-5.svg';
import DifficultyLevel6 from './difficulty-level-6.svg';

import DifficultyLevel1Failed from './difficulty-level-1-failed.svg';
import DifficultyLevel2Failed from './difficulty-level-2-failed.svg';
import DifficultyLevel3Failed from './difficulty-level-3-failed.svg';
import DifficultyLevel4Failed from './difficulty-level-4-failed.svg';
import DifficultyLevel5Failed from './difficulty-level-5-failed.svg';
import DifficultyLevel6Failed from './difficulty-level-6-failed.svg';

import CaseDifficultyEmpty from './case-difficulty-empty.svg';
import CaseDifficultyEasy from './case-difficulty-easy.svg';
import CaseDifficultyMedium from './case-difficulty-medium.svg';
import CaseDifficultyHard from './case-difficulty-hard.svg';
import CaseDifficultyVeryHard from './case-difficulty-very-hard.svg';

export const Icons = {
  DifficultyLevel1,
  DifficultyLevel2,
  DifficultyLevel3,
  DifficultyLevel4,
  DifficultyLevel5,
  DifficultyLevel6,
  DifficultyLevel1Failed,
  DifficultyLevel2Failed,
  DifficultyLevel3Failed,
  DifficultyLevel4Failed,
  DifficultyLevel5Failed,
  DifficultyLevel6Failed,
  CaseDifficultyEmpty,
  CaseDifficultyEasy,
  CaseDifficultyMedium,
  CaseDifficultyHard,
  CaseDifficultyVeryHard,
};
