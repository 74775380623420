import { ButtonIcon } from '@dermloop/ui/components';
import {
  Colors,
  GENERAL_SPACING,
  Icons,
  MEDIUM_WEIGHT,
} from '@dermloop/ui/util';
import { useState } from 'react';
import styled from 'styled-components';

export interface AdditionalDiagnosisProps {
  children?: JSX.Element | JSX.Element[];
  diagnosisId: string;
  isOpen: boolean;
  onAddClick?: () => void;
  onRemoveClick?: () => void;
  disabled?: boolean;
  title: string;
}

const StyledAdditionalDiagnosis = styled.div``;

const AddAditionalDiagnosis = styled.div`
  padding: ${GENERAL_SPACING}px;
  display: flex;
  background-color: ${Colors.BRAND_WHITE};
  border-bottom-color: ${Colors.BRAND_GRAY_MEDIUM};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const AddButton = styled.div`
  margin-left: auto;
`;

const AddLabel = styled.div`
  font-weight: ${MEDIUM_WEIGHT};
  font-size: 18px;
  align-self: center;
`;

export function AdditionalDiagnosis(props: AdditionalDiagnosisProps) {
  const [showAdditionalDiagnosis, setShowAdditionalDiagnosis] = useState(
    props.diagnosisId ? true : false
  );

  return (
    <StyledAdditionalDiagnosis>
      {props.diagnosisId && !props.isOpen ? null : (
        <AddAditionalDiagnosis>
          <AddLabel>{props.title}</AddLabel>
          <AddButton>
            <ButtonIcon
              children={showAdditionalDiagnosis ? Icons.TrashBin : Icons.Plus}
              onPress={() => {
                if (props.disabled) {
                  props.onAddClick();
                  return;
                }
                if (showAdditionalDiagnosis && props.onRemoveClick) {
                  props.onRemoveClick();
                }
                setShowAdditionalDiagnosis(!showAdditionalDiagnosis);
              }}
              type={'solid'}
              color={'secondary'}
              outline={true}
              disabled={props.disabled}
            />
          </AddButton>
        </AddAditionalDiagnosis>
      )}
      {showAdditionalDiagnosis ? props.children : null}
    </StyledAdditionalDiagnosis>
  );
}

export default AdditionalDiagnosis;
