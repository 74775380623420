import { ButtonIcon } from '@dermloop/ui/components';
import { BUTTON_BORDER_RADIUS, Colors, Icons } from '@dermloop/ui/util';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export interface ImageCompareDropdownProps {
  clinicalImagesComapreDisabled?: boolean;
  dermoscopicImagesCompareDisabled?: boolean;
  onClickCompareClinicalImages?: () => void;
  onClickCompareDermoscopicImages?: () => void;
}

const StyledArrow = styled.div`
  padding: 0 0 0 1rem;
  margin: auto;
`;

const StyledDropdown = styled.div<{ disabled: boolean }>`
  margin: auto 0.5rem;
  cursor: ${(props) => (!props.disabled ? 'pointer' : null)};
  user-select: none;
`;

const StyledDropdownHeader = styled.div<{ disabled: boolean }>`
  display: flex;
  padding: 0.5rem 1rem;
  border: 1px solid ${Colors.BRAND_TERTIARY};
  border-radius: ${BUTTON_BORDER_RADIUS}px;
  color: ${(props) =>
    !props.disabled ? Colors.BRAND_SUCCESS : Colors.BRAND_TERTIARY};
`;

const DropdownListContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: inherit;
  padding-top: 0.4rem;
`;

const DropdownList = styled.div`
  margin: 0;
  background: ${Colors.BRAND_WHITE};
  border: 1px solid ${Colors.BRAND_TERTIARY};
  box-sizing: border-box;
  color: ${Colors.BRAND_SUCCESS};
  border-radius: 2px;
`;

const DropdownListItem = styled.div`
  list-style: none;
  padding: 0.8rem;

  &:first-child {
    border-bottom: 1px solid ${Colors.BRAND_TERTIARY};
  }

  &:hover {
    background: ${Colors.BRAND_SECONDARY_TRANSPARENT_BACKGROUND};
  }
`;

function useOutsideAlerter(ref, callback: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        //alert('You clicked outside of me!');
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export function ImageCompareDropdown(props: ImageCompareDropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef, () => {
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [props]);

  return (
    <StyledDropdown
      disabled={
        props.clinicalImagesComapreDisabled &&
        props.dermoscopicImagesCompareDisabled
      }
      ref={wrapperRef}
    >
      <StyledDropdownHeader
        onClick={() =>
          !props.clinicalImagesComapreDisabled ||
          !props.dermoscopicImagesCompareDisabled
            ? setIsDropdownOpen(!isDropdownOpen)
            : null
        }
        disabled={
          props.clinicalImagesComapreDisabled &&
          props.dermoscopicImagesCompareDisabled
        }
      >
        Compare images
        <StyledArrow>
          <ButtonIcon
            children={!isDropdownOpen ? Icons.ArrowDown : Icons.ArrowUp}
            onPress={() =>
              !props.clinicalImagesComapreDisabled ||
              !props.dermoscopicImagesCompareDisabled
                ? setIsDropdownOpen(!isDropdownOpen)
                : null
            }
            type={'clear'}
            color={'primary'}
            outline={false}
            disabled={
              props.clinicalImagesComapreDisabled &&
              props.dermoscopicImagesCompareDisabled
            }
            noPadding={true}
            size={'small'}
          />
        </StyledArrow>
      </StyledDropdownHeader>
      {isDropdownOpen && (
        <DropdownListContainer>
          <DropdownList>
            {!props.clinicalImagesComapreDisabled ? (
              <DropdownListItem
                onClick={() => {
                  setIsDropdownOpen(false);
                  props.onClickCompareClinicalImages();
                }}
                key={Math.random()}
              >
                Clinical images
              </DropdownListItem>
            ) : null}

            {!props.dermoscopicImagesCompareDisabled ? (
              <DropdownListItem
                onClick={() => {
                  setIsDropdownOpen(false);
                  props.onClickCompareDermoscopicImages();
                }}
                key={Math.random()}
              >
                Dermoscopic images
              </DropdownListItem>
            ) : null}
          </DropdownList>
        </DropdownListContainer>
      )}
    </StyledDropdown>
  );
}
