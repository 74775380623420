import { Colors, DESKTOP_HEADER_HEIGHT, Icons } from '@dermloop/ui/util';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface SlottedPageProps {
  header: JSX.Element;
  mainContent: JSX.Element;
  rightSideContent?: JSX.Element;
  hideSideContent?: boolean;
  disableToggleSideContent?: boolean;
  toggleSideContent?: () => void;
}
const HeaderSafeArea = styled.div`
  padding-top: ${DESKTOP_HEADER_HEIGHT}px;
  display: flex;
  flex-grow: 1;
  flex-flow: column;
`;

const Content = styled.div<{
  hideSideContent: boolean;
  hasRightSideContent: boolean;
}>`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: ${(props) =>
    props.hideSideContent || !props.hasRightSideContent ? '100%' : '80vw'};
`;

const MainContent = styled.div`
  flex-grow: 1;
  height: 100%;
  background-color: ${Colors.BACKGROUND_WHITE};
`;

const SideBar = styled.div`
  display: flex;
  width: 20vw;
  padding-left: 20px;
  height: 100%;
  flex-direction: column;
  position: fixed;
  right: 0;
  background-color: ${Colors.BACKGROUND_WHITE};
  overflow-x: hidden;
`;

const SidebarOpener = styled.div`
  width: 20px;
  font-size: 2rem;
  height: 100%;
  border-left: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  top: 0;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: ${Colors.BACKGROUND_WHITE};
  cursor: pointer;

  :hover {
    background-color: ${Colors.BRAND_SECONDARY_TRANSPARENT_BACKGROUND};
  }
`;

const SidebarCloser = styled.button<{ disableToggleSideContent: boolean }>`
  margin: 0;
  border: none;
  width: 20px;
  font-size: 2rem;
  height: 100%;
  position: absolute;
  border-left: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  border-right: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  top: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.BACKGROUND_WHITE};
  cursor: pointer;

  :hover {
    background-color: ${(props) =>
      !props.disableToggleSideContent
        ? Colors.BRAND_SECONDARY_TRANSPARENT_BACKGROUND
        : null};
  }
`;

const SidebarImage = styled.img`
  width: 0.9rem;
  height: 0.9rem;
`;

export function SlottedPage(props: SlottedPageProps) {
  return (
    <>
      {props.header}
      <HeaderSafeArea>
        <Content
          hideSideContent={props.hideSideContent}
          hasRightSideContent={props.rightSideContent !== null}
        >
          <MainContent>{props.mainContent}</MainContent>
        </Content>
        {props.rightSideContent ? (
          props.hideSideContent && !props.disableToggleSideContent ? (
            <SidebarOpener onClick={() => props.toggleSideContent()}>
              <SidebarImage
                alt="arrows-expend"
                src={Icons.ArrowsExpand}
              ></SidebarImage>
            </SidebarOpener>
          ) : (
            <SideBar>
              {!props.disableToggleSideContent ? (
                <SidebarCloser
                  onClick={() =>
                    !props.disableToggleSideContent && props.toggleSideContent()
                  }
                  disableToggleSideContent={props.disableToggleSideContent}
                >
                  {!props.disableToggleSideContent ? (
                    <SidebarImage
                      alt="arrows-expend"
                      src={Icons.ArrowsCollapse}
                    ></SidebarImage>
                  ) : null}
                </SidebarCloser>
              ) : null}
              {props.rightSideContent}
            </SideBar>
          )
        ) : null}
      </HeaderSafeArea>
    </>
  );
}

export default SlottedPage;
