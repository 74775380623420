import { BORDER_WIDTH, Colors } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import Typography from '../../typography/typography';

export interface PatientInformationDisplayProps {
  value: string;
  placeholder: string;
  color?: 'success';
}

const StyledPatientInformationDisplay = styled.View`
  flex: 1;
`;

const Underline = styled.View<{ color?: 'success' }>`
  height: 10px;
  border-bottom-width: ${BORDER_WIDTH}px;
  border-bottom-color: ${(props) =>
    props?.color === 'success' ? Colors.BRAND_SUCCESS : Colors.BRAND_BLACK};
`;

export function PatientInformationDisplay(
  props: PatientInformationDisplayProps
) {
  const { value, placeholder, color } = props;
  return (
    <StyledPatientInformationDisplay>
      <Typography color={color} size="normal">
        {value || placeholder}
      </Typography>
      <Underline color={color} />
    </StyledPatientInformationDisplay>
  );
}

export default PatientInformationDisplay;
