import * as Sentry from '@sentry/react';
import { Breadcrumb, SeverityLevel } from '@sentry/react';
import * as logger from 'loglevel';

const isReactNative =
  typeof navigator !== 'undefined' && navigator.product === 'ReactNative';

export class Logger {
  constructor() {}

  static setLevel(level: logger.LogLevelDesc) {
    logger.setLevel(level, false);
  }
  static trace?(logDataInput: string | LogData) {
    log(logDataInput, isReactNative ? console.log : logger.trace, 'log');
  }
  static debug?(logDataInput: string | LogData) {
    log(logDataInput, isReactNative ? console.log : logger.debug, 'debug');
  }
  static info(logDataInput: string | LogData) {
    log(logDataInput, isReactNative ? console.log : logger.info, 'info');
  }
  static error(logDataInput: string | LogData) {
    log(logDataInput, isReactNative ? console.log : logger.error, 'error');
  }
  static warn(logDataInput: string | LogData) {
    log(logDataInput, isReactNative ? console.warn : logger.warn, 'warning');
  }
}

function log(
  logDataInput: string | LogData,
  logger: (LogData) => void,
  severity: SeverityLevel
) {
  const logData = toLogData(logDataInput);
  logger(logData);

  Sentry.addBreadcrumb(createSentryBreadCrumb(logData, severity));

  if (severity === 'error') {
    if (logData?.error) {
      Sentry.captureException(logData?.error, {
        fingerprint: logData.message ? [logData.message] : undefined,
      });
    } else {
      Sentry.captureMessage(logData.message, severity);
    }
  }
}

function toLogData(input: string | LogData): LogData {
  if (typeof input === 'string') {
    return { message: input };
  } else {
    return input;
  }
}

function createSentryBreadCrumb(
  logdata: LogData,
  level: SeverityLevel
): Breadcrumb {
  return {
    category: 'console',
    level: level,
    data: logdata.extras,
    message: logdata.context ? `[${logdata.context}] ` : '' + logdata.message,
  };
}

export interface LogData {
  // Optional message. A message is not strictly required
  message?: string;
  extras?: any;
  context?: string;
  // Optional error being logged
  error?: Error;
}
