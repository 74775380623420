/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei';
import { ReactNode, forwardRef } from 'react';
import femaleDraco from './female-draco.glb';

export const FemaleModel = forwardRef<
  any,
  { children?: ReactNode | undefined }
>(({ ...props }, ref) => {
  const { nodes, materials } = useGLTF(femaleDraco, true) as any;

  const material = nodes.FemaleMuscularBaseMesh001.material;
  material.metalness = 0;
  return (
    <group {...props} dispose={null}>
      <mesh
        ref={ref}
        geometry={nodes.FemaleMuscularBaseMesh001.geometry}
        material={material}
      />
    </group>
  );
});

export default FemaleModel;
