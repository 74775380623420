import {
  LesionImageDto,
  LesionImageFocalPoint,
  LesionTriageDto,
  UpdateLesionTriageDto,
} from '@dermloop/api-dtos';
import { PhotoType } from '@dermloop/static-values';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type EditLesionType = 'patient' | 'lesion' | null;

export type LesionTriageSliceTask = {
  lesionTriageId: string;
  lesionId: string;
  type:
    | 'upload-clinical-image'
    | 'upload-dermoscopic-image'
    | 'update'
    | 'delete';
};

export type EditState = {
  editType?: EditLesionType;
  selectedLesionTriageId: string;
  healthRecordId: string;
  lesionId: string;
};
export interface LesionTriageSlice {
  lesionTriageTasks?: LesionTriageSliceTask[];
  lesionTriageFailedTasks?: LesionTriageSliceTask[];
  currentLesionTriage?: LesionTriageDto;
  fetchingCurrentLesionTriage?: boolean;
  fetchedCurrentLesionTriage?: boolean;
  pendingTriageUpdate?: { id: string; lesionId: string }[];
  selectedClinicalImageId?: string;
  selectedDermoscopicImageId?: string;
  editState?: EditState;
}

const initialState: LesionTriageSlice = {
  lesionTriageTasks: [],
  lesionTriageFailedTasks: [],
};

const slice = createSlice({
  name: 'lesionTriage',
  initialState,
  reducers: {
    getCurrentLesionTriage: (state, _: PayloadAction<{ triageId: string }>) => {
      return {
        ...state,
        fetchingCurrentLesionTriage: true,
        fetchedCurrentLesionTriage: false,
      };
    },
    update: (state, action: PayloadAction<Partial<LesionTriageSlice>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setEditState: (state, action: PayloadAction<EditState>) => {
      return {
        ...state,
        editState: action.payload,
      };
    },
    updateLesionTriage: (
      state,
      action: PayloadAction<{
        lesionUpdate: Partial<UpdateLesionTriageDto>;
        succeededTask?: LesionTriageSliceTask;
      }>
    ) => {
      return {
        ...state,
        currentLesionTriage: {
          ...state.currentLesionTriage,
          ...action.payload.lesionUpdate,
        },
        lesionTriageTasks: state.lesionTriageTasks.filter(
          (t) =>
            t.lesionTriageId !== action.payload.succeededTask?.lesionTriageId ||
            t.type !== action.payload.succeededTask?.type
        ),
        lesionTriageFailedTasks: state.lesionTriageFailedTasks.filter(
          (t) =>
            t.lesionTriageId !== action.payload.succeededTask?.lesionTriageId ||
            t.type !== action.payload.succeededTask?.type
        ),
      };
    },
    deleteLesionTriage: (
      state,
      action: PayloadAction<{
        lesionId: string;
        lesionTriageId: string;
        updateLesionTriages?: boolean;
      }>
    ) => {
      const isDeleting = state.lesionTriageTasks.find(
        (t) =>
          t.lesionTriageId === action.payload.lesionTriageId &&
          t.type === 'delete'
      );
      if (isDeleting) {
        return { ...state };
      }
      const newTasks: LesionTriageSliceTask[] = [
        ...state.lesionTriageTasks,
        {
          lesionTriageId: action.payload.lesionTriageId,
          lesionId: action.payload.lesionId,
          type: 'delete',
        },
      ];
      return {
        ...state,
        lesionTriageTasks: newTasks,
        lesionTriageFailedTasks: state.lesionTriageFailedTasks.filter(
          (ft) =>
            ft.lesionTriageId === action.payload.lesionTriageId &&
            ft.type === 'delete'
        ),
      };
    },
    deleteLesionImage: (
      state,
      action: PayloadAction<{
        imageId: string;
        photoType: PhotoType;
      }>
    ) => {
      return {
        ...state,
        currentLesionTriage: {
          ...state.currentLesionTriage,
          clinicalImages:
            action.payload.photoType === 'Clinical'
              ? state.currentLesionTriage?.clinicalImages?.filter(
                  (image) => image.id !== action.payload.imageId
                )
              : state.currentLesionTriage?.clinicalImages,
          dermoscopicImages:
            action.payload.photoType === 'Dermoscopy'
              ? state.currentLesionTriage?.dermoscopicImages?.filter(
                  (image) => image.id !== action.payload.imageId
                )
              : state.currentLesionTriage?.dermoscopicImages,
        },
      };
    },
    deletedLesionTriage: (
      state,
      action: PayloadAction<{
        lesionId: string;
        lesionTriageId: string;
        updateLesionTriages?: boolean;
      }>
    ) => {
      return {
        ...state,
        lesionTriageTasks: state.lesionTriageTasks.filter(
          (t) =>
            t.lesionTriageId !== action.payload.lesionTriageId &&
            t.type === 'delete'
        ),
        lesionTriageFailedTasks: state.lesionTriageFailedTasks.filter(
          (t) =>
            t.lesionTriageId !== action.payload.lesionTriageId &&
            t.type === 'delete'
        ),
      };
    },
    persistLesionImage: (
      state,
      action: PayloadAction<{
        temporaryImageId: string;
        image: LesionImageFocalPoint;
        uri: string;
        flashUri?: string;
        sharpness?: number;
        flashSharpness?: number;
        lesionId: string;
        lesionTriageId: string;
        type: PhotoType;
      }>
    ) => {
      const isCreating = state.lesionTriageTasks.find(
        (t) =>
          t.lesionTriageId === action.payload.lesionTriageId &&
          t.type === `upload-${action.payload.type}-image`
      );
      if (isCreating) {
        return { ...state };
      }
      const newTasks: LesionTriageSliceTask[] = [
        ...state.lesionTriageTasks,
        {
          lesionTriageId: action.payload.lesionTriageId,
          lesionId: action.payload.lesionId,
          type:
            action.payload.type === 'Clinical'
              ? 'upload-clinical-image'
              : 'upload-dermoscopic-image',
        },
      ];

      const newImage: LesionImageDto = {
        id: action.payload.temporaryImageId,
        alternativeImageThumbnailXsUrl: action.payload.flashUri,
        alternativeImageThumbnailSUrl: action.payload.flashUri,
        alternativeImageThumbnailLUrl: action.payload.flashUri,
        alternativeImageUrl: action.payload.flashUri,
        thumbnailXsUrl: action.payload.uri,
        thumbnailSUrl: action.payload.uri,
        thumbnailLUrl: action.payload.uri,
        url: action.payload.uri,
        focalPointX: action.payload.image.focalPointX,
        focalPointY: action.payload.image.focalPointY,
        sharpness: action.payload.sharpness,
        alternativeImageSharpness: action.payload.flashSharpness,
      };

      return {
        ...state,
        lesionTriageTasks: newTasks,
        currentLesionTriage: {
          ...state.currentLesionTriage,
          clinicalImages:
            action.payload.type === 'Clinical' &&
            action.payload.lesionTriageId === state.currentLesionTriage?.id
              ? [...(state.currentLesionTriage?.clinicalImages ?? []), newImage]
              : state.currentLesionTriage?.clinicalImages,
          dermoscopicImages:
            action.payload.type === 'Dermoscopy' &&
            action.payload.lesionTriageId === state.currentLesionTriage?.id
              ? [
                  ...(state.currentLesionTriage?.dermoscopicImages ?? []),
                  newImage,
                ]
              : state.currentLesionTriage?.dermoscopicImages,
        },
      };
    },
    errorPersistingLesionImage: (
      state,
      action: PayloadAction<{
        /**
         * Might either be an actual id or a temporary id
         */
        id: string;
        type: PhotoType;
      }>
    ) => {
      return {
        ...state,
        currentLesionTriage: {
          ...state.currentLesionTriage,
          clinicalImages:
            action.payload.type === 'Clinical'
              ? state.currentLesionTriage?.clinicalImages?.filter(
                  (l) => l.id !== action.payload.id
                )
              : state.currentLesionTriage?.clinicalImages,
          dermoscopicImages:
            action.payload.type === 'Dermoscopy'
              ? state.currentLesionTriage?.dermoscopicImages?.filter(
                  (l) => l.id !== action.payload.id
                )
              : state.currentLesionTriage?.dermoscopicImages,
        },
      };
    },
    updateLesionImage: (
      state,
      action: PayloadAction<{
        temporaryImageId: string;
        image: LesionImageDto;
        lesionId: string;
        lesiontriageId: string;
        type: PhotoType;
      }>
    ) => {
      return { ...state };
    },
    error: (
      state,
      action: PayloadAction<{
        message: string;
        failedTask?: LesionTriageSliceTask;
        error?: Error;
      }>
    ) => {
      if (!action.payload.failedTask) return { ...state };
      return {
        ...state,
        errorMessage: action.payload.message,
        lesionTriageTasks: state.lesionTriageTasks.filter(
          (t) =>
            t.lesionTriageId !== action.payload.failedTask?.lesionTriageId &&
            t.type === action.payload.failedTask?.type
        ),
        lesionTriageFailedTasks: [
          ...state.lesionTriageFailedTasks,
          action.payload.failedTask,
        ],
      };
    },
  },
});

export const lesionTriageActions = slice.actions;
export const lesionTriageReducer = slice.reducer;
