import {
  LesionIndexDataDto,
  TrainingAssessmentIndexDataDto,
  TrainingLesionIndexDataDto,
  UserStatisticsIndexDto,
} from './index-dtos';

export enum DermloopSearchIndexName {
  Lesion = 'lesion',
  TrainingAssessment = 'training-assessment',
  TrainingLesion = 'training-lesion',
  UserStatistics = 'user-statistics',
}

/**
 * Mapping of search index names to their data DTOs.
 */
export type DermloopSearchIndexNameDtoMapping = {
  [DermloopSearchIndexName.Lesion]: LesionIndexDataDto;
  [DermloopSearchIndexName.TrainingAssessment]: TrainingAssessmentIndexDataDto;
  [DermloopSearchIndexName.TrainingLesion]: TrainingLesionIndexDataDto;
  [DermloopSearchIndexName.UserStatistics]: UserStatisticsIndexDto;
};
