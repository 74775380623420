import styled from 'styled-components';
import Banner from './components/banner/banner';
import { Route, Switch } from 'react-router-dom';
import OverviewPage from './pages/overview-page/overview-page';
import SessionPage from './pages/session-page/session-page';
import { dermloopApi } from './store/api';
import { useAppSelector } from './store/hooks';
import { useRtkNullDataIfSkip } from '@dermloop/redux';

const StyledApp = styled.div`
  width: 100%;
`;

/**
 *
 */
export function App() {
  const { token } = useAppSelector((s) => s.authentication);
  const { data: currentUser } = useRtkNullDataIfSkip({
    query: dermloopApi.useGetCurrentUserQuery,
    queryData: null,
    skipPredicate: () => !token,
  });
  return (
    <Switch>
      <Route exact path={['', '/']}>
        <StyledApp>
          <Banner></Banner>
          <StyledTextContent id="description">
            <h2>Trial description</h2>
            <p>
              When a doctor suspects a skin lesion to be malignant, it is
              removed by a surgeon. Afterward, a pathologist assesses it under
              the microscope, determining whether the skin lesion is benign or
              malignant. However, interpreting a skin lesion is not always
              straightforward. Since pathologists can’t analyze every millimeter
              of the skin lesion, and they have not seen it macroscopically,
              they may be missing critical information about whether the lesion
              has malignant potential or not.
            </p>
            <p>
              We have collected more than 200 anonymous potentially malignant
              skin lesion cases for this study. Each case consists of a clinical
              image, a dermoscopic image, and 1-2 histopathological images.
            </p>
            <p>
              It also includes information about the localization of the skin
              lesion, the sex and age of the patient at the time of surgery, and
              essential information about the lesion and the patient's history.
            </p>
            <p>
              By participating, you provide more information about how clinical
              data affect the final diagnosis made by the pathologist. This may
              have an impact on how we diagnose skin lesions in the future.
            </p>
          </StyledTextContent>
        </StyledApp>
      </Route>
      {currentUser ? (
        <>
          <Route path={'/overview'}>
            <OverviewPage />
          </Route>

          <Route path={'/session'} exact={true}>
            <SessionPage />
          </Route>
          <Route path={'/session/:lesionIdentifier'}>
            <SessionPage />
          </Route>
        </>
      ) : null}
    </Switch>
  );
}

export default App;

const StyledTextContent = styled.div`
  max-width: 30%;
  margin: 30px auto;
`;
