import { Colors, GENERAL_SPACING } from '@dermloop/ui/util';
import OpenSeadragon from 'openseadragon';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import '../../../../../../../../node_modules/openseadragonimagefilters/openseadragon-filtering/openseadragon-filtering.js';
import Knob from './knob';

const ViewerContainer = styled.div<{ fitPositionedContainer?: boolean }>`
  ${(props) =>
    props.fitPositionedContainer
      ? 'position: absolute;left: 0;right: 0;bottom: 0;top: 0;'
      : 'padding-bottom: 100%;width: 100%;position: relative;'}
`;

const OpenSeaDragonViewerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

const CircleOverlay = styled.div<{ hidden?: boolean }>`
  position: absolute;
  display: none;
  padding: 5rem;
  border: 2px solid ${Colors.BRAND_WHITE};
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.hidden ? 0 : 1)};
`;

const StyledViewerTools = styled.div`
  position: absolute;
  bottom: ${GENERAL_SPACING}px;
  left: ${GENERAL_SPACING}px;
`;

export interface OpenSeaDragonViewerProps {
  enableRotation?: boolean;
  rotation?: number;
  onRotationChange?: (rotation: number) => void;
  /**
   * Target imageUrl
   */
  imageUrl?: string;

  /**
   * Additional image urls that are added in order to osd viewer.
   * Currently only support non *.dzi files.
   */
  imageUrls?: string[];
  imageId: string;

  focalPointX: number;
  focalPointY: number;
  hasOutline: boolean;
  showOutline: boolean;
  fitPositionedContainer?: boolean;
  /**
   * Is only applied to tiled sources
   */
  gammaCorrectionValue?: number;
}

const OpenSeaDragonViewer = (props: OpenSeaDragonViewerProps) => {
  const [viewer, setViewer] = useState<OpenSeadragon.Viewer>();
  const [localRotation, setLocalRotation] = useState(0);
  const externallyControlledRotation = typeof props.rotation === 'number';

  useEffect(() => {
    if (
      viewer?.viewport &&
      viewer?.drawer?.canRotate &&
      externallyControlledRotation
    ) {
      viewer.viewport.setRotation(props.rotation);
    }
  }, [props.rotation, viewer, externallyControlledRotation]);

  const InitOpenseadragon = () => {
    viewer && viewer.destroy();
    const osd = OpenSeadragon({
      crossOriginPolicy: props.gammaCorrectionValue ? 'Anonymous' : undefined,
      id: props.imageId,
      // animationTime: 0.25,
      blendTime: 0.5,
      constrainDuringPan: true,
      maxZoomPixelRatio: 5,
      gestureSettingsTouch: {
        pinchToZoom: true,
        scrollToZoom: false,
        clickToZoom: false,
      },
      minZoomImageRatio: 1,
      visibilityRatio: 1,
      // zoomPerScroll: 1.2,
      // zoomPerClick: 1.3,
      showNavigationControl: false,
    });

    setViewer(osd);
    return osd;
  };

  const toggleFocalPoint = (viewer: OpenSeadragon.Viewer) => {
    if (viewer && props.hasOutline) {
      const point = new OpenSeadragon.Point(
        props.focalPointX,
        props.focalPointY
      );

      const id = props.imageId + '-focal-point';
      const overlay = viewer.getOverlayById(id);

      if (overlay) viewer.removeOverlay(id);
      else {
        //When adding an overlay osd removes the node from dom therefore we have to clone the node.
        //To enable toggling the overlay on and off the element must be added like this.
        const focalPointElement = document
          .getElementById(id + '-raw')
          .cloneNode();

        if (focalPointElement instanceof HTMLElement) {
          focalPointElement.id = id;
          focalPointElement.style.display = 'block';
          viewer.addOverlay(focalPointElement, point);
        }
      }
    }
  };
  useEffect(() => {
    if (props.imageUrl && viewer) {
      if (props.imageUrl.includes('.dzi')) {
        viewer.addTiledImage({ tileSource: props.imageUrl });

        if (props.gammaCorrectionValue && 'setFilterOptions' in viewer) {
          // A bit hacky code to apply gamma correction filter
          // https://pages.nist.gov/OpenSeadragonFiltering/
          const v = viewer as OpenSeadragon.Viewer & {
            setFilterOptions: (config: unknown) => void;
          };
          v.setFilterOptions({
            filters: {
              processors: [
                OpenSeadragon['Filters'].GAMMA(props.gammaCorrectionValue),
              ],
            },
            loadMode: 'async',
          });
        }
      } else {
        if (props.imageUrls?.length) {
          for (const url of props.imageUrls) {
            viewer.addSimpleImage({ url: url });
          }
        } else if (props.imageUrl) {
          viewer.addSimpleImage({ url: props.imageUrl });
        }
      }
      setLocalRotation(0);
    }

    toggleFocalPoint(viewer);
  }, [viewer, props.imageUrl]);

  useEffect(() => {
    toggleFocalPoint(viewer);
  }, [props.showOutline]);

  useEffect(() => {
    InitOpenseadragon();

    return () => {
      viewer && viewer.destroy();
    };
  }, [props.imageUrl]);

  return (
    <ViewerContainer fitPositionedContainer={props.fitPositionedContainer}>
      <OpenSeaDragonViewerContainer
        id={props.imageId}
      ></OpenSeaDragonViewerContainer>
      {props.hasOutline ? (
        <CircleOverlay
          className="hidden"
          id={props.imageId + '-focal-point-raw'}
        ></CircleOverlay>
      ) : null}
      <StyledViewerTools>
        {props.enableRotation ? (
          <Knob
            rotation={
              externallyControlledRotation ? props.rotation : localRotation
            }
            onRotationChange={(r) => {
              if (viewer.viewport) {
                if (externallyControlledRotation) {
                  props.onRotationChange(r);
                } else {
                  viewer.viewport.setRotation(r);
                  setLocalRotation(r);
                }
              }
            }}
          />
        ) : null}
      </StyledViewerTools>
    </ViewerContainer>
  );
};

export { OpenSeaDragonViewer };
