import { RiskFactorDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const riskFactorEndpoints = (builder: EndpointBuilderType) => ({
  getRiskFactors: builder.query<RiskFactorDto[], void>({
    query: () => 'risk-factors',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'RISK_FACTORS', id: 'LIST' }],
  }),
});
