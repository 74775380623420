import { BaseButton } from '@dermloop/ui/components';
import styled from 'styled-components';
import LoadingIndicator from '../loading-indicator/loading-indicator';

const StyledButton = styled.button<
  Pick<BaseButton.Props, 'color' | 'fill' | 'textColor' | 'cursorDisabled'>
>`
  ${(props) => BaseButton.styles(props)}
`;

export function Button(props: BaseButton.Props) {
  return (
    <StyledButton
      onClick={props.onPress}
      fill={props.fill}
      color={props.color}
      textColor={props.textColor}
      cursorDisabled={props.cursorDisabled}
    >
      {props.loading ? <LoadingIndicator /> : props.name}
    </StyledButton>
  );
}

export default Button;
