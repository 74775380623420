import { baseEnvironment } from '@dermloop/frontend-environment';
import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { MinimalState } from '../state';
import { ApiNameType } from './constants';
import {
  appearanceChangeEndpoints,
  approximateAgeEndpoints,
  biopsyTypeEndpoints,
  dahtEndPoints,
  diagnosisEndpoints,
  diagnosticDifficutlyEndpoints,
  fieldConfigurationEndpoints,
  genderEndpoints,
  healthRecordEndpoints,
  lesionEndpoints,
  lesionHistoryEndpoints,
  lesionImageEndpoints,
  lesionLocationEndpoints,
  lesionTriageEndpoints,
  macroscopicInspectionTypeEndpoints,
  medicalUnitUserAffiliationEndpoints,
  objectiveFindingEndpoints,
  occupationEndpoints,
  patientDetailsEndpoints,
  riskFactorEndpoints,
  skinTypeEndpoints,
  specialityEndpoints,
  symptomsEndpoints,
  treatmentsEndpoints,
  userEndpoints,
} from './endpoints';
import { DermloopBaseApiTagTypes } from './tag-types';

/**
 * Interface for select API query result parameters.
 */
export interface ApiQueryResult<T> {
  data: T;
  error: FetchBaseQueryError;
  isLoading: boolean;
}

const reducerPath: ApiNameType = 'dermloopApi';
export const dermloopBaseApi = createApi({
  reducerPath,
  baseQuery: fetchBaseQuery({
    baseUrl: baseEnvironment.API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as MinimalState)?.authentication?.token;
      const medicalUnitId = (getState() as MinimalState)?.authentication
        ?.medicalUnit?.id;

      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-medical-unit', medicalUnitId);

      return headers;
    },
  }),
  // baseQuery: async (args, api, extraOptions) => {
  //   const rawBaseQuery = fetchBaseQuery({
  //     baseUrl: 'http://0.0.0.0:3333',
  //     prepareHeaders: (headers, { getState }) => {
  //       const token = (getState() as MinimalState)?.authentication?.token;
  //       const medicalUnitId = (getState() as MinimalState)?.authentication
  //         ?.medicalUnit?.id;

  //       headers.set('authorization', `Bearer ${token}`);
  //       headers.set('x-medical-unit', medicalUnitId);

  //       return headers;
  //     },
  //   });
  //   return rawBaseQuery(args, api, extraOptions);
  tagTypes: DermloopBaseApiTagTypes,
  endpoints: (builder) => ({
    ...appearanceChangeEndpoints(builder),
    ...approximateAgeEndpoints(builder),
    ...biopsyTypeEndpoints(builder),
    ...diagnosisEndpoints(builder),
    ...diagnosticDifficutlyEndpoints(builder),
    ...genderEndpoints(builder),
    ...lesionLocationEndpoints(builder),
    ...lesionTriageEndpoints(builder),
    ...macroscopicInspectionTypeEndpoints(builder),
    ...medicalUnitUserAffiliationEndpoints(builder),
    ...objectiveFindingEndpoints(builder),
    ...riskFactorEndpoints(builder),
    ...skinTypeEndpoints(builder),
    ...symptomsEndpoints(builder),
    ...treatmentsEndpoints(builder),
    ...healthRecordEndpoints(builder),
    ...fieldConfigurationEndpoints(builder),
    ...lesionHistoryEndpoints(builder),
    ...lesionEndpoints(builder),
    ...lesionImageEndpoints(builder),
    ...patientDetailsEndpoints(builder),
    ...occupationEndpoints(builder),
    ...specialityEndpoints(builder),
    ...dahtEndPoints(builder),
    ...userEndpoints(builder),
  }),
});
