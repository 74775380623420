export const DermloopBaseApiTagTypes = [
  'APPROXIMATE_AGES',
  'BIOPSY_TYPES',
  'DERMOSCOPIC_CRITERIA',
  'CHUCK_NORRIS',
  'DIFFICULTY_LEVEL_GROUPINGS',
  'DIAGNOSES',
  'DEVELOPER',
  'DIAGNOSIS_GROUPS',
  'DIAGNOSIS_GROUP_DISTRIBUTIONS',
  'DIAGNOSTIC_DIFFICULTIES',
  'GENDERS',
  'LESION_LOCATIONS',
  'MACROSCOPIC_INSPECTION_TYPES',
  'OBJECTIVE_FINDINGS',
  'OCCUPATIONS',
  'RISK_FACTORS',
  'SKIN_TYPES',
  'TREATMENTS',
  'SYMPTOMS',
  'SPECIALITIES',
  'APPEARANCE_CHANGES',
  'HEALTH_RECORD',
  'LESION_TRIAGES',
  'LESION',
  'LESION_IMAGE',
  'LESION2',
  'RECENT_LESIONS',
  'RECENT_LESIONS2',
  'USERS',
  'GENERAL_SYMPTOMS',
  'FIELD_CONFIGURATION',
  'FIELD_CONFIGURATIONS',
  'LESION_HISTORY',
  'MEDICAL_UNIT_USER_AFFILIATIONS',
  'JURISDICTIONAL_GROUPS',
  'PATIENT_DETAILS',
  'SKIN_TYPE_CONDITION',
  'MEDICAL_UNIT_GROUPS',
  'POLARIZED_IMAGES',
  'REPORTS',
  'LEVELS',
  'LESSONS',
  'LESION_IMPORTS',
  'REPORT_TYPES',
  'DAHT_SESSION',
  'DAHT_LESIONS',
  'OCCUPATIONS',
  'MEDICAL_UNITS',
  'TRAINING_LESION',
  'TRAINING_SESSION_SIMULATION',
  'TRAINING_LESION_STATUSES',
  'COUNTRIES',
  'COURSES',
  'REFERRALS',
] as const;

export type DermloopBaseApiTag = typeof DermloopBaseApiTagTypes[number];
