import {
  CreateHealthRecordDto,
  HealthRecordDto,
  SearchHealthRecordRequestDto,
  UpdateHealthRecordConsentDto,
} from '@dermloop/api-dtos';
import { Api, makeSafeRequest } from '../api-client';

export function searchHealthRecord(dto: SearchHealthRecordRequestDto) {
  return makeSafeRequest(
    Api.post<HealthRecordDto[]>('health-records/search', dto)
  );
}

export function getHealthRecord(id: string) {
  return makeSafeRequest(Api.get<HealthRecordDto>('health-records/' + id));
}

export function createHealthRecord(dto: CreateHealthRecordDto) {
  return makeSafeRequest(Api.post<HealthRecordDto>('health-records', dto));
}

export function updateConsent(id: string, dto: UpdateHealthRecordConsentDto) {
  return makeSafeRequest(
    Api.put<HealthRecordDto>(`health-records/${id}/consent`, dto)
  );
}
