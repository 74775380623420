import { IsNumber, ValidateNested } from 'class-validator';

export class ModelViewerLocationVector {
  @IsNumber()
  x: number;

  @IsNumber()
  y: number;

  @IsNumber()
  z: number;
}

export class ModelViewerLocation {
  @ValidateNested()
  normal: ModelViewerLocationVector;

  @ValidateNested()
  position: ModelViewerLocationVector;
}
