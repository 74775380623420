import { Colors, DESKTOP_HEADER_HEIGHT } from '@dermloop/ui/util';
import styled from 'styled-components';

/**
 * Component for creating a styled content page. This component supports
 * a left and right non-collapsable sidebar. Both are optional.
 *
 * | titleContent                                                     |
 * --------------------------------------------------------------------
 * | leftSideContent | mainContent                 | rightSideContent |
 * |                 |                             |                  |
 */

/* eslint-disable-next-line */
export interface SlottedContentPageProps {
  mainContent: JSX.Element;
  titleContent: JSX.Element;
  // Optional height of header (including unit, e.g. px, vh etc.). If not set, no height will be given to header container
  titleContentHeight?: string;
  leftSideContent?: JSX.Element;
  // Should there be displayed a divider between left content and main content?
  leftContentDivider?: boolean;
  // Optional background color. If none provided, white is used by default
  leftBackgroundColor?: string;
  leftContentDividerThicknessPx?: number;
  leftContentDividerColor?: string;
  rightSideContent?: JSX.Element;
  // Should there be displayed a divider between main content and right content?
  rightContentDivider?: boolean;
  rightContentDividerThicknessPx?: number;
  rightContentDividerColor?: string;
  // Optional background color. If none provided, white is used by default
  rightBackgroundColor?: string;
  // Optionally provide minimum width of left or right sidebar. E.g. '200px'
  minLeftWidth?: string;
  minRightWidth?: string;
}

export function SlottedContentPage(props: SlottedContentPageProps) {
  return (
    <StyledSlottedContentPage>
      <TitleContent>{props.titleContent}</TitleContent>
      <MainFlow>
        {props.leftSideContent ? (
          <SideContent
            isLeftContent={true}
            contentDivider={props.leftContentDivider}
            contentDividerThicknessPx={props.leftContentDividerThicknessPx}
            contentDividerColor={props.leftContentDividerColor}
            backgroundColor={props.leftBackgroundColor}
            minWidth={props.minLeftWidth}
          >
            {props.leftSideContent}
          </SideContent>
        ) : null}

        <MainContent>{props.mainContent}</MainContent>

        {props.rightSideContent ? (
          <SideContent
            isLeftContent={false}
            contentDivider={props.rightContentDivider}
            contentDividerThicknessPx={props.rightContentDividerThicknessPx}
            contentDividerColor={props.rightContentDividerColor}
            backgroundColor={props.rightBackgroundColor}
            minWidth={props.minRightWidth}
          >
            {props.rightSideContent}
          </SideContent>
        ) : null}
      </MainFlow>
    </StyledSlottedContentPage>
  );
}

export default SlottedContentPage;

const SideContent = styled.div<{
  isLeftContent: boolean;
  contentDivider?: boolean;
  contentDividerThicknessPx?: number;
  contentDividerColor?: string;
  backgroundColor?: string;
  minWidth?: string;
}>`
  background-color: ${(props) =>
    props.backgroundColor || Colors.BACKGROUND_WHITE};
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.contentDivider
      ? `border-${props.isLeftContent ? 'right' : 'left'}: ${
          props.contentDividerThicknessPx > 0
            ? props.contentDividerThicknessPx
            : 1
        }px solid ${props.contentDividerColor || Colors.BRAND_TERTIARY};`
      : ''}
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : '')}
`;

const MainContent = styled.div`
  background-color: ${Colors.BACKGROUND_WHITE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TitleContent = styled.div`
  display: flex;
  min-height: ${DESKTOP_HEADER_HEIGHT}px;
`;

const MainFlow = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

const StyledSlottedContentPage = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-flow: column;
`;
