import { ClinicalPatient, Page } from '@dermloop/legacy-api-consumer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ClinicalPatientSlice {
  searchQuery?: string;
  searchResults?: Page<ClinicalPatient>;
  searching?: boolean;
  currentPatient?: ClinicalPatient;
  fetchingCurrentPatient?: boolean;
}

const initialState: ClinicalPatientSlice = {};

const clinicalPatientSlice = createSlice({
  name: 'clinicalPatient',
  initialState,
  reducers: {
    fetchCurrentPatient: (state, _: PayloadAction<{ uid: string }>) => {
      return { ...state, fetchingCurrentPatient: true };
    },
    search: (state, action: PayloadAction<{ query: string }>) => {
      return {
        ...state,
        searching: true,
        searchQuery: action.payload.query,
      };
    },
    update: (state, action: PayloadAction<Partial<ClinicalPatientSlice>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    error: (state) => {
      return {
        ...state,
        searching: false,
      };
    },
  },
});

export const clinicalPatientActions = clinicalPatientSlice.actions;
export const clinicalPatientReducer = clinicalPatientSlice.reducer;
