import {
  BUTTON_BORDER_RADIUS,
  Colors,
  NATIVE_BORDER_WIDTH,
  TAG_HEIGHT,
  TAG_WIDTH,
} from '@dermloop/ui/util';
import styled from 'styled-components/native';
import { webSpecificValue } from '../utils';

/* eslint-disable-next-line */
export interface TagProps {
  // Tag text
  children: string;

  // Use an existing color profile. Custom background, text and border colors have precedence
  // Defaults to 'green' if neither color nor custom colors are provided
  color?: TagColor;

  // Optional custom background color (rgb or rgba)
  backgroundColor?: string;

  // Optional custom text color (rgb or rgba)
  textColor?: string;

  // Optional custom border color (rgb or rgba)
  borderColor?: string;

  // If dynamic width, the tag width will be set to the width of the text. Else uses default tag width
  // Defaults to false
  dynamicWidth?: boolean;
}

export type TagColor = 'green' | 'yellow' | 'red';

/**
 * Get the tag background color.
 * @param color
 * @returns
 */
const getBackgroundColor = (color: TagColor) => {
  switch (color) {
    case 'green':
      return Colors.LIGHT_BRAND_SUCCESS;
    case 'yellow':
      return Colors.TAG_YELLOW_BACKGROUND;
    case 'red':
      return Colors.TAG_RED_BACKGROUND;
  }
};

/**
 * Get the color of tag text.
 * @param color
 * @returns
 */
const getTextColor = (color: TagColor) => {
  switch (color) {
    case 'green':
      return Colors.BRAND_SUCCESS;
    case 'yellow':
      return Colors.BRAND_MANDATORY;
    case 'red':
      return Colors.BRAND_WARNING;
  }
};

/**
 * Get color of tag border. Currently same color as text.
 * @param color
 * @returns
 */
const getBorderColor = (color: TagColor) => getTextColor(color);

const TagStyle = styled.View<{
  color?: TagColor;
  backgroundColor?: string;
  borderColor?: string;
  dynamicWidth?: boolean;
}>`
  ${webSpecificValue('position: static;')};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: auto;
  min-height: ${TAG_HEIGHT}px;
  padding: 0 16px;
  border-width: ${NATIVE_BORDER_WIDTH}px;
  border-radius: ${BUTTON_BORDER_RADIUS}px;
  border-color: ${(props) =>
    props.borderColor ?? getBorderColor(props.color || 'green')};
  background-color: ${(props) =>
    props.backgroundColor ?? getBackgroundColor(props.color || 'green')};
  ${(props) => (props.dynamicWidth ? '' : `width: ${TAG_WIDTH}px;`)}
`;

const TagTextStyle = styled.Text<{ color?: TagColor; textColor?: string }>`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.textColor ?? getTextColor(props.color || 'green')};
`;

export function Tag(props: TagProps) {
  return (
    <TagStyle
      color={props.color}
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
      dynamicWidth={props.dynamicWidth}
    >
      <TagTextStyle color={props.color} textColor={props.textColor}>
        {props.children}
      </TagTextStyle>
    </TagStyle>
  );
}

export default Tag;
