import { Colors, IconType } from '@dermloop/ui/util';
import styled from 'styled-components/native';
import Icon from '../icon/icon';

export interface PaginationSelectorProps {
  currentPage: number;
  totalPages: number;
  selectPage: (number) => void;
  // Maximum number of pages to show
  maxPageCount?: number;
}

const PaginationSelectorStyle = styled.View`
  position: static;
  z-index: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  /* Ensures user cannot do selections on component */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const PaginationArrowStyle = styled.Pressable`
  position: static;
  z-index: unset;
  padding: 7px;
`;

const SelectorStyle = styled.Pressable<{ isSelected?: boolean }>`
  position: static;
  z-index: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${(props) =>
    props.isSelected ? Colors.BRAND_SUCCESS : Colors.TRANSPARENT};
  border-radius: 2px;
`;
const SelectorTextStyle = styled.Text<{ isSelected?: boolean }>`
  font-size: 10px;
  font-weight: 500;
  color: ${(props) =>
    props.isSelected ? Colors.BRAND_WHITE : Colors.BRAND_SUCCESS};
`;

const DotSelectorStyle = styled.Text`
  position: static;
  z-index: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: 500;
  color: ${Colors.BRAND_SUCCESS};
`;

const getSelector = (
  value: number,
  isSelected: boolean,
  onPress: () => void
) => (
  <SelectorStyle key={value} isSelected={isSelected} onPress={onPress}>
    <SelectorTextStyle isSelected={isSelected}>{value}</SelectorTextStyle>
  </SelectorStyle>
);

const getDots = (key: number) => (
  <DotSelectorStyle key={key}>...</DotSelectorStyle>
);

const getArrowButton = (icon: IconType, onPress: () => void) => (
  <PaginationArrowStyle onPress={onPress}>
    <Icon icon={icon} />
  </PaginationArrowStyle>
);

export function PaginationSelector(props: PaginationSelectorProps) {
  // How many pages that are avaliable
  const pageCount = props.maxPageCount
    ? Math.min(props.totalPages, props.maxPageCount)
    : props.totalPages;

  return (
    <PaginationSelectorStyle>
      {getArrowButton('LeftArrow', () =>
        props.selectPage(props.currentPage > 1 ? props.currentPage - 1 : 1)
      )}
      {[...Array(pageCount)].map((_, i) => {
        // The number in the list is always i + 1
        const number = i + 1;
        const selected = props.currentPage === number;

        // 1st button must always be displayed
        if (number === 1) {
          return getSelector(number, selected, () => props.selectPage(number));
        }

        // Total number of pages must always be shown
        if (number === pageCount) {
          return getSelector(number, selected, () => props.selectPage(number));
        }

        // If the number is the current page
        if (number === props.currentPage) {
          return getSelector(number, selected, () => props.selectPage(number));
        }

        // Numbers 2 and 3 are always displayed if current page is less than or equal to 3
        if ([2, 3].includes(number) && props.currentPage <= 3) {
          return getSelector(number, selected, () => props.selectPage(number));
        }

        // The last two pages up to the total pages count must be displayed, if the current number is up there
        if (
          [pageCount - 1, pageCount - 2].includes(number) &&
          props.currentPage >= pageCount - 2
        ) {
          return getSelector(number, selected, () => props.selectPage(number));
        }

        // We want dots on each side of the selected number
        const diff = Math.abs(number - props.currentPage);
        if (
          diff === 1 ||
          (props.currentPage <= 3 && number === 4) ||
          (props.currentPage >= pageCount - 3 && number === pageCount - 3)
        ) {
          return getDots(number);
        }
        return null;
      })}
      {getArrowButton('RightArrow', () =>
        props.selectPage(
          props.currentPage < pageCount ? props.currentPage + 1 : pageCount
        )
      )}
    </PaginationSelectorStyle>
  );
}
