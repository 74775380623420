import { Colors } from '@dermloop/ui/util';
import { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import Button from '../button/button';

/**
 * NOTE!
 * This component was initially designed with a slider with two nobs. However, because of CI build problems,
 * it has now been changed with two text boxes.
 */

export interface SelectRangeProps {
  title: string;
  minValue: number;
  maxValue: number;
  startMinValue?: number;
  startMaxValue?: number;
  onChange: (min: number, max: number) => void;
  // Optional margin to give select range
  margin?: string;
}

const widthPx = 400;
const paddingLeftRightPx = 15;
// const markerDiameterPx = 20;
// const sliderTrackHeightPx = 3;
// // The width of the slider track in px
// const sliderTrackwidthPx = widthPx - paddingLeftRightPx * 2;
// // The x-value in px for the start position of the right marker
// const rightMarkerStartXValue =
//   sliderTrackwidthPx - paddingLeftRightPx * 2 - markerDiameterPx / 2;

// /**
//  * Function for creating a Pan Response Instance.
//  * TODO This method is slightly buggy. When the future x-value is above or below the getMinX or getMaxX,
//  * movement is cancelled. Instead, movement should be updated to exactly be allowed positioning on the min
//  * and max x value.
//  * @param pan               Animated value that stores position.
//  * @param getMinX           Minimum allowed x-value of the pan.
//  * @param getMaxX           Maximum allowed x-value of the pan.
//  * @param onPositionChange  Function to be called when position is updated.
//  * @returns
//  */
// const createPan = (
//   pan: Animated.Value,
//   getMinX: () => number,
//   getMaxX: () => number,
//   onPositionChange: (curX: number) => void
// ): PanResponderInstance => {
//   // Create listener for pan x-value changes
//   pan.addListener((state) => {
//     onPositionChange(state.value);
//   });

//   return PanResponder.create({
//     onMoveShouldSetPanResponder: (evt, gestureState) => {
//       // Only set pan responder if dx >= 1. Prevents clicking from setting pan responder
//       return Math.abs(gestureState.dx) >= 1;
//     },

//     onPanResponderGrant: () => {
//       // We use "as any" to hide vscode errors
//       pan.setOffset((pan as any)._value);
//       pan.setValue(0);
//     },
//     onPanResponderMove: (event, gestureState) => {
//       const curX = JSON.parse(JSON.stringify(pan));
//       const newX = curX + gestureState.dx;

//       if (newX < getMinX()) {
//         return;
//       }

//       if (newX > getMaxX()) {
//         return;
//       }

//       Animated.event([null, { dx: pan }], {
//         useNativeDriver: false,
//       })(event, gestureState);
//     },
//     onPanResponderRelease: (event, gestureState) => {
//       pan.flattenOffset();
//     },
//     onPanResponderTerminate: (evt, gestureState) => {},
//   });
// };

export function SelectRange(props: SelectRangeProps) {
  // /**
  //  * Function to map an x value to the corresponding numeric value.
  //  * The value is rounded to 0 decimals.
  //  * @param x
  //  * @param xCorrection Number to correct x. E.g. the right marker has initial x-value increased by the left marker diameter.
  //  * @returns
  //  */
  // const xToValue = (x: number, xCorrection: number) =>
  //   Math.round(props.maxValue / (sliderTrackwidthPx / (x + xCorrection)));

  // /**
  //  * Function to map a value to the corresponding x value. The x value is rounded to 0 decimals.
  //  * @param value
  //  * @param xCorrection
  //  * @returns
  //  */
  // const valueToX = (value: number, xCorrection: number) =>
  //   Math.round((value * sliderTrackwidthPx) / props.maxValue - xCorrection);

  // State for storing current selected value representation of the x-value of markers
  const [selectedMin, setSelectedMin] = useState<string>(
    (props.startMinValue || props.minValue).toString()
  );
  const [selectedMax, setSelectedMax] = useState<string>(
    (props.startMaxValue || props.maxValue).toString()
  );

  // // State for storing current x value of the two markers
  // const [curLeftX, setCurLeftX] = useState(() => valueToX(selectedMin, 0));
  // const [curRightX, setCurRightX] = useState(() =>
  //   valueToX(selectedMax, markerDiameterPx * 2)
  // );

  // // Animated values for using pans of the markers.
  // const panLeft = useRef(new Animated.Value(curLeftX)).current;
  // const panRight = useRef(new Animated.Value(curRightX)).current;

  // // Observables that handle updating the curLeftX and curRightX, as well as the selected min and max
  // // after some delay
  // const curLeftXObs = new Subject<number>();
  // const curRightXObs = new Subject<number>();

  // /**
  //  * Create subscriptions to curLeft and curRight observables.
  //  * Sets curLeftX and curRightX as soon as changes are noticed,
  //  * and updates selected min and max once every 50 ms, to ensure
  //  * filters are not updated rapidly
  //  */
  // useEffect(() => {
  //   curLeftXObs
  //     .pipe(
  //       // Updated current left x value
  //       tap((curX) => {
  //         setCurLeftX(curX);
  //       }),
  //       // Buffer for 50 ms before calling prop.onChange
  //       debounceTime(50)
  //     )
  //     .subscribe((curX) => {
  //       setSelectedMin(xToValue(curX, 0));
  //     });
  //   curRightXObs
  //     .pipe(
  //       // Updated current left x value
  //       tap((curX) => {
  //         setCurRightX(curX);
  //       }),
  //       // Buffer for 50 ms before calling prop.onChange
  //       debounceTime(50)
  //     )
  //     .subscribe((curX) => {
  //       setSelectedMax(xToValue(curX, markerDiameterPx * 2));
  //     });

  //   // Removes all subscriptions and listeners when component is removed
  //   return () => {
  //     if (panLeft) panLeft.removeAllListeners();
  //     if (panRight) panRight.removeAllListeners();
  //     curLeftXObs?.unsubscribe();
  //     curRightXObs?.unsubscribe();
  //   };
  // }, []);

  /**
   * When the selected minimum or maximum value is changed, invoke prop function.
   */
  useEffect(() => {
    props.onChange(+selectedMin, +selectedMax);
  }, [selectedMin, selectedMax]);

  /**
   * Resets the current filter. Sets min and max value and moves markers to start positions
   */
  const reset = () => {
    // setCurLeftX(0);
    // setCurRightX(rightMarkerStartXValue);
    // panLeft.setValue(0);
    // panRight.setValue(rightMarkerStartXValue);

    setSelectedMin(props.minValue.toString());
    setSelectedMax(props.maxValue.toString());
  };

  // /**
  //  * Create left and right pan responders.
  //  */
  // const panLeftResponder = React.useRef(
  //   createPan(
  //     panLeft,
  //     // Left pan is allowed to reach a min x of 0
  //     () => 0,
  //     // Left pan is allowed to reach a max x of the right pans x value
  //     // Note that we use parse(stringify()) as the current state is not available to this function
  //     () => JSON.parse(JSON.stringify(panRight)),
  //     (curX: number) => {
  //       curLeftXObs.next(curX);
  //     }
  //   )
  // ).current;
  // const panRightResponder = React.useRef(
  //   createPan(
  //     panRight,
  //     // Right pan is allowed to reach a min x of the left pans x value
  //     // Note that we use parse(stringify()) as the current state is not available to this function
  //     () => JSON.parse(JSON.stringify(panLeft)),
  //     // Right pan is allowed to reach a max of the track width subtracted a value
  //     // corresponding to the start position of the right pan. The start position is affected by the left
  //     // pans start position and width.
  //     () => sliderTrackwidthPx - markerDiameterPx * 2,
  //     (curX: number) => {
  //       curRightXObs.next(curX);
  //     }
  //   )
  // ).current;

  return (
    <StyledSelectRange margin={props.margin}>
      <StyledTitle>
        <>{props.title}</>

        <Button
          onPress={reset}
          color={'primary'}
          type={'clear'}
          noShadow={true}
        >
          Reset
        </Button>
      </StyledTitle>
      <InputBoxWrapper>
        <StyledInput
          value={selectedMin?.toString()}
          keyboardType={'numeric'}
          onChange={(val) => {
            const txt = val?.nativeEvent?.text;
            const numeric = +txt;
            if (numeric >= 0) {
              setSelectedMin(txt);
            } else if (!txt) {
              setSelectedMin('0');
            }
          }}
        ></StyledInput>
        <StyledInput
          value={selectedMax?.toString()}
          keyboardType={'numeric'}
          onChange={(val) => {
            const txt = val?.nativeEvent?.text;
            const numeric = +txt;
            if (numeric > props.maxValue) {
              setSelectedMax(props.maxValue.toString());
            } else if (numeric >= 0) {
              setSelectedMax(txt);
            } else if (!txt) {
              setSelectedMax('0');
            }
          }}
        ></StyledInput>
      </InputBoxWrapper>
      {/* <StyledSlider>
        <StyledSliderTrack>
          <Animated.View
            style={{
              transform: [{ translateX: panLeft }],
            }}
            {...panLeftResponder.panHandlers}
          >
            <MarkerWrapper>
              <StyledMarkerLabel> */}
      {/* We do not use the already stored selectedMin, as this variable is not updated live */}
      {/* {xToValue(curLeftX, 0)}
              </StyledMarkerLabel>
              <StyledMarker />
            </MarkerWrapper>
          </Animated.View>
          <Animated.View
            style={{
              transform: [{ translateX: panRight }],
            }}
            {...panRightResponder.panHandlers}
          >
            <MarkerWrapper>
              <StyledMarkerLabel> */}
      {/* We do not use the already stored selectedMax, as this variable is not updated live */}
      {/* {xToValue(curRightX, markerDiameterPx * 2)}
              </StyledMarkerLabel>
              <StyledMarker />
            </MarkerWrapper>
          </Animated.View>
        </StyledSliderTrack>
        <StyledSliderTrackHighlight
          widthPx={curRightX - curLeftX}
          leftPx={curLeftX}
        />
      </StyledSlider> */}
    </StyledSelectRange>
  );
}

/** Commented out CSS
  padding: 20px ${paddingLeftRightPx}px ${22 + markerDiameterPx / 2}px
    ${paddingLeftRightPx}px;
 */
const StyledSelectRange = styled.View<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.BRAND_WHITE};
  border: 1px solid ${Colors.BRAND_TERTIARY};
  width: ${widthPx}px;
  padding: 20px ${paddingLeftRightPx}px;
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const StyledTitle = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
`;

const InputBoxWrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
`;

const StyledInput = styled.TextInput`
  border: 1px solid ${Colors.BRAND_TERTIARY};
  width: 50px;
  text-size: 14px;
  padding: 5px;
`;

// const MarkerWrapper = styled.View`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   top: -${17 + markerDiameterPx / 2}px;
// `;
// const StyledMarker = styled.View`
//   top: -${markerDiameterPx / 2 - sliderTrackHeightPx / 2}px;
//   height: ${markerDiameterPx}px;
//   width: ${markerDiameterPx}px;
//   border-radius: 12px;
//   border-color: ${BRAND_SUCCESS}
//   border-width: 2px;
//   background-color: ${BRAND_WHITE};
//   cursor: grab;
//   z-index: 10;
// `;
// const StyledMarkerLabel = styled.Text`
//   display: 'block';
//   font-size: 10px;
//   font-weight: 500;
//   margin-bottom: ${markerDiameterPx / 2 + 6}px;
//   z-index: 20;
// `;

// const StyledSlider = styled.View`
//   width: 100%;
//   margin-top: 57px;
// `;

// const StyledSliderTrack = styled.View`
//   display: flex;
//   flex-direction: row;
//   height: ${sliderTrackHeightPx}px;
//   /* margin-left: ${(widthPx - sliderTrackwidthPx - paddingLeftRightPx * 2) /
//   2}px; */
//   /* widthPx is corregated such that correct edge padding is maintained when markers are in min/max */
//   width: ${sliderTrackwidthPx}px;
//   background-color: ${rgba(RGB_SUCCESS, 0.3)};
//   z-index: 0;
// `;
// const StyledSliderTrackHighlight = styled.View<{
//   widthPx: number;
//   leftPx: number;
// }>`
//   height: ${sliderTrackHeightPx}px;
//   background-color: ${BRAND_SUCCESS};
//   width: ${(props) => props.widthPx}px;
//   left: ${(props) =>
//     props.leftPx +
//     (widthPx - sliderTrackwidthPx - paddingLeftRightPx * 2) / 2 +
//     markerDiameterPx}px;
//   top: -${sliderTrackHeightPx}px;
//   z-index: 1;
// `;
