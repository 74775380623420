import {
  BaseDto,
  MinimalHealthRecordDto,
  HealthRecordDto,
  PatientDetailsDto,
  SearchHealthRecordRequestDto,
} from '@dermloop/api-dtos';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Task = {
  type: 'update-patient-details';
  healthRecordId: string;
};

export interface HealthRecordSlice {
  tasks: Task[];
  failedTasks: Task[];
  currentHealthRecord?: MinimalHealthRecordDto;
  patientDetails?: PatientDetailsDto;
  fetchingCurrentHealthRecord?: boolean;
  searchResult?: HealthRecordDto;
  searching?: boolean;
  fetchingHealthRecords?: boolean;
  recentHealthRecords?: HealthRecordDto[];
}

function cleanDto<T extends BaseDto>(dto: T) {
  if (!dto) return dto;

  dto.id = undefined;
  dto.created = undefined;
  dto.updated = undefined;
  dto.deleted = undefined;
  dto.createdByUser = undefined;
  dto.updatedByUser = undefined;
  dto.deletedByUser = undefined;

  return dto;
}

const initialState: HealthRecordSlice = { tasks: [], failedTasks: [] };

const slice = createSlice({
  name: 'healthRecord',
  initialState,
  reducers: {
    getHealthRecord: (state, _: PayloadAction<{ healthRecordId: string }>) => {
      return {
        ...state,
        fetchingCurrentHealthRecord: true,
        patientDetails: undefined,
      };
    },
    reloadHealthRecord: (state) => {
      return {
        ...state,
        fetchingCurrentHealthRecord: true,
        patientDetails: undefined,
      };
    },
    searchHealthRecord: (
      state,
      _: PayloadAction<{
        searchHealthRecordDto: SearchHealthRecordRequestDto;
        onSuccess?: (healthRecord: HealthRecordDto) => void;
      }>
    ) => {
      return { ...state, searching: true };
    },
    update: (state, action: PayloadAction<Partial<HealthRecordSlice>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updatePatientDetails: (
      state,
      action: PayloadAction<Partial<PatientDetailsDto>>
    ) => {
      let patientDetails: PatientDetailsDto;

      // Should update patientDetails and only on save or leaving page update currentHealthRecord.latestPatientDetails
      if (!state.patientDetails) {
        const temp = { ...state?.currentHealthRecord?.patientDetails };
        patientDetails = cleanDto(temp);
      } else {
        patientDetails = state.patientDetails;
      }

      return {
        ...state,
        patientDetails: { ...patientDetails, ...action.payload },
      };
    },
    fetchLatestHealthRecords: (
      state,
      _: PayloadAction<{ searchHealthRecords: SearchHealthRecordRequestDto }>
    ) => {
      return { ...state, fetchingHealthRecords: true };
    },
    persistPatientDetails: (
      state,
      action: PayloadAction<{
        details: PatientDetailsDto;
        healthRecordId: string;
      }>
    ) => {
      const isCreating = state.tasks.find(
        (t) =>
          t.healthRecordId === action.payload.healthRecordId &&
          t.type === 'update-patient-details'
      );
      if (isCreating) {
        return { ...state };
      }
      const newTasks: Task[] = [
        ...state.tasks,
        {
          healthRecordId: action.payload.healthRecordId,
          type: 'update-patient-details',
        },
      ];

      return {
        ...state,
        tasks: newTasks,
      };
    },
    persistedPatientDetails: (
      state,
      action: PayloadAction<{
        healthRecordId: string;
      }>
    ) => {
      return {
        ...state,
        tasks: state.tasks.filter(
          (t) =>
            t.healthRecordId !== action.payload.healthRecordId &&
            t.type === 'update-patient-details'
        ),
        failedTasks: state.failedTasks.filter(
          (t) =>
            t.healthRecordId !== action.payload.healthRecordId &&
            t.type === 'update-patient-details'
        ),
      };
    },
    error: (
      state,
      action: PayloadAction<{
        message: string;
        failedTask?: Task;
        error?: Error;
        stateUpdate?: Partial<HealthRecordSlice>;
      }>
    ) => {
      if (!action.payload.failedTask)
        return { ...state, searching: false, ...action.payload.stateUpdate };

      return {
        ...state,
        errorMessage: action.payload,
        searching: false,
        tasks: state.tasks.filter(
          (t) =>
            t.healthRecordId !== action.payload.failedTask?.healthRecordId &&
            t.type === action.payload.failedTask?.type
        ),
        failedTasks: [...state.failedTasks, action.payload.failedTask],
        ...action.payload.stateUpdate,
      };
    },
  },
});

export const healthRecordReducer = slice.reducer;
export const healthRecordActions = slice.actions;
