import {
  DAHTNumericalValue,
  DAHTNumericalValues,
  DAHTSecondOpinion,
} from '@dermloop/static-values';
import {
  IsBoolean,
  IsEnum,
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateIf,
} from 'class-validator';
import type { DiagnosisGroupDto } from '../diagnoses';
import type { DAHTLesionDto } from './lesion.dto';

export class UpdateDAHTAssessmentDto {
  @IsUUID()
  @IsNotEmpty()
  selectedDiagnosisGroupId: string;

  @IsOptional()
  selectedDiagnosisGroup?: DiagnosisGroupDto;

  @IsString()
  @IsOptional()
  otherDiagnosis: string;

  @IsNumber()
  @IsNotEmpty()
  timeSpentMs: number;

  @IsIn(DAHTNumericalValues)
  confidence: DAHTNumericalValue;

  @IsIn(DAHTNumericalValues)
  difficulty: DAHTNumericalValue;

  @IsNotEmpty()
  @IsEnum(DAHTSecondOpinion)
  secondOpinion: DAHTSecondOpinion;

  @IsNotEmpty()
  @IsBoolean()
  additionalStainsHelpful: boolean;

  @ValidateIf((o: UpdateDAHTAssessmentDto) => o.additionalStainsHelpful)
  @IsNotEmpty()
  @IsString()
  additionalStainsHelpfulDetails: string;

  @IsString()
  @IsOptional()
  comment: string;
}

export class DAHTAssessmentDto {
  id: string;
  order: number;
  assessedDate: Date;
  lesion: DAHTLesionDto;
  selectedDiagnosisGroupId?: string;
  selectedDiagnosisGroup?: DiagnosisGroupDto;
  otherDiagnosis?: string;
}
