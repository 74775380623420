import { PatientDetailsDto } from '@dermloop/api-dtos';
import { PatientHistoryKey } from '@dermloop/static-values';
import { useReducer } from 'react';

export enum PatientHistoryActionType {
  SetOpenControlGroup = 'set-open-control-group',
  ToggleOpenControlGroup = 'toggle-open-control-group',
  Reset = 'reset',
}

interface PatientHistoryAction {
  type: PatientHistoryActionType;
  payload?: { key: PatientHistoryKey };
}

type State = {
  openControlGroups: { [key in PatientHistoryKey]?: boolean };
  initialPatientHistory: PatientDetailsDto;
};

/**
 *
 * @param patientDetails Used to initialize control groups state
 * @returns state and dispatch function
 */
export function usePatientHistory(patientDetails: PatientDetailsDto) {
  const reducer = (state: State, action: PatientHistoryAction): State => {
    switch (action.type) {
      case 'set-open-control-group':
        return {
          openControlGroups: {
            ...state.openControlGroups,
            pregnancy:
              patientDetails?.pregnancyWithinLast6Months !== false &&
              !patientDetails?.pregnancyWithinLast6Months,
            'skin-cancer':
              patientDetails?.formerSkinCancer !== false &&
              !patientDetails?.formerSkinCancer,
            'family-history': patientDetails?.familyHistoryOfMelanoma == null,
            'risk-factors': patientDetails?.hasRiskFactors == null,
            'objective-findings': patientDetails?.hasObjectiveFindings == null,
          },
          initialPatientHistory: patientDetails,
        };
      case 'toggle-open-control-group':
        return {
          ...state,
          openControlGroups: {
            ...state.openControlGroups,
            [action.payload?.key]:
              !state.openControlGroups[action.payload?.key],
          },
        };
      case 'reset':
        return {
          openControlGroups: {},
          initialPatientHistory: null,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    openControlGroups: {
      pregnancy:
        patientDetails?.pregnancyWithinLast6Months !== false &&
        !patientDetails?.pregnancyWithinLast6Months,
      'skin-cancer':
        patientDetails?.formerSkinCancer !== false &&
        !patientDetails?.formerSkinCancer,
      'family-history': patientDetails?.familyHistoryOfMelanoma == null,
      'risk-factors': patientDetails?.hasRiskFactors == null,
      'objective-findings': patientDetails?.hasObjectiveFindings == null,
    },
    initialPatientHistory: patientDetails,
  });

  return {
    state,
    dispatch,
  };
}
