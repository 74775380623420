import {
  LatestLesionInfoDto,
  MinimalHealthRecordDto,
  UpdateLesionDto,
} from '@dermloop/api-dtos';
import { getStyledDate } from '@dermloop/shared';
import { Colors, Icons } from '@dermloop/ui/util';
import { useState } from 'react';
import styled from 'styled-components';
import LesionAvatar from '../lesion-avatar/lesion-avatar';
import ListItem from '../list-item/list-item';
import Seperator from '../seperator/seperator';
import React from 'react';
import NoScrollComponent from '../no-scroll-component/no-scroll-component';

export interface LesionListProps {
  title: string;
  lesions: LatestLesionInfoDto[];
  patient?: MinimalHealthRecordDto;
  selectedLesionUuid?: string;
  enableEditLocation?: boolean;
  getAction?: (lesion: LatestLesionInfoDto) => string;
  getActionDetailAndStatus?: (lesion: LatestLesionInfoDto) => string;
  getActionStatus?: (lesion: LatestLesionInfoDto) => string;
  getDiagnosis?: (lesion: LatestLesionInfoDto) => string;
  onSelectLesion: (lesionUuid: string) => void;
  onLesionUpdate?: (updatedLesion: UpdateLesionDto) => void;
}

const RightBordered = styled.div`
  border-right: 1px solid ${Colors.BRAND_GRAY_MEDIUM};
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const LesionListHeader = styled.div`
  display: flex;
  padding: 1rem;
  height: 100%;
  align-items: center;
  flex-direction: row;
  height: 60px;
`;

const LesionListContent = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  overflow: scroll;

  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;

  /* this will hide the scrollbar in webkit based browsers - safari, chrome, etc */
  ::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

export function LesionList(props: LesionListProps) {
  const [hoveredLesionUuid, setHoveredLesionUuid] = useState('');

  return (
    <RightBordered>
      {props.patient ? (
        <>
          <LesionAvatar
            onHoverLesion={(lesionUuid) => {
              setHoveredLesionUuid(lesionUuid);
            }}
            onSelectLesion={(lesionUuid) => {
              props.onSelectLesion(lesionUuid);
            }}
            selectedLesionUuid={props.selectedLesionUuid}
            hoveredLesionUuid={hoveredLesionUuid}
            allowEdit={props.enableEditLocation}
            patient={props.patient}
            onPositionSelected={(position) => {
              props.onLesionUpdate({ preciseLocation: position });
            }}
          />

          <Seperator />
        </>
      ) : null}
      <NoScrollComponent>
        {(ref) => <LesionListHeader ref={ref}>{props.title}</LesionListHeader>}
      </NoScrollComponent>
      <Seperator />
      <LesionListContent data-cy="lesionList">
        {props.lesions?.map((lesion, i) => {
          return (
            <ListItem
              selected={props.selectedLesionUuid === lesion.id}
              hovered={hoveredLesionUuid === lesion.id}
              key={lesion.id + ' ' + i}
              onClick={() => props.onSelectLesion(lesion.id)}
              onMouseOver={() => setHoveredLesionUuid(lesion.id)}
              onMouseOut={() => setHoveredLesionUuid('')}
              images={[
                {
                  src:
                    lesion.latestClinicalImage?.thumbnailSUrl ||
                    Icons.ClinicalImagePlaceholder,
                },
              ]}
              action={props.getAction(lesion)}
              actionDetailAndStatus={props.getActionDetailAndStatus(lesion)}
              date={getStyledDate(lesion?.updated)}
              title={`${lesion.creationOrder < 10 ? '#0' : '#'}${
                lesion.creationOrder
              } `}
              diagnosis={props.getDiagnosis(lesion)}
            />
          );
        })}
      </LesionListContent>
    </RightBordered>
  );
}
