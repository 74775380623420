import { LesionTriageClient } from '@dermloop/api-client';
import { UpdateLesionTriageDto } from '@dermloop/api-dtos';
import { Action } from '@reduxjs/toolkit';
import axios from 'axios';
import { Epic } from 'redux-observable';
import { combineLatest, EMPTY, from, of } from 'rxjs';
import {
  auditTime,
  buffer,
  concatMap,
  filter,
  groupBy,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { MinimalPatientState, MinimalState } from '../state';
import { lesionTriageActions } from './lesion-triage.slice';

const getLesionTriage: Epic<Action, Action, MinimalState> = (action$, state) =>
  action$.pipe(
    filter(lesionTriageActions.getCurrentLesionTriage.match),
    switchMap((action) => {
      return LesionTriageClient.getLesionTriage(action.payload.triageId);
    }),
    switchMap((response) => {
      if (axios.isAxiosError(response)) {
        return from([
          lesionTriageActions.error({
            message: response?.response?.statusText,
            error: response,
          }),
          lesionTriageActions.update({ fetchingCurrentLesionTriage: false }),
        ]);
      }

      return of(
        lesionTriageActions.update({
          currentLesionTriage: response.data,
          fetchingCurrentLesionTriage: false,
          fetchedCurrentLesionTriage: true,
        })
      );
    })
  );

const updateTriage: Epic<Action, Action, MinimalPatientState> = (
  action$,
  state
) =>
  action$.pipe(
    filter(lesionTriageActions.updateLesionTriage.match),
    groupBy((a) => a.payload.lesionUpdate.id),
    mergeMap((group) => group.pipe(buffer(group.pipe(auditTime(2000))))),
    tap((action) => console.log('Trigger epic', action)),
    concatMap((actions) => {
      const lesionUpdateId = actions[0]?.payload?.lesionUpdate?.id;
      if (!lesionUpdateId) {
        return EMPTY;
      }

      const filteredActions = actions?.filter(
        (a) => a.payload.lesionUpdate.id === lesionUpdateId
      );
      const resolvedIds = filteredActions?.map(
        (a) => a.payload.lesionUpdate.id
      );
      const update: UpdateLesionTriageDto = filteredActions.reduce(
        (acc, action) => {
          return {
            ...acc,
            ...action.payload.lesionUpdate,
          };
        },
        {}
      );

      return combineLatest([
        LesionTriageClient.updateLesionTriage(lesionUpdateId, update),
        of(resolvedIds),
      ]);
    }),
    switchMap(([response, ids]) => {
      if (axios.isAxiosError(response)) {
        const errorActions: Action[] = [
          lesionTriageActions.error({
            message: 'Your recent changes might not be saved correctly',
            error: response,
          }),
        ];
        //Only refresh if still on current lesion triage
        if (state.value?.lesionTriage?.currentLesionTriage?.id) {
          errorActions.push(
            lesionTriageActions.getCurrentLesionTriage({
              triageId: state.value.lesionTriage.currentLesionTriage.id,
            })
          );
        }

        return from(errorActions);
      }
      const pendingIds = [
        ...(state.value.lesionTriage?.pendingTriageUpdate || []),
      ];
      ids.forEach((id) => {
        pendingIds.length &&
          pendingIds.map((i) => i.id).indexOf(id) > -1 &&
          pendingIds.splice(pendingIds.map((i) => i.id).indexOf(id), 1);
      });

      return of(
        lesionTriageActions.update({ pendingTriageUpdate: pendingIds })
      );
    })
  );

const deleteLesionTriage: Epic<Action, Action> = (action$, state) =>
  action$.pipe(
    filter(lesionTriageActions.deleteLesionTriage.match),
    switchMap((action) => {
      return combineLatest([
        of(action.payload),
        LesionTriageClient.deleteLesionTriage(action.payload.lesionTriageId),
      ]);
    }),
    map(([{ lesionTriageId, lesionId, updateLesionTriages }, response]) => {
      if (axios.isAxiosError(response)) {
        return lesionTriageActions.error({
          message: response.response?.statusText,
          error: response,
          failedTask: {
            lesionTriageId: lesionTriageId,
            lesionId: lesionId,
            type: 'delete',
          },
        });
      }
      return lesionTriageActions.deletedLesionTriage({
        lesionId: lesionId,
        lesionTriageId: lesionTriageId,
        updateLesionTriages: updateLesionTriages,
      });
    })
  );

export const lesionTriageEpics = [
  getLesionTriage,
  deleteLesionTriage,
  updateTriage,
];
