import { LesionTriageDto } from '@dermloop/api-dtos';
import { isValidDiagnosisSelection } from '@dermloop/shared';
import {
  ClinicalAssessmentKey,
  DermloopReferralTypeEnum,
} from '@dermloop/static-values';
import { useReducer } from 'react';

export enum ClinicalEvaluationActionType {
  SetOpenControlGroup = 'set-open-control-group',
  ToggleOpenControlGroup = 'toggle-open-control-group',
  Reset = 'reset',
}

interface ClinicalEvaluationAction {
  type: ClinicalEvaluationActionType;
  payload?: { key: ClinicalAssessmentKey };
}

type State = {
  openControlGroups: { [key in ClinicalAssessmentKey]?: boolean };
  triageIsLoaded: boolean;
};

/**
 * Set triage loaded state and open control groups
 * @param lesionTriage Used to initialize control groups state
 * @param validDiagnosisSelection Used to initialize control groups state
 * @returns state and dispatch function
 */
export function useClinicalEvaluation(lesionTriage: LesionTriageDto) {
  const validDiagnosisSelection = isValidDiagnosisSelection(
    lesionTriage?.fieldConfiguration,
    lesionTriage?.diagnosisGroupIds,
    lesionTriage?.diagnosisId
  );

  const reducer = (state: State, action: ClinicalEvaluationAction): State => {
    switch (action.type) {
      case 'set-open-control-group':
        return {
          openControlGroups: {
            ...state.openControlGroups,
            'clinical-diagnosis':
              !validDiagnosisSelection || !lesionTriage?.difficultyId,
            'clinical-action':
              //Do not collapse if referral is not selected or if urgent referrals are allowed but not urgency is selected for tele referral.
              (!lesionTriage?.referralAction ||
                (lesionTriage?.referralAction &&
                  lesionTriage.fieldConfiguration.allowUrgentReferrals &&
                  lesionTriage?.referralAction?.referralType ===
                    DermloopReferralTypeEnum.Tele &&
                  typeof lesionTriage?.referralAction?.urgent !== 'boolean')) &&
              !lesionTriage?.plannedBiopsyAction &&
              !lesionTriage?.dismissalAction &&
              !lesionTriage?.requestedFollowUp?.followUpMonths &&
              !lesionTriage?.biopsyAction?.biopsyTypeId,
            inspection:
              !lesionTriage?.lesionDetails?.macroscopicInspection?.length,
            'clinical-age': !lesionTriage?.lesionDetails?.ageId,
            symptoms: !(
              lesionTriage?.lesionDetails?.symptoms?.length ||
              lesionTriage?.lesionDetails?.otherSymptoms ||
              lesionTriage?.lesionDetails?.hasSymptoms === false
            ),
            'appearance-changes': !(
              lesionTriage?.lesionDetails?.appearanceChanges?.length ||
              lesionTriage?.lesionDetails?.hasAppearanceChanges === false
            ),
            'former-treatments': !(
              lesionTriage?.lesionDetails?.treatments?.length ||
              lesionTriage?.lesionDetails?.otherTreatments ||
              lesionTriage?.lesionDetails?.hasTreatments === false
            ),
            'additional-images': lesionTriage?.newImageRegistration,
          },
          triageIsLoaded: true,
        };
      case 'toggle-open-control-group':
        return {
          ...state,
          openControlGroups: {
            ...state.openControlGroups,
            [action.payload?.key]:
              !state.openControlGroups[action.payload?.key],
          },
        };
      case 'reset':
        return {
          openControlGroups: {},
          triageIsLoaded: false,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    openControlGroups: {},
    triageIsLoaded: false,
  });

  return {
    state,
    dispatch,
  };
}
