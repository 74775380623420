import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
  ValidateNested,
} from 'class-validator';
import { LevelDto } from './level.dtos';

/**
 * DTO representing a single course entity for listing.
 */
export interface CourseDto {
  id: string;
  title: string;
  description: string;
  duration: string;
  published?: boolean;
  courseNumber: number;
  courseStarted?: boolean;
  courseCompleted?: boolean;
  courseProgress?: number;
  userCourseId?: string;
  levels: LevelDto[];
}

export interface UserCourseDto {
  id: string;
  completed: boolean;
  completedDate?: Date;
}

/**
 * DTO for starting a course.
 */
export class StartCourseDto {
  @IsNotEmpty()
  @IsUUID()
  courseId: string;

  // If true, forces a new UserCourse creation, even if it already exists
  @IsOptional()
  @IsBoolean()
  forceNewUserCourse?: boolean;
}

export class CreateCourseDto {
  @IsNotEmpty()
  @IsString()
  title: string;

  @IsNotEmpty()
  @IsString()
  description: string;

  @IsNotEmpty()
  @IsString()
  duration: string;

  @IsOptional()
  @IsBoolean()
  published?: boolean;
}

export class UpdateCourseDto {
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  title?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  description?: string;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  duration?: string;

  @IsOptional()
  @IsBoolean()
  published?: boolean;
}

/**
 * DTO for changing order of courses.
 */
export class ChangeCourseOrderDto {
  @Type(() => CourseOrderDto)
  @ValidateNested({ each: true })
  courses: CourseOrderDto[];
}

/**
 * DTO for changing the order of a single course.
 */
export class CourseOrderDto {
  @IsNotEmpty()
  @IsUUID()
  courseId: string;

  @IsNotEmpty()
  @IsNumber()
  @Min(1)
  courseNumber: number;
}

/**
 * DTO for changing course publish state.
 */
export class CoursePublishDto {
  @IsNotEmpty()
  @IsUUID()
  courseId: string;

  @IsBoolean()
  published: boolean;

  // If true, all levels and lessons are also updated
  @IsBoolean()
  updateLevelsAndLessons: boolean;
}
