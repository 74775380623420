import { Colors } from '@dermloop/ui/util';
import { useState } from 'react';
import styled from 'styled-components/native';
import Button from '../button/button';
import Checkbox from '../checkbox/checkbox';
import { SearchBar } from '../search-bar/search-bar';

/* eslint-disable-next-line */
export interface SelectSearchProps {
  // Title to display in top of component
  title: string;
  // Text to show in searchbar
  searchbarText: string;
  // List of entities that are selectable
  options: SelectSearchOption[];
  selectedIds: string[];
  // If true, allows for selecting multiple elements
  multiSelect: boolean;
  // selectedIds is a list of the ids currently selected on click
  // Function to be called when option is selected
  // If multiSelect is false, is given a list containing one element
  onSelect: (selectedOptionIds: string[]) => void;
  // Optional margin to give to select search
  margin?: string;
}
export type SelectSearchOption = {
  name: string;
  id: string;
  // If an element should be pre-selected
  selected?: boolean;
};

const SelectSearchStyle = styled.View<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.BRAND_WHITE};
  border: 1px solid ${Colors.BRAND_TERTIARY};
  margin: ${(props) => props.margin || '0'}
    ${(props) => `margin: ${props.margin};` || ''};
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
`;

const SearchbarStyle = styled.View``;

const OptionListSelectedHeaderStyle = styled.Text`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const OptionListSelectedStyle = styled.View`
  margin-top: 15px;
  padding-bottom: 18px;
  box-shadow: 0px 1px 0px ${Colors.rgba(Colors.RGB_BLACK, 0.25)};
`;

const OptionListNotSelectedStyle = styled.View<{ hasSelectedValues: boolean }>`
  margin-top: 15px;
`;

const CheckboxStyle = styled.View<{ firstCheckbox: boolean }>`
  margin-top: ${(props) => (props.firstCheckbox ? 0 : 16)}px;
`;

const StyledOptionsWrapper = styled.View`
  overflow-y: auto;
  max-height: 300px;
`;

export function SelectSearch(props: SelectSearchProps) {
  // Initial state consists of pre-selected ids

  // Search query, always stored as lowercase
  const [searchQueryLower, setSearchQueryLower] = useState('');

  // const hasSelectedOptions = !!selectedOptions?.length;
  // const selectedOptionIdsSet = new Set(
  //   selectedOptions.map((option) => option.id)
  // );

  /**
   * When the selected options are changed, call the prop onSelect function.
   */
  // useEffect(() => {
  //   props.onSelect(selectedOptions);
  // }, [selectedOptions]);

  const onSelect = (option: SelectSearchOption) => () => {
    // Determine if option is already selected
    const alreadySelected = props.selectedIds?.includes(option.id);

    // New selected options array
    let newSelectedOptions: string[] = null;

    if (!props.multiSelect) {
      if (alreadySelected) {
        // If not multiselect and already selected, remove selection
        newSelectedOptions = [];
      } else {
        // If not multiselect and not selected, set selection to this option
        newSelectedOptions = [option?.id];
      }
    } else {
      if (alreadySelected) {
        // If multiselect and already selected, remove option
        newSelectedOptions = props.selectedIds?.filter(
          (selectedOptionId) => selectedOptionId !== option?.id
        );
      } else {
        // If multiselect and not already selected, append to selected options
        newSelectedOptions = [...(props.selectedIds || []), option?.id];
      }
    }

    // Update state
    props.onSelect(newSelectedOptions);
  };

  // Function that lowercases search query and sets search query state
  const handleSearchQueryChange = (query) =>
    setSearchQueryLower(query.toLowerCase());

  /**
   * Reset the current selection.
   */
  const reset = () => {
    props.onSelect([]);
  };

  return (
    <SelectSearchStyle margin={props.margin}>
      <SearchbarStyle>
        <SearchBar
          placeholder={props.searchbarText}
          onTypeSearch={handleSearchQueryChange}
          onSearch={handleSearchQueryChange}
          onSearchClear={() => handleSearchQueryChange('')}
        ></SearchBar>
      </SearchbarStyle>
      <StyledOptionsWrapper>
        {props.selectedIds?.length ? (
          <OptionListSelectedStyle>
            <OptionListSelectedHeaderStyle>
              Filter
              <Button
                onPress={reset}
                color={'primary'}
                type={'clear'}
                noShadow={true}
              >
                Reset
              </Button>
            </OptionListSelectedHeaderStyle>
            {props.options
              // Filter to selected options
              .filter((option) => props.selectedIds?.includes(option?.id))
              // Map to checkboxes
              .map((option, index) => (
                <CheckboxStyle key={option.id} firstCheckbox={index === 0}>
                  <Checkbox
                    id={option.id}
                    text={option.name}
                    selected={true}
                    onPress={onSelect(option)}
                  ></Checkbox>
                </CheckboxStyle>
              ))}
          </OptionListSelectedStyle>
        ) : null}

        <OptionListNotSelectedStyle
          hasSelectedValues={!!props.selectedIds?.length}
        >
          <OptionListSelectedHeaderStyle>
            {props.title}
          </OptionListSelectedHeaderStyle>
          {props.options
            // Filter to non-selected options
            .filter((option) => !props.selectedIds?.includes(option?.id))
            // Filter to search query
            .filter((option) => {
              // If no search query, nothing to filter
              if (!searchQueryLower) return true;

              return option.name.toLowerCase().includes(searchQueryLower);
            })
            // Map to checkboxes
            .map((option, index) => (
              <CheckboxStyle key={option.id} firstCheckbox={index === 0}>
                <Checkbox
                  id={option.id}
                  text={option.name}
                  selected={false}
                  onPress={onSelect(option)}
                ></Checkbox>
              </CheckboxStyle>
            ))}
        </OptionListNotSelectedStyle>
      </StyledOptionsWrapper>
    </SelectSearchStyle>
  );
}

export default SelectSearch;
