import {
  authenticationReducer,
  rtkQueryErrorHandlingMiddleware,
} from '@dermloop/redux';
import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { dermloopApi } from './api';

const rootReducer = combineReducers({
  [dermloopApi.reducerPath]: dermloopApi.reducer,
  authentication: authenticationReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
const persistConfig = {
  key: 'root',
  whitelist: ['application'],
  storage,
};

const epicMiddleware = createEpicMiddleware<Action, Action, RootState>();
export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      dermloopApi.middleware,
      epicMiddleware,
      rtkQueryErrorHandlingMiddleware,
    ]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export default store;
