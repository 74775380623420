import { DiagnosisDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const diagnosisEndpoints = (builder: EndpointBuilderType) => ({
  getDiagnoses: builder.query<DiagnosisDto[], void>({
    query: () => `diagnoses`,
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result, error, arg) => [{ type: 'DIAGNOSES', id: 'LIST' }],
  }),
});
