import { Colors } from '@dermloop/ui/util';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface SeperatorProps {}

const StyledSeperator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.BRAND_GRAY_MEDIUM};
`;

export function Seperator(props: SeperatorProps) {
  return <StyledSeperator />;
}

export default Seperator;
