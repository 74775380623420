export enum DermloopUserRoleEnum {
  LearnUser = 'LearnUser', // Users with access to learn
  Manager = 'Manager', // Intended for executive users and all developers
  MedicalUnitManager = 'MedicalUnitManager', // Executive users of a medical unit, sort of like a medical unit admin
  MedicalUnitParticipant = 'MedicalUnitParticipant', // User that is part of any medical unit
  Editor = 'Editor', // Users that have access to editing training lesions
  Reviewer = 'Reviewer', // Intended for users with access to view all training lesions and propose changes that must be approved by managers or possibly editors
  Admin = 'Admin', // Intended for select developers
  MedicalUnitPathologist = 'MedicalUnitPathologist', // Medical unit role specifically for pathologiests
  MedicalUnitTeledermatologist = 'MedicalUnitTeledermatologist', // Medical unit role specifically for teledermotologists
  MedicalUnitTeledermatologistUrgent = 'MedicalUnitTeledermatologistUrgent', // Additional role for teledermatologists handling urgent referrals. Needs to be applied together with MedicalUnitTeledermatologistUrgent
}

export const DermloopMedicalUnitRoles: DermloopUserRoleEnum[] = [
  DermloopUserRoleEnum.MedicalUnitParticipant,
  DermloopUserRoleEnum.MedicalUnitManager,
  DermloopUserRoleEnum.MedicalUnitPathologist,
  DermloopUserRoleEnum.MedicalUnitTeledermatologist,
  DermloopUserRoleEnum.MedicalUnitTeledermatologistUrgent,
];

export type DermloopMedicalUnitRole = typeof DermloopMedicalUnitRoles[number];

/**
 * Display names for user roles. Used for displaying user roles in the UI to end users.
 */
export const DermloopUserRoleEnumDisplayNames: {
  [key in DermloopUserRoleEnum]: string;
} = {
  [DermloopUserRoleEnum.LearnUser]: 'Learn User',
  [DermloopUserRoleEnum.Manager]: 'Manager',
  [DermloopUserRoleEnum.MedicalUnitManager]: 'Admin',
  [DermloopUserRoleEnum.MedicalUnitParticipant]: 'Clinician',
  [DermloopUserRoleEnum.Editor]: 'Editor',
  [DermloopUserRoleEnum.Reviewer]: 'Reviewer',
  [DermloopUserRoleEnum.Admin]: 'Dermloop Admin',
  [DermloopUserRoleEnum.MedicalUnitPathologist]: 'Pathologist',
  [DermloopUserRoleEnum.MedicalUnitTeledermatologist]: 'Teledermatologist',
  [DermloopUserRoleEnum.MedicalUnitTeledermatologistUrgent]:
    'Tele (inc. cancer pathways)',
};

/**
 * Additional user roles, that end users cannot assign to users.
 * Used for displaying user roles in the UI to end users that are
 * considered "special".
 */
export const AdditionalMedicalUnitRoles: DermloopUserRoleEnum[] = [
  DermloopUserRoleEnum.MedicalUnitManager,
  DermloopUserRoleEnum.MedicalUnitPathologist,
  DermloopUserRoleEnum.MedicalUnitTeledermatologist,
];
