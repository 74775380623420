import { Colors } from '@dermloop/ui/util';
import styled from 'styled-components';

export const MediumGray = styled.span`
  white-space: pre-wrap;
  color: ${Colors.BRAND_GRAY_MEDIUM};
`;

export const Secondary = styled.span`
  white-space: pre-wrap;
  color: ${Colors.BRAND_SECONDARY};
`;

export const Colored = {
  /** Colored.MediumGray */
  MG: MediumGray,
  SECONDARY: Secondary,
};

// export function Colored(props: ColoredProps) {
//   return <StyledColored />;
// }
