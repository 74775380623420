import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import type { DiagnosisDto } from '../diagnoses';
import type { DiagnosisGroupDto } from '../diagnoses/diagnosis-group.dto';
import { LesionImageDto } from '../lesion-info';
import { ModelViewerLocation } from '../model-viewer-location.dto';

export class ClinicalAssessmentAssessmentDto {
  @IsOptional()
  @IsString()
  id?: string;

  @IsOptional()
  @IsString()
  type?: 'pathology' | 'tele';

  @IsOptional()
  @ValidateNested()
  diagnosis?: DiagnosisDto;

  @IsOptional()
  @ValidateNested()
  secondaryDiagnosis?: DiagnosisDto;

  @IsOptional()
  @ValidateNested()
  tertiaryDiagnosis?: DiagnosisDto;

  @IsOptional()
  @IsString()
  proposedClinicalAction?: string;
}

export class ClinicalAssessmentsSearchDto {
  @IsOptional()
  @IsNumber()
  take?: number;

  @IsOptional()
  @IsNumber()
  skip?: number;
}

export class ClinicalAssessmentDto {
  @IsNotEmpty()
  @IsUUID('all')
  lesionId: string;

  @IsOptional()
  @ValidateNested()
  preciseLocation?: ModelViewerLocation;

  @IsOptional()
  @IsString()
  gender?: string;

  @IsOptional()
  @IsNumber()
  age?: number;

  @IsNotEmpty()
  @IsUUID('all')
  triageId: string;

  @IsNotEmpty()
  @IsUUID('all')
  triageCreatedByUserId: string;

  @IsOptional()
  @IsString()
  triageCreated?: string;

  @IsOptional()
  @IsString()
  triageUpdated?: string;

  @IsOptional()
  @ValidateNested()
  assessment?: ClinicalAssessmentAssessmentDto;

  @IsOptional()
  @ValidateNested()
  latestDiagnosisGroup?: DiagnosisGroupDto;

  @IsOptional()
  @ValidateNested()
  latestDiagnosis?: DiagnosisDto;

  @IsOptional()
  @ValidateNested()
  latestDifferentialDiagnosisGroup?: DiagnosisGroupDto;

  @IsOptional()
  @ValidateNested()
  latestDifferentialDiagnosis?: DiagnosisDto;

  @IsOptional()
  @IsBoolean()
  correctDiagnosis?: boolean;

  @IsOptional()
  @IsString()
  latestAction?: string;

  @IsOptional()
  @IsString()
  latestActionDetails?: string;

  @ValidateNested()
  latestDermoscopicImage: LesionImageDto;

  @ValidateNested()
  userDermoscopicImage: LesionImageDto;
}
