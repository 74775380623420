import 'reflect-metadata';
import { IsIn, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { createAndValidateEnvironment } from './environment.utils';

const envTypes = ['development', 'test', 'production', 'ci'] as const;
export class BaseEnvironment {
  @IsNotEmpty()
  @IsString()
  @IsIn(envTypes)
  ENV!: typeof envTypes[number];

  @IsNotEmpty()
  @IsString()
  API_URL!: string;

  @IsOptional()
  @IsString()
  SENTRY_DSN?: string;
}

export const baseEnvironment = createAndValidateEnvironment(BaseEnvironment);
