import { forwardRef, useState } from 'react';
import styled from 'styled-components/native';

import {
  BORDER_RADIUS,
  Colors,
  GENERAL_SPACING,
  INPUT_HEIGHT,
} from '@dermloop/ui/util';
import {
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  TextInput,
  TextInputFocusEventData,
} from 'react-native';
import { BaseInputProps, ValidationState } from '../base-input-props';
import InputContainer from '../input-container/input-container';
import { cprMask } from '../input-mask/cpr-mask';
import { MaskType } from '../input-mask/mask-type';
import { specimenIdMask } from '../input-mask/specimen-id-mask';

/* eslint-disable-next-line */
export interface TextFieldProps extends BaseInputProps {
  value?: string;
  disabled?: boolean;
  mask?: MaskType;
  onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  keyboardType?: KeyboardTypeOptions;
  onValueChange?: (value: string) => void;
  autocomplete?: 'email' | 'password' | 'username' | 'tel' | 'tel-country-code';
}

export const TextField = forwardRef<TextInput, TextFieldProps>((props, ref) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <InputContainer
      label={props.label}
      validationMessage={props.validationMessage}
      validationState={props.validationState}
      hasFocus={hasFocus}
      hasValue={!!props.value}
    >
      <StyledTextInput
        ref={ref}
        validationState={props.validationState}
        value={applyMask(props.value, props.mask)}
        focused={hasFocus}
        keyboardType={props.keyboardType}
        placeholderTextColor={
          props.validationState === 'error'
            ? Colors.BRAND_WARNING
            : Colors.BRAND_SECONDARY
        }
        focusable={!props.disabled}
        disabled={props.disabled}
        editable={!props.disabled}
        selectTextOnFocus={!props.disabled}
        autoComplete={props.autocomplete}
        onFocus={(event) => {
          if (!props.disabled) setHasFocus(true);
          props.onFocus?.(event);
        }}
        secureTextEntry={props.autocomplete === 'password'}
        onBlur={(e) => {
          setHasFocus(false);
          props.onBlur?.(e);
        }}
        placeholder={!hasFocus ? props.label : undefined}
        onChange={(e) => {
          props.onValueChange?.(applyMask(e.nativeEvent.text, props.mask));
        }}
      />
    </InputContainer>
  );
});

export default TextField;

function applyMask(value: string, mask: MaskType) {
  switch (mask) {
    case 'cpr':
      return cprMask(value);
    case 'specimenId':
      return specimenIdMask(value);
    default:
      return value;
  }
}

const StyledTextInput = styled(TextInput)<{
  focused?: boolean;
  disabled?: boolean;
  validationState?: ValidationState;
}>`
border: 1px solid
    ${(props) =>
      props.validationState === 'error'
        ? Colors.BRAND_WARNING
        : props.focused
        ? Colors.BRAND_INFO
        : Colors.BRAND_SECONDARY};
  padding: ${0.25 * GENERAL_SPACING}px ${GENERAL_SPACING}px;
  border-radius: ${BORDER_RADIUS}px;
  width: 100%;
  height:${INPUT_HEIGHT}px;
  outline-color: ${(props) =>
    props.disabled
      ? Colors.BRAND_SECONDARY
      : props.validationState === 'error'
      ? Colors.BRAND_WARNING
      : Colors.BRAND_INFO};
  color: ${(props) =>
    props.disabled
      ? Colors.BRAND_SECONDARY
      : props.validationState === 'error'
      ? Colors.BRAND_WARNING
      : Colors.TEXT};
}

`;
