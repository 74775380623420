import {
  Colors,
  GENERAL_SPACING,
  HEADING_FONT_SIZE,
  MEDIUM_WEIGHT,
  NATIVE_BORDER_WIDTH,
} from '@dermloop/ui/util';
import styled from 'styled-components/native';
import { Button } from '../button/button';

/* eslint-disable-next-line */
export interface PanelHeaderProps {
  title: string;
  // If editable, shows the progress. Otherwise it displays the "edit" button
  editable: boolean;
  // If true, the panel won't display the "edit" button
  readOnly?: boolean;
  // Optional entityId. Must be set if entityIdList is set
  entityId?: string;
  // Optional entity id list, used for finding current indexing
  entityIdList?: string[];
  // Optional current index. Can be set if entityIdList is not set
  index?: number;
  // Optional max index. Can be set if entityIdList is not set
  maxIndex?: number;
  onPress?: () => void;
  shadow?: boolean;
  // If true, "editable" should also be true to display the progress
  showProgress?: boolean;
}

export function PanelHeader(props: PanelHeaderProps) {
  const index = props.entityIdList?.length
    ? props.entityIdList.indexOf(props.entityId) + 1
    : props.index;
  const length = props.entityIdList?.length
    ? props.entityIdList.length
    : props.maxIndex;
  return (
    <StyledPanelHeader shadow={props.shadow}>
      <PanelStatus>
        <PanelStatusTitle>{props.title}</PanelStatusTitle>
        {props.readOnly ? null : props.editable && props.showProgress ? (
          <PanelWrapperIndexStatus>
            <PanelStatusIndex>{index}</PanelStatusIndex>
            <PanelStatusSeparator>/</PanelStatusSeparator>
            <PanelStatusTotal>{length}</PanelStatusTotal>
          </PanelWrapperIndexStatus>
        ) : !props.editable ? (
          <Button
            onPress={() =>
              !props.editable && props.onPress ? props.onPress() : null
            }
            type={'outline'}
            color={'primary'}
            disabled={props.editable}
            display="inline-narrow"
          >
            Edit
          </Button>
        ) : null}
      </PanelStatus>
      {props.editable && props.showProgress && !props.readOnly && (
        <ProgressBarContainer>
          <ProgressBar index={index} length={length} />
        </ProgressBarContainer>
      )}
    </StyledPanelHeader>
  );
}

export default PanelHeader;

const StyledPanelHeader = styled.View<{ shadow: boolean }>`
  shadow-color: ${Colors.BRAND_BLACK};
  shadow-offset: 0 2px;
  shadow-radius: 5px;
  shadow-opacity: ${(props) => (props.shadow ? 0.25 : 0)};
  margin-bottom: ${(props) => (props.shadow ? 10 : 0)}px;
  background-color: ${Colors.BRAND_WHITE};
  border-bottom-width: ${({ shadow }) => (shadow ? 0 : NATIVE_BORDER_WIDTH)}px;
  border-bottom-color: ${Colors.BRAND_GRAY_MEDIUM};
`;

const PanelStatus = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${GENERAL_SPACING}px;
`;
const PanelStatusTitle = styled.Text`
  font-size: ${HEADING_FONT_SIZE}px;
  font-weight: ${MEDIUM_WEIGHT};
`;
const PanelStatusIndex = styled.Text`
  font-size: ${HEADING_FONT_SIZE}px;
  font-weight: ${MEDIUM_WEIGHT};
  color: ${Colors.BRAND_BLACK};
`;
const PanelStatusSeparator = styled(PanelStatusIndex)`
  color: ${Colors.BRAND_TERTIARY};
`;
const PanelStatusTotal = styled.Text`
  font-size: 14px;
  font-weight: ${MEDIUM_WEIGHT};
  color: ${Colors.BRAND_TERTIARY};
`;
const PanelWrapperIndexStatus = styled.View`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ProgressBarContainer = styled.View`
  background-color: ${Colors.LIGHT_BRAND_SUCCESS};
  width: 100%;
`;

const ProgressBar = styled.View<{ index: number; length: number }>`
  display: absolute;
  left: 0;
  background-color: ${Colors.BRAND_SUCCESS};
  height: 4px;
  width: ${({ index, length }) => (index / length) * 100}%;
`;
