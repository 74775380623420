import {
  BUTTON_BORDER_RADIUS,
  Colors,
  GENERAL_SPACING,
} from '@dermloop/ui/util';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { Option } from '../multi-option-picker/multi-option-picker';
import Typography from '../typography/typography';
import { webSpecificValue } from '../utils';

export interface RangePointOption<T> extends Option<T> {
  value: T;
  name: string;
  label?: string;
}

/* eslint-disable-next-line */
export interface RangePointPickerProps<T> {
  selectedValue?: T;
  onOptionSelected: (option?: RangePointOption<T>) => void;
  range: RangePointOption<T>[];
  noBorder?: boolean;
  readonly?: boolean;
}

const StyledRangePointPicker = styled.View<{ noBorder: boolean }>`
  border-top-color: ${Colors.BRAND_GRAY_MEDIUM};
  border-top-width: ${(props) => (props.noBorder ? 0 : 1)}px;
  padding: ${(props) => (props.noBorder ? 0 : GENERAL_SPACING)}px
    ${GENERAL_SPACING}px ${2 * GENERAL_SPACING}px ${GENERAL_SPACING}px;
  ${webSpecificValue(`z-index: auto;`)};
`;

const StyledRangeContainer = styled.View`
  ${webSpecificValue(`z-index: auto;`)};
`;

const StyledRangeLine = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2px;
  background-color: ${Colors.BRAND_TERTIARY};
`;

const StyledRangeLineLabel = styled.Text``;
const StyledRangeLineLabels = styled.View`
  padding: ${GENERAL_SPACING}px 0 ${2 * GENERAL_SPACING}px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledRangePoint = styled.Pressable<{
  active: boolean;
  readonly: boolean;
}>`
  width: 20px;
  height: 20px;
  top: -10px;
  border-radius: ${BUTTON_BORDER_RADIUS}px;
  background-color: ${(props) =>
    props.active ? Colors.BRAND_SUCCESS : Colors.BRAND_WHITE};
  border-color: ${(props) =>
    props.active ? Colors.BRAND_WHITE : Colors.BRAND_TERTIARY};
  border-width: 2px;
  ${(props) =>
    webSpecificValue(`cursor: ${props.readonly ? 'default' : 'pointer'};`)};
`;

/**
 *
 * @param props
 */
export function RangePointPicker<T>(props: RangePointPickerProps<T>) {
  if (!props.range) return null;

  return (
    <StyledRangePointPicker noBorder={props.noBorder}>
      <StyledRangeContainer>
        <StyledRangeLineLabels>
          {props.range.map((option, index) => {
            return (
              <StyledRangeLineLabel key={index}>
                {option?.name}
              </StyledRangeLineLabel>
            );
          })}
          {/* <StyledRangeLineLabel>{props.range[0]?.name}</StyledRangeLineLabel>
          <StyledRangeLineLabel>
            {props.range[props.range.length - 1]?.name}
          </StyledRangeLineLabel> */}
        </StyledRangeLineLabels>

        <StyledRangeLine>
          {props.range &&
            props.range?.map((o, index) => {
              return (
                <View
                  key={index}
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <StyledRangePoint
                    onPress={() =>
                      !props.readonly &&
                      (props.selectedValue !== o?.value
                        ? props.onOptionSelected(o)
                        : props.onOptionSelected(null))
                    }
                    hitSlop={8}
                    active={o.value === props.selectedValue}
                    key={index}
                    readonly={props.readonly}
                  />
                  {o.label ? (
                    <Typography size="small" color="medium-light">
                      {o.label}
                    </Typography>
                  ) : null}
                </View>
              );
            })}
        </StyledRangeLine>
      </StyledRangeContainer>
    </StyledRangePointPicker>
  );
}

export default RangePointPicker;
