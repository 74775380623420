export type ImageType =
  | 'Clinical'
  | 'Dermoscopy'
  | 'Follow-up dermoscopy'
  | 'Overview'
  | 'Close up';

export const PhotoTypes = [
  'Clinical',
  'Dermoscopy',
  'Follow-up dermoscopy',
  'Overview',
  'Close up',
] as const;
export type PhotoType = typeof PhotoTypes[number];
