import { DiagnosticDifficultyDto } from '@dermloop/api-dtos';
import {
  CLINICAL_DETAILS_CACHE_TIME_SECONDS,
  EndpointBuilderType,
} from '../constants';

export const diagnosticDifficutlyEndpoints = (
  builder: EndpointBuilderType
) => ({
  getDiagnosticDifficulty: builder.query<DiagnosticDifficultyDto[], void>({
    query: () => 'diagnostic-difficulties',
    keepUnusedDataFor: CLINICAL_DETAILS_CACHE_TIME_SECONDS,
    providesTags: (result) => [{ type: 'DIAGNOSTIC_DIFFICULTIES', id: 'LIST' }],
  }),
});
