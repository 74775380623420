import {
  CreateMedicalUnitUserAffiliationDto,
  DeleteMedicalUnitUserAffiliationResultDto,
  MedicalUnitUserAffiliationDto,
  SearchMedicalUnitUserAffiliationDto,
  UpdateMedicalUnitUserAffiliationDto,
} from '@dermloop/api-dtos';
import { EndpointBuilderType } from '../constants';
import { DermloopBaseApiTag } from '../tag-types';

const baseUrl = 'medical-unit-user-affiliations';

export const medicalUnitUserAffiliationEndpoints = (
  builder: EndpointBuilderType
) => ({
  getMedicalUnitUserAffiliation: builder.query<
    MedicalUnitUserAffiliationDto,
    { id: string }
  >({
    query: ({ id }) => `${baseUrl}/${id}`,
    providesTags: (result, error, arg) => {
      if (!result?.id) return [];
      return [{ type: 'MEDICAL_UNIT_USER_AFFILIATIONS', id: result.id }];
    },
  }),
  searchMedicalUnitUserAffiliations: builder.query<
    MedicalUnitUserAffiliationDto[],
    SearchMedicalUnitUserAffiliationDto
  >({
    query: (data) => ({
      url: `${baseUrl}/search`,
      method: 'POST',
      body: data,
    }),
    providesTags: [{ type: 'MEDICAL_UNIT_USER_AFFILIATIONS', id: 'LIST' }],
  }),
  createMedicalUnitUserAffiliation: builder.mutation<
    MedicalUnitUserAffiliationDto,
    { data: CreateMedicalUnitUserAffiliationDto }
  >({
    query: ({ data }) => ({
      url: baseUrl,
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result, error, arg) => [
      { type: 'MEDICAL_UNIT_USER_AFFILIATIONS', id: 'LIST' },
    ],
  }),
  updateMedicalUnitUserAffiliation: builder.mutation<
    MedicalUnitUserAffiliationDto,
    { id: string; data: UpdateMedicalUnitUserAffiliationDto }
  >({
    query: ({ id, data }) => ({
      url: `${baseUrl}/${id}`,
      method: 'PUT',
      body: data,
    }),
    invalidatesTags: (result, error, arg) => {
      const res: { type: DermloopBaseApiTag; id: string }[] = [
        { type: 'MEDICAL_UNIT_USER_AFFILIATIONS', id: 'LIST' },
      ];
      if (!result?.id) {
        return res;
      }

      return [
        ...res,
        { type: 'MEDICAL_UNIT_USER_AFFILIATIONS', id: result.id },
      ];
    },
  }),
  deleteMedicalUnitUserAffiliation: builder.mutation<
    DeleteMedicalUnitUserAffiliationResultDto,
    { id: string }
  >({
    query: ({ id }) => ({
      url: `${baseUrl}/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, arg) => [
      { type: 'MEDICAL_UNIT_USER_AFFILIATIONS' },
    ],
  }),
  acceptMedicalUnitUserAffiliationInvitation: builder.mutation<
    MedicalUnitUserAffiliationDto,
    { id: string }
  >({
    query: ({ id }) => ({
      url: `${baseUrl}/${id}/accept-invitation`,
      method: 'POST',
    }),
    invalidatesTags: (result, error, arg) => [
      { type: 'MEDICAL_UNIT_USER_AFFILIATIONS' },
    ],
  }),
  rejectMedicalUnitUserAffiliationInvitation: builder.mutation<
    DeleteMedicalUnitUserAffiliationResultDto,
    { id: string }
  >({
    query: ({ id }) => ({
      url: `${baseUrl}/${id}/reject-invitation`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, arg) => [
      { type: 'MEDICAL_UNIT_USER_AFFILIATIONS' },
    ],
  }),
  getPendingMedicalUnitUserAffiliationInvitations: builder.query<
    MedicalUnitUserAffiliationDto[],
    void
  >({
    query: () => `${baseUrl}/pending-invitations`,
    providesTags: (result, error, arg) => [
      { type: 'MEDICAL_UNIT_USER_AFFILIATIONS', id: 'PENDING_INVITATIONS' },
    ],
  }),
});
