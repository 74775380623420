import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { Colors } from '@dermloop/ui/util';
import Typography from '../typography/typography';
import ReAnimated, {
  SharedValue,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated';

/* eslint-disable-next-line */
export interface ProgressBarProps {
  /**
   * Progress value must be between 0-1
   */
  staticProgress?: number;
  progress?: SharedValue<number>;
  roundBorder?: boolean;
  showProgress?: boolean;
  showPercent?: boolean;
  maxValue?: number;
  progressColor?: 'success' | 'white';
}

const ProgressBarWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

const StyledProgressBar = styled.View<ProgressBarProps>`
  border-radius: ${(props) => (props.roundBorder ? '999px' : '0')};
  background-color: ${(props) =>
    props.progressColor === 'success'
      ? Colors.LIGHT_BRAND_SUCCESS
      : Colors.HOVER_PRIMARY_BUTTON};
  height: 10px;
  flex: 1;
`;
const StyledProgressIndicator = styled(ReAnimated.View)<ProgressBarProps>`
  border-radius: ${(props) => (props.roundBorder ? '999px' : '0')};
  height: 100%;
  background: ${({ progressColor }) =>
    progressColor === 'success' ? Colors.BRAND_SUCCESS : Colors.BRAND_WHITE};
  opacity: ${(props) => (props.progress || props.staticProgress ? 1.0 : 0)};
`;

/**
 *
 * @param staticProgress  static value between 0-1 animated withSpring
 * @param progress        animated value between 0-1
 * @param roundBorder     round the border of the progress bar
 * @param showProgress    show the progress value
 * @param progressColor   color of the progress bar
 * @param props
 * @returns
 */
export function ProgressBar(props: ProgressBarProps) {
  const [cardWidth, setCardWidth] = React.useState(0);
  const progressBarWidthAnimated = useAnimatedStyle(() => {
    if (props.progress) {
      return {
        width: props.progress.value * cardWidth,
      };
    } else {
      return {
        width: withSpring(props.staticProgress * cardWidth, {
          overshootClamping: true,
          stiffness: 50,
        }),
      };
    }
  });

  const maxValue = props.maxValue || 10;
  const progressAbsolute = Math.round(
    props.progress
      ? props.progress.value * maxValue
      : props.staticProgress * maxValue
  );

  const progressText = `${progressAbsolute.toString()}/${maxValue}`;
  return (
    <ProgressBarWrapper>
      <StyledProgressBar
        {...props}
        onLayout={(event) => setCardWidth(event.nativeEvent.layout.width)}
      >
        <StyledProgressIndicator
          {...props}
          style={[progressBarWidthAnimated]}
        />
      </StyledProgressBar>
      {props.showProgress ? (
        <View style={{ paddingLeft: 5 }}>
          <Typography size="medium-small" color="medium">
            {props.showPercent
              ? `${((progressAbsolute / maxValue) * 100).toString()}%`
              : progressText}
          </Typography>
        </View>
      ) : null}
    </ProgressBarWrapper>
  );
}

export default ProgressBar;
