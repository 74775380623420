import { ReactElement, ReactNode, useEffect } from 'react';
import ReactDomServer from 'react-dom/server';
import ReactToolTip from 'react-tooltip';
import styled from 'styled-components';

type OnClickFunctionType = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;

export interface TooltipProps {
  // Tooltip text. Can be a string or a react element. The react element will be rendered as string
  tooltip: string | ReactElement;

  // If true, hides the tooltip
  hide?: boolean;

  // React node to render as the tooltip target
  children: ReactNode;

  // Optional onclick method for the tooltip target. If provided, mouse will be pointer above target
  onClick?: OnClickFunctionType;
}

export function Tooltip(props: TooltipProps) {
  useEffect(() => {
    ReactToolTip.rebuild();
  }, []);

  return (
    <StyledTooltip
      data-html={typeof props.tooltip !== 'string'}
      data-tip={
        typeof props.tooltip === 'string'
          ? props.tooltip
          : ReactDomServer.renderToStaticMarkup(props.tooltip)
      }
      data-tip-disable={props.hide}
      onClick={props.onClick}
    >
      {props.children}
    </StyledTooltip>
  );
}

const StyledTooltip = styled.div<{ onClick: OnClickFunctionType }>`
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')}
`;
