import { LesionDto, UpdateLesionDto } from '@dermloop/api-dtos';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type LesionSliceTask = {
  lesionId: string;
  type:
    | 'upload-clinical-image'
    | 'upload-dermoscopic-image'
    | 'update'
    | 'delete';
};

export interface LesionSlice {
  currentLesion?: LesionDto;
  currentLesionId?: string;
  fetchingCurrentLesion?: boolean;
  imageCompareOverlay?: 'dermoscopic' | 'clinical';
  lesionTasks?: LesionSliceTask[];
  lesionFailedTasks?: LesionSliceTask[];
}

const initialState: LesionSlice = {
  lesionTasks: [],
  lesionFailedTasks: [],
};

const slice = createSlice({
  name: 'lesion',
  initialState,
  reducers: {
    getCurrentLesionHistory: (
      state,
      action: PayloadAction<{ lesionId: string }>
    ) => {
      return { ...state, currentLesionHistory: undefined };
    },
    getCurrentLesion: (state, action: PayloadAction<{ lesionId: string }>) => {
      return {
        ...state,
        fetchingCurrentLesion: true,
        currentLesion:
          state?.currentLesion?.id !== action.payload.lesionId
            ? null
            : state.currentLesion,
        currentLesionId: action.payload.lesionId,
      };
    },
    update: (state, action: PayloadAction<Partial<LesionSlice>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateLesion: (
      state,
      _: PayloadAction<{ lesionId: string; update: UpdateLesionDto }>
    ) => {
      return state;
    },
    deleteLesion: (
      state,
      action: PayloadAction<{
        lesionId: string;
        healthRecordId: string;
        skipTasks?: boolean;
      }>
    ) => {
      const isDeleting = state.lesionTasks.find(
        (t) => t.lesionId === action.payload.lesionId && t.type === 'delete'
      );
      if (isDeleting || action.payload.skipTasks) {
        return { ...state };
      }
      const newTasks: LesionSliceTask[] = [
        ...state.lesionTasks,
        {
          lesionId: action.payload.lesionId,
          type: 'delete',
        },
      ];
      return {
        ...state,
        lesionTasks: newTasks,
        lesionFailedTasks: state.lesionFailedTasks.filter(
          (ft) =>
            ft.lesionId === action.payload.lesionId && ft.type === 'delete'
        ),
      };
    },
    deletedLesion: (
      state,
      action: PayloadAction<{
        lesionId: string;
      }>
    ) => {
      return {
        ...state,
        lesionTasks: state.lesionTasks.filter(
          (t) => t.lesionId !== action.payload.lesionId && t.type === 'delete'
        ),
        lesionFailedTasks: state.lesionFailedTasks.filter(
          (t) => t.lesionId !== action.payload.lesionId && t.type === 'delete'
        ),
      };
    },
    error: (
      state,
      action: PayloadAction<{
        message: string;
        failedTask?: LesionSliceTask;
        error?: Error;
      }>
    ) => {
      if (!action.payload.failedTask)
        return {
          ...state,
          errorMessage: action.payload.message,
          error: action.payload.error,
        };
      return {
        ...state,
        errorMessage: action.payload.message,
        error: action.payload.error,
        lesionTasks: state.lesionTasks.filter(
          (t) =>
            t.lesionId !== action.payload.failedTask?.lesionId &&
            t.type === action.payload.failedTask?.type
        ),
        lesionFailedTasks: [
          ...state.lesionFailedTasks,
          action.payload.failedTask,
        ],
      };
    },
  },
});

export const lesionReducer = slice.reducer;
export const lesionActions = slice.actions;
