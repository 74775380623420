// import { HttpStatus } from '@nestjs/common';
import { Logger } from '@dermloop/shared';
import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';

/**
 * Middleware for handling rtk query errors from API.
 * @param api
 * @returns
 */
export const rtkQueryErrorHandlingMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.log('ERROR', action);
      Logger.error({
        message: `Error occured: ${action.payload?.data?.message}`,
        error: action.payload?.data?.error,
        extras: { payload: action.payload, arg: action?.meta?.arg },
      });
    }

    return next(action);
  };
