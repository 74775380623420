import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { BaseDto } from '../base.dto';
import { UserDto } from '../user.dto';

export class CreateDismissalDto {
  @IsNotEmpty()
  @IsString()
  medicalUnitId: string;
}

export class UpdateDismissalDto {
  @IsOptional()
  @IsNotEmpty()
  @IsString()
  medicalUnitId: string;
}

export class DismissalDto implements BaseDto {
  id: string;
  createdByUser?: UserDto;
  updatedByUser?: UserDto;
  deletedByUser?: UserDto;
  created?: string;
  updated?: string;
  deleted?: string;

  @IsNotEmpty()
  @IsString()
  medicalUnitId: string;
}
