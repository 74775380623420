import {
  DermloopClinicalActionTypeEnum,
  DiagnosisStatusEnum,
} from '@dermloop/static-values';
import {
  IsArray,
  IsBoolean,
  IsEnum,
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  MaxLength,
  ValidateNested,
} from 'class-validator';
import { BaseDto } from '../base.dto';
import { DiagnosisGroupDto } from '../diagnoses/diagnosis-group.dto';
import { DiagnosisDto } from '../diagnoses/diagnosis.dto';
import { MedicalUnitDto } from '../medical-unit.dtos';
import { ModelViewerLocation } from '../model-viewer-location.dto';
import {
  HealthRecordDto,
  MinimalHealthRecordDto,
} from '../patient/health-record.dtos';
import { LesionIndexDataDto } from '../search';
import { LesionImageDto, LesionImageUrlsDto } from './lesion-image.dtos';
import { LesionTriageDto } from './lesion-triage.dtos';

export class CreateLesionDto {
  @IsOptional()
  @IsString()
  preciseLocation?: string;

  @IsNotEmpty()
  @IsString()
  healthRecordId: string;
}

export enum SearchLesionDtoUser {
  All = 'All',
  Own = 'Own',
  MedicalUnit = 'MedicalUnit',
}

export enum SearchLesionDtoAction {
  Dismiss = 'dissmiss',
  TeledermatologicalReferral = 'tele',
  Biopsy = 'biopsy',
  FollowUp = 'follow-up',
}

export interface SearchLesionDto {
  take?: number;
  users?: SearchLesionDtoUser;
  actions?: DermloopClinicalActionTypeEnum[];
  status?: DiagnosisStatusEnum;
  query?: string;
  skip?: number;
}

export class SearchLesionDto implements SearchLesionDto {
  @IsOptional()
  @IsNumber()
  @Max(30)
  take?: number;

  @IsOptional()
  @IsIn([SearchLesionDtoUser.MedicalUnit, SearchLesionDtoUser.Own])
  users?: SearchLesionDtoUser;

  @IsOptional()
  @IsEnum(DiagnosisStatusEnum)
  status?: DiagnosisStatusEnum;

  @IsOptional()
  @IsBoolean()
  correct?: boolean;

  @IsOptional()
  @IsUUID('all', { each: true })
  diagnosisIds?: string[];

  @IsOptional()
  @IsEnum(DermloopClinicalActionTypeEnum, { each: true })
  @IsArray()
  actions?: DermloopClinicalActionTypeEnum[];

  // SSN query
  @IsOptional()
  @IsString()
  @MaxLength(11)
  query?: string;

  @IsOptional()
  @IsNumber()
  skip?: number;

  // If true, includes clinical image urls in response
  @IsOptional()
  @IsBoolean()
  includeImageUrls?: boolean;
}

export class UpdateLesionDto {
  @IsOptional()
  @ValidateNested()
  preciseLocation?: ModelViewerLocation;
}

export class LesionDto extends BaseDto {
  // latest images in DB
  latestClinicalImage?: LesionImageDto;
  latestDermoscopicImage?: LesionImageDto;

  latestLesionTriage?: LesionTriageDto;

  lesionTriages?: LesionTriageDto[];

  @IsOptional()
  @IsBoolean()
  complete?: boolean;

  @IsOptional()
  @ValidateNested()
  preciseLocation?: ModelViewerLocation;

  @IsNotEmpty()
  @IsNumber()
  creationOrder: number;

  @IsNotEmpty()
  @IsString()
  healthRecordId: string;

  healthRecord: HealthRecordDto;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  medicalUnitId?: string;

  medicalUnit?: MedicalUnitDto;

  dermoscopicImages?: LesionImageDto[];

  clinicalImages?: LesionImageDto[];
}

export class LatestLesionInfoDto implements BaseDto {
  id: string;
  created?: string;
  updated?: string;
  createdByUserId?: string;

  @IsOptional()
  @IsString()
  latestTriageCreatedByUserId?: string;

  latestClinicalImage: LesionImageDto;

  @IsBoolean()
  complete: boolean;

  @IsOptional()
  @IsNotEmpty()
  preciseLocation?: ModelViewerLocation;

  @IsOptional()
  @IsNotEmpty()
  @IsString()
  healthRecordId: string;

  @IsString()
  latestTriageId: string;

  @IsString()
  latestAction: string;

  @IsString()
  latestActionDetails: string;

  @IsNotEmpty()
  @IsString()
  lesionLatestDiagnosisName: string;

  @IsOptional()
  @IsBoolean()
  isTriageDiagnosed?: boolean;

  @IsOptional()
  @IsBoolean()
  canBeDeleted?: boolean;

  @IsNotEmpty()
  @IsNumber()
  creationOrder: number;
}
export type AssessmentType = 'pathology' | 'tele' | 'clinical';
export interface AssessmentDto {
  type: AssessmentType;
  action?: DermloopClinicalActionTypeEnum;
  actionDetails?: string;
  diagnosis?: DiagnosisDto;
  diagnosisGroup?: DiagnosisGroupDto;
  differentialDiagnosis?: DiagnosisDto;
  differentialDiagnosisGroup?: DiagnosisGroupDto;
  secondaryDiagnosis?: DiagnosisDto;
  secondaryDiagnosisGroup?: DiagnosisGroupDto;
  createdDate: string;
  isComplete?: boolean;
}

export class LesionSearchResultDto {
  @IsString()
  @IsNotEmpty()
  lesionId: string;

  @IsNotEmpty()
  healthRecord: MinimalHealthRecordDto;

  @IsOptional()
  @IsNotEmpty()
  preciseLocation?: ModelViewerLocation;

  @IsOptional()
  latestAssessment?: AssessmentDto;

  @IsOptional()
  latestUserAssessment?: AssessmentDto;

  @IsOptional()
  @IsBoolean()
  latestUserAssessmentCorrect?: boolean;

  @IsOptional()
  pendingAssessment?: AssessmentType[];

  @IsOptional()
  latestDermoscopicImage?: LesionImageDto;

  @IsOptional()
  latestClinicalImage?: LesionImageDto;

  @IsOptional()
  userLatestDermoscopicImage?: LesionImageDto;

  @IsOptional()
  userLatestClinicalImage?: LesionImageDto;
}

/**
 * DTO for lesion search results. Includes all data of lesoin index data dto,
 * and optional urls.
 */
export interface LesionSearch2ResultDto extends LesionIndexDataDto {
  urls?: LesionImageUrlsDto;
}
