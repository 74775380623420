import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BiopsyTypeDto,
  TreatmentDto,
  SymptomDto,
  ApproximateAgeDto,
  AppearanceChangeDto,
  SkinTypeDto,
  GenderDto,
  MacroscopicInspectionTypeDto,
  DiagnosisDto,
  DiagnosisGroupDto,
  DiagnosticDifficultyDto,
  RiskFactorDto,
  ObjectiveFindingDto,
  NamedEntityDto,
  MedicalUnitDto,
} from '@dermloop/api-dtos';

export type ClinicalDetailsSlice = {
  biopsyTypes?: BiopsyTypeDto[];
  treatments?: TreatmentDto[];
  symptoms?: SymptomDto[];
  diagnosticDifficulties?: DiagnosticDifficultyDto[];
  approximateAges?: ApproximateAgeDto[];
  appearanceChanges?: AppearanceChangeDto[];
  skinTypes?: SkinTypeDto[];
  genders?: GenderDto[];
  macroscopicInspectionTypes?: MacroscopicInspectionTypeDto[];
  riskFactors?: RiskFactorDto[];
  objectiveFindings?: ObjectiveFindingDto[];
  diagnoses?: DiagnosisDto[];
  locations?: NamedEntityDto[];
  defaultDiagnosisGroup?: DiagnosisGroupDto;
  defaultDiagnosisIdentifiers?: string[];
  medicalUnits: MedicalUnitDto[];
};

const initialState: ClinicalDetailsSlice = {
  biopsyTypes: [],
  treatments: [],
  symptoms: [],
  approximateAges: [],
  appearanceChanges: [],
  skinTypes: [],
  genders: [],
  macroscopicInspectionTypes: [],
  riskFactors: [],
  objectiveFindings: [],
  diagnoses: [],
  locations: [],
  defaultDiagnosisIdentifiers: [
    'benign_nevus',
    'seborrheic_keratosis',
    'dermatofibroma',
    'actinic_keratosis',
    'squamous_cell_carcinoma',
    'basal_cell_carcinoma',
    'hemangioma',
    'unspecified_melanoma',
  ],
  medicalUnits: [],
};

const slice = createSlice({
  name: 'clinicalDetail',
  initialState,
  reducers: {
    fetchDefaultValues: (state) => {
      return {
        ...state,
      };
    },
    update: (
      state,
      action: PayloadAction<{ update: Partial<ClinicalDetailsSlice> }>
    ) => {
      return { ...state, ...action.payload?.update };
    },
    setCurrentLesion: (state, _: PayloadAction<{ lesionUuid: string }>) => {
      return state;
    },
  },
});

export const clinicalDetailActions = slice.actions;
export const clinicalDetailReducer = slice.reducer;
