/**
 * Custom exception codes. Must be of format:
 * XYZZ
 * X = Exception type
 * Y = Exception sub-type
 * ZZ = Specific exception
 */
export enum ExceptionCodeEnum {
  /**
   * User errors
   */
  UserMustResetPassword = '1000',
  UserInvalidRoles = '1001',
  /**
   * MFA exception codes
   */
  MfaRequired = '4000',
  MfaMissingPhonenumber = '4001',
  MfaExpiredOtp = '4002',
  MfaOtpAlreadySent = '4003',
  MfaOtpTooManyAttempts = '4004',
}
